import Dwelling from 'interfaces/dwelling'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingField from 'interfaces/dwelling/field'
import Image from 'interfaces/image'
import Project from 'interfaces/project'
import { uniqBy } from 'lodash'
import DwellingInfoFieldType from 'types/dwelling/info-field-type'
import format from 'utils/format'
import {
  build as buildNomenclatures,
  match as matchNomenclatures,
} from 'utils/nomenclature'
import { normalizeToNumber, normalizeToString } from '../normalization'

export const getAvailableDwellings = (project: Project): DwellingExtended[] => {
  const dwellings = getExtendedDwellings(project)

  if (!dwellings?.length) {
    return []
  }

  return dwellings.filter((dwelling) => isDwellingStatusAvailable(dwelling))
}

export const getUnknownDwellings = (project: Project): Dwelling[] => {
  const dwellings = project.dwellings?.promotion?.dwellings

  if (!dwellings?.length) {
    return []
  }

  return dwellings.filter((dwelling) => isDwellingStatusUnknown(dwelling))
}

export const isDwellingStatusAvailable = (dwelling: Dwelling) => {
  return normalizeToNumber(dwelling.statusCode) === 1
}

export const isDwellingStatusUnknown = (dwelling: Dwelling) => {
  return normalizeToNumber(dwelling.statusCode) === 0
}

/**
 * Extends the Dwelling object with extra location related
 * properties extracted from the promotion parent object.
 */
export const getExtendedDwellings = (project: Project) => {
  const dwellings = project.dwellings?.promotion?.dwellings

  if (!dwellings?.length) {
    return []
  }

  let extendedDwellings: DwellingExtended[] = []
  for (const dwelling of dwellings as DwellingExtended[]) {
    dwelling.project = project.name
    dwelling.location = project.dwellings?.promotion?.city
      ? project.dwellings?.promotion.city.name
      : false
    dwelling.constructionType = project.dwellings?.promotion
      ?.promotionContructionType
      ? project.dwellings?.promotion.promotionContructionType.name
      : false

    extendedDwellings.push(dwelling)
  }

  return extendedDwellings
}

export const getProjectsExtendedDwellings = (projects: Project[]) => {
  let extendedDwellings: DwellingExtended[] = []

  for (const project of projects) {
    extendedDwellings.push(...getExtendedDwellings(project))
  }

  return extendedDwellings
}

export const getProjectsExtendedDwellingsById = (
  projects: Project[],
  ids: Dwelling['id'][]
) =>
  getProjectsExtendedDwellings(projects).filter((extendedDwelling) =>
    ids.includes(extendedDwelling.id)
  )

export const getDwellingInfoFieldValue = (
  field: DwellingField,
  type: DwellingInfoFieldType,
  dwellings?: Dwelling[]
): React.ReactNode => {
  const suffixSpan =
    !!field.value && !!field.suffix ? (
      <span
        dangerouslySetInnerHTML={{
          __html: ` ${field.suffix}`,
        }}
      />
    ) : null

  switch (type) {
    case 'normal':
    case 'sum':
      if (typeof field.value === 'undefined') {
        console.warn(
          `A DwellingInfoField with type of 'normal' or 'sum' was used without providing a field.value`
        )
      }

      let value = field.value || '-'
      value = field.format
        ? format(field.format, normalizeToString(value))
        : value

      return (
        <span>
          {value} {suffixSpan}
        </span>
      )

    case 'range':
      if (!dwellings || !field.key) {
        return null
      }

      const values = dwellings
        .map((dwelling) => dwelling[field.key!])
        .filter(
          (value): value is number =>
            typeof value === 'number' && !isNaN(value) && value > 0
        )

      const min = field.format
        ? format(field.format, Math.min(...values))
        : Math.min(...values)
      const max = field.format
        ? format(field.format, Math.max(...values))
        : Math.max(...values)

      return (
        <span>
          {min || '-'} {suffixSpan} to {max || '-'} {suffixSpan}
        </span>
      )

    default:
      return null
  }
}

/**
 * Populates the field `value` property with
 * the appropriate value, using the key.
 *
 * Also formats the value, and hides `price` keyed values.
 */
export const populateDwellingFieldValue = (
  field: DwellingField,
  dwelling: Dwelling,
  dwellingEnabled: boolean
) => {
  if (!field.key) {
    console.warn('Tried populating a field value without a key property')
    return field
  }

  const value = dwelling[field.key]
    ? normalizeToString(dwelling[field.key])
    : ''

  field.value = field.format ? format(field.format, value) : value

  // Only show prices for enabled dwellings.
  if (field.key === 'price' && !dwellingEnabled) {
    field.value = '-'
  }

  return field
}

/**
 * Matching of dwellings and their images (by typology) is made
 * loosely removing separators from both strings/string arrays
 * and then doing the comparison as openly as possible.
 *
 * This is being done this way because the dwelling's `typology`
 * field is being used by Odoo to construct the commercial naming
 * of each unit, changing it's content from just including the
 * typology name to something like "V12 - Klimt" which by
 * itself matches nothing.
 */
export const getDwellingsMediaByTypology = (
  dwellings: Dwelling[],
  images: Image[]
) => {
  const separators = ['-', ' ']
  const regExp = new RegExp(`[${separators.join('')}]`, 'g')

  const dwellingsImages = dwellings.flatMap((dwelling) => {
    const dwellingImages = images.filter((image) => {
      if (!dwelling.typology || !image.typologies) {
        return false
      }

      const dwellingTypologyToken = (dwelling.typology as string)
        .replace(regExp, '')
        .toLowerCase()
      const imageTypologiesTokens = image.typologies.map((typology) =>
        typology.replace(regExp, '').toLowerCase()
      )

      for (const imageTypologiesToken of imageTypologiesTokens) {
        if (dwellingTypologyToken.includes(imageTypologiesToken)) {
          return true
        }
      }

      return false
    })

    return dwellingImages
  })

  return uniqBy(dwellingsImages, (image) => {
    return image.url
  })
}

export const getDwellingsMediaByNomenclature = (
  dwellings: Dwelling[],
  images: Image[],
  nomenclatureSegment?: string
) => {
  if (!images.length) {
    return []
  }

  const dwellingsImages = dwellings.flatMap((dwelling) => {
    const dwellingImages = images.filter((image) => {
      const nomenclatures = buildNomenclatures(dwelling)
      return matchNomenclatures(image.url, nomenclatures, nomenclatureSegment)
    })

    return dwellingImages
  })

  return uniqBy(dwellingsImages, (image) => {
    return image.url
  })
}
