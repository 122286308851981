import { useQuery } from '@tanstack/react-query'
import { errorMessages } from 'constants/errors'
import { localStorageKeys } from 'constants/local-storage'
import { queryKeys } from 'constants/queries'
import OdooAccountMeResponse from 'interfaces/odoo/account/me-response'
import { getSSOToken } from 'utils/sso/token'

type TQueryFnData = OdooAccountMeResponse
type TError = Error

const useOdooAccountMe = () => {
  // This staleTime avoids re-renders in essential parts (like the PhotoSwipe lightbox).
  const staleTime = Infinity

  /**
   * First get the token as a prerequisite for Me's query.
   */
  const { data: token } = useQuery(queryKeys.ssoToken, getSSOToken, { staleTime })

  return useQuery<TQueryFnData, TError>(
    queryKeys.me,
    async (): Promise<TQueryFnData> => {
      const response = await fetch(
        import.meta.env.VITE_ODOO_ACCOUNTS_API_ME_ENDPOINT,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (!response.ok) {
        throw new Error(errorMessages.odooAccountMe)
      }

      const data: TQueryFnData = await response.json()

      // Update local storage last read + changelog last read with whatever is in the account.
      if (data.metadata?.last_read) {
        localStorage.setItem(localStorageKeys.lastRead, data.metadata.last_read)
      }
      if (data.metadata?.changelog_last_read) {
        localStorage.setItem(
          localStorageKeys.changelogLastRead,
          data.metadata.changelog_last_read
        )
      }

      return data
    },
    {
      enabled: !!token,
      staleTime,
    }
  )
}

export default useOdooAccountMe
