import { LoadingButton } from '@mui/lab'
import { Alert, Box, Collapse, Grid, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useQueryClient } from '@tanstack/react-query'
import ShareProjectStrips from 'components/molecules/share/ShareProjectStrips'
import { mailSubjects, mailTemplates } from 'constants/account/mail'
import { errorMessages } from 'constants/errors'
import { partnerCategories } from 'constants/partner'
import { queryKeys } from 'constants/queries'
import AccountRoleContext from 'contexts/AccountRoleContext'
import ProjectFocusContext from 'contexts/ProjectFocusContext'
import useOdooAccountCreate from 'hooks/odoo/account/useOdooAccountCreate'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useOdooApiUpdate from 'hooks/odoo/api/useOdooApiUpdate'
import useAccountInvitationLink from 'hooks/useAccountInvitationLink'
import useMail from 'hooks/useMail'
import useProjectPermissions from 'hooks/useProjectPermissions'
import useProjects from 'hooks/useProjects'
import Account from 'interfaces/account'
import Project from 'interfaces/project'
import { useContext, useEffect, useState } from 'react'
import AccountRole from 'types/account/role'
import {
  getAccountRoleFormatted,
  getAccountShareableRoles,
} from 'utils/account/role'
import { epoch } from 'utils/date'
import { getAvailableDwellings } from 'utils/dwelling'
import isEmail from 'validator/lib/isEmail'

// TODO: Refactor this. Split? Maybe extract handleInvite to a hook or something.

const ShareProjectsForm = () => {
  const queryClient = useQueryClient()
  const { data: me } = useOdooAccountMe()
  const { mutateAsync: create } = useOdooAccountCreate()
  const { mutateAsync: update } = useOdooApiUpdate()
  const { generate: generateInvitationLink } = useAccountInvitationLink()
  const { send } = useMail()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [role, setRole] = useState<AccountRole>('client')

  const { data: projects } = useProjects()
  const { value: focusedProject } = useContext(ProjectFocusContext)
  const { permissions, changePermissions } = useProjectPermissions(
    /**
     * Determines initial project permissions.
     */
    (() => {
      const initialProjects: Project[] = []

      if (focusedProject) {
        initialProjects.push(focusedProject)
      } else {
        initialProjects.push(
          // Only projects with available dwellings.
          ...projects.filter(
            (project) => !!getAvailableDwellings(project).length
          )
        )
      }

      return initialProjects.map((project) => project.slug.current)
    })()
  )

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('')

    const value = e.target.value
    setIsEmailValid(isEmail(value))
    setEmail(value)
  }

  const handleRoleChange = (e: SelectChangeEvent) => {
    setRole(e.target.value as AccountRole)
  }

  /**
   * Invitation process:
   * - Create the account, disabled.
   * - Generate an invitation link to enable it.
   * - Send mails.
   * - Invalidate the accounts query, updating it.
   */
  const handleInvite = async () => {
    setLoading(true)

    try {
      // Create the account, disabled.
      const metadata: Partial<Account['metadata']> = {
        enabled: false,
        invited: epoch(),
        invited_by: me?.email ?? 'Unknown',
        role,
        projects: role === 'admin' ? [] : permissions.map((slug) => ({ slug })),
      }
      const response = await create({ email, metadata })

      if (!response?.created) {
        throw new Error(errorMessages.odooAccountCreate)
      }

      // Add some tags to the account (res.partner).
      const categoryIds = [partnerCategories.dashboardUser]
      switch (role) {
        case 'agent':
        case 'partner':
          categoryIds.push(partnerCategories.agent)
          break
      }
      await update({
        model: 'res.partner',
        id: response.id,
        data: { category_id: categoryIds },
      })

      // Generate the invitation link.
      const invitationLink = await generateInvitationLink(
        response.id,
        email,
        role
      )

      /**
       * Send mails:
       * - Send a mail notifying of the account creation with the invitation link.
       * - Send a mail notifying the sender also with the invitation link.
       */
      const mailSent = await send(
        [email],
        mailSubjects.accountInvited,
        mailTemplates.accountInvited,
        {
          link: invitationLink,
          year: new Date().getFullYear(),
        }
      )

      if (!mailSent) {
        throw new Error(errorMessages.mail)
      }

      if (me?.email) {
        await send(
          [me.email],
          mailSubjects.accountInvitedSender,
          mailTemplates.accountInvitedSender,
          {
            email,
            link: invitationLink,
            year: new Date().getFullYear(),
          }
        )
      } else {
        console.warn(
          `Sender's email is unknown, therefore a confirmation mail couldn't be sent`
        )
      }

      // Invalidate the accounts query, updating it.
      queryClient.invalidateQueries(queryKeys.accounts)

      setSuccess(`Invitation sent successfully to ${email}`)
    } catch (error: any) {
      setError(error.message)
    }

    setLoading(false)
  }

  /**
   * Hide both the 'success' and the 'error' messages when certain things change.
   */
  useEffect(() => {
    setSuccess('')
    setError('')
  }, [permissions, email])

  if (!me) {
    return null
  }

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="email"
              type="email"
              label="Email"
              value={email}
              error={!isEmailValid && email !== ''}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                size="small"
                labelId="role-select-label"
                id="role-select"
                value={role}
                label="Role"
                onChange={handleRoleChange}
              >
                {getAccountShareableRoles(me).map((role, index) => (
                  <MenuItem key={index} value={role}>
                    {getAccountRoleFormatted(role)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <LoadingButton
              disableElevation
              fullWidth
              variant="contained"
              disabled={
                !isEmailValid || (!permissions.length && role !== 'admin')
              }
              onClick={handleInvite}
              loading={loading}
            >
              Invite
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>

      <Collapse in={error !== ''} timeout="auto">
        <Alert sx={{ marginBottom: 3 }} severity="error">
          {error}
        </Alert>
      </Collapse>

      <Collapse in={error === '' && success !== ''} timeout="auto">
        <Alert
          sx={{ marginBottom: 3 }}
          severity="success"
          onClose={() => setSuccess('')}
        >
          {success}
        </Alert>
      </Collapse>

      <AccountRoleContext.Provider value={role}>
        <ShareProjectStrips
          permissions={permissions}
          changePermissions={changePermissions}
        />
      </AccountRoleContext.Provider>
    </>
  )
}

export default ShareProjectsForm
