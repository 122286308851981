import { useParams } from 'react-router-dom'
import useProjects from './useProjects'

/**
 * Tries getting the current project using the
 * slug in the URL or the project name if provided.
 * 
 * @param name Project name.
 * @param unfiltered See useProjects' `unfiltered` param.
 * @returns The project, or `undefined` if not ready.
 */
const useProject = (name?: string, unfiltered = false) => {
  const { projectSlug } = useParams()
  const { data: projects } = useProjects(unfiltered)

  const project = projects.find((project) =>
    name ? project.name === name : project.slug.current === projectSlug
  )

  return project
}

export default useProject
