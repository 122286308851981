import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Dwelling from 'interfaces/dwelling'
import { useEffect, useState } from 'react'
import DwellingSortOrder from 'types/dwelling/sort-order'

export type DwellingsSortProps = {
  columnKey: keyof Dwelling
  sortKey: keyof Dwelling | null
  onChange: (key: keyof Dwelling, order: DwellingSortOrder) => void
}

const DwellingsSort = ({
  columnKey,
  sortKey,
  onChange,
}: DwellingsSortProps) => {
  const theme = useTheme()
  const [order, setOrder] = useState<DwellingSortOrder>(null)

  const active = columnKey === sortKey

  const handleClick = () => {
    switch (order) {
      case null:
        setOrder('asc')
        break
      case 'asc':
        setOrder('desc')
        break
      case 'desc':
        setOrder(null)
        break
    }
  }

  useEffect(() => {
    onChange(columnKey, order)
  }, [columnKey, onChange, order])

  return (
    <IconButton
      onClick={handleClick}
      size="small"
      sx={[
        {
          opacity: theme.palette.action.disabledOpacity / 2,
        },
        active &&
          order !== null && {
            opacity: 1,
          },
      ]}
    >
      {(order === null || order === 'asc') && (
        <ArrowUpwardIcon fontSize="inherit" />
      )}

      {order === 'desc' && <ArrowDownwardIcon fontSize="inherit" />}
    </IconButton>
  )
}

export default DwellingsSort
