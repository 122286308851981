import {
  Checkbox,
  Grid,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
  listItemButtonClasses,
} from '@mui/material'
import Overline from 'components/atoms/Overline'
import DwellingChip from 'components/atoms/dwellings/DwellingChip'
import FavoriteButton from 'components/molecules/FavoriteButton'
import DwellingFieldValue from 'components/molecules/dwellings/DwellingFieldValue'
import DwellingDetailButton from 'components/molecules/dwellings/detail/DwellingDetailButton'
import useDwellingEnabled from 'hooks/dwelling/useDwellingEnabled'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingField from 'interfaces/dwelling/field'
import { memo } from 'react'
import DwellingAction from 'types/dwelling/action'
import { populateDwellingFieldValue } from 'utils/dwelling'

type Props = {
  dwelling: DwellingExtended
  fields: DwellingField[]
  action: DwellingAction
  selected: boolean
  onSelect: (selected: boolean, dwelling: DwellingExtended) => void
}

const DwellingsListItem = (props: Props) => {
  const enabled = useDwellingEnabled(props.dwelling)

  /**
   * NOTE: This handler is basically the same as DwellingsTableRow's handleAction.
   *
   * Can't really find a decent abstraction for it, so it's repeated. Fuck you DRY.
   */
  const handleAction = () => {
    if (!enabled) {
      return
    }

    switch (props.action) {
      case 'detail':
        props.onSelect(true, props.dwelling)
        break

      case 'select':
        props.onSelect(!props.selected, props.dwelling)
        break
    }
  }

  const [firstField, ...fields] = props.fields.map((field) =>
    populateDwellingFieldValue(field, props.dwelling, enabled)
  )

  return (
    <ListItem disablePadding>
      <ListItemButton
        disableRipple
        disabled={!enabled}
        onClick={handleAction}
        sx={{
          px: 1,
          py: 2,
          [`&.${listItemButtonClasses.disabled}`]: {
            opacity: 1,
            bgcolor: (theme) =>
              `rgba(0, 0, 0, ${theme.palette.action.hoverOpacity / 2})`,
            // Allows clicks to go through for favorites.
            pointerEvents: 'auto',
          },
        }}
      >
        <Stack spacing={1.5} sx={{ flexGrow: 1 }}>
          {/* Title row */}
          <Stack
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <DwellingChip
              statusCode={props.dwelling.statusCode}
              value={<DwellingFieldValue field={firstField} />}
              resale={props.dwelling.resale}
            />

            <FavoriteButton dwelling={props.dwelling} />
          </Stack>

          {/* Rest of fields + detail dialog row */}
          <Stack
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid container spacing={2}>
              {fields.map((field, index) => (
                <Grid item key={index} xs="auto">
                  <Stack>
                    <Overline>{field.name}</Overline>

                    <Typography variant="body2">
                      <DwellingFieldValue field={field} />
                    </Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>

            {props.action === 'detail' && (
              <DwellingDetailButton disabled={!enabled} />
            )}
            {props.action === 'select' && (
              <Checkbox
                size="small"
                checked={props.selected}
                disabled={!enabled}
              />
            )}
          </Stack>
        </Stack>
      </ListItemButton>
    </ListItem>
  )
}

export default memo(
  DwellingsListItem,
  (oldProps, newProps) =>
    oldProps.dwelling.id === newProps.dwelling.id &&
    oldProps.fields.length === newProps.fields.length &&
    oldProps.action === newProps.action &&
    oldProps.selected === newProps.selected
)
