import Dwelling from 'interfaces/dwelling'
import Project from 'interfaces/project'
import {
  getDwellingsMediaByNomenclature,
  getDwellingsMediaByTypology,
} from 'utils/dwelling'

const useDwellingsMediaPhotos = (dwellings: Dwelling[], project?: Project) => {
  if (!project) {
    return []
  }

  return [
    ...getDwellingsMediaByTypology(dwellings, project.resources?.photos ?? []),
    ...getDwellingsMediaByNomenclature(
      dwellings,
      project.resources?.photosByFolder?.folder ?? [],
      project.resources?.photosByFolder?.nomenclatureSegment
    ),
  ]
}

export default useDwellingsMediaPhotos
