import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import ShareAccounts from 'components/organisms/share/ShareAccounts'
import ShareDwellingsForm from 'components/organisms/share/ShareDwellingsForm'
import ShareProjectsForm from 'components/organisms/share/ShareProjectsForm'
import ShareDialogMode from 'types/share/dialog-mode'

export const shareDialogModes: {
  slug: ShareDialogMode
  name: string
  icon:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  component: React.ReactNode
  divider?: boolean
}[] = [
  {
    slug: 'projects',
    name: 'Projects',
    icon: <MapsHomeWorkOutlinedIcon fontSize="small" />,
    component: <ShareProjectsForm />,
  },
  {
    slug: 'dwellings',
    name: 'Units',
    icon: <LinkOutlinedIcon fontSize="small" />,
    component: <ShareDwellingsForm />,
    divider: true,
  },
  {
    slug: 'accounts',
    name: 'Accounts',
    icon: <PersonOutlineOutlinedIcon fontSize="small" />,
    component: <ShareAccounts />,
  },
]
