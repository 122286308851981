import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Stack, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'

type Props = {
  title: string
  titleId?: string
  afterTitle?: React.ReactNode
  onCloseClick?: React.MouseEventHandler<HTMLButtonElement>
}

const DialogHeader = ({ title, titleId, afterTitle, onCloseClick }: Props) => {
  return (
    <Stack
      sx={{ mb: 3 }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={{
          xs: 1.5,
          sm: 3,
        }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
      >
        <Typography id={titleId} variant="h5" component="h3">
          {title}
        </Typography>

        {afterTitle}
      </Stack>

      {onCloseClick && (
        <IconButton aria-label="Close" edge="end" onClick={onCloseClick}>
          <CloseOutlinedIcon />
        </IconButton>
      )}
    </Stack>
  )
}

export default DialogHeader
