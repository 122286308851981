export const mailSubjects = {
  accountInvited: 'Roel Sales: Exclusive access to our private area',
  accountInvitedSender: "You've just invited {email} to the Roel Sales private area",
  accountInvitedReminder: "Roel Sales private area is waiting for you",
  accountEnabled: "Your account for Roel Sales private area is now active",
  accountPasswordResetRequest:
    "Your password reset link for Roel Sales private area",
  accountPasswordReset:
    "Your password for Roel Sales private area has been updated",
  accountAccessRequest:
    '{name} sent you an access request to Roel Sales private area',
  contact: '{name} sent you a message on Roel Sales private area',
}

export const mailTemplates = {
  accountInvited: 'account-invited',
  accountInvitedSender: 'account-invited-sender',
  accountInvitedReminder: 'account-invited-reminder',
  accountEnabledClientInvestor: 'account-enabled-client-investor',
  accountEnabledAgentPartner: 'account-enabled-agent-partner',
  accountPasswordResetRequest: 'account-password-reset-request',
  accountPasswordReset: 'account-password-reset',
  accountAccessRequest: 'account-access-request',
  contact: 'contact',
}
