import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { Button } from '@mui/material'
import DwellingFilter from 'interfaces/dwelling/filter'
import { dwellingFiltersActive } from 'utils/dwelling/filters'

type Props = {
  filtersOpen: boolean
  setFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>
  filters: DwellingFilter[]
}
const DwellingsFilterButton = ({
  filtersOpen,
  setFiltersOpen,
  filters,
}: Props) => {
  return (
    <Button
      variant={'text'}
      startIcon={
        filtersOpen ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />
      }
      sx={{
        color: dwellingFiltersActive(filters) ? 'primary' : 'action.active',
        textTransform: 'none',
      }}
      onClick={() => {
        setFiltersOpen(!filtersOpen)
      }}
    >
      Filters
    </Button>
  )
}

export default DwellingsFilterButton
