import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Box, CircularProgress, Fade } from '@mui/material'
import { statusToIdleTimeout } from 'constants/status'
import Image from 'interfaces/image'
import { useState } from 'react'
import Status from 'types/status'
import { download } from 'utils/downloads'
import ImageGalleryItemCaption from './ImageGalleryItemCaption'

type Props = {
  image: Image
  in: boolean
}

const ImageGalleryItemDownloadableHover = ({ image, in: propIn }: Props) => {
  const [status, setStatus] = useState<Status>('idle')

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    setStatus('loading')

    const url = image.url

    if (!url) {
      setStatus('error')
      return
    }

    const fileName = `${image.title ?? ''} - ${image.subtitle ?? ''}`
    await download(url, fileName)

    setStatus('success')
    setTimeout(() => {
      setStatus('idle')
    }, statusToIdleTimeout)
  }

  return (
    <Fade in={propIn}>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          backgroundColor: (theme) => theme.custom.darkOverlayColor,
          color: 'common.white',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
        onClick={handleClick}
      >
        <ImageGalleryItemCaption
          title={image.title}
          subtitle={image.subtitle}
        />

        <Box
          sx={{
            p: {
              xs: 2,
              lg: 3,
            },
          }}
        >
          {status === 'idle' && <FileDownloadOutlinedIcon />}
          {status === 'loading' && (
            <CircularProgress color="inherit" size="1.5rem" />
          )}
          {status === 'success' && <CheckOutlinedIcon />}
          {status === 'error' && <ErrorOutlineOutlinedIcon />}
        </Box>
      </Box>
    </Fade>
  )
}

export default ImageGalleryItemDownloadableHover
