import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import LoadingImageGallery from 'components/organisms/LoadingImageGallery'
import PlansWithVariants from 'components/organisms/plans-with-variants/PlansWithVariants'
import useProject from 'hooks/useProject'
import DwellingTypology from 'interfaces/dwelling/typology'
import { getProjectPhotos, getProjectRenders } from 'utils/project'

type Props = {
  typology: DwellingTypology
}

const DwellingTypologyContent = ({ typology }: Props) => {
  const theme = useTheme()
  const project = useProject()

  const renders = getProjectRenders(project).filter((render) =>
    render.typologies?.includes(typology.slug.current)
  )
  const photos = getProjectPhotos(project).filter((photo) =>
    photo.typologies?.includes(typology.slug.current)
  )
  const floorPlans = typology.floorPlans

  if (!project) {
    return null
  }

  return (
    // This `minHeight` avoids scroll enabling/disabling.
    <Box sx={{ minHeight: theme.custom.fullHeightMinusHeader }}>
      {!!renders.length && (
        <Box sx={{ mb: 8 }}>
          <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
            Renders
          </Typography>

          <LoadingImageGallery id="typology-renders-gallery" images={renders} />
        </Box>
      )}

      {!!photos.length && (
        <Box sx={{ mb: 8 }}>
          <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
            Photos
          </Typography>

          <LoadingImageGallery id="typology-photos-gallery" images={photos} />
        </Box>
      )}

      {!!floorPlans && (
        <Box sx={{ mb: 8 }}>
          <PlansWithVariants title="Floor plans" value={floorPlans} />
        </Box>
      )}
    </Box>
  )
}

export default DwellingTypologyContent
