/**
 * Build custom arrows' HTML string for PhotoSwipe using React.
 *
 * @link https://react.dev/reference/react-dom/server/renderToString#removing-rendertostring-from-the-client-code
 */

import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import { Box } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import ThemeProviderWithGlobalFontFamily from 'components/atoms/ThemeProviderWithGlobalFontFamily'
import { flushSync } from 'react-dom'
import { createRoot } from 'react-dom/client'
import { theme } from 'styles/theme'

// Emulates an IconButton, but uses a span to accomodate inside PhotoSwipe's button.
const buttonSx: SxProps<Theme> = {
  display: 'inline-flex',
  borderRadius: '50%',
  padding: 1.5,
  backgroundColor: `${theme.palette.secondary.main}cc`, // 'cc' is 80% opacity,
  color: 'common.white',
}

let div, root: any

div = document.createElement('div')
root = createRoot(div)
flushSync(() => {
  root.render(
    <ThemeProviderWithGlobalFontFamily theme={theme}>
      <Box component="span" sx={buttonSx}>
        <ArrowBackIosOutlinedIcon />
      </Box>
    </ThemeProviderWithGlobalFontFamily>
  )
})
const prev = div.innerHTML

div = document.createElement('div')
root = createRoot(div)
flushSync(() => {
  root.render(
    <ThemeProviderWithGlobalFontFamily theme={theme}>
      <Box component="span" sx={buttonSx}>
        <ArrowForwardIosOutlinedIcon />
      </Box>
    </ThemeProviderWithGlobalFontFamily>
  )
})
const next = div.innerHTML

export const getPhotoSwipeLightboxArrow = (variant: 'prev' | 'next') =>
  variant === 'prev' ? prev : next
