import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import {
  Alert,
  Box,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Stack } from '@mui/system'
import Subtitle from 'components/atoms/Subtitle'
import FavoriteButton from 'components/molecules/FavoriteButton'
import LoadingBox from 'components/molecules/LoadingBox'
import DwellingInfoField from 'components/molecules/dwellings/DwellingInfoField'
import DwellingDetailTitle from 'components/molecules/dwellings/detail/DwellingDetailTitle'
import LoadingImageGallery from 'components/organisms/LoadingImageGallery'
import ShareLinkConfigDialog from 'components/organisms/share/ShareLinkConfigDialog'
import useDwellingsMediaPhotos from 'hooks/dwelling/media/useDwellingsMediaPhotos'
import useDwellingsMediaRenders from 'hooks/dwelling/media/useDwellingsMediaRenders'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useProject from 'hooks/useProject'
import useShareLink from 'hooks/useShareLink'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingField from 'interfaces/dwelling/field'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { accountCanShare } from 'utils/account/role'
import { isShareRoute } from 'utils/share'
import DwellingMedia from '../media'

type Props = {
  dwelling: DwellingExtended
  children?: React.ReactNode
  onBack?: (e: React.MouseEvent) => void
  variant?: 'dialog' | 'single'
}

const DwellingDetail = ({
  dwelling,
  children,
  onBack,
  variant = 'dialog',
}: Props) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true })
  const fontSize = mobile ? 'small' : 'medium'
  const project = useProject(dwelling.project, true)
  const renders = useDwellingsMediaRenders([dwelling], project)
  const photos = useDwellingsMediaPhotos([dwelling], project)
  const { data: me } = useOdooAccountMe()

  const [shareConfigDialogOpen, setShareConfigDialogOpen] = useState(false)
  const {
    severity: shareLinkSeverity,
    message: shareLinkMessage,
    loading: shareLinkLoading,
    copyLink: copyShareLink,
    restart: restartShareLink,
  } = useShareLink([dwelling])
  const { pathname } = useLocation()
  const shareRoute = isShareRoute(pathname)

  const handleShareConfigDialogOpen = () => setShareConfigDialogOpen(true)
  const handleShareConfigDialogClose = () => setShareConfigDialogOpen(false)

  if (!project) {
    return null
  }

  const fields: DwellingField[] = [
    {
      name: 'Bedrooms',
      value: dwelling.beds,
    },
    {
      name:
        dwelling.constructionType === 'Villas' ? 'Plot Area' : 'Garden Area',
      value: dwelling.gardenArea,
      suffix: 'm<sup>2</sup>',
    },
    {
      name: 'Baths',
      value: dwelling.baths,
    },
    {
      name: 'Indoor Constructed Area',
      value: dwelling.indoorConstructed,
      suffix: 'm<sup>2</sup>',
    },
    {
      name: 'Parking',
      value: dwelling.parkingLots,
    },
    {
      name: 'Terrace Constructed Area',
      value: dwelling.terraceConstructed,
      suffix: 'm<sup>2</sup>',
    },
    {
      name: 'Storage',
      value: dwelling.storage,
    },
    {
      name: 'Total Constructed Area',
      value: dwelling.totalConstructed,
      suffix: 'm<sup>2</sup>',
    },
    {
      name: 'Orientation',
      value: dwelling.orientation,
    },
    {
      name: 'Total Usable Area',
      value: dwelling.totalUsable,
      suffix: 'm<sup>2</sup>',
    },
  ]

  return (
    <Stack spacing={6}>
      <Box>
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <DwellingDetailTitle
            dwelling={dwelling}
            projectName={project.name}
            onBack={onBack}
            variant={variant === 'single' ? 'simple' : 'regular'}
          />

          <Stack direction="row" alignItems="center" spacing={2}>
            {!shareRoute && accountCanShare(me) && !project.hidden && (
              <>
                <LoadingBox loading={shareLinkLoading}>
                  <IconButton
                    aria-label="Share"
                    onClick={handleShareConfigDialogOpen}
                  >
                    <ShareOutlinedIcon fontSize={fontSize} />
                  </IconButton>
                </LoadingBox>

                <ShareLinkConfigDialog
                  open={shareConfigDialogOpen}
                  onClose={handleShareConfigDialogClose}
                  onDone={copyShareLink}
                />
              </>
            )}

            <FavoriteButton dwelling={dwelling} />
          </Stack>
        </Stack>
      </Box>

      <Collapse
        in={!!shareLinkSeverity && !!shareLinkMessage}
        timeout="auto"
        sx={{ mt: '0 !important' }}
      >
        <Alert
          sx={{ mt: 6 }}
          severity={shareLinkSeverity}
          onClose={restartShareLink}
        >
          {shareLinkMessage}
        </Alert>
      </Collapse>

      <Box>
        <Grid container rowSpacing={1.5} columnSpacing={3}>
          {fields.map((field, index) => (
            <Grid item key={index} xs={6}>
              <DwellingInfoField
                field={field}
                type="normal"
                variant="horizontal"
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <DwellingMedia
        dwelling={dwelling}
        project={project}
        includes={['floor-plans', 'plot-plans']}
      />

      {!!renders.length && (
        <Box>
          <Stack
            direction="row"
            spacing={3}
            alignItems="flex-end"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Subtitle value="Renders" />

            <Typography variant="caption" sx={{ textAlign: 'right' }}>
              Intended for illustrative purposes only
            </Typography>
          </Stack>

          <LoadingImageGallery id="renders-gallery" images={renders} />
        </Box>
      )}

      {!!photos.length && (
        <Box>
          <Stack
            direction="row"
            spacing={3}
            alignItems="flex-end"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Subtitle value="Photos" />

            <Typography variant="caption" sx={{ textAlign: 'right' }}>
              Intended for illustrative purposes only
            </Typography>
          </Stack>

          <LoadingImageGallery id="photos-gallery" images={photos} />
        </Box>
      )}

      <DwellingMedia
        dwelling={dwelling}
        project={project}
        includes={['real-views']}
      />

      {children}
    </Stack>
  )
}

export default DwellingDetail
