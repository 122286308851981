import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { errorMessages } from 'constants/errors'
import { queryKeys } from 'constants/queries'
import OdooApiQueryResponse from 'interfaces/odoo/api/query/response'
import OdooApiQueryVariables from 'interfaces/odoo/api/query/variables'
import { odooBuildQuery, odooBuildStringFilter } from 'utils/odoo'
import useOdooAdmin from '../useOdooAdmin'

export const queryFn = async <T>({
  model,
  query,
  filter,
  limit,
  page,
  page_size,
  order,
  token,
}: OdooApiQueryVariables & {
  token: string
}) => {
  const parameters = new URLSearchParams({
    ...(query && { query: odooBuildQuery(query) }),
    ...(filter && { filter: odooBuildStringFilter(filter) }),
    ...(limit && { limit: limit.toString() }),
    ...(page_size && { page_size: page_size.toString() }),
    ...(page && { page: page.toString() }),
    ...(order && { order }),
  })

  const response = await fetch(
    `${
      import.meta.env.VITE_ODOO_API_ENDPOINT
    }/${model}?${parameters.toString()}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  const { result }: OdooApiQueryResponse<T> = await response.json()

  if (!response.ok) {
    throw new Error(errorMessages.odooApi)
  }

  return result
}

const useOdooApiQuery = <T>(
  {
    model,
    query,
    filter,
    limit,
    page,
    page_size,
    order,
  }: OdooApiQueryVariables,
  options?: UseQueryOptions<T[], Error>
) => {
  const { data: admin } = useOdooAdmin()

  type TQueryFnData = OdooApiQueryResponse<T>['result']
  type TError = Error

  return useQuery<TQueryFnData, TError>(
    [
      ...queryKeys.odooApiQuery,
      model,
      query,
      filter,
      limit,
      page,
      page_size,
      order,
    ],
    async (): Promise<TQueryFnData> =>
      queryFn({
        model,
        query,
        filter,
        limit,
        page,
        page_size,
        order,
        token: admin?.data.token ?? '',
      }),
    {
      enabled: !!admin?.data.token,
      ...options,
    }
  )
}

export default useOdooApiQuery
