import { Box, CircularProgress, Fade } from '@mui/material'
import useAddImagesDimensions from 'hooks/useAddImagesDimensions'
import { getDownloadableImages } from 'utils/images'
import ImageGallery, { ImageGalleryProps } from './ImageGallery'

const LoadingImageGallery = (props: ImageGalleryProps) => {
  const images = props.downloadable
    ? getDownloadableImages(props.images)
    : props.images

  const [imagesWithDimensions, imagesLoadingProgress] =
    useAddImagesDimensions(images)

  if (!images.length) {
    return null
  }

  return (
    <Box sx={{ position: 'relative', minHeight: (theme) => theme.spacing(16) }}>
      <Fade in={!imagesWithDimensions.length}>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress
            color="secondary"
            variant={
              imagesLoadingProgress === 0 ? 'indeterminate' : 'determinate'
            }
            value={imagesLoadingProgress}
          />
        </div>
      </Fade>

      <Fade in={!!imagesWithDimensions.length}>
        <div>
          <ImageGallery
            id={props.id}
            images={imagesWithDimensions}
            downloadable={props.downloadable}
          />
        </div>
      </Fade>
    </Box>
  )
}

export default LoadingImageGallery
