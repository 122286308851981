import { Grid, Stack, Typography } from '@mui/material'
import Image from 'components/atoms/Image'
import Project from 'interfaces/project'

type Props = {
  project: Project
}

const ShareSectionOverview = ({ project }: Props) => {
  const content = project?.overview?.primary?.content ?? []

  return (
    <Grid container spacing={3}>
      {!!content.length && (
        <Grid item xs={12} sm={6}>
          <Stack spacing={3}>
            {content.map((contentItem, index) => (
              <Typography key={index}>{contentItem}</Typography>
            ))}
          </Stack>
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <Image
          style={{
            width: '100%',
            display: 'block',
            userSelect: 'none',
          }}
          src={project.images?.thumbnail}
        />
      </Grid>
    </Grid>
  )
}

export default ShareSectionOverview
