import { Divider, Stack } from '@mui/material'
import Account from 'interfaces/account'
import { accountIsEnabled } from 'utils/account/metadata'
import AccountInvitationReminderChip from './AccountInvitationReminderChip'
import AccountRoleUpdateChip from './AccountRoleUpdateChip'

export const baseAccountChipsSx = [
  {
    borderWidth: 0,
  },
]

type Props = {
  account: Account
}

const AccountChips = ({ account }: Props) => {
  return (
    <Stack direction="row" alignItems="center">
      {!accountIsEnabled(account) && (
        <>
          <AccountInvitationReminderChip account={account} />
          <Divider orientation="vertical" flexItem />
        </>
      )}

      <AccountRoleUpdateChip account={account} />
    </Stack>
  )
}

export default AccountChips
