import { useMutation } from '@tanstack/react-query'
import { errorMessages } from 'constants/errors'
import Account from 'interfaces/account'
import OdooAccountUpdateResponse from 'interfaces/odoo/account/update-response'
import useOdooAdmin from '../useOdooAdmin'

type TVariables = {
  id: number
  email: string
  metadata: Partial<Account['metadata']>
  password?: string
}
type TData = OdooAccountUpdateResponse['result']
type TError = Error

const useOdooAccountUpdate = () => {
  const { data: admin } = useOdooAdmin()

  const mutation = async (variables: TVariables): Promise<TData> => {
    if (!admin?.success) {
      throw new Error(errorMessages.odooAdmin)
    }

    const response = await fetch(
      import.meta.env.VITE_ODOO_ACCOUNTS_API_UPDATE_ENDPOINT,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${admin.data.token}`,
        },
        body: JSON.stringify({
          params: {
            args: [
              {
                id: variables.id,
                email: variables.email,
                metadata: variables.metadata,
                password: variables.password,
              },
            ],
          },
        }),
      }
    )

    const { result, error }: OdooAccountUpdateResponse = await response.json()

    if (!response.ok || !result?.updated || error) {
      throw new Error(error?.data.message ?? errorMessages.odooAccountUpdate)
    }

    return result
  }

  return useMutation<TData, TError, TVariables>(mutation)
}

export default useOdooAccountUpdate
