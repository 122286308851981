import { Paper } from '@mui/material'

type Props = {
  children: React.ReactNode
}

const OutlinedShadowPaper = ({ children }: Props) => (
  <Paper
    variant="outlined"
    sx={{
      p: 3,
      borderRadius: 3,
      bgcolor: 'grey.50',
    }}
  >
    {children}
  </Paper>
)

export default OutlinedShadowPaper
