import { Box, Container, Stack, Typography } from '@mui/material'
import Content from 'components/atoms/Content'
import MarketingMaterialDisclaimer from 'components/atoms/MarketingMaterialDisclaimer'
import LinksTable from 'components/organisms/LinksTable'
import LoadingImageGallery from 'components/organisms/LoadingImageGallery'
import ProjectHeader from 'components/organisms/project/ProjectHeader'
import useProject from 'hooks/useProject'
import { getDownloadableImages } from 'utils/images'
import { getProjectPhotos, getProjectRenders } from 'utils/project'

const ProjectDownloads = () => {
  const project = useProject()

  if (!project) {
    return null
  }

  const haveDownloadableRenders = !!getDownloadableImages(
    getProjectRenders(project)
  ).length
  const haveDownloadablePhotos = !!getDownloadableImages(
    getProjectPhotos(project)
  ).length
  const haveLinks = !!project?.links?.length

  return (
    <>
      <ProjectHeader project={project} />

      <Container>
        <Content>
          {haveDownloadableRenders && (
            <Box sx={{ mb: 8 }}>
              <Stack spacing={3}>
                <Stack
                  direction="row"
                  spacing={3}
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="h2">
                    Renders
                  </Typography>

                  <Typography variant="caption" sx={{ textAlign: 'right' }}>
                    Click on each thumbnail to download
                  </Typography>
                </Stack>

                <LoadingImageGallery
                  id="renders-gallery"
                  images={getProjectRenders(project)}
                  downloadable
                />

                <MarketingMaterialDisclaimer />
              </Stack>
            </Box>
          )}

          {haveDownloadablePhotos && (
            <Box sx={{ mb: 8 }}>
              <Stack spacing={3}>
                <Stack
                  direction="row"
                  spacing={3}
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="h2">
                    Photos
                  </Typography>

                  <Typography variant="caption" sx={{ textAlign: 'right' }}>
                    Click on each thumbnail to download
                  </Typography>
                </Stack>

                <LoadingImageGallery
                  id="photos-gallery"
                  images={getProjectPhotos(project)}
                  downloadable
                />

                <MarketingMaterialDisclaimer />
              </Stack>
            </Box>
          )}

          {haveLinks && (
            <Box sx={{ mb: 8 }}>
              <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
                Links
              </Typography>

              <LinksTable project={project} />
            </Box>
          )}
        </Content>
      </Container>
    </>
  )
}

export default ProjectDownloads
