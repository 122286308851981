import { useMutation } from '@tanstack/react-query'
import { errorMessages } from 'constants/errors'
import Account from 'interfaces/account'
import Dwelling from 'interfaces/dwelling'
import OdooFavoriteDeleteResponse from 'interfaces/odoo/favorite/delete-response'
import useOdooAdmin from '../useOdooAdmin'

type TVariables = {
  dwellingIds: Dwelling['id'][]
  accountIds: Account['id'][]
}
type TData = true
type TError = Error

const useOdooFavoriteDelete = () => {
  const { data: admin } = useOdooAdmin()

  const mutation = async (variables: TVariables): Promise<TData> => {
    if (!admin?.success) {
      throw new Error(errorMessages.odooAdmin)
    }

    const response = await fetch(
      import.meta.env.VITE_ODOO_FAVORITES_API_DELETE_ENDPOINT,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${admin.data.token}`,
        },
        body: JSON.stringify({
          params: {
            args: [variables.dwellingIds, variables.accountIds],
          },
        }),
      }
    )

    const { result, error }: OdooFavoriteDeleteResponse = await response.json()

    if (!response.ok || !result?.deleted || error) {
      throw new Error(error?.data.message ?? errorMessages.odooFavoriteDelete)
    }

    return true
  }

  return useMutation<TData, TError, TVariables>(mutation)
}

export default useOdooFavoriteDelete
