import { Collapse } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import ProjectStripWithPermission from 'components/organisms/project/ProjectStripWithPermission'
import ProjectFocusContext from 'contexts/ProjectFocusContext'
import { changePermissionsType } from 'hooks/useProjectPermissions'
import useProjects from 'hooks/useProjects'
import ProjectPermission from 'interfaces/project-permission'
import { memo, useContext } from 'react'
import ShareSeeMore from './ShareSeeMore'

type Props = {
  permissions: ProjectPermission['slug'][]
  changePermissions: changePermissionsType
}

const ShareProjectStrips = ({ permissions, changePermissions }: Props) => {
  const { data: projects } = useProjects()
  const { value: focusedProject } = useContext(ProjectFocusContext)

  return (
    <>
      {projects
        .filter((project) => !project.hidden)
        .map((project, index) => {
          const show = focusedProject ? focusedProject.id === project.id : true
          const sx: SxProps<Theme> = show
            ? {
                mb: 3,
                '&:last-child': {
                  mb: 0,
                },
              }
            : {}

          return (
            <Collapse key={index} in={show} sx={sx}>
              <ProjectStripWithPermission
                project={project}
                projectStripProps={{ in: show }}
                permissions={permissions}
                changePermissions={changePermissions}
              />
            </Collapse>
          )
        })}

      <ShareSeeMore />
    </>
  )
}

export default memo(ShareProjectStrips)
