import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'
import { Collapse, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ProjectStrip, {
  ProjectStripProps,
} from 'components/organisms/project/ProjectStrip'
import ProjectThumbnail from 'components/organisms/project/ProjectThumbnail'
import Project from 'interfaces/project'
import { useState } from 'react'

type Props = Omit<ProjectStripProps, 'thumbnail'> & {
  project: Project
}

/**
 * Minimizes the `sideContent` in xs.
 */
const ProjectStripMinimizable = ({ project, sideContent, ...rest }: Props) => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const [minimized, setMinimized] = useState(true)

  const handleToggle = () => {
    setMinimized(!minimized)
  }

  // Only if on xs and there's actual side content.
  const afterTitle = isXs && !!sideContent && (
    <IconButton
      aria-label="minimize"
      size="small"
      color="secondary"
      onClick={handleToggle}
    >
      {minimized ? (
        <AddOutlinedIcon color="action" fontSize="inherit" />
      ) : (
        <RemoveOutlinedIcon color="action" fontSize="inherit" />
      )}
    </IconButton>
  )

  return (
    <ProjectStrip
      {...rest}
      thumbnail={<ProjectThumbnail project={project} afterTitle={afterTitle} />}
      sideContent={<Collapse in={!minimized || !isXs}>{sideContent}</Collapse>}
    />
  )
}

export default ProjectStripMinimizable
