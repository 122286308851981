import { CircularProgress, Fade, Stack, Typography } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

type Props = {
  in: boolean
  text?: string
  sx?: SxProps<Theme>
}

const CenteredSpinner = (props: Props) => (
  <Fade in={props.in}>
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={6}
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        p: 1.5,
        textAlign: 'center',
      }}
    >
      <CircularProgress color="secondary" sx={props.sx} />

      <Fade in={!!props.text}>
        <Typography>{props.text}</Typography>
      </Fade>
    </Stack>
  </Fade>
)

export default CenteredSpinner
