import TabContext from '@mui/lab/TabContext'
import { Box } from '@mui/material'
import EllipsisSpan from 'components/atoms/EllipsisSpan'
import PersistentTabPanel from 'components/atoms/PersistentTabPanel'
import RailTab from 'components/molecules/RailTab'
import RailTabList from 'components/molecules/RailTabList'
import PlansWithVariants from 'components/organisms/plans-with-variants/PlansWithVariants'
import useProject from 'hooks/useProject'
import { useState } from 'react'

const DwellingBasements = () => {
  const project = useProject()
  const basements = project?.dwellings?.basements
  const [active, setActive] = useState(
    basements ? basements[0].slug.current : ''
  )

  const handleChange = (event: React.SyntheticEvent, newActive: string) => {
    setActive(newActive)
  }

  if (!basements) {
    return null
  }

  return (
    <TabContext value={active}>
      <RailTabList
        onChange={handleChange}
        sx={{
          /**
           * DwellingBasements' RailTabList goes after DwellingTypologies', because of that,
           * the indicator can overlap and show (backgrounds are transparent and sticky
           * positioning just places thing on top of each other). This padding top helps
           * with that, displacing it enough to occlude DwellingTypologies' indicator.
           */
          pt: '1px',
        }}
      >
        {basements.map((basement) => (
          <RailTab
            key={basement.slug.current}
            value={basement.slug.current}
            label={
              <EllipsisSpan
                sx={{
                  fontSize: {
                    xs: '0.775rem',
                    md: '0.875rem', // Default
                  },
                }}
              >
                {basement.name}
              </EllipsisSpan>
            }
          />
        ))}
      </RailTabList>

      {basements.map((basement) => (
        <PersistentTabPanel
          key={basement.slug.current}
          value={basement.slug.current}
          sx={{ px: 0, py: 4 }}
        >
          <Box sx={{ mb: 8 }}>
            <PlansWithVariants
              title="Basement plan"
              subtitle="(Depending on plots)"
              value={basement.floorPlans}
            />
          </Box>
        </PersistentTabPanel>
      ))}
    </TabContext>
  )
}

export default DwellingBasements
