import LoadingButton from '@mui/lab/LoadingButton'
import {
  Alert,
  Box,
  Collapse,
  Dialog,
  DialogContent,
  Link,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import DialogHeader from 'components/molecules/DialogHeader'
import { errorMessages } from 'constants/errors'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useOdooAccountUpdate from 'hooks/odoo/account/useOdooAccountUpdate'
import { useState } from 'react'
import { legalRouteMetadata } from 'routes-metadata'
import { accountIsAdmin } from 'utils/account/role'
import { epoch } from 'utils/date'

const LegalDialog = () => {
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const { data: me } = useOdooAccountMe()
  const { mutateAsync: update } = useOdooAccountUpdate()

  const handleUpdate = async () => {
    if (!me) {
      return
    }
    setLoading(true)

    try {
      const response = await update({
        id: me.id,
        email: me.email,
        metadata: {
          legal: epoch(),
        },
      })

      if (!response?.updated) {
        throw new Error(errorMessages.odooAccountUpdate)
      }

      setOpen(false)
    } catch (error: any) {
      setError(error.message)
    }

    setLoading(false)
  }

  if (!me || !!me.metadata?.legal || accountIsAdmin(me)) {
    return null
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="legal-dialog-title"
      aria-describedby="legal-dialog-description"
      disableAutoFocus
    >
      <DialogContent>
        <DialogHeader
          titleId="legal-dialog-title"
          title="Review our Terms and Privacy Policy"
        />

        <Box id="legal-dialog-description">
          <Typography variant="body2" sx={{ mb: 3 }}>
            Hi {me.metadata?.contact_name || 'there'}! Please, take a moment to
            read and review our{' '}
            <Link
              target="_blank"
              href={`/${legalRouteMetadata.slug}`}
              underline="hover"
            >
              Terms & Conditions and Privacy Policy
            </Link>
            . Agreeing is compulsory in order to continue browsing.
          </Typography>

          <Typography variant="body2" sx={{ mb: 3 }}>
            If you disagree with any of the preceeding, we kindly ask you not to
            use our site.
          </Typography>
        </Box>

        <Collapse in={error !== ''} timeout="auto">
          <Alert sx={{ marginBottom: 3 }} severity="error">
            {error}
          </Alert>
        </Collapse>

        <Box sx={{ textAlign: 'right' }}>
          <LoadingButton
            onClick={handleUpdate}
            loading={loading}
            variant="contained"
            disableElevation
          >
            Agree
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default LegalDialog
