import useChangelog from 'hooks/changelog/useChangelog'
import ChangelogList from '../../organisms/changelog/ChangelogList'

const ChangelogContent = () => {
  const { data: changelogEntries } = useChangelog()
  const content = changelogEntries.filter(
    (changelogEntry) => changelogEntry.type === 'content'
  )
  if (!content.length) return null

  return <ChangelogList changelogEntries={content} />
}

export default ChangelogContent
