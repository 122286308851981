import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queries'
import SettingsResponse from 'interfaces/settings/response'

const useSettings = () => {
  const api = `${import.meta.env.VITE_SETTINGS_API_ENDPOINT}?key=${
    import.meta.env.VITE_SETTINGS_API_KEY
  }`
  return useQuery<SettingsResponse>(queryKeys.settings, () =>
    fetch(api).then((res) => res.json())
  )
}

export default useSettings
