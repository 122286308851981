import { Box, Collapse, Grid, Stack, Typography } from '@mui/material'
import DwellingsFilterButton from 'components/atoms/dwellings/DwellingsFilterButton'
import DwellingsFilterName from 'components/molecules/dwellings/DwellingsFilterName'
import DwellingsTableByEmail from 'components/molecules/dwellings/DwellingsTableByEmail'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingField from 'interfaces/dwelling/field'
import DwellingFilter from 'interfaces/dwelling/filter'
import { useCallback, useState } from 'react'
import { filterDwellings } from 'utils/dwelling/filters'
import Dwellings from '.'
import AvailabilityChips from '../AvailabilityChips'
import DwellingsFilters from './DwellingsFilters'

type Props = {
  fields: DwellingField[] | []
  dwellings: DwellingExtended[]
  variant?: 'small' | 'medium'
  initialFilters: DwellingFilter[]
}

const DwellingsFiltered = ({ fields, dwellings, initialFilters }: Props) => {
  const [filters, setFilters] = useState<DwellingFilter[]>([])
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [chipsFilter, setChipsFilter] = useState<DwellingFilter | null>(null)

  const handleFiltersChange = useCallback(
    (filters: DwellingFilter[]) => {
      const newFilters: DwellingFilter[] = [...filters]

      if (chipsFilter) {
        newFilters.push(chipsFilter)
      }

      setFilters(newFilters)
    },
    [chipsFilter]
  )

  if (!dwellings) {
    return null
  }

  /**
   * Filter dwellings.
   */
  let processedDwellings = [...dwellings]
  processedDwellings = filterDwellings(processedDwellings, filters)

  return (
    <>
      <Stack
        spacing={3}
        direction="row"
        alignItems={{
          xs: 'flex-end',
          sm: 'center',
        }}
        justifyContent="space-between"
        sx={{ mb: 3 }}
      >
        <DwellingsFilterButton
          filters={(() => {
            const value = [...filters]

            if (chipsFilter) {
              value.push(chipsFilter)
            }

            return value
          })()}
          setFiltersOpen={setFiltersOpen}
          filtersOpen={filtersOpen}
        />

        <DwellingsTableByEmail />
      </Stack>

      <Collapse in={filtersOpen}>
        <Box
          sx={{
            p: 3,
            mb: 3,
            bgcolor: 'grey.50',
            borderRadius: (theme) => theme.shape.borderRadius,
          }}
        >
          <DwellingsFilters
            onChange={handleFiltersChange}
            initialFilters={initialFilters}
          >
            <Grid item xs={12} sm={6} lg={4}>
              <DwellingsFilterName name="Availability" sx={{ mb: 1.5 }} />

              <AvailabilityChips
                onFilter={(filter) => setChipsFilter(filter)}
                includeCodes={dwellings.map((dwelling) => dwelling.statusCode)}
              />
            </Grid>
          </DwellingsFilters>
        </Box>
      </Collapse>

      {!processedDwellings.length ? (
        <Typography variant="subtitle2" sx={{ py: 6, textAlign: 'center' }}>
          No units match the current filters
        </Typography>
      ) : (
        <Dwellings dwellings={processedDwellings} fields={fields} />
      )}
    </>
  )
}

export default DwellingsFiltered
