export const base64ToBlobUrl = (base64: string, mimetype: string) => {
  const binaryString = window.atob(base64)
  const binaryLen = binaryString.length
  const bytes = new Uint8Array(binaryLen)
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  const blob = new Blob([bytes], { type: mimetype })
  const url = URL.createObjectURL(blob)
  return url
}
