import { Link, LinkBaseProps } from '@mui/material'
import { forwardRef } from 'react'
import { useMatch, useResolvedPath } from 'react-router-dom'

interface Props extends LinkBaseProps {
  href: string
}

const NavLink = forwardRef(({ children, href, ...props }: Props, ref) => {
  const resolved = useResolvedPath(href)
  const match = useMatch({ path: resolved.pathname, end: true })

  return (
    <Link style={{ fontWeight: match ? '500' : '300' }} href={href} {...props}>
      {children}
    </Link>
  )
})

export default NavLink
