import { useMutation, useQueryClient } from '@tanstack/react-query'
import { localStorageKeys } from 'constants/local-storage'
import { queryKeys } from 'constants/queries'
import { ssoStorageKeys } from 'constants/sso-storage'
import ssoStorage from 'utils/sso/storage'

type TData = true
type TError = Error

const useOdooLogout = () => {
  const queryClient = useQueryClient()

  const mutation = async (): Promise<TData> => {
    await ssoStorage.removeItem(ssoStorageKeys.token)

    localStorage.removeItem(localStorageKeys.version)

    queryClient.invalidateQueries(queryKeys.ssoToken)
    queryClient.invalidateQueries(queryKeys.me)

    return true
  }

  return useMutation<TData, TError>(mutation)
}

export default useOdooLogout
