import { TabList } from '@mui/lab'
import { tabsClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const PillTabList = styled(TabList)(({ theme }) => ({
  minHeight: 'initial',
  [`& .${tabsClasses.flexContainer}`]: {
    flexWrap: 'wrap',
  },
  [`& .${tabsClasses.indicator}`]: {
    display: 'none',
  },
}))

export default PillTabList
