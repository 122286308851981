import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Stack,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

type Props = {
  onClick?: () => void
  disabled?: boolean
  iconButtonProps?: IconButtonProps
  buttonProps?: ButtonProps
}

const DwellingDetailButton = ({
  onClick,
  disabled,
  iconButtonProps,
  buttonProps,
}: Props) => {
  const theme = useTheme()
  const mdAndBelow = useMediaQuery(theme.breakpoints.down('lg'), {
    noSsr: true,
  })

  return mdAndBelow ? (
    <Button
      aria-label="Open dwelling detail"
      variant="text"
      color="secondary"
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
      sx={{
        textTransform: 'none',
        color: (theme) => theme.palette.action.active,
        px: 1.5,
      }}
    >
      <Stack direction="row" spacing={0.5} alignItems="center">
        <span>Details</span>
        <ChevronRightOutlinedIcon fontSize="small" />
      </Stack>
    </Button>
  ) : (
    <IconButton
      aria-label="Open dwelling detail"
      onClick={onClick}
      disabled={disabled}
      {...iconButtonProps}
    >
      <ChevronRightOutlinedIcon />
    </IconButton>
  )
}

export default DwellingDetailButton
