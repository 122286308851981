import { Container } from '@mui/material'
import Content from 'components/atoms/Content'
import DwellingBasements from 'components/organisms/dwellings/typologies/DwellingBasements'
import DwellingTypologies from 'components/organisms/dwellings/typologies/DwellingTypologies'
import ProjectHeader from 'components/organisms/project/ProjectHeader'
import useProject from 'hooks/useProject'

const ProjectTypologies = () => {
  const project = useProject()

  if (!project) {
    return null
  }

  return (
    <>
      <ProjectHeader project={project} />

      <Container>
        <Content sx={{ paddingY: { xs: 2, sm: 4 } }}>
          <DwellingTypologies />
          <DwellingBasements />
        </Content>
      </Container>
    </>
  )
}

export default ProjectTypologies
