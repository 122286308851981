import { Box, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { hasMouseSupport } from 'utils/devices'

type Props = {
  children: React.ReactNode
  hideMs?: number
}

/**
 * In devices with pointer (mouse) support, it shows the
 * hint on hover. On touch devices shows the hint and
 * hides it after the configured ms amount.
 */
const LightboxHint = ({ children, hideMs = 3000 }: Props) => {
  const [show, setShow] = useState(false)
  const mouse = hasMouseSupport()

  useEffect(() => {
    if (mouse) {
      return
    }

    setShow(true)

    const timeoutId = setTimeout(() => {
      setShow(false)
    }, hideMs)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [hideMs, mouse])

  return (
    <Box
      sx={[
        {
          position: 'relative',

          '& .lightbox-hint': {
            opacity: 0,
            transition: (theme) =>
              theme.transitions.create('opacity', {
                duration: theme.transitions.duration.complex,
              }),
          },
          '& .lightbox-hint.show': {
            opacity: 1,
          },
        },
        mouse && {
          '&:hover .lightbox-hint': {
            opacity: 1,
          },
        },
      ]}
    >
      {children}

      <Stack
        className={`lightbox-hint ${show ? 'show' : ''}`}
        alignItems="center"
        justifyContent="center"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          height: '100%',
          transform: 'translate(-50%, -50%)',
          pointerEvents: 'none',
          backgroundColor: (theme) => theme.custom.darkOverlayColor,
        }}
      >
        <Typography variant="subtitle1" color="white">
          Click to enlarge
        </Typography>
      </Stack>
    </Box>
  )
}

export default LightboxHint
