export const allProgress = (
  promises: Promise<any>[],
  progressCallback: Function = () => {}
) => {
  let done = 0

  progressCallback(0)

  const thenHandler = () => {
    done++
    progressCallback((done * 100) / promises.length)
  }

  for (const p of promises) {
    p.then(thenHandler)
  }

  return Promise.all(promises)
}
