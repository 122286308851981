import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'
import { Box, Stack, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import SlimChip from 'components/atoms/SlimChip'
import ConfirmDialog from 'components/organisms/ConfirmDialog'
import { mailSubjects, mailTemplates } from 'constants/account/mail'
import { errorMessages } from 'constants/errors'
import { queryKeys } from 'constants/queries'
import { statusToIdleTimeout } from 'constants/status'
import useOdooAccountUpdate from 'hooks/odoo/account/useOdooAccountUpdate'
import useAccountInvitationLink from 'hooks/useAccountInvitationLink'
import useMail from 'hooks/useMail'
import Account from 'interfaces/account'
import { useState } from 'react'
import Status from 'types/status'
import { accountIsEnabled, getAccountHumanDates } from 'utils/account/metadata'
import { getAccountRole } from 'utils/account/role'
import { epoch } from 'utils/date'
import AccountChipIcon from './AccountChipIcon'
import { baseAccountChipsSx } from './AccountChips'

type Props = {
  account: Account
}

const AccountInvitationReminderChip = ({ account }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [status, setStatus] = useState<Status>('idle')
  const { generate: generateInvitationLink } = useAccountInvitationLink()
  const { send } = useMail()
  const { mutateAsync: update } = useOdooAccountUpdate()
  const queryClient = useQueryClient()
  const { invited, reminded } = getAccountHumanDates(account)

  const handleOpenDialog = () => setDialogOpen(true)
  const handleCloseDialog = () => setDialogOpen(false)

  const handleSendInvitationReminder = async () => {
    setStatus('loading')

    try {
      /**
       * Send the invitation reminder mail.
       */
      const invitationLink = await generateInvitationLink(
        account.id,
        account.email,
        getAccountRole(account) || 'client'
      )
      const mailSent = await send(
        [account.email],
        mailSubjects.accountInvitedReminder,
        mailTemplates.accountInvitedReminder,
        {
          link: invitationLink,
          year: new Date().getFullYear(),
        }
      )

      if (!mailSent) {
        throw new Error(errorMessages.mail)
      }

      /**
       * Update the account's `reminded` metadata.
       */
      const response = await update({
        id: account.id,
        email: account.email,
        metadata: {
          reminded: epoch(),
        },
      })

      if (!response?.updated) {
        throw new Error(errorMessages.odooAccountUpdate)
      }

      queryClient.invalidateQueries(queryKeys.accounts)

      setStatus('success')

      // And after a few seconds, revert to idle.
      setTimeout(() => {
        setStatus('idle')
      }, statusToIdleTimeout)
    } catch (error) {
      setStatus('error')
    }
  }

  // Just in case, double checking.
  if (accountIsEnabled(account)) {
    return null
  }

  return (
    <>
      <SlimChip
        clickable
        size="small"
        onClick={handleOpenDialog}
        label={
          <Stack
            spacing={0.5}
            component="span"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <AccountChipIcon
              idleIcon={<ForwardToInboxOutlinedIcon sx={{ py: 0.125 }} />}
              status={status}
            />

            <Box
              component="span"
              sx={{
                display: {
                  xs: 'none',
                  lg: 'initial',
                },
              }}
            >
              Remind
            </Box>
          </Stack>
        }
        sx={[
          ...baseAccountChipsSx,
          {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        ]}
      />

      <ConfirmDialog
        open={dialogOpen}
        title="Send invitation reminder"
        description={
          <>
            <Typography variant="body2" sx={{ mb: 1.5 }}>
              This will send an email to the user reminding that the invitation
              process hasn't been completed yet, and providing a button to do
              so.
            </Typography>

            <Typography variant="body2" sx={{ mb: 3 }}>
              Last invitation was sent on {reminded || invited}
            </Typography>
          </>
        }
        action="Send"
        handleClose={handleCloseDialog}
        handleConfirm={handleSendInvitationReminder}
      />
    </>
  )
}

export default AccountInvitationReminderChip
