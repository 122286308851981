import Dwelling from 'interfaces/dwelling'
import { normalizeToString } from './normalization'

/**
 * Builds the v0 of the nomenclature.
 *
 * @deprecated This nomenclature version is not used in any project anymore.
 * @returns Nomenclature string.
 */
export const v0 = (
  building: string | number,
  portal: string | number,
  apartment: string | number,
  floor: string | number
) => {
  const buildingPart = `B${normalizeToString(building).padStart(2, '0')}`
  const portalPart = `P${normalizeToString(portal).padStart(2, '0')}`
  const apartmentPart = `A${normalizeToString(apartment).padStart(2, '0')}`
  const floorPart = `F${normalizeToString(floor).padStart(2, '0')}`

  return `${buildingPart}_${portalPart}_${apartmentPart}_${floorPart}`
}

/**
 * Builds the v1 of the nomenclature.
 *
 * @deprecated This nomenclature version is not used in any project anymore.
 * @returns Nomenclature string.
 */
export const v1 = (
  building: string | number,
  gate: string | number,
  floor: string | number,
  apartment: string | number
) => {
  const buildingPart = `B${normalizeToString(building).padStart(2, '0')}`
  const gatePart = `G${normalizeToString(gate).padStart(2, '0')}`
  const floorPart = `F${normalizeToString(floor).padStart(2, '0')}`
  const apartmentPart = `A${normalizeToString(apartment).padStart(2, '0')}`

  return `${buildingPart}_${gatePart}_${floorPart}_${apartmentPart}`
}

/**
 * Builds the v2 of the nomenclature.
 *
 * @deprecated This nomenclature version is not used in any project anymore.
 * @returns Nomenclature string.
 */
export const v2 = (
  gate: string | number,
  floor: string | number,
  apartment: string | number
) => {
  const gatePart = normalizeToString(gate).padStart(2, '0')
  const floorPart = normalizeToString(floor)
  const apartmentPart = normalizeToString(apartment)

  return `ES${gatePart}.${floorPart}.${apartmentPart}`
}

/**
 * Builds the v3 of the nomenclature.
 *
 * @returns Nomenclature string.
 */
export const v3 = (
  gate: string | number,
  floor: string | number,
  apartment: string | number,
  hidePrepend?: boolean
) => {
  const gatePart = normalizeToString(gate).padStart(2, '0')
  const floorPart = normalizeToString(floor)
  const apartmentPart = normalizeToString(apartment)

  /**
   * `XXX` goes for the three letters identifying the project/promotion
   * they belong to. We shouldn't care as the configs in the API should
   * only allow media for the correct project/promotion each time.
   *
   * For example: TGA_ES19.0.0
   */
  return hidePrepend
    ? `ES${gatePart}.${floorPart}.${apartmentPart}`
    : `XXX_ES${gatePart}.${floorPart}.${apartmentPart}`
}

/**
 * Builds nomenclatures, all versions.
 *
 * @returns Array with all nomenclature versions.
 */
export const build = (dwelling: Dwelling): string[] => {
  return [
    // v0(
    //   dwelling.building,
    //   dwelling.gate,
    //   dwelling.apartment,
    //   dwelling.floorplan
    // ),
    // v1(
    //   dwelling.building,
    //   dwelling.gate,
    //   dwelling.floorplan,
    //   dwelling.apartment
    // ),
    // v2(dwelling.gate, dwelling.floorplan, dwelling.apartment),
    v3(dwelling.gate, dwelling.floorplan, dwelling.apartment),
  ]
}

/**
 * Tries to guess if a given string is potentially a nomenclature.
 *
 * @param string A potential nomenclature string.
 * @returns Is the string following nomenclature or not.
 */
export const check = (string: string) => {
  if (!string) {
    return false
  }

  if (
    // v0 + v1
    string.length !== 15 &&
    // v2
    string.length !== 8 &&
    // v3
    string.length !== 12 &&
    string.length !== 13
  ) {
    return false
  }

  /**
   * Check for key characters in key positions.
   *
   * v0 + v1
   *
   * 0: B
   * 4: P|G
   * 8: A|F
   * 12: F|A
   */
  if (
    string.length === 15 &&
    string[0] === 'B' &&
    (string[4] === 'P' || string[4] === 'G') &&
    (string[8] === 'A' || string[8] === 'F') &&
    (string[12] === 'F' || string[12] === 'A')
  ) {
    return true
  }

  /**
   * v2 + v3
   */
  const dotCount = string.split('.').length - 1
  if (
    (string.length === 8 || string.length === 12 || string.length === 13) &&
    // Must contain "ES"
    string.includes('ES') &&
    // and two dots
    dotCount === 2
  ) {
    return true
  }

  return false
}

/**
 * Tests if a string matches with a given nomenclature.
 */
export const match = (
  string: string,
  nomenclatures: string[],
  nomenclatureSegment?: string
) => {
  if (!nomenclatureSegment) {
    return false
  }

  for (const nomenclature of nomenclatures) {
    if (!check(nomenclature)) {
      console.warn('A nomenclature check failed:', nomenclature)
      return false
    }

    /**
     * Count the amount of 'X' in the `nomenclature`, that may indicate
     * a prepend that's not part of the actual useful nomenclature.
     * That part must be skipped when doing the substring.
     */
    const prependLength = nomenclature.replace(/[^X]/g, '').length
    const substringStart = prependLength > 0 ? prependLength + 1 : 0
    const substringEnd = nomenclatureSegment.length

    if (string.includes(nomenclature.substring(substringStart, substringEnd))) {
      return true
    }
  }

  return false
}
