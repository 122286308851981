/**
 * This is a very special component.
 *
 * That's because it sometimes is rendered by ReactDOMServer.renderToString
 * (in DwellingMedia*.tsx) and therefore in that case Emotion (from MUI)
 * wont print it's styles. Would look off in that case.
 *
 * That's why this components's styles are bespoke in a different file,
 * and also why it really doesn't use any MUI component directly.
 *
 * It also relies on body's font-family.
 */

import styles from 'styles/ImageGalleryItemCaption.module.css'

type Props = {
  title?: string
  subtitle?: string
}

const ImageGalleryItemCaption = ({ title, subtitle }: Props) => {
  if (!title && !subtitle) {
    return null
  }

  return (
    <div className={`caption-content ${styles.captionContent}`}>
      {title && <h2 className={styles.captionTitle}>{title}</h2>}
      {subtitle && <h6 className={styles.captionSubtitle}>{subtitle}</h6>}
    </div>
  )
}

export default ImageGalleryItemCaption
