import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import {
  Box,
  Dialog,
  DialogContent,
  Fade,
  ListItemIcon,
  MenuItem,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import SlideUp from 'components/atoms/SlideUp'
import DialogHeader from 'components/molecules/DialogHeader'
import ChangelogDialogBody from 'components/organisms/changelog/ChangelogDialogBody'
import { localStorageKeys } from 'constants/local-storage'
import useChangelog from 'hooks/changelog/useChangelog'
import { useCallback, useEffect, useState } from 'react'
import { getLocalStorageJSON } from 'utils/local-storage'

const ChangelogDialog = () => {
  const [open, setOpen] = useState(false)
  const [initialOpen, setInitialOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const { data: changelogEntries } = useChangelog()

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true })

  const currentTime = new Date(Date.now()).getTime()

  const handleClose = () => {
    setOpen(false)
  }

  const shouldOpenDialog = useCallback(() => {
    if (changelogEntries) {
      return (
        changelogEntries.some((changelogEntry) => {
          const changelogEntryDate = new Date(changelogEntry.date).getTime()
          const lastReadDate: {
            [key: string]: number
          } = getLocalStorageJSON(localStorageKeys.changelogLastRead)
          if (!lastReadDate || !lastReadDate[changelogEntry.type]) {
            return true
          }
          const isChangelogEntryDateBiggerThanLastRead = Object.keys(
            lastReadDate
          ).some((property) => {
            if (changelogEntry.type === property) {
              return changelogEntryDate > lastReadDate[property]
            }
            return false
          })
          return (
            changelogEntryDate <= currentTime &&
            isChangelogEntryDateBiggerThanLastRead
          )
        }) && !open
      )
    }
    return false
  }, [changelogEntries, currentTime, open])

  useEffect(() => {
    if (shouldOpenDialog() && !initialOpen) {
      setOpen(true)
      setInitialOpen(true)
    }
  }, [initialOpen, shouldOpenDialog])

  return (
    <>
      <MenuItem onClick={handleOpen} aria-label="Notifications">
        <ListItemIcon>
          <NotificationsNoneOutlinedIcon fontSize="small" />
        </ListItemIcon>
        Notifications
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="changelog-dialog-title"
        disableScrollLock
        maxWidth="lg"
        fullWidth
        fullScreen={mobile}
        TransitionComponent={mobile ? SlideUp : Fade}
      >
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <DialogHeader
              titleId="changelog-dialog-title"
              title="What's new?"
              onCloseClick={handleClose}
            />
          </Box>

          <ChangelogDialogBody changelogEntries={changelogEntries} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ChangelogDialog
