import { TabList } from '@mui/lab'
import { tabsClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const ButtonTabList = styled(TabList)(({ theme }) => ({
  minHeight: 'initial',
  [`& .${tabsClasses.indicator}`]: {
    display: 'none',
  },
}))

export default ButtonTabList
