import { Dialog, DialogContent, Fade, IconButtonProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import SlideUp from 'components/atoms/SlideUp'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingDetail from './DwellingDetail'

type Props = {
  dwelling: DwellingExtended
  open: boolean
  onClose: (e: React.MouseEvent) => void
  iconButtonProps?: IconButtonProps
}

const DwellingDetailDialog = ({ dwelling, open, onClose }: Props) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      fullScreen={mobile}
      TransitionComponent={mobile ? SlideUp : Fade}
    >
      <DialogContent>
        <DwellingDetail dwelling={dwelling} onBack={onClose} />
      </DialogContent>
    </Dialog>
  )
}

export default DwellingDetailDialog
