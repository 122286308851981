import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from '@mui/material'
import Image from 'components/atoms/Image'
import Project from 'interfaces/project'
import DwellingsBulletPoints from '../../molecules/dwellings/DwellingsBulletPoints'
import ProjectStrip from '../project/ProjectStrip'

type Props = {
  projects: Project[]
  onSelect: (project: Project) => void
}

const ShareProjectsSelector = ({ projects, onSelect }: Props) => {
  return (
    <Stack spacing={6}>
      {projects.map((project) => {
        const dwellings = project.dwellings?.promotion?.dwellings ?? []

        return (
          <Card key={project.name} onClick={() => onSelect(project)}>
            <CardActionArea>
              <CardContent sx={{ padding: 0 }}>
                <ProjectStrip
                  thumbnail={
                    <Image
                      src={project.images?.thumbnail}
                      alt={`${project.slug.current}-thumbnail`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  }
                  sideContent={
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      spacing={3}
                      sx={{
                        padding: 3,
                      }}
                    >
                      <Stack spacing={3}>
                        <Typography variant="h6">{project.name}</Typography>

                        <Box>
                          {project.overview?.excerpt?.map((line, index) => (
                            <Typography key={index}>{line}</Typography>
                          ))}
                        </Box>

                        <Box>
                          <Typography>
                            {`${dwellings.length} ${
                              dwellings.length > 1 ? 'units' : 'unit'
                            } shared with you:`}
                          </Typography>

                          <DwellingsBulletPoints
                            dwellings={
                              project.dwellings?.promotion?.dwellings ?? []
                            }
                            project={project}
                          />
                        </Box>
                      </Stack>

                      <Box sx={{ p: 1 }}>
                        <ChevronRightOutlinedIcon
                          sx={{ color: (theme) => theme.palette.action.active }}
                        />
                      </Box>
                    </Stack>
                  }
                  collapseDisabled={true}
                  WrapperComponent={Box}
                />
              </CardContent>
            </CardActionArea>
          </Card>
        )
      })}
    </Stack>
  )
}

export default ShareProjectsSelector
