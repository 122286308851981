import { Box, Grid, Typography } from '@mui/material'
import Overline from 'components/atoms/Overline'
import Dwelling from 'interfaces/dwelling'
import DwellingField from 'interfaces/dwelling/field'
import DwellingInfoFieldType from 'types/dwelling/info-field-type'
import { getDwellingInfoFieldValue } from 'utils/dwelling'

type Props = {
  field: DwellingField
  type: DwellingInfoFieldType
  dwellings?: Dwelling[]
  variant?: 'horizontal' | 'vertical'
}

const DwellingInfoField = ({
  field,
  type,
  dwellings,
  variant = 'horizontal',
}: Props) => {
  const value = getDwellingInfoFieldValue(field, type, dwellings)

  switch (variant) {
    case 'horizontal':
      return (
        <Box>
          <Grid
            container
            spacing={{
              xs: 0,
              md: 3,
            }}
            alignItems="center"
          >
            <Grid item xs={12} md={7}>
              <Overline>{field.name}</Overline>
            </Grid>

            <Grid item xs={12} md={5}>
              <Typography variant="body2">{value}</Typography>
            </Grid>
          </Grid>
        </Box>
      )

    case 'vertical':
      return (
        <Box>
          <Overline>{field.name}</Overline>

          <Typography variant="body1">{value}</Typography>
        </Box>
      )

    default:
      return null
  }
}

export default DwellingInfoField
