import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@mui/material'
import { useState } from 'react'

type Props = {
  id: string
  label: string
  password: string
  disabled?: boolean
  onChange?:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined
  onKeyUp?:
    | React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined
}

const PasswordTextField = ({
  id,
  label,
  password,
  disabled,
  onChange,
  onKeyUp,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <FormControl disabled={disabled} variant="filled" fullWidth>
      <InputLabel disabled={disabled}>{label}</InputLabel>

      <FilledInput
        disabled={disabled}
        id={id}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={onChange}
        onKeyUp={onKeyUp}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => {
                setShowPassword(!showPassword)
              }}
              onMouseDown={(e: React.MouseEvent) => {
                e.preventDefault()
              }}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  )
}

export default PasswordTextField
