import { TabList } from '@mui/lab'
import { tabsClasses } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

type Props = {
  children: React.ReactNode
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void
  sx?: SxProps<Theme>
}

const RailTabList = ({ children, onChange, sx }: Props) => {
  return (
    <TabList
      allowScrollButtonsMobile
      onChange={onChange}
      textColor="secondary"
      variant="scrollable"
      aria-label="Rail tab list"
      sx={{
        position: 'sticky',
        top: (theme) => theme.custom.headerHeight,
        zIndex: 2,
        [`&:before`]: {
          content: '""',
          position: 'absolute',
          height: '100%',
          width: '100%',
          bottom: '1px',
          bgcolor: 'common.white',
        },
        [`&:after`]: {
          content: '""',
          position: 'absolute',
          bottom: '1px',
          height: '1px',
          width: '100%',
          bgcolor: 'grey.600',
        },
        ...sx,
      }}
      TabIndicatorProps={{
        sx: {
          [`&.${tabsClasses.indicator}`]: {
            bgcolor: 'grey.600',
            height: '3px',
          },
        },
      }}
    >
      {children}
    </TabList>
  )
}

export default RailTabList
