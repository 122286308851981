import { Checkbox } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import DwellingChip from 'components/atoms/dwellings/DwellingChip'
import DwellingsTableCell from 'components/atoms/dwellings/DwellingsTableCell'
import FavoriteButton from 'components/molecules/FavoriteButton'
import DwellingFieldValue from 'components/molecules/dwellings/DwellingFieldValue'
import DwellingDetailButton from 'components/molecules/dwellings/detail/DwellingDetailButton'
import useDwellingEnabled from 'hooks/dwelling/useDwellingEnabled'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingField from 'interfaces/dwelling/field'
import { memo } from 'react'
import DwellingAction from 'types/dwelling/action'
import { populateDwellingFieldValue } from 'utils/dwelling'

type Props = {
  dwelling: DwellingExtended
  fields: DwellingField[]
  action: DwellingAction
  selected: boolean
  onSelect: (selected: boolean, dwelling: DwellingExtended) => void
}

const DwellingsTableRow = ({
  dwelling,
  fields,
  action,
  selected,
  onSelect,
}: Props) => {
  const enabled = useDwellingEnabled(dwelling)

  const handleAction = () => {
    if (!enabled) {
      return
    }

    switch (action) {
      case 'detail':
        onSelect(true, dwelling)
        break

      case 'select':
        onSelect(!selected, dwelling)
        break
    }
  }

  return (
    <TableRow
      hover={enabled}
      sx={{
        cursor: enabled ? 'pointer' : undefined,
        userSelect: 'none',
      }}
    >
      {fields
        .map((field) => populateDwellingFieldValue(field, dwelling, enabled))
        .map((field, index) => {
          const component = index === 0 ? 'th' : undefined
          const scope = index === 0 ? 'row' : undefined

          return (
            <DwellingsTableCell
              key={index}
              component={component}
              scope={scope}
              onClick={handleAction}
            >
              {index === 0 ? (
                <DwellingChip
                  statusCode={dwelling.statusCode}
                  value={<DwellingFieldValue field={field} />}
                  resale={dwelling.resale}
                />
              ) : (
                <DwellingFieldValue field={field} />
              )}
            </DwellingsTableCell>
          )
        })}

      <DwellingsTableCell>
        <FavoriteButton dwelling={dwelling} />
      </DwellingsTableCell>

      <DwellingsTableCell>
        {action === 'detail' && (
          <DwellingDetailButton disabled={!enabled} onClick={handleAction} />
        )}
        {action === 'select' && (
          <Checkbox
            size="small"
            checked={selected}
            disabled={!enabled}
            onChange={handleAction}
            sx={{
              // To equalize left/right spacing inside the row.
              mr: 2,
            }}
          />
        )}
      </DwellingsTableCell>
    </TableRow>
  )
}

export default memo(
  DwellingsTableRow,
  (oldProps, newProps) =>
    oldProps.dwelling.id === newProps.dwelling.id &&
    oldProps.fields.length === newProps.fields.length &&
    oldProps.action === newProps.action &&
    oldProps.selected === newProps.selected
)
