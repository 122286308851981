import ProjectFontFamily from 'components/organisms/project/ProjectFontFamily'
import useProject from 'hooks/useProject'
import { Outlet } from 'react-router-dom'

/**
 * This route adds the project font family.
 */
const ProjectRootRoute = () => {
  const project = useProject()

  if (!project) {
    return null
  }

  return (
    <ProjectFontFamily project={project}>
      <Outlet />
    </ProjectFontFamily>
  )
}

export default ProjectRootRoute
