import Maintenance from 'components/templates/Maintenance'
import useSettings from 'hooks/useSettings'

type Props = {
  children: React.ReactNode
}

const MaintenanceGuard = ({ children }: Props) => {
  const { data: settings } = useSettings()

  if (!settings) {
    return null
  }

  if (settings.maintenance) {
    return <Maintenance />
  }

  return <>{children}</>
}

export default MaintenanceGuard
