import { Box, Fade } from '@mui/material'
import { SxProps, Theme, useTheme } from '@mui/material/styles'
import SectionDivider from 'components/atoms/sections/SectionDivider'
import SectionSpacer from 'components/atoms/sections/SectionSpacer'
import SectionImage from 'components/molecules/sections/SectionImage'
import SectionImageGallery from 'components/molecules/sections/SectionImageGallery'
import SectionImageSlider from 'components/molecules/sections/SectionImageSlider'
import SectionText from 'components/molecules/sections/SectionText'
import SectionImageImage from 'components/organisms/sections/SectionImageImage'
import SectionText33Image66 from 'components/organisms/sections/SectionText33Image66'
import SectionText66Image33 from 'components/organisms/sections/SectionText66Image33'
import SectionTextTextImage from 'components/organisms/sections/SectionTextTextImage'
import { sectionSpacing } from 'constants/section'
import Section from 'interfaces/section'
import SectionDividerProps from 'interfaces/section/divider'
import SectionImageProps from 'interfaces/section/image'
import SectionImageGalleryProps from 'interfaces/section/image-gallery'
import SectionImageImageProps from 'interfaces/section/image-image'
import SectionImageSliderProps from 'interfaces/section/image-slider'
import SectionSpacerProps from 'interfaces/section/spacer'
import SectionTextProps from 'interfaces/section/text'
import SectionTextTextImageProps from 'interfaces/section/text-text-image'
import SectionText33Image66Props from 'interfaces/section/text33-image66'
import SectionText66Image33Props from 'interfaces/section/text66-image33'

type Props = {
  sections: Section[]
}

const Sections = ({ sections }: Props) => {
  const theme = useTheme()

  return (
    <>
      {sections.map((section, index) => {
        const { layout, tags } = section
        const content = section[layout]

        if (!content) {
          console.error(
            'Tried rendering a section with a layout without content',
            layout
          )
          return null
        }

        /**
         * Pick a component based on layout.
         */
        let Component: React.ReactNode
        switch (layout) {
          case 'sectionSpacer':
            Component = <SectionSpacer {...(content as SectionSpacerProps)} />
            break

          case 'sectionDivider':
            Component = <SectionDivider {...(content as SectionDividerProps)} />
            break

          case 'sectionText':
            Component = <SectionText {...(content as SectionTextProps)} />
            break

          case 'sectionImage':
            Component = (
              <SectionImage {...(content as SectionImageProps)} index={index} />
            )
            break

          case 'sectionImageSlider':
            Component = (
              <SectionImageSlider {...(content as SectionImageSliderProps)} />
            )
            break

          case 'sectionImageGallery':
            Component = (
              <SectionImageGallery
                {...(content as SectionImageGalleryProps)}
                index={index}
              />
            )
            break

          case 'sectionImageImage':
            Component = (
              <SectionImageImage
                {...(content as SectionImageImageProps)}
                index={index}
              />
            )
            break

          case 'sectionText33Image66':
            Component = (
              <SectionText33Image66
                {...(content as SectionText33Image66Props)}
                index={index}
              />
            )
            break

          case 'sectionText66Image33':
            Component = (
              <SectionText66Image33
                {...(content as SectionText66Image33Props)}
                index={index}
              />
            )
            break

          case 'sectionTextTextImage':
            Component = (
              <SectionTextTextImage
                {...(content as SectionTextTextImageProps)}
                index={index}
              />
            )
            break
        }

        /**
         * Apply some styling to component container based on layout.
         */
        let sx: SxProps<Theme>
        switch (layout) {
          case 'sectionImageSlider':
            sx = {}
            break

          default:
            sx = {
              my: sectionSpacing * 2,
            }
        }

        return (
          <Fade
            in
            key={index}
            timeout={{
              enter: theme.transitions.duration.enteringScreen * 2,
            }}
          >
            <Box sx={sx}>{Component}</Box>
          </Fade>
        )
      })}
    </>
  )
}

export default Sections
