import { Chip, chipClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

export const slimChipTypographyStyles = {
  fontSize: '0.6875rem',
  lineHeight: 1.75,
  textTransform: 'uppercase',
}

const SlimChip = styled(Chip)(({ theme }) => ({
  ...slimChipTypographyStyles,
  /**
   * NOTE: For some god-damn reason I
   * have to duplicate the textTransform
   * line, what the fuck TypeScript.
   */
  textTransform: 'uppercase',
  height: 'auto',
  minHeight: 20,
  borderStyle: 'solid',
  borderColor: theme.palette.grey[400],
  borderWidth: '1px',
  [`&.${chipClasses.filled}`]: {
    borderColor: 'transparent',
  },
}))

export default SlimChip
