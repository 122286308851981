import { Box, Container, Grid, Typography } from '@mui/material'
import Logo from 'components/atoms/Logo'
import LoadingBox from 'components/molecules/LoadingBox'
import AccountUpdateForm from 'components/organisms/account/AccountUpdateForm'
import FatalErrorMessage from 'components/organisms/FatalErrorMessage'
import { accountLoginRedirectTimeoutMs } from 'constants/account'
import { mailSubjects, mailTemplates } from 'constants/account/mail'
import { partnerTypes } from 'constants/partner'
import useOdooAccountExists from 'hooks/odoo/account/useOdooAccountExists'
import useOdooApiCreate from 'hooks/odoo/api/useOdooApiCreate'
import { queryFn as odooApiQueryFn } from 'hooks/odoo/api/useOdooApiQuery'
import useOdooApiUpdate from 'hooks/odoo/api/useOdooApiUpdate'
import useOdooAdmin from 'hooks/odoo/useOdooAdmin'
import useOdooLogin from 'hooks/odoo/useOdooLogin'
import useMail from 'hooks/useMail'
import useSettings from 'hooks/useSettings'
import AccountEnableToken from 'interfaces/account/tokens/enable-token'
import Lead from 'interfaces/odoo/lead'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { sendEvent } from 'utils/gtag'
import { heuristicMatch } from 'utils/heuristics'
import { normalizeToNumber } from 'utils/normalization'

const AccountEnable = () => {
  const navigate = useNavigate()
  const accountExists = useOdooAccountExists()
  const { data: settings } = useSettings()
  const { token } = useParams()
  const { send } = useMail()
  const { data: admin } = useOdooAdmin()
  const { mutateAsync: login } = useOdooLogin()
  const { mutateAsync: create } = useOdooApiCreate()
  const { mutateAsync: update } = useOdooApiUpdate()
  const [error, setError] = useState('')

  const tokenDecoded = (() => {
    if (!token) {
      return
    }
    try {
      return jwtDecode<AccountEnableToken>(token)
    } catch (error) {
      console.error(error)
    }
  })()

  /**
   * When conditions are met, check if the account has already been enabled.
   */
  useEffect(() => {
    if (!tokenDecoded?.email || accountExists.status !== 'idle') {
      return
    }

    ;(async () => {
      const response = await accountExists.mutateAsync({
        email: tokenDecoded.email,
      })

      if (response?.account.metadata?.enabled) {
        setTimeout(() => {
          navigate('/')
        }, accountLoginRedirectTimeoutMs)

        setError(
          `This account has already been enabled. We'll be redirecting you to the log in page shortly...`
        )
      }
    })()
  }, [tokenDecoded?.email, accountExists, navigate])

  if (!tokenDecoded) {
    setError('The invitation is not valid anymore. Ask us for a new one')
    return null
  }

  if (error) {
    return <FatalErrorMessage hideLogoutButton text={error} />
  }

  /**
   * Attempt at matching a partner to an existing
   * lead in Odoo. Otherwise, create a new one.
   */
  const matchPartnerToLead = async (
    firstName: string,
    lastName: string,
    email: string
  ): Promise<Lead['id'] | false> => {
    const leads = await odooApiQueryFn<Lead>({
      model: 'crm.lead',
      query: ['id', 'name', 'contact_name', 'last_name', 'email_from', 'type'],
      filter: [
        ['type', '=', 'lead'],
        '|',
        '|',
        '|',
        ['email_from', '=', email],
        ['name', 'ilike', `${firstName} ${lastName}`],
        ['contact_name', 'ilike', firstName],
        ['last_name', 'ilike', lastName],
      ],
      token: admin!.data.token,
    })

    // Heuristically match if any of the results are good enough.
    const matches = leads.filter(
      (lead) =>
        lead.email_from === email ||
        heuristicMatch(lead, [firstName, lastName, email], 2)
    )

    if (matches.length) {
      const match = matches[0]

      const updated = await update({
        model: 'crm.lead',
        id: match.id,
        data: { partner_id: tokenDecoded.id },
      })

      if (!updated) {
        console.error(
          `Couldn't update the related lead matched with the enabled partner`
        )
        return false
      }

      return match.id
    } else {
      const created = await create({
        model: 'crm.lead',
        data: {
          name: `${firstName} ${lastName}`,
          email_from: email,
          contact_name: firstName,
          last_name: lastName,
          partner_id: tokenDecoded.id,
        },
      })

      if (!created) {
        console.error(`Couldn't create a lead for the enabled partner`)
        return false
      }

      return created
    }
  }

  /**
   * Finds the inviting partner for the passed partner ID.
   *
   * Uses the 'invited_by' email field for
   * doing the requried queries.
   */
  const getInvitingPartner = async (
    id: number
  ): Promise<{
    id: number | false
    user_dashboard_id: number | false
    user_ids: number[]
  }> => {
    const [invitedPartner] = await odooApiQueryFn<{
      invited_by: string
    }>({
      model: 'res.partner',
      query: ['invited_by'],
      filter: [['id', '=', id]],
      token: admin!.data.token,
    })

    if (!invitedPartner) {
      return {
        id: false,
        user_dashboard_id: false,
        user_ids: [],
      }
    }

    const [invitingPartner] = await odooApiQueryFn<{
      id: string
      user_dashboard_id: number | false
      user_ids: number[]
    }>({
      model: 'res.partner',
      query: ['id', 'user_dashboard_id', 'user_ids'],
      filter: [['email', '=', invitedPartner.invited_by]],
      token: admin!.data.token,
    })

    if (!invitingPartner) {
      return {
        id: false,
        user_dashboard_id: false,
        user_ids: [],
      }
    }

    return {
      id: normalizeToNumber(invitingPartner.id),
      user_dashboard_id: invitingPartner.user_dashboard_id,
      user_ids: invitingPartner.user_ids,
    }
  }

  const handleEnableSuccess = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) => {
    // Send a mail notifying that the account has been enabled.
    await send(
      [tokenDecoded.email],
      mailSubjects.accountEnabled,
      tokenDecoded.role === 'agent' || tokenDecoded.role === 'partner'
        ? mailTemplates.accountEnabledAgentPartner
        : mailTemplates.accountEnabledClientInvestor,
      {
        name: firstName,
        year: new Date().getFullYear(),
      },
      settings?.replies
    )

    // If client or investor, match/create a new lead for it.
    if (tokenDecoded.role === 'client' || tokenDecoded.role === 'investor') {
      const leadId = await matchPartnerToLead(firstName, lastName, email)

      // Further filling of contact owner/type for the lead.
      if (leadId) {
        const invitingPartner = await getInvitingPartner(tokenDecoded.id)

        /**
         * If the inviting partner doesn't have a Dashboard account, the only
         * plausible explanation for it inviting someone is for the partner
         * to be related with an Odoo account. That would mean an admin.
         *
         * Invitations by admin are considered direct.
         */
        const direct = !invitingPartner.user_dashboard_id

        await update({
          model: 'crm.lead',
          id: leadId,
          data: direct
            ? {
                user_id: invitingPartner.user_ids[0], // Sales Person
                contact_type_id: partnerTypes.direct,
              }
            : {
                contact_owner: invitingPartner.id,
                contact_type_id: partnerTypes.external,
              },
        })
      }
    }

    // Attempt to login.
    const success = await login({ email, password })

    if (success) {
      sendEvent(`login_${email}`)
      navigate('/')
    }
  }

  return (
    <Container>
      <Grid
        container
        spacing={3}
        sx={{ height: '100vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              marginBottom: 6,
            }}
          >
            <Logo />
          </Box>

          <Typography variant="h6" component="h1" mb={3}>
            Enable your account
          </Typography>

          <LoadingBox loading={!admin}>
            <AccountUpdateForm
              accountId={tokenDecoded.id}
              config={{
                labels: {
                  submitButton: 'Enable',
                },
                initialValues: {
                  email: tokenDecoded.email,
                },
              }}
              onSuccess={handleEnableSuccess}
            />
          </LoadingBox>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AccountEnable
