import { AppBar, Container, Toolbar } from '@mui/material'
import Content from 'components/atoms/Content'
import Logo from 'components/atoms/Logo'
import CenteredSpinner from 'components/molecules/CenteredSpinner'
import FatalErrorMessage from 'components/organisms/FatalErrorMessage'
import ShareProjects from 'components/organisms/share/ShareProjects'
import useProjects from 'hooks/useProjects'
import {
  ExpiringShareToken,
  ShareToken,
} from 'interfaces/account/tokens/share-token'
import jwtDecode from 'jwt-decode'
import { useParams } from 'react-router-dom'
import { getShareTokenFilteredProjects } from 'utils/share'
import { isTokenExpired } from 'utils/token'

const Share = () => {
  const { data: projects, isLoading: isProjectsLoading } = useProjects(true)
  const { token } = useParams()

  if (isProjectsLoading) {
    return <CenteredSpinner in={true} />
  }

  /**
   * Ensure the token works in a multitude of ways.
   */
  if (!token) {
    return (
      <FatalErrorMessage
        hideLogoutButton
        text="This link is not well formed, please ask for a new one."
      />
    )
  }

  const tokenExpired = isTokenExpired(token)
  if (tokenExpired) {
    return (
      <FatalErrorMessage
        hideLogoutButton
        text="This link has expired, please ask for a new one."
      />
    )
  }

  const tokenDecoded = token
    ? (() => {
        try {
          return jwtDecode<ShareToken | ExpiringShareToken>(token)
        } catch (error) {
          console.error(error)
        }
      })()
    : false
  if (!tokenDecoded) {
    return (
      <FatalErrorMessage
        hideLogoutButton
        text="This link is not valid, please ask for a new one."
      />
    )
  }

  /**
   * Use the IDs stored in tokenDecoded to search through
   * all projects and get the dwellings matching.
   */
  const filteredProjects = getShareTokenFilteredProjects(projects, tokenDecoded)
  if (!filteredProjects.length) {
    return (
      <FatalErrorMessage
        hideLogoutButton
        text="This link contains units that are no longer available, please ask for a new one."
      />
    )
  }

  return (
    <>
      <AppBar
        sx={{
          top: 0,
          border: 'none',
          borderBottom: '1px solid',
          borderColor: (theme) => theme.palette.divider,
          bgcolor: 'common.white',
        }}
        position="sticky"
        elevation={0}
      >
        <Toolbar
          sx={{
            color: 'grey.600',
          }}
        >
          <Logo />
        </Toolbar>
      </AppBar>

      <Container>
        <Content
          sx={{
            py: {
              xs: 3,
              lg: 6,
            },
          }}
        >
          <ShareProjects projects={filteredProjects} />
        </Content>
      </Container>
    </>
  )
}

export default Share
