import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { IconButton, IconButtonProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'constants/queries'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useOdooFavoriteCreate from 'hooks/odoo/favorite/useOdooFavoriteCreate'
import useOdooFavoriteDelete from 'hooks/odoo/favorite/useOdooFavoriteDelete'
import Dwelling from 'interfaces/dwelling'

type Props = {
  dwelling: Dwelling
  iconButtonProps?: IconButtonProps
}

const FavoriteButton = ({ dwelling, iconButtonProps }: Props) => {
  const queryClient = useQueryClient()
  const { data: me } = useOdooAccountMe()
  const { mutateAsync: create, status: createStatus } = useOdooFavoriteCreate()
  const { mutateAsync: deleteFn, status: deleteStatus } =
    useOdooFavoriteDelete()

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true })
  const fontSize = mobile ? 'small' : 'medium'

  const active = !!me?.metadata?.favorites.find((id) => id === dwelling.id)

  const handleClick = async (e: React.MouseEvent) => {
    /**
     * Prevents the propagation of the click event that
     * would otherwise bubble to possible surrounding
     * buttons triggering their click handler.
     */
    e.stopPropagation()

    /**
     * Favoriting works using partner ID.
     */
    if (!active) {
      await create({
        dwellingIds: [dwelling.id],
        accountIds: [me!.partner_id!.id],
      })
    } else {
      await deleteFn({
        dwellingIds: [dwelling.id],
        accountIds: [me!.partner_id!.id],
      })
    }

    queryClient.invalidateQueries(queryKeys.me)
  }

  if (!me?.partner_id?.id || !me.metadata) {
    return null
  }

  return (
    <IconButton
      aria-label={active ? 'Remove from favorites' : 'Add to favorites'}
      onClick={handleClick}
      disabled={createStatus === 'loading' || deleteStatus === 'loading'}
      {...iconButtonProps}
    >
      {active ? (
        <FavoriteIcon fontSize={fontSize} />
      ) : (
        <FavoriteBorderIcon fontSize={fontSize} />
      )}
    </IconButton>
  )
}

export default FavoriteButton
