import Lightbox from 'components/atoms/Lightbox'
import useAddImagesDimensions from 'hooks/useAddImagesDimensions'
import Project from 'interfaces/project'

type Props = {
  project: Project
}

const ShareMasterPlan = ({ project }: Props) => {
  const [imagesWithDimensions] = useAddImagesDimensions(
    project.overview?.masterPlan?.url
      ? {
          url: project.overview.masterPlan.url,
        }
      : []
  )

  const imageWithDimensions = !!imagesWithDimensions.length
    ? imagesWithDimensions[0]
    : null

  if (!imageWithDimensions) {
    return null
  }

  return (
    <Lightbox
      id="share-master-plan"
      alt="Share master plan"
      image={imageWithDimensions}
    />
  )
}

export default ShareMasterPlan
