import { Box, Fade } from '@mui/material'
import CenteredSpinner from 'components/molecules/CenteredSpinner'
import useImgLoaded from 'hooks/useImgLoaded'
import Project from 'interfaces/project'

type Props = {
  project: Project
  fullHeight?: boolean
}

const ProjectHero = ({ project, fullHeight = false }: Props) => {
  const isBgImgLoaded = useImgLoaded(project.images?.background)

  return (
    <div style={{ position: 'relative' }}>
      <Fade in={isBgImgLoaded} appear={false}>
        <Box
          sx={{
            position: 'relative',
            height: fullHeight ? '100vh' : '66vh',
            width: '100%',
            backgroundImage: project.images?.background
              ? `url(${project.images?.background})`
              : 'none',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            '&::after': !fullHeight
              ? {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  height: '15vh',
                  width: '100%',
                  background:
                    'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)',
                }
              : null,
          }}
        ></Box>
      </Fade>

      <CenteredSpinner in={!isBgImgLoaded} />
    </div>
  )
}

export default ProjectHero
