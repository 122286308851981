import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { ListItemIcon, MenuItem } from '@mui/material'
import useOdooLogout from 'hooks/odoo/useOdooLogout'
import { useNavigate } from 'react-router-dom'
import { sendEvent } from 'utils/gtag'

type Props = {
  onClick: () => void
}

const LogoutMenuItem = ({ onClick }: Props) => {
  const navigate = useNavigate()
  const { mutateAsync: logout } = useOdooLogout()

  const handleLogout = async () => {
    onClick()

    await logout()

    sendEvent('logout')
    navigate('/login')
  }

  return (
    <MenuItem onClick={handleLogout} aria-label="Log out">
      <ListItemIcon>
        <LogoutOutlinedIcon fontSize="small" />
      </ListItemIcon>
      Log out
    </MenuItem>
  )
}

export default LogoutMenuItem
