import { Chip, Stack, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getAvailabilityColor } from 'styles/availability-colors'
import DwellingStatusCode from 'types/dwelling/status-code'
import { hasMouseSupport } from 'utils/devices'

type Props = {
  value: React.ReactNode
  statusCode?: DwellingStatusCode
  resale?: boolean
}

const DwellingChip = ({ value, statusCode, resale }: Props) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true })
  const mouse = hasMouseSupport()
  const size = mobile ? 'small' : 'medium'

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Chip
        size={size}
        label={
          <Typography
            component="span"
            variant="body2"
            sx={[
              !!statusCode && {
                paddingLeft: 2.5,
                paddingRight: 1,
                position: 'relative',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: 0,
                  width: theme.spacing(1),
                  height: theme.spacing(1),
                  borderRadius: '50%',
                  bgcolor: getAvailabilityColor(statusCode),
                },
              },
            ]}
          >
            {value}
          </Typography>
        }
        sx={{
          cursor: 'inherit',
        }}
      />

      {resale && (
        <Tooltip title="Resale">
          <Chip
            size={size}
            label={
              <Typography component="span" variant="body2">
                {mouse ? 'RS' : 'Resale'}
              </Typography>
            }
            sx={{
              cursor: 'inherit',
            }}
          />
        </Tooltip>
      )}
    </Stack>
  )
}

export default DwellingChip
