import { Box, Stack, Typography } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import CenteredSpinner from 'components/molecules/CenteredSpinner'
import { errorMessages } from 'constants/errors'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useOdooAccountsGet from 'hooks/odoo/account/useOdooAccountsGet'
import AccountFilter from 'interfaces/account/filter'
import Project from 'interfaces/project'
import { useCallback, useState } from 'react'
import { filterAndSortAccounts } from 'utils/account/filter'
import { accountCanShare } from 'utils/account/role'
import AccountsFilters from './AccountsFilters'
import AccountsListItem from './AccountsListItem'

type Props = {
  project?: Project
  itemsPerPage?: number
  showProjects?: boolean
}

/**
 * A list of all accounts.
 *
 * @param project Filters the list by project in case it's provided.
 * @param itemsPerPage Accounts shown by page.
 * @param showProjects Includes the AccountProjects component.
 */
const AccountsList = ({
  project,
  itemsPerPage = 10,
  showProjects = false,
}: Props) => {
  const { data: me } = useOdooAccountMe()
  const { data: getResponse, isError, isLoading } = useOdooAccountsGet()

  const initialPage = 1
  const [page, setPage] = useState(initialPage)

  const [filters, setFilters] = useState<AccountFilter[]>([])

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
  }
  const handleAccountsFiltersChange = useCallback(
    (filters: AccountFilter[]) => {
      setFilters(filters)
      setPage(initialPage)
    },
    []
  )

  if (!me || !accountCanShare(me)) return null

  if (isLoading || !getResponse) {
    return (
      <Box sx={{ position: 'relative', py: 16 }}>
        <CenteredSpinner in />
      </Box>
    )
  }

  if (isError) {
    return (
      <Typography variant="subtitle1" component="span">
        {errorMessages.odooAccountsGet}
      </Typography>
    )
  }

  let { accounts } = getResponse

  // Filter/sort accounts.
  accounts = filterAndSortAccounts(accounts, me, project, filters)

  // Client side pagination, server side was proven too conflicting with the filtering.
  const pages = Math.ceil(accounts.length / itemsPerPage)
  const pageStart = (page - 1) * itemsPerPage
  const pageEnd = pageStart + itemsPerPage
  accounts = accounts.slice(pageStart, pageEnd)

  return (
    <>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <AccountsFilters onChange={handleAccountsFiltersChange} />
      </Box>

      {!accounts.length && (
        <Typography variant="subtitle1" component="span">
          No accounts found
        </Typography>
      )}

      {accounts.map((account, index) => {
        return (
          <Box key={index} sx={{ mb: index !== accounts.length - 1 ? 2 : 0 }}>
            <AccountsListItem account={account} showProjects={showProjects} />
          </Box>
        )
      })}

      {pages > 1 && (
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            mt: 4,
          }}
        >
          <Pagination count={pages} page={page} onChange={handlePageChange} />
        </Stack>
      )}
    </>
  )
}

export default AccountsList
