import { Stack } from '@mui/material'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { SxProps, Theme } from '@mui/material/styles'
import DwellingsTableCell from 'components/atoms/dwellings/DwellingsTableCell'
import DwellingsSort, {
  DwellingsSortProps,
} from 'components/organisms/dwellings/DwellingsSort'
import Dwelling from 'interfaces/dwelling'
import DwellingField from 'interfaces/dwelling/field'
import React from 'react'

type Props = {
  fields: DwellingField[] | []
  sortKey: keyof Dwelling | null
  onSort: DwellingsSortProps['onChange']
}

const DwellingsTableHead = ({ fields, sortKey, onSort }: Props) => {
  const cellSx: SxProps<Theme> = [
    {
      border: 'none',
      bgcolor: 'grey.200',
    },
  ]

  return (
    <TableHead>
      <TableRow>
        {fields.map((field, index) => {
          const spanStyle: React.CSSProperties = {
            whiteSpace: 'nowrap',
          }

          return (
            <DwellingsTableCell
              key={index}
              sx={[
                ...cellSx,
                index === 0 && {
                  borderTopLeftRadius: (theme) => theme.spacing(1),
                  borderBottomLeftRadius: (theme) => theme.spacing(1),
                },
              ]}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <span style={spanStyle}>{field.name}</span>

                <DwellingsSort
                  columnKey={field.key!}
                  sortKey={sortKey}
                  onChange={onSort}
                />
              </Stack>
            </DwellingsTableCell>
          )
        })}

        {/* Favorite button cell */}
        <DwellingsTableCell sx={cellSx} />

        {/* Action button cell */}
        <DwellingsTableCell
          sx={[
            ...cellSx,
            {
              borderTopRightRadius: (theme) => theme.spacing(1),
              borderBottomRightRadius: (theme) => theme.spacing(1),
            },
          ]}
        />
      </TableRow>
    </TableHead>
  )
}

export default DwellingsTableHead
