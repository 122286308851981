import { useMutation } from '@tanstack/react-query'
import { errorMessages } from 'constants/errors'
import OdooAccountExistsResponse from 'interfaces/odoo/account/exists-response'
import useOdooAdmin from '../useOdooAdmin'

type TVariables = {
  email: string
}
type TData = OdooAccountExistsResponse['result']
type TError = Error

const useOdooAccountExists = () => {
  const {
    data: admin,
    status: adminStatus,
    isLoading: isAdminLoading,
  } = useOdooAdmin()

  const mutation = async (variables: TVariables): Promise<TData> => {
    if (!admin?.success) {
      throw new Error(errorMessages.odooAdmin)
    }

    const response = await fetch(
      import.meta.env.VITE_ODOO_ACCOUNTS_API_EXISTS_ENDPOINT,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${admin.data.token}`,
        },
        body: JSON.stringify({
          params: {
            args: [
              {
                email: variables.email,
              },
            ],
          },
        }),
      }
    )

    const { result, error }: OdooAccountExistsResponse = await response.json()

    if (!response.ok || !result?.exists || error) {
      /**
       * Error message reporting for this specific endpoint is a bit cumbersome.
       *
       * When no account exists it doesn't give an error, it just sets
       * the `exists` flag to `false`, so we have to account for it.
       */
      const message = (() => {
        if (result?.exists === false) {
          return result.message
        }

        if (error?.data.message) {
          return error.data.message
        }

        return errorMessages.odooAccountExists
      })()

      throw new Error(message)
    }

    return result
  }

  const { isLoading, status, ...rest } = useMutation<TData, TError, TVariables>(
    mutation
  )

  return {
    ...rest,
    isLoading: isAdminLoading || isLoading,
    status: adminStatus !== 'success' ? adminStatus : status,
  }
}

export default useOdooAccountExists
