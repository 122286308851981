import { Box, Checkbox } from '@mui/material'
import AccountsList from 'components/organisms/account/AccountsList'
import ProjectStrip, {
  ProjectStripProps,
} from 'components/organisms/project/ProjectStrip'
import ProjectThumbnail from 'components/organisms/project/ProjectThumbnail'
import { changePermissionsType } from 'hooks/useProjectPermissions'
import Project from 'interfaces/project'
import ProjectPermission from 'interfaces/project-permission'
import DwellingsInfoFields from '../dwellings/DwellingsInfoFields'

type Props = {
  project: Project
  projectStripProps?: Partial<ProjectStripProps>
  permissions: ProjectPermission['slug'][]
  changePermissions: changePermissionsType
}

/**
 * Wraps `ProjectStrip` and gives it the props needed for permissions setting.
 */
const ProjectStripWithPermission = ({
  project,
  projectStripProps,
  permissions,
  changePermissions,
}: Props) => {
  return (
    <ProjectStrip
      thumbnail={
        <ProjectThumbnail
          project={project}
          afterTitle={
            <Checkbox
              size="small"
              value={project.slug.current}
              checked={permissions.includes(project.slug.current)}
              onChange={(e, checked) =>
                changePermissions(
                  checked ? 'add' : 'delete',
                  project.slug.current
                )
              }
            />
          }
        />
      }
      sideContent={<DwellingsInfoFields project={project} />}
      collapseTitle="Accounts with access"
      collapseContent={
        <Box
          sx={{
            py: 3,
            px: 1.5,
            pl: {
              xs: 1.5,
              sm: 3,
            },
          }}
        >
          <AccountsList project={project} />
        </Box>
      }
      {...projectStripProps}
    />
  )
}

export default ProjectStripWithPermission
