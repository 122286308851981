import { TabPanelProps, useTabContext } from '@mui/lab'
import { Box } from '@mui/material'

/**
 * This should work more or less like MUI's own `TabPanel`,
 * but this one will not unmount hidden tabs.
 *
 * @link https://github.com/mui/material-ui/issues/21250
 * @param props
 * @returns
 */
const PersistentTabPanel = (props: TabPanelProps) => {
  const { children, sx, value: id, ...rest } = props
  const context = useTabContext()

  if (context === null) {
    throw new TypeError('No TabContext provided')
  }

  return (
    <Box
      {...rest}
      sx={{
        ...sx,
        visibility: id === context.value ? 'visible' : 'hidden',
      }}
      hidden={id !== context.value}
    >
      {children}
    </Box>
  )
}

export default PersistentTabPanel
