import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'

const HomeButton = (props: IconButtonProps) => {
  const navigate = useNavigate()

  const handleHome = () => {
    navigate('/selector')
  }

  return (
    <IconButton
      onClick={handleHome}
      aria-label="Home"
      color="inherit"
      {...props}
    >
      <HomeOutlinedIcon />
    </IconButton>
  )
}

export default HomeButton
