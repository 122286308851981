import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Collapse, Stack } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { useTheme } from '@mui/material/styles'
import { useQueryClient } from '@tanstack/react-query'
import SlimChip from 'components/atoms/SlimChip'
import { errorMessages } from 'constants/errors'
import { queryKeys } from 'constants/queries'
import { statusToIdleTimeout } from 'constants/status'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useOdooAccountUpdate from 'hooks/odoo/account/useOdooAccountUpdate'
import Account from 'interfaces/account'
import { useState } from 'react'
import AccountRole from 'types/account/role'
import Status from 'types/status'
import { accountIsEnabled } from 'utils/account/metadata'
import {
  accountIsAdmin,
  getAccountAvailableRoles,
  getAccountRoleFormatted,
} from 'utils/account/role'
import AccountChipIcon from './AccountChipIcon'
import { baseAccountChipsSx } from './AccountChips'
import AccountRoleUpdateChipList from './AccountRoleUpdateChipList'

/**
 * When the account is disabled the leftmost border radiuses go
 * to 0 in order to accommodate with the invitation reminder chip
 * (most probably) being rendered alongside it.
 */
const getBorderRadiusSx = (account: Account) => {
  if (accountIsEnabled(account)) {
    return []
  }

  return [
    {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  ]
}

type Props = {
  account: Account
}

const AccountRoleUpdateChip = ({ account }: Props) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { mutateAsync: update } = useOdooAccountUpdate()
  const [rolesListOpen, setRolesListOpen] = useState(false)
  const [status, setStatus] = useState<Status>('idle')
  const { data: me } = useOdooAccountMe()

  const sx = [...baseAccountChipsSx, ...getBorderRadiusSx(account)]
  const availableRoles = getAccountAvailableRoles(account, me)
  const formattedRole = getAccountRoleFormatted(account.metadata?.role)

  const handleUpdate = async (role: AccountRole) => {
    setRolesListOpen(false)

    /**
     * Wait the standard transition duration (applied
     * in the Collapse of the roles list) so it has
     * time to close before updating the role.
     */
    setTimeout(async () => {
      setStatus('loading')

      try {
        const response = await update({
          id: account.id,
          email: account.email,
          metadata: {
            role,
          },
        })

        if (!response?.updated) {
          throw new Error(errorMessages.odooAccountUpdate)
        }

        queryClient.invalidateQueries(queryKeys.accounts)

        setStatus('success')

        // And after a few seconds, revert to idle.
        setTimeout(() => {
          setStatus('idle')
        }, statusToIdleTimeout)
      } catch (error) {
        setStatus('error')
      }
    }, theme.transitions.duration.standard)
  }

  if (!availableRoles.length || accountIsAdmin(account)) {
    return <SlimChip size="small" sx={sx} label={formattedRole} />
  }

  return (
    <ClickAwayListener onClickAway={() => setRolesListOpen(false)}>
      <Box
        sx={{
          position: 'relative',
          /**
           * This avoids growing unnecessarily beyond
           * the required for the content.
           */
          display: 'flex',
        }}
      >
        <SlimChip
          size="small"
          sx={sx}
          onClick={() => setRolesListOpen(!rolesListOpen)}
          label={
            <Stack
              spacing={0.5}
              component="span"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <span>{formattedRole}</span>

              <AccountChipIcon
                idleIcon={<ExpandMoreIcon />}
                rotated={rolesListOpen}
                status={status}
              />
            </Stack>
          }
        />

        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '100%',
            transform: 'translateX(-50%)',
            zIndex: 2 /* So it stays above ProjectStrip */,
          }}
        >
          <Collapse in={rolesListOpen}>
            <AccountRoleUpdateChipList
              availableRoles={availableRoles}
              handleUpdate={handleUpdate}
            />
          </Collapse>
        </Box>
      </Box>
    </ClickAwayListener>
  )
}

export default AccountRoleUpdateChip
