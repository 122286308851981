import { Box, Container } from '@mui/material'
import ProjectHero from 'components/atoms/ProjectHero'
import OverviewMasterPlan from 'components/molecules/overview/OverviewMasterPlan'
import OverviewContentSection from 'components/organisms/overview/OverviewContentSection'
import OverviewLocationSection from 'components/organisms/overview/OverviewLocationSection'
import ProjectHeader from 'components/organisms/project/ProjectHeader'
import SectionSpacingContext from 'contexts/SectionSpacingContext'
import useProject from 'hooks/useProject'

const ProjectOverview = () => {
  const project = useProject()
  const sectionSpacing = { xs: 6, md: 9 }

  if (!project) {
    return null
  }

  return (
    <SectionSpacingContext.Provider value={sectionSpacing}>
      <ProjectHeader project={project} />

      <ProjectHero
        project={project}
        fullHeight={
          // If there is no meaningful content, print it full height.
          !project.overview?.primary &&
          !project.overview?.location &&
          !project.overview?.secondary
        }
      />

      {project.overview?.primary && (
        <Container>
          <Box sx={{ marginY: sectionSpacing }}>
            <OverviewContentSection contentSection={project.overview.primary} />
          </Box>
        </Container>
      )}

      {project.overview?.location && (
        <Box sx={{ marginY: sectionSpacing }}>
          <OverviewLocationSection
            locationSection={project.overview.location}
          />
        </Box>
      )}

      {project.overview?.secondary && (
        <Container>
          <Box sx={{ marginY: sectionSpacing }}>
            <OverviewContentSection
              contentSection={project.overview.secondary}
            />
          </Box>
        </Container>
      )}

      {project.overview?.masterPlan && (
        <Container>
          <Box sx={{ marginY: sectionSpacing, marginTop: { md: -6 } }}>
            <OverviewMasterPlan
              title={project.overview.masterPlan.title}
              image={{
                url: project.overview.masterPlan.url,
                thumbUrl: project.overview.masterPlan.url,
              }}
            />
          </Box>
        </Container>
      )}
    </SectionSpacingContext.Provider>
  )
}

export default ProjectOverview
