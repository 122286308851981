import { Tab, tabClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const RailTab = styled(Tab)(({ theme }) => ({
  flex: 1,
  minWidth: 100,
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  color: theme.palette.grey[600],
  fontWeight: 300,
  [`&.${tabClasses.selected}`]: {
    fontWeight: 500,
  },
}))

export default RailTab
