import { Stack, Typography } from '@mui/material'
import Logo from 'components/atoms/Logo'

const Maintenance = () => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={6}
      sx={{
        padding: 6,
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Logo />

      <Typography>
        We're currently under maintenance, we'll be back soon
      </Typography>
    </Stack>
  )
}

export default Maintenance
