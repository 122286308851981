import { useMutation } from '@tanstack/react-query'
import { errorMessages } from 'constants/errors'
import OdooAuthResponse from 'interfaces/odoo/auth-response'

type TVariables = {
  email: string
  password: string
}
type TData = OdooAuthResponse['result']
type TError = Error

export const mutationFn = async (variables: TVariables): Promise<TData> => {
  const response = await fetch(import.meta.env.VITE_ODOO_AUTH_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      params: {
        login: variables.email,
        password: variables.password,
        db: import.meta.env.VITE_ODOO_DB,
      },
    }),
  })

  const { result, error }: OdooAuthResponse = await response.json()

  if (!response.ok || !result?.success || error) {
    throw new Error(error?.data.message ?? errorMessages.odooAuth)
  }

  return result
}

const useOdooAuth = () => useMutation<TData, TError, TVariables>(mutationFn)

export default useOdooAuth
