import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Stack, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import StackOverflow from 'components/atoms/StackOverflow'
import ButtonTab from 'components/atoms/tabs/ButtonTab'
import ButtonTabList from 'components/atoms/tabs/ButtonTabList'
import { shareDialogModes } from 'constants/share/dialog-modes'
import ShareDialogMode from 'types/share/dialog-mode'

type Props = {
  titleId: string
  title: string
  onCloseClick?: React.MouseEventHandler<HTMLButtonElement>
  onTabChange: (event: React.SyntheticEvent, newMode: ShareDialogMode) => void
}

const ShareDialogHeader = ({
  titleId,
  title,
  onCloseClick,
  onTabChange,
}: Props) => {
  return (
    <Stack
      sx={{
        mb: 3,
        position: 'relative',
        zIndex: 3 /* So it stays above some possible sticky headers under it */,
      }}
      direction="row"
      justifyContent="space-between"
      alignItems={{
        xs: 'flex-start',
        sm: 'center',
      }}
    >
      <Stack
        spacing={3}
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        alignItems={{
          xs: 'flex-start',
          sm: 'center',
        }}
        sx={{ maxWidth: '100%' }}
      >
        <Typography id={titleId} variant="h5" component="h3">
          {title}
        </Typography>

        <StackOverflow>
          <ButtonTabList
            onChange={onTabChange}
            aria-label="Share mode tabs"
            sx={{ overflow: 'visible' }}
          >
            {shareDialogModes.map((mode) => (
              <ButtonTab
                key={mode.slug}
                label={mode.name}
                value={mode.slug}
                iconPosition="start"
                icon={mode.icon}
                sx={(() => {
                  /**
                   * Adds an ':after' based divider when appropiate.
                   *
                   * NOTE: This isn't done with `Divider` because
                   * inside a `TabList` we can't add arbitrary
                   * elements that are not `Tab`s.
                   */
                  if (!mode.divider) {
                    return undefined
                  }

                  return {
                    overflow: 'visible',
                    marginRight: 2,
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      right: (theme) => theme.spacing(-1.5),
                      transform: 'translateX(50%)',
                      height: '80%',
                      width: '1px',
                      bgcolor: 'action.focus',
                    },
                  }
                })()}
              />
            ))}
          </ButtonTabList>
        </StackOverflow>
      </Stack>

      {onCloseClick && (
        <IconButton
          aria-label="Close"
          edge="end"
          onClick={onCloseClick}
          sx={{
            /**
             * Absolutely positioned because tabs should use as
             * much space as possible, and we don't want them
             * to be constricted by the button.
             */
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      )}
    </Stack>
  )
}

export default ShareDialogHeader
