import { Container, Grid, Typography } from '@mui/material'
import Lightbox from 'components/atoms/Lightbox'
import { sectionSpacing } from 'constants/section'
import useAddImagesDimensions from 'hooks/useAddImagesDimensions'
import SectionImageImageProps from 'interfaces/section/image-image'

const SectionImageImage = ({
  title,
  leftSubtitle,
  leftImage,
  rightSubtitle,
  rightImage,
  index,
}: SectionImageImageProps & { index: number }) => {
  const [[leftImageWithDimensions]] = useAddImagesDimensions(
    leftImage
      ? {
          url: leftImage,
        }
      : []
  )
  const [[rightImageWithDimensions]] = useAddImagesDimensions(
    rightImage
      ? {
          url: rightImage,
        }
      : []
  )

  return (
    <Container>
      <Grid container spacing={sectionSpacing}>
        <Grid item xs={12}>
          {!!title && (
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {!!leftSubtitle && (
            <Typography
              variant="subtitle1"
              component="h3"
              sx={{ mb: sectionSpacing / 2 }}
            >
              {leftSubtitle}
            </Typography>
          )}
          {!!leftImageWithDimensions && (
            <Lightbox
              id={`section-image-image-${index}-left-image`}
              image={leftImageWithDimensions}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {!!rightSubtitle && (
            <Typography
              variant="subtitle1"
              component="h3"
              sx={{ mb: sectionSpacing / 2 }}
            >
              {rightSubtitle}
            </Typography>
          )}
          {!!rightImageWithDimensions && (
            <Lightbox
              id={`section-image-image-${index}-right-image`}
              image={rightImageWithDimensions}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default SectionImageImage
