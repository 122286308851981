import useOdooPartnerDwellings from 'hooks/odoo/partner/useOdooPartnerDwellings'
import Dwelling from 'interfaces/dwelling'
import { useLocation } from 'react-router-dom'
import {
  isDwellingStatusAvailable,
  isDwellingStatusUnknown,
} from 'utils/dwelling'
import { isShareRoute } from 'utils/share'

/**
 * Checks if a dwelling is enabled (details should be shown).
 */
const useDwellingEnabled = (dwelling: Dwelling) => {
  const { pathname } = useLocation()

  const shareRoute = isShareRoute(pathname)
  const available = isDwellingStatusAvailable(dwelling)
  const unknown = isDwellingStatusUnknown(dwelling)
  const { dwellings: partnerDwellings } = useOdooPartnerDwellings()

  return (
    dwelling.resale ||
    shareRoute ||
    available ||
    unknown ||
    !!partnerDwellings.find(
      (partnerDwelling) => partnerDwelling.id === dwelling.id
    )
  )
}

export default useDwellingEnabled
