import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

const InteractiveTooltip = styled(
  ({ className, PopperProps, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      PopperProps={{ ...PopperProps, style: { zIndex: 0 } }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    /**
     * Both padding and background color should be
     * provided by the tooltip's content.
     *
     * This allows for better flexibility in
     * styles/interactivity.
     */
    padding: theme.spacing(0),
    backgroundColor: 'transparent',

    color: theme.palette.secondary.light,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: `${theme.palette.grey[100]}cc`,
  },
}))

export default InteractiveTooltip
