import { Grid, Paper } from '@mui/material'
import useCacheImages from 'hooks/useCacheImages'
import { ChangelogEntryWithIsLastEntryUnread } from 'interfaces/changelog/changelog-entry-with-is-last-entry-unread'
import { useEffect, useState } from 'react'
import { isChangelogEntryUnread } from 'utils/changelog/local-storage'

import ChangelogEntryPortableText from './ChangelogEntryPortableText'
import Lightbox from 'components/atoms/Lightbox'
import Image from 'interfaces/image'

const ChangelogEntry = (props: ChangelogEntryWithIsLastEntryUnread) => {
  const [featuredImageVisible, setFeaturedImageVisible] = useState(true)
  const featuredImage: Image | undefined = props.featuredImage
    ? {
        url: props.featuredImage?.url,
        width: props.featuredImage?.metadata.dimensions.width,
        height: props.featuredImage?.metadata.dimensions.height,
      }
    : undefined

  const [unread, setUnread] = useState(false)

  const imageBlocks = props.content.filter((block) => block._type === 'image')
  const srcs = imageBlocks.map((block) => block.asset && block.asset.url)
  const cached = useCacheImages(srcs)

  useEffect(() => {
    setUnread(isChangelogEntryUnread(props))
  }, [props])

  if (!cached) return null

  return (
    <Paper sx={{ width: '100%' }}>
      <Grid container position="relative">
        {featuredImage && featuredImageVisible && (
          <Grid item xs={12} sm={4}>
            <Lightbox
              id="changelog-entry-featured-image"
              image={featuredImage}
              alt="Changelog entry image"
              imageStyle={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                display: 'block',
                minHeight: 250,
                maxHeight: 250,
              }}
            />
          </Grid>
        )}

        <ChangelogEntryPortableText
          entry={props}
          entryUnread={unread}
          onExpandedChange={(newExpanded) =>
            setFeaturedImageVisible(!newExpanded)
          }
        />
      </Grid>
    </Paper>
  )
}

export default ChangelogEntry
