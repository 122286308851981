import ForcedWatermarkContext from 'contexts/ForcedWatermarkContext'
/**
 * PhotoSwipe doesn't have type definitions for v5.
 */
import { PhotoSwipeOptions } from 'photoswipe'
// @ts-expect-error
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
import { useContext } from 'react'
import 'styles/photoswipe.css'
import { accountHasWatermarks } from 'utils/account/role'
import { getPhotoSwipeLightboxArrow } from 'utils/photoswipe'
import useOdooAccountMe from './odoo/account/useOdooAccountMe'

export type LightboxOptions = PhotoSwipeOptions & {
  counterPrefix?: string
}

const usePhotoSwipeLightbox = () => {
  const loadingClass = 'pswp-loading'
  const { data: me } = useOdooAccountMe()
  const forcedWatermark = useContext(ForcedWatermarkContext)

  const createLightbox = (options: LightboxOptions) => {
    const { counterPrefix, ...customOptions } = options

    // Create the lightbox (without initialization).
    const lightbox = new PhotoSwipeLightbox({
      // Default options.
      showHideAnimationType: 'fade',
      indexIndicatorSep: ' of ',
      arrowPrevSVG: getPhotoSwipeLightboxArrow('prev'),
      arrowNextSVG: getPhotoSwipeLightboxArrow('next'),
      pswpModule: () => import('photoswipe'),

      ...customOptions,
    })

    // Custom caption, code from PhotoSwipe docs.
    lightbox.on('uiRegister', function () {
      if (forcedWatermark || !me || accountHasWatermarks(me)) {
        lightbox?.pswp?.element?.classList.add('pswp__watermarked')
      }

      lightbox?.pswp?.ui.registerElement({
        name: 'custom-caption',
        order: 9,
        isButton: false,
        appendTo: 'root',
        html: 'Caption text',
        onInit: (el: any, pswp: any) => {
          lightbox?.pswp?.on('change', () => {
            let captionHTML = ''

            /**
             * First try getting the caption (custom) from the data source.
             */
            captionHTML = lightbox?.pswp?.currSlide.data.caption || ''

            /**
             * Second try getting the caption from the DOM.
             */
            const currSlideElement = lightbox?.pswp?.currSlide.data.element
            if (currSlideElement) {
              const captionEl =
                currSlideElement.querySelector('.caption-content')

              if (captionEl) {
                captionHTML = captionEl.outerHTML
              }
            }

            el.innerHTML = captionHTML || ''
          })
        },
      })

      if (counterPrefix) {
        lightbox?.pswp?.ui.registerElement({
          name: 'counter-prefix',
          order: 4,
          tagName: 'span',
          html: counterPrefix,
        })
      }
    })

    return lightbox
  }

  /**
   * Sets a loading class in the `<body>` of the DOM that lets other parts of the
   * application know that you are loading images for PhotoSwipe to show.
   */
  const setLightboxLoadingClass = () => {
    document.querySelector('body')?.classList.add(loadingClass)
  }

  /**
   * Removes the loading class in the `<body>` of the DOM that lets other parts of the
   * application know that you are loading images for PhotoSwipe to show.
   */
  const removeLightboxLoadingClass = () => {
    document.querySelector('body')?.classList.remove(loadingClass)
  }

  return {
    loadingClass,
    createLightbox,
    setLightboxLoadingClass,
    removeLightboxLoadingClass,
  }
}

export default usePhotoSwipeLightbox
