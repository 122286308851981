import ProjectPermission from 'interfaces/project-permission'
import { useCallback, useMemo, useState } from 'react'

export type changePermissionsType = (
  action: 'add' | 'delete',
  value: ProjectPermission['slug']
) => void

/**
 * Manages a set of project permissions, returning their
 * values and providing a function to change them.
 */
const useProjectPermissions = (initial?: ProjectPermission['slug'][]) => {
  const [permissions, setPermissions] = useState<
    Set<ProjectPermission['slug']>
  >(new Set(initial))

  const changePermissions = useCallback<changePermissionsType>(
    (action, value) => {
      setPermissions((prevPermissions) => {
        const nextPermissions = new Set<ProjectPermission['slug']>(
          prevPermissions
        )

        if (action === 'add') {
          nextPermissions.add(value)
        }
        if (action === 'delete') {
          nextPermissions.delete(value)
        }

        return nextPermissions
      })
    },
    []
  )

  const memoizedPermissions = useMemo(() => [...permissions], [permissions])

  return {
    permissions: memoizedPermissions,
    changePermissions,
  }
}

export default useProjectPermissions
