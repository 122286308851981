import { List, ListItem, Stack, Typography } from '@mui/material'
import Dwelling from 'interfaces/dwelling'
import Project from 'interfaces/project'
import format from 'utils/format'

type Props = {
  dwellings: Dwelling[]
  project: Project
}

const DwellingsBulletPoints = ({ dwellings, project }: Props) => {
  const constructionType = project.dwellings?.promotion
    ?.promotionContructionType
    ? project.dwellings?.promotion.promotionContructionType.name
    : false
  const hasMultipleDwellings = dwellings.length > 1

  const prices = dwellings.map((dwelling) => dwelling.price)
  const minPrice = Math.min(...prices)
  const maxPrice = Math.max(...prices)

  const beds = dwellings.map((dwelling) => dwelling.beds)
  const minBed = Math.min(...beds)
  const maxBed = Math.max(...beds)

  const totalsConstructed = dwellings.map(
    (dwelling) => dwelling.totalConstructed
  )
  const minTotalConstructed = Math.min(...totalsConstructed)

  const gardenAreas = dwellings.map((dwelling) => dwelling.gardenArea)
  const minGardenArea = Math.min(...gardenAreas)

  return (
    <Stack spacing={1.5}>
      <List
        dense
        sx={{
          pl: 3,
          listStyleType: 'disc',
          '& .MuiListItem-root': {
            display: 'list-item',
          },
        }}
      >
        {minPrice > 0 && (
          <ListItem>
            <Typography>
              From {format('currency', minPrice, { maximumFractionDigits: 0 })}{' '}
              {hasMultipleDwellings &&
                minPrice !== maxPrice &&
                `to ${format('currency', maxPrice, {
                  maximumFractionDigits: 0,
                })}`}
            </Typography>
          </ListItem>
        )}

        {minBed > 0 && (
          <ListItem>
            <Typography>
              {minBed}{' '}
              {hasMultipleDwellings && minBed !== maxBed && `and ${maxBed}`}{' '}
              bedrooms
            </Typography>
          </ListItem>
        )}

        {minTotalConstructed > 0 && (
          <ListItem>
            <Typography>
              From {minTotalConstructed} m<sup>2</sup> total built
            </Typography>
          </ListItem>
        )}

        {minGardenArea > 0 && (
          <ListItem>
            <Typography>
              {`${
                constructionType === 'Villas' ? 'Plot size' : 'Terrace size'
              } from ${minGardenArea}`}{' '}
              m<sup>2</sup>
            </Typography>
          </ListItem>
        )}
      </List>
    </Stack>
  )
}

export default DwellingsBulletPoints
