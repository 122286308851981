import ShareLifespan from 'types/share/lifespan'

const shareLifespans: ShareLifespan[] = [
  {
    id: '1-day',
    label: '1 day',
    value: 86400,
  },
  {
    id: '1-week',
    label: '1 week',
    value: 604800,
    default: true
  },
  {
    id: '1-month',
    label: '1 month',
    value: 2678400,
  },
  {
    id: '2-months',
    label: '2 months',
    value: 5356800,
  },
]

export default shareLifespans
