import GlobalStyles from '@mui/material/GlobalStyles'
import { ThemeProvider } from '@mui/material/styles'
import { DefaultTheme, ThemeProviderProps } from '@mui/system'

const globalFontFamily = (
  <GlobalStyles
    styles={(theme) => ({
      body: { fontFamily: theme.typography.fontFamily },
    })}
  />
)

const ThemeProviderWithGlobalFontFamily = <T = DefaultTheme,>({
  theme,
  children,
}: ThemeProviderProps<T>) => {
  return (
    <ThemeProvider theme={theme}>
      {globalFontFamily}

      {children}
    </ThemeProvider>
  )
}

export default ThemeProviderWithGlobalFontFamily
