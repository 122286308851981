import { ListItem, ListItemButton, ListItemText } from '@mui/material'
import Badge from '@mui/material/Badge'
import NavLink from 'components/atoms/NavLink'
import Project from 'interfaces/project'
import RouteMetadata from 'interfaces/route-metadata'
import { useEffect, useState } from 'react'
import { sendEvent } from 'utils/gtag'
import lastRead from 'utils/last-read'

type Props = {
  project: Project
  variant: 'row' | 'column'
  routeMetadata: RouteMetadata
}

const NavListItem = ({ project, variant, routeMetadata }: Props) => {
  const [isBadgeInvisible, setIsBadgeInvisible] = useState(true)

  const handleLinkClick = (routeSlug: string) => {
    sendEvent(`page_view_${project.slug.current}_${routeSlug}`)
  }

  // Check if the content of the route this NavListItem is representing has changed.
  useEffect(() => {
    if (!routeMetadata.content) {
      return
    }

    const changed = lastRead.changed(
      lastRead.buildKey(project.slug.current, routeMetadata.slug),
      routeMetadata.content(project)
    )

    setIsBadgeInvisible(!changed)
  }, [project, routeMetadata])

  return (
    <ListItem
      sx={{
        width: variant === 'row' ? 'auto' : null,
      }}
      key={routeMetadata.slug}
      disablePadding
    >
      <ListItemButton
        component={NavLink}
        href={`/${project.slug.current}/${routeMetadata.slug}`}
        sx={{
          whiteSpace: 'nowrap',
          paddingX:
            variant === 'row'
              ? {
                  lg: 1.5,
                  xl: 3,
                }
              : null,
        }}
        onClick={() => handleLinkClick(routeMetadata.slug)}
      >
        <Badge
          sx={{
            width: '100%',
            '& .MuiBadge-badge': {
              top: variant === 'row' ? 0 : '50%',
            },
          }}
          color="primary"
          variant="dot"
          invisible={isBadgeInvisible}
        >
          <ListItemText
            // This 'inherit' allows the parent NavLink to establish the weight.
            primaryTypographyProps={{ sx: { fontWeight: 'inherit' } }}
            primary={routeMetadata.name}
          />
        </Badge>
      </ListItemButton>
    </ListItem>
  )
}

export default NavListItem
