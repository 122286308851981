import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { SvgIconProps } from '@mui/material'
import ClickAwayTooltip from 'components/molecules/ClickAwayTooltip'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import Account from 'interfaces/account'
import { getAccountHumanDates } from 'utils/account/metadata'
import { accountIsAdmin } from 'utils/account/role'

const getTooltipText = (account: Account) => {
  const by = account.metadata?.invited_by
  const { invited, reminded } = getAccountHumanDates(account)

  if (!by) {
    return
  }

  /**
   * NOTE: This logic below may appear confusing at first, and
   * that is exactly because *it is*.
   *
   * So, the database initially only saved one field, `invited`,
   * in said field we'd save the first invitation date and any
   * subsequent reminder dates, thus overriding the first
   * invitation date. Now we've added a new `reminded` field,
   * improving the situation and allowing for distinct first
   * invitation date and last reminder date.
   *
   * If both `invited` and `reminded` fields exist, we assume
   * the account was created after the database update. If only
   * `invited` exists, we assume it was created with the old
   * database and therefore date accuracy is reduced to only
   * the notification sent date.
   */

  if (invited && reminded) {
    return (
      <span>
        Invited by {by} on {invited}
        <br />
        Reminder sent on {reminded}
      </span>
    )
  }

  if (invited) {
    return (
      <span>
        Invited by {by}
        <br />
        Notification sent on {invited}
      </span>
    )
  }
}

type Props = {
  account: Account
  fontSize?: SvgIconProps['fontSize']
}

const AccountActionInfoTooltip = ({ account, fontSize }: Props) => {
  const tooltipText = getTooltipText(account)
  const { data: me } = useOdooAccountMe()

  if (!tooltipText || !accountIsAdmin(me)) {
    return null
  }

  return (
    <ClickAwayTooltip
      title={tooltipText}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: '50vw',
            textAlign: 'center',
          },
        },
      }}
    >
      <InfoOutlinedIcon
        sx={{ m: 1, color: 'action.active' }}
        fontSize={fontSize}
      />
    </ClickAwayTooltip>
  )
}

export default AccountActionInfoTooltip
