import { IconButton, SvgIcon } from '@mui/material'
import useOdooPartnerDwellings from 'hooks/odoo/partner/useOdooPartnerDwellings'
import { ReactComponent as KeySvg } from 'images/key.svg'
import { useNavigate } from 'react-router-dom'
import { accountPropertiesRouteMetadata } from 'routes-metadata'

const AccountPropertiesButton = () => {
  const { dwellings } = useOdooPartnerDwellings()
  const navigate = useNavigate()

  if (!dwellings.length) {
    return null
  }

  const handleClick = () => {
    navigate(`/${accountPropertiesRouteMetadata.slug}`)
  }

  return (
    <IconButton
      aria-label="My Private Area"
      color="inherit"
      onClick={handleClick}
    >
      <SvgIcon component={KeySvg} inheritViewBox />
    </IconButton>
  )
}

export default AccountPropertiesButton
