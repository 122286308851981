import { Box } from '@mui/material'
import { forwardRef } from 'react'

type Props = {
  children: React.ReactNode
  target: 'screen' | 'print'
}

/**
 * Only shows its content when the specified media type target is matched.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_media_queries/Using_media_queries#targeting_media_types
 */
const TargetMediaType = forwardRef(({ children, target }: Props, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        '@media screen': {
          display: target === 'screen' ? 'block' : 'none',
        },
        '@media print': {
          display: target === 'print' ? 'block' : 'none',
          '*': {
            breakInside: 'avoid',
          },
        },
      }}
    >
      {children}
    </Box>
  )
})

export default TargetMediaType
