import {
  Box,
  Collapse,
  Fade,
  Grid,
  Paper,
  PaperTypeMap,
  Stack,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import ProjectStripCollapseToggle from './ProjectStripCollapseToggle'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export type ProjectStripProps = {
  thumbnail: React.ReactNode
  sideContent: React.ReactNode
  disabled?: boolean
  disabledReason?: string
  collapseTitle?: string
  collapseContent?: React.ReactNode
  collapseDisabled?: boolean
  collapseForceOpen?: boolean
  WrapperComponent?: OverridableComponent<PaperTypeMap<{}, "div">>
  in?: boolean
}

const ProjectStrip = ({
  thumbnail,
  sideContent,
  disabled = false,
  disabledReason,
  collapseTitle,
  collapseContent,
  collapseDisabled = false,
  collapseForceOpen = false,
  WrapperComponent = Paper,
  in: inProp = true,
}: ProjectStripProps) => {
  const theme = useTheme()
  const [collapseOpen, setCollapseOpen] = useState(false)

  // Force a collapse open based on prop.
  useEffect(() => {
    if (collapseForceOpen) {
      setCollapseOpen(true)
    }
  }, [collapseForceOpen])

  return (
    <>
      <Fade
        in={inProp}
        style={{
          /**
           * This delay helps stabilizing the UI when
           * nesting this component inside a `Collapse`.
           */
          transitionDelay: `${theme.transitions.duration.short}ms`,
        }}
      >
        <WrapperComponent
          sx={{
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={5} sx={{ position: 'relative' }}>
              {thumbnail}
            </Grid>

            <Grid item xs={12} sm={7} sx={{ position: 'relative' }}>
              {!disabled ? (
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  sx={{ height: '100%' }}
                >
                  {sideContent}

                  {!collapseDisabled && (
                    <Box
                      sx={{
                        p: {
                          xs: 1.5,
                          sm: 3,
                        },
                      }}
                    >
                      <ProjectStripCollapseToggle
                        open={collapseOpen}
                        onChange={(newOpen) => setCollapseOpen(newOpen)}
                        title={collapseTitle}
                      />
                    </Box>
                  )}
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ height: '100%' }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      p: {
                        xs: 1.5,
                        sm: 3,
                      },
                    }}
                  >
                    {disabledReason}
                  </Typography>
                </Stack>
              )}
            </Grid>
          </Grid>
        </WrapperComponent>
      </Fade>

      {!collapseDisabled && (
        <Paper
          elevation={collapseOpen ? 1 : 0}
          sx={{ position: 'relative', mt: -1, pt: 1 }}
        >
          <Collapse in={collapseOpen}>{collapseContent}</Collapse>
        </Paper>
      )}
    </>
  )
}

export default ProjectStrip
