import { Box } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

type Props = {
  children: React.ReactNode
  sx?: SxProps<Theme>
}

const Content = ({
  children,
  sx = [{ paddingY: { xs: 3, sm: 5 } }],
}: Props) => {
  return <Box sx={sx}>{children}</Box>
}

export default Content
