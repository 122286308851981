import { useEffect, useState } from 'react'

/**
 * Tells you if the image has loaded.
 *
 * It defaults to `true` and then switches back to `false`
 * in case the img element's complete property is `false`.
 *
 * Doing this allows for better integration with MUI's Fade transition
 * used in conjunction with it's `appear` property set to `false`.
 *
 * @param src The image src
 * @returns Image load status
 */
const useImgLoaded = (src?: string) => {
  const [isLoaded, setIsLoaded] = useState(true)

  useEffect(() => {
    if (!src) {
      return
    }

    const img = new Image()
    img.src = src

    if (!img.complete) {
      setIsLoaded(false)
    }

    img.onload = () => {
      setIsLoaded(true)
    }
  }, [src])

  return isLoaded
}

export default useImgLoaded
