import { Box, Button, Typography } from '@mui/material'
import Logo, { width as logoWidth } from 'components/atoms/Logo'
import useOdooLogout from 'hooks/odoo/useOdooLogout'
import { useNavigate } from 'react-router-dom'

type Props = {
  text: React.ReactNode
  hideLogoutButton?: boolean
}

const FatalErrorMessage = ({ text, hideLogoutButton = false }: Props) => {
  const navigate = useNavigate()
  const { mutateAsync: logout } = useOdooLogout()

  const handleLogout = async () => {
    await logout()
    navigate('/login')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 6,
        width: '100%',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <div style={{ minWidth: logoWidth }}>
        <Box sx={{ mb: 3 }}>
          <Logo />
        </Box>

        <Typography sx={{ mb: 3 }}>{text}</Typography>

        {!hideLogoutButton && (
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            onClick={handleLogout}
          >
            Log Out
          </Button>
        )}
      </div>
    </Box>
  )
}

export default FatalErrorMessage
