import { Tab, tabClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const PillTab = styled(Tab)(({ theme }) => ({
  textTransform: 'initial',
  minHeight: 'initial',
  borderRadius: 16,
  height: 32,
  padding: 0,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  /**
   * '0d' is 5% opacity
   * '26' is 15% opacity
   * '54' is 33% opacity
   * 'cc' is 80% opacity
   */
  [`&.${tabClasses.textColorPrimary}`]: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.light}54`,
    border: `1px solid ${theme.palette.primary.light}cc`,
    [`&.${tabClasses.selected}`]: {
      color: theme.palette.primary.dark,
      backgroundColor: `${theme.palette.primary.light}cc`,
    },
  },
  [`&.${tabClasses.textColorSecondary}`]: {
    color: theme.palette.secondary.light,
    backgroundColor: `${theme.palette.secondary.light}0d`,
    border: `1px solid ${theme.palette.secondary.light}26`,
    [`&.${tabClasses.selected}`]: {
      color: theme.palette.secondary.main,
      backgroundColor: `${theme.palette.secondary.light}26`,
    },
  },
}))

export default PillTab
