import DwellingField from 'interfaces/dwelling/field'

type Props = {
  field: DwellingField
}

const DwellingFieldValue = ({ field }: Props) => {
  return (
    <>
      <span>{field.value || '-'}</span>

      {!!field.value && !!field.suffix && (
        <span
          dangerouslySetInnerHTML={{
            __html: ` ${field.suffix}`,
          }}
        />
      )}
    </>
  )
}

export default DwellingFieldValue
