import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Image from 'components/atoms/Image'
import SectionSpacingContext from 'contexts/SectionSpacingContext'
import OverviewContentLocationInterface from 'interfaces/overview-location-section'
import { useContext } from 'react'
import { A11y, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'

const StyledSwiper = styled(Swiper)(({ theme }) => ({
  '& .swiper-button-prev': {
    color: theme.palette.common.white,
    left: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      left: theme.spacing(6),
    },
  },
  '& .swiper-button-next': {
    color: theme.palette.common.white,
    right: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      right: theme.spacing(6),
    },
  },
  '& .swiper-button-prev::after': {
    textShadow: theme.custom.textShadow,
    fontSize: theme.spacing(3),
  },
  '& .swiper-button-next::after': {
    textShadow: theme.custom.textShadow,
    fontSize: theme.spacing(3),
  },
}))

type Props = {
  locationSection: OverviewContentLocationInterface | null
}

const OverviewLocationSection = ({ locationSection }: Props) => {
  const sectionSpacing = useContext(SectionSpacingContext)
  const images = locationSection?.images ?? []

  return (
    <Box
      sx={{
        paddingX: {
          xs: 3,
          lg: 6,
        },
        paddingY: sectionSpacing,
        paddingTop: { xs: 3, md: 6 },
        bgcolor: 'secondary.light',
        color: 'common.white',
      }}
    >
      {locationSection?.title && (
        <Typography sx={{ marginBottom: 3 }} variant="h4" component="h2">
          {locationSection.title}
        </Typography>
      )}

      {!!images.length && (
        <StyledSwiper
          modules={[Navigation, A11y]}
          spaceBetween={24}
          slidesPerView={1}
          navigation
          loop
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <Box
                sx={{
                  cursor: 'grab',
                  '&:active': {
                    cursor: 'grabbing',
                  },
                  bgcolor: 'common.white',
                  padding: {
                    xs: 1,
                    sm: 2,
                  },
                }}
              >
                <Image
                  style={{
                    width: '100%',
                    display: 'block',
                    userSelect: 'none',
                  }}
                  src={image}
                  alt={`Slide ${index}`}
                />
              </Box>
            </SwiperSlide>
          ))}
        </StyledSwiper>
      )}
    </Box>
  )
}

export default OverviewLocationSection
