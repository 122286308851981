import { Box } from '@mui/material'
import Image from 'components/atoms/Image'
import LightboxHint from 'components/atoms/LightboxHint'
import Subtitle from 'components/atoms/Subtitle'
import Watermarked from 'components/atoms/Watermarked'
import CenteredSpinner from 'components/molecules/CenteredSpinner'
import useDwellingMediaLightbox from 'hooks/dwelling/media/useDwellingMediaLightbox'
import ImageProps from 'interfaces/image'
import { useState } from 'react'

type Props = {
  images: ImageProps[]
  title?: string
  hideHint?: boolean
}

const DwellingMediaLightbox = ({ images, title, hideHint }: Props) => {
  const [loading, setLoading] = useState(false)
  const { initAndOpen: initAndOpenLightbox } = useDwellingMediaLightbox({
    counterPrefix: title,
  })

  const handleImageClick = () => {
    initAndOpenLightbox(images, setLoading)
  }

  if (!images.length) {
    return null
  }

  const thumbnail = (
    <Image
      src={images[0].thumbUrl || images[0].url}
      alt="Dwelling media images"
      style={{
        display: 'block',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        opacity: loading ? 0.66 : 1,
      }}
      onClick={handleImageClick}
    />
  )

  return (
    <Box>
      {!!title && <Subtitle value={title} sx={{ mb: 2 }} />}

      <Watermarked>
        {hideHint ? thumbnail : <LightboxHint>{thumbnail}</LightboxHint>}
      </Watermarked>

      <CenteredSpinner in={loading} />
    </Box>
  )
}

export default DwellingMediaLightbox
