import { TabPanel } from '@mui/lab'
import TabContext from '@mui/lab/TabContext'
import { Box, Grid } from '@mui/material'
import PlansWithVariantsStateContext from 'contexts/PlansWithVariantsStateContext'
import PlansWithVariantsProps from 'interfaces/plans-with-variants'
import PlansWithVariantsVariantState from 'interfaces/plans-with-variants/variant-state'
import { useState } from 'react'
import { getInitialVariantsState, getPlan } from 'utils/plans-with-variants'
import ZoomablePlan from '../ZoomablePlan'
import PlansWithVariantsHeader from './PlansWithVariantsHeader'
import PlansWithVariantsVariants from './PlansWithVariantsVariants'

type Props = {
  title: string
  subtitle?: string
  value: PlansWithVariantsProps
}

const PlansWithVariants = ({ title, subtitle, value }: Props) => {
  const { floors, plans } = value
  const [activeFloor, setActiveFloor] = useState(floors[0])
  const [variantsState, setVariantsState] = useState<
    PlansWithVariantsVariantState[]
  >(getInitialVariantsState(activeFloor))

  return (
    <PlansWithVariantsStateContext.Provider
      value={{ value: variantsState, setter: setVariantsState }}
    >
      <TabContext value={activeFloor.level}>
        <Grid container spacing={6}>
          {/* Plan */}
          <Grid item xs={12} md={7}>
            <Box sx={{ mb: 4 }}>
              <PlansWithVariantsHeader
                title={title}
                subtitle={subtitle}
                floors={floors}
                setActiveFloor={setActiveFloor}
              />
            </Box>

            {floors.map((floor) => (
              <TabPanel key={floor.level} value={floor.level} sx={{ p: 0 }}>
                <ZoomablePlan
                  image={getPlan(plans, activeFloor, variantsState)}
                />
              </TabPanel>
            ))}
          </Grid>

          {/* Variants */}
          <Grid item xs={12} md={5}>
            {floors.map((floor) => (
              <TabPanel key={floor.level} value={floor.level} sx={{ p: 0 }}>
                <PlansWithVariantsVariants variants={floor.variants} />
              </TabPanel>
            ))}
          </Grid>
        </Grid>
      </TabContext>
    </PlansWithVariantsStateContext.Provider>
  )
}

export default PlansWithVariants
