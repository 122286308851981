import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  Radio,
  Stack,
  Typography,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import ShareLayout from 'types/share/layout'

const Layout = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  cursor: 'pointer',
  height: '100%',

  transition: theme.transitions.create('background-color', {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
}))

const LayoutSection = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  backgroundColor: theme.palette.grey[200],
  borderRadius: theme.shape.borderRadius,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
}))

type Props = {
  activeLayout: ShareLayout
  onChange: (layout: ShareLayout) => void
}

const ShareDwellingsLayoutSelector = ({ activeLayout, onChange }: Props) => {
  const theme = useTheme()
  const labelComponentsProps: FormControlLabelProps['componentsProps'] = {
    typography: {
      variant: 'overline',
      sx: [
        {
          lineHeight: theme.typography.caption.lineHeight,
          fontSize: {
            xs: '0.625rem',
            sm: theme.typography.overline.fontSize,
          },
        },
      ],
    },
  }

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item xs={6}>
        <Layout
          alignItems="center"
          spacing={1.5}
          onClick={() => onChange('project')}
        >
          <FormControlLabel
            name="layout"
            value="project"
            label="Non qualified leads"
            labelPlacement="top"
            checked={activeLayout === 'project'}
            control={<Radio size="small" />}
            componentsProps={labelComponentsProps}
          />

          <Stack direction="column" spacing={1.5} sx={{ width: '100%' }}>
            <LayoutSection>
              <Typography variant="body2">Project details</Typography>
            </LayoutSection>
            <LayoutSection>
              <Typography variant="body2">Renders</Typography>
            </LayoutSection>
            <LayoutSection>
              <Typography variant="body2">Units list</Typography>
            </LayoutSection>
          </Stack>
        </Layout>
      </Grid>

      <Grid item xs={6}>
        <Layout
          alignItems="center"
          spacing={1.5}
          onClick={() => onChange('dwellings')}
        >
          <FormControlLabel
            name="layout"
            value="dwellings"
            label="Qualified leads/owners"
            labelPlacement="top"
            checked={activeLayout === 'dwellings'}
            control={<Radio size="small" />}
            componentsProps={labelComponentsProps}
          />

          <Stack direction="column" spacing={1.5} sx={{ width: '100%' }}>
            <LayoutSection>
              <Typography variant="body2">Units</Typography>
            </LayoutSection>
            <LayoutSection>
              <Typography variant="body2">Renders</Typography>
            </LayoutSection>
            <LayoutSection>
              <Typography variant="body2">Project details</Typography>
            </LayoutSection>
          </Stack>
        </Layout>
      </Grid>
    </Grid>
  )
}

export default ShareDwellingsLayoutSelector
