import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Collapse,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import Logo from 'components/atoms/Logo'
import { mailSubjects, mailTemplates } from 'constants/account/mail'
import useOdooAccountExists from 'hooks/odoo/account/useOdooAccountExists'
import useMail from 'hooks/useMail'
import useToken from 'hooks/useToken'
import { useState } from 'react'
import { accountPasswordResetRouteMetadata } from 'routes-metadata'
import isEmail from 'validator/lib/isEmail'

// TODO: Maybe this and it's sibling (`AccountPasswordReset`) need a form library of some sort?

const AccountPasswordResetRequest = () => {
  const { send } = useMail()
  const { mutateAsync: token } = useToken()
  const { mutateAsync: exists } = useOdooAccountExists()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [done, setDone] = useState(false)

  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)

  const handleRequest = async () => {
    setLoading(true)

    try {
      // First check that the account actually exists.
      const response = await exists({ email })

      if (!response?.exists || !response.account) {
        throw new Error(
          `We don't recognize any account registered with that email, please try another one`
        )
      }

      const { account } = response

      // Generate the token with the account ID to reset the password for encoded in.
      const name = account.metadata?.contact_name || 'there' // Hah, when no name for some reason?
      const tokenResponse = await token({
        payload: {
          id: account.id,
          email: account.email,
          name,
        },
      })

      // Send a mail with the password reset link.
      const mailSent = await send(
        [email],
        mailSubjects.accountPasswordResetRequest,
        mailTemplates.accountPasswordResetRequest,
        {
          name,
          link: `${
            window.location.origin
          }/${accountPasswordResetRouteMetadata.slug.replace(
            ':token',
            tokenResponse.token
          )}`,
          year: new Date().getFullYear(),
        }
      )

      if (!mailSent) {
        throw new Error(
          'Something went wrong trying to send the password reset link to your email. Try again later'
        )
      }

      setSuccess(`We've sent you a password reset link`)
      setDone(true)
    } catch (error: any) {
      setError(error.message)
    }

    setLoading(false)
  }

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && isEmailValid && !done) {
      handleRequest()
    }
  }

  return (
    <Container>
      <Grid
        container
        spacing={3}
        sx={{ height: '100vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              marginBottom: 6,
            }}
          >
            <Logo />
          </Box>

          <Typography variant="h6" component="h1" mb={3}>
            Request your password reset
          </Typography>

          <TextField
            fullWidth
            sx={{ marginBottom: 3 }}
            id="email"
            type="email"
            label="Email"
            variant="filled"
            value={email}
            error={!isEmailValid && email !== ''}
            onChange={(e) => {
              setError('')

              const value = e.target.value
              setIsEmailValid(isEmail(value))
              setEmail(value)
            }}
            onKeyUp={handleKeyUp}
          />

          <Collapse in={error !== ''} timeout="auto">
            <Alert sx={{ marginBottom: 3 }} severity="error">
              {error}
            </Alert>
          </Collapse>

          <Collapse in={error === '' && success !== ''} timeout="auto">
            <Alert sx={{ marginBottom: 3 }} severity="success">
              {success}
            </Alert>
          </Collapse>

          <Box sx={{ textAlign: 'right' }}>
            <LoadingButton
              disableElevation
              variant="contained"
              disabled={!isEmailValid || done}
              onClick={handleRequest}
              loading={loading}
            >
              Request
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AccountPasswordResetRequest
