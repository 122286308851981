import { Divider, List, ListItemButton, ListItemText } from '@mui/material'
import { slimChipTypographyStyles } from 'components/atoms/SlimChip'
import React from 'react'
import AccountRole from 'types/account/role'
import { getAccountRoleFormatted } from 'utils/account/role'

type Props = {
  availableRoles: AccountRole[]
  handleUpdate: (role: AccountRole) => Promise<void>
}

const AccountRoleUpdateChipList = ({ availableRoles, handleUpdate }: Props) => {
  return (
    <List
      dense
      sx={{
        textAlign: 'center',
        mt: 1,
        p: 0,
        borderRadius: (theme) => theme.shape.borderRadius,
        overflow: 'hidden',
        bgcolor: 'grey.200',
      }}
    >
      {availableRoles.map((availableRole, index) => (
        <React.Fragment key={index}>
          <ListItemButton
            onClick={() => handleUpdate(availableRole)}
            sx={{
              textAlign: 'center',
              py: 0.25,
            }}
          >
            <ListItemText
              primary={getAccountRoleFormatted(availableRole)}
              primaryTypographyProps={{
                sx: {
                  ...slimChipTypographyStyles,
                },
              }}
              sx={{ m: 0 }}
            />
          </ListItemButton>

          <Divider
            light
            variant="fullWidth"
            sx={{
              '&:last-child': {
                display: 'none',
              },
            }}
          />
        </React.Fragment>
      ))}
    </List>
  )
}

export default AccountRoleUpdateChipList
