import { useMutation } from '@tanstack/react-query'
import { errorMessages } from 'constants/errors'
import TokenResponse from 'interfaces/token/response'

type TVariables = {
  payload: any
}
type TData = TokenResponse
type TError = Error

const useToken = () => {
  const mutation = async (variables: TVariables): Promise<TData> => {
    const endpoint = `${import.meta.env.VITE_TOKEN_API_ENDPOINT}?key=${
      import.meta.env.VITE_TOKEN_API_KEY
    }`
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        payload: variables.payload,
      }),
    })

    if (!response.ok) {
      throw new Error(errorMessages.token)
    }

    return await response.json()
  }

  return useMutation<TData, TError, TVariables>(mutation)
}

export default useToken
