import useProject from 'hooks/useProject'
import Dwelling from 'interfaces/dwelling'
import DwellingField from 'interfaces/dwelling/field'
import Project from 'interfaces/project'
import DwellingFieldsScheme from 'types/dwelling/fields-scheme'
import { populateDwellingFieldValue } from 'utils/dwelling'
import useDwellingEnabled from './useDwellingEnabled'

const getFields = (scheme: DwellingFieldsScheme, project?: Project) => {
  if (!project || !project.dwellings?.fields) {
    return null
  }

  return project.dwellings?.fields[scheme]
}

/**
 * Gets dwelling fields (info) using a scheme defined in the project config.
 */
const useDwellingFields = (
  dwelling: Dwelling,
  fieldsScheme: DwellingFieldsScheme,
  project?: Project
): DwellingField[] => {
  /**
   * Try getting the current project as a fallback if one wasn't provided in props.
   *
   * useProject won't always return a Project -- only when inside one.
   */
  const hookProject = useProject()
  const maybeProject = project || hookProject
  const fields = getFields(fieldsScheme, maybeProject)

  const enabled = useDwellingEnabled(dwelling)

  if (!fields) {
    return []
  }

  return fields.map((field) =>
    populateDwellingFieldValue(field, dwelling, enabled)
  )
}

export default useDwellingFields
