import { useMutation } from '@tanstack/react-query'
import { errorMessages } from 'constants/errors'
import OdooAccountDeleteResponse from 'interfaces/odoo/account/delete-response'
import useOdooAdmin from '../useOdooAdmin'

type TVariables = {
  id: number
}
type TData = OdooAccountDeleteResponse['result']
type TError = Error

const useOdooAccountDelete = () => {
  const { data: admin } = useOdooAdmin()

  const mutation = async (variables: TVariables): Promise<TData> => {
    if (!admin?.success) {
      throw new Error(errorMessages.odooAdmin)
    }

    const response = await fetch(
      import.meta.env.VITE_ODOO_ACCOUNTS_API_DELETE_ENDPOINT,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${admin.data.token}`,
        },
        body: JSON.stringify({
          params: {
            args: [variables.id],
          },
        }),
      }
    )

    const { result, error }: OdooAccountDeleteResponse = await response.json()

    if (!response.ok || !result?.deleted || error) {
      throw new Error(error?.data.message ?? errorMessages.odooAccountDelete)
    }

    return result
  }

  return useMutation<TData, TError, TVariables>(mutation)
}

export default useOdooAccountDelete
