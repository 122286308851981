import { Box } from '@mui/material'
import {
  LegalLink as Link,
  LegalParagraph as Paragraph,
  LegalSubtitle as Subtitle,
  LegalTitle as Title,
} from 'components/templates/Legal'

const LegalContent = () => {
  return (
    <Box
      sx={{
        '& strong': {
          fontWeight: 500,
        },
      }}
    >
      <Title>General Information</Title>
      <Paragraph>
        The owner of the website <strong>Roel Sales Tech SL</strong> ("Roel
        Sales", "we"), with NIF B44975829 and address located at{' '}
        <strong>
          Calle Hotel Puente Romano, Local 27, Marbella, 29602, Malaga
        </strong>
        . The entity is registered in the Commercial Registry of Málaga, volume
        5324, sheet 151, inscription 1, sheet number MA-124983.
      </Paragraph>
      <Paragraph>
        In order that the use of the website meets the criteria of transparency,
        clarity and simplicity, <strong>Roel Sales</strong> informs the user
        that any suggestions, questions or queries about this Legal Notice or
        any other information document on the website will be received and
        resolved by contacting <strong>Roel Sales</strong>, via email address:{' '}
        <Link href="mailto:private@roelsales.com">private@roelsales.com</Link>.
      </Paragraph>
      <Paragraph>
        Any person accessing this website assumes the role of user, committing
        to the observance and strict compliance with the provisions herein, as
        well as any other legal provision that may apply.
      </Paragraph>
      <Paragraph>
        <strong>Roel Sales</strong> reserves the right to modify any type of
        information that may appear on the website, without any obligation to
        give prior notice or inform users of such obligations, being understood
        as sufficient with the publication on the website of{' '}
        <strong>Roel Sales</strong>.
      </Paragraph>

      <Title>Intellectual Property Rights</Title>
      <Paragraph>
        <strong>Roel Sales</strong> is the owner of all copyrights, intellectual
        and industrial property rights, know-how and any other rights related to
        the contents of the website and the services offered therein, as well as
        the programs necessary for its implementation and the related
        information.
      </Paragraph>
      <Paragraph>
        The reproduction, publication and/or non strictly private use of the
        contents, in whole or in part, of the website is not permitted without
        prior written consent.
      </Paragraph>
      <Paragraph>
        All contents displayed on this website, and especially designs, text,
        images, logos, icons, trade names, trademarks or any other information
        susceptible of industrial and/or commercial use are protected by the
        corresponding copyright, not allowing its reproduction, transmission or
        registration of information unless prior authorization from the owner,{' '}
        <strong>Roel Sales</strong>. However, the user may use the information
        provided to manage your order and the corresponding contact details.
      </Paragraph>
      <Paragraph>
        The use contrary to the legislation on intellectual property of the
        services provided by <strong>Roel Sales</strong> is prohibited and, in
        particular:
      </Paragraph>
      <ul>
        <li>
          The use that is contrary to Spanish law or infringes the rights of
          third parties.
        </li>
        <li>
          The publication or transmission of any content that is violent,
          obscene, abusive, legal, racial, xenophobic or defamatory.
        </li>
        <li>
          Any content that violates the intellectual property rights of third
          parties.
        </li>
        <li>
          The use of the domain's mail server and e-mail addresses to send
          unwanted bulk mail.
        </li>
        <li>
          The collection and/or use of personal data of other users without
          their express consent or in contravention of the provisions of
          Regulation (EU) 2016/679 of the European Parliament and of the Council
          of 27 April 2016 on the protection of individuals with regard to the
          processing of personal data and on the free movement of such data.
        </li>
      </ul>

      <Title>Privacy Policy</Title>
      <Paragraph>
        This privacy policy for the use of the website{' '}
        <Link target="_blank" href="https://private.roelsales.com">
          https://private.roelsales.com
        </Link>{' '}
        (hereinafter referred to as the "Website"). The following information
        refers to the website, unless expressly stated otherwise.
      </Paragraph>
      <Paragraph>
        <strong>Roel Sales</strong>, as the responsible entity, informs its
        users of:
      </Paragraph>
      <ul>
        <li>
          With the use of the website will proceed to the processing of their
          personal data, in particular:
          <ul>
            <li>
              Personal data (e-mail address, name, surname, first name, title,
              postal address, telephone number, etc. (hereinafter referred to as
              "data" or "personal data") provided by users when registering on
              the website (e.g. in contact requests);
            </li>
          </ul>
        </li>
      </ul>

      <Title>Purpose of data processing</Title>
      <Paragraph>
        <strong>Roel Sales</strong>, as the responsible entity, in application
        of current legislation on personal data, reports that the personal data
        collected through the forms on the website, are included in the specific
        automated files of users. <strong>Roel Sales</strong> will treat the
        personal data of users of the website for the following purposes:
      </Paragraph>
      <ul>
        <li>
          Fulfilling and resolving requests and inquiries from users (the legal
          basis is the fulfillment of the contract, as well as the legitimate
          interest of <strong>Roel Sales</strong> to transmit requests for
          contact within the company <strong>Roel Sales</strong>.{' '}
        </li>
        <li>
          To send an e-mail newsletter (only on the website) about our own
          offers, as well as own and third-party advertising, to the extent
          permitted by law or based on consent (the legal basis is consent or{' '}
          <strong>Roel Sales</strong>'s legitimate interest in direct marketing,
          provided that the marketing is carried out in compliance with data
          protection and competition laws);
        </li>
        <li>
          To maintain the functionality and optimize the website. (The legal
          basis is <strong>Roel Sales</strong>'s legitimate interest in
          continuously improving the quality of the website and ensuring its
          user-friendliness);
        </li>
        <li>
          Website analysis to determine user behavior and for interest-based
          advertising (legal basis is consent);
        </li>
        <li>
          Sending advertising information about real estate marketed by{' '}
          <strong>Roel Sales</strong> (or its licensee partners) (pursuant to
          Article 6(1)(f) of the RGPD; the legitimate interest of{' '}
          <strong>Roel Sales</strong> consists in the processing of personal
          data for direct advertising purposes).
        </li>
        <li>
          To prevent misuse or fraudulent activities on or in connection with
          the Website as well as the requirements of applicable laws regarding a
          secure website/App (the legal basis is <strong>Roel Sales</strong>'s
          legitimate interest in eliminating malfunctions, ensuring system
          security and detecting access attempts or unauthorized access).
        </li>
      </ul>
      <Paragraph>
        <strong>Roel Sales</strong> or Group companies will not send commercial
        communications to users who have given their consent until 90 days after
        acceptance of the terms and conditions of use.
      </Paragraph>
      <Paragraph>
        The legal basis for the processing of personal data is Article 6(1)(a)
        of the RGPD insofar as the processing is based on consent; Article
        6(1)(b) of the RGPD if the basis of the processing is the contractual
        relationship and Article 6(1)(f) of the RGPD if the basis is the
        legitimate interest of <strong>Roel Sales</strong>. The user's consent
        is free, specific, informed and unambiguous, insofar as we inform you by
        making this privacy policy available to you, which after reading it, if
        you agree, you can accept by a statement or a clear affirmative action,
        such as checking a box provided for this purpose.
      </Paragraph>
      <Paragraph>
        Provided that the legal basis is your consent, you have the right to
        revoke such consent at any time without affecting the lawfulness of the
        processing carried out on the basis of the consent until revocation. If
        the legal basis is a legitimate interest, you also have the right, in
        general, to object to the processing of personal data concerning you at
        any time on grounds arising from your particular situation by sending an
        e-mail to the address indicated above. Article 21 of the RGPD applies in
        this regard.
      </Paragraph>
      <Paragraph>
        <strong>Roel Sales</strong> takes the necessary measures to ensure the
        security, integrity and confidentiality of the data in accordance with
        the provisions of Regulation (EU) 2016/679 of the European Parliament
        and of the Council of 27 April 2016 on the protection of natural persons
        with regard to the processing of personal data and on the free movement
        of such data.
      </Paragraph>
      <Paragraph>
        The user declares that all the data provided by him/her are true and
        correct, and undertakes to keep them updated, communicating any changes
        to{' '}
        <Link href="mailto:private@roelsales.com">private@roelsales.com</Link>.
      </Paragraph>

      <Title>
        Type of provision of personal data and consequences of refusal
      </Title>
      <Paragraph>
        The provision of personal data by users is voluntary. Users are free to
        decide whether they wish to provide personal data to{' '}
        <strong>Roel Sales</strong>, as well as to subsequently revoke their
        authorization to process the personal data provided. If applicable, the
        revocation may result in the impossibility to use the{' '}
        <strong>Roel Sales</strong> website.
      </Paragraph>

      <Title>Access to data</Title>
      <Paragraph>
        Personal data will be processed by <strong>Roel Sales</strong> and,
        individually, by: Companies (permanent collaborators and independent
        real estate consultants) and consultants authorized to manage the
        website and who are in charge of offering the related services. This
        company is Roel Sales Tech S.L, registered in the Commercial Registry of
        Malaga, volume 6323, sheet 122, inscription 1, sheet number MA-173747.
      </Paragraph>

      <Title>Transfer of data to non-EEA countries</Title>
      <Paragraph>
        <strong>Roel Sales</strong> will not communicate any personal data to
        non-EEA countries. The servers used by <strong>Roel Sales</strong> are
        located within the European Union. If your inquiry concerns{' '}
        <strong>Roel Sales</strong> outside the European Union, we will transfer
        your data after receiving your consent.
      </Paragraph>
      <Paragraph>
        In cases where personal data is transferred to countries that do not
        offer the same level of protection as in the European Union and you have
        not expressly consented to the transfer of your data to those countries
        by your consent, <strong>Roel Sales</strong> will ensure that certain
        contractual obligations are agreed with each service provider in
        accordance with applicable data protection legislation (including the
        implementation of EU Standard Contractual Clauses) unless{' '}
        <strong>Roel Sales</strong> can invoke other legal bases for the
        transfer of personal data.
      </Paragraph>

      <Title>Retention and deletion of data</Title>
      <Paragraph>
        <strong>Roel Sales</strong> will retain personal data for as long as
        necessary to fulfill the purpose for which they were collected and
        processed, respecting the retention periods stipulated by applicable
        law, and in any case for no longer than necessary for the purposes of
        the processing of personal data.
      </Paragraph>
      <Paragraph>
        <strong>Roel Sales</strong> stores personal data in connection with a
        contractual relationship during the retention period, as required by
        law. Likewise, <strong>Roel Sales</strong> will store your data in
        connection with the receipt of advertising information until you revoke
        your authorization.
      </Paragraph>

      <Title>Use of cookies and other tracking technologies</Title>
      <Subtitle>Use of cookies on the website</Subtitle>
      <Paragraph>
        In addition to the processing of personal data mentioned above, cookies
        and other tracking technologies are stored on your terminal device
        during or after your visit to{' '}
        <Link target="_blank" href="https://private.roelsales.com">
          https://private.roelsales.com
        </Link>
        .
      </Paragraph>
      <Paragraph>
        Cookies are small text files that are sent when visiting our Internet
        site and stored in the user's web browser. If the relevant website is
        visited again, the user's browser returns the contents of the cookies
        and thus allows the user to be recognized again.
      </Paragraph>
      <Paragraph>
        Some cookies are automatically deleted at the end of the browser session
        (so-called session cookies), others are stored in the user's browser for
        a certain period of time or permanently and then automatically deleted
        (so-called temporary or permanent cookies).
      </Paragraph>
      <Subtitle>
        What cookies does <strong>Roel Sales</strong> use in general?
      </Subtitle>
      <Paragraph>
        Depending on their function and purpose, <strong>Roel Sales</strong>{' '}
        cookies are divided into different categories. "Essential" and
        "functional" cookies are needed to enable <strong>Roel Sales</strong> to
        provide the online service in a secure and user-friendly manner. This
        category includes, for example, cookies that make it possible to browse
        the online offerings of
        <strong>Roel Sales</strong> and use the functions as desired. Without
        these cookies, essential <strong>Roel Sales</strong> services could not
        be provided. They are only used when visiting our online offers and are
        usually deleted after closing the browser. They are also used to obtain
        the optimized display of the website when accessing it with a mobile
        device, so that the data volume is not unnecessarily consumed. The legal
        basis for setting these cookies is the legitimate interest of{' '}
        <strong>Roel Sales</strong> that online services can be used safely and
        easily and that the quality of online services and content is constantly
        improved. No consent is required for the use of "essential" and
        "functional" cookies. They cannot be disabled.
      </Paragraph>
      <Paragraph>
        <strong>Roel Sales</strong> also uses cookies for analytical
        ("analytical cookies") and advertising ("advertising cookies") purposes.{' '}
        <strong>Roel Sales</strong> uses the profiles created with the help of
        these cookies to obtain statistical information about the use of{' '}
        <strong>Roel Sales</strong> online services and to display advertising
        or offers that are tailored to your interests ("interest-based
        advertising"). The legal basis for the use of these cookies is your
        consent.
      </Paragraph>

      <Title>How can I manage, prevent or eliminate the use of cookies?</Title>
      <Paragraph>
        You can use{' '}
        <Link target="_blank" href="https://private.roelsales.com">
          https://private.roelsales.com
        </Link>{' '}
        without "Analytical" and "Advertising" cookies and other tracking
        mechanisms on the website. In this case, only "essential" and
        "functional" cookies remain, which are necessary for the use of the
        online offers.
      </Paragraph>
      <Paragraph>
        You can deactivate the storage of cookies through your browser settings
        and delete cookies already stored in your browser at any time. However,
        please note that this online offer may not function or may function only
        to a limited extent without cookies. We also recommend that you visit
        the{' '}
        <Link target="_blank" href="https://youronlinechoices.com">
          https://youronlinechoices.com
        </Link>{' '}
        website, where you can obtain information and assistance regarding web
        tracking mechanisms.
      </Paragraph>

      <Title>Rights</Title>
      <Paragraph>
        You have the right to receive information about the processing of your
        personal data (e.g. origin, purpose, data processing methods). In
        addition, under certain circumstances, you may at any time object to the
        processing of your data in the future, restrict it or even demand that
        the data be deleted. Finally, you may also prevent at any time the
        sending of advertising material or the performance of market research or
        commercial communications. In short, you have the right to:
      </Paragraph>
      <ul>
        <li>information,</li>
        <li>rectification,</li>
        <li>deletion,</li>
        <li>limitation of processing,</li>
        <li>data portability,</li>
        <li>opposition to the processing of your personal data,</li>
        <li>complaint to the supervisory authorities.</li>
      </ul>
      <Paragraph>
        Please note that your right to erasure is subject to restrictions. For
        example, we are not obliged to delete any data that we are required to
        retain for legal retention periods or we are not permitted to do so.
        Data that we need to assert, exercise or defend legal claims are also
        excluded from your right of deletion.
      </Paragraph>

      <Paragraph>
        In order to exercise the aforementioned rights and request information,
        you can write to the e-mail address{' '}
        <Link href="mailto:private@roelsales.com">private@roelsales.com</Link>{' '}
        or to the postal address Calle Hotel Puente Romano, LOCAL 27, Marbella,
        29602, Malaga. Likewise, users may revoke, rectify and cancel the
        processing of their data at any time.
      </Paragraph>

      <Title>Contact</Title>
      <Paragraph>
        The data controller is the company <strong>Roel Sales</strong>. If you
        have any questions about data processing, we will be happy to provide
        you with information. If you have any questions about this privacy
        policy and data protection, please contact the following e-mail address:{' '}
        <Link href="mailto:private@roelsales.com">private@roelsales.com</Link>.
      </Paragraph>
      <Paragraph>
        If you have any complaints about the way we handle your data, you can
        file a complaint with the Spanish Data Protection Agency. The
        supervisory authority is:
      </Paragraph>
      <blockquote>
        Spanish Data Protection Agency. <br />
        C/ Jorge Juan, 6 <br />
        28001-Madrid <br />
        <Link
          target="_blank"
          href="https://www.agpd.es/portalwebAGPD/CanalDelCiudadano/contacteciudadano/index-ides-idphp.php"
        >
          AEPD
        </Link>
      </blockquote>

      <Title>Applicable Law and Jurisdiction</Title>
      <Paragraph>
        The Applicable Law in case of dispute or conflict of interpretation of
        the terms that make up this Legal Notice, as well as any matter related
        to the services of the website, shall be Spanish law. For the resolution
        of any dispute that may arise on the occasion of visiting the website,{' '}
        <strong>Roel Sales</strong> and the user agree to submit to tribunals of
        Marbella by the parties, waiving their own jurisdiction.
      </Paragraph>
    </Box>
  )
}

export default LegalContent
