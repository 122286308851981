/**
 * Fetches a URL, converts it to a blob, feeds it to an invisible
 * anchor element, then clicks it triggering a download.
 */
export const download = async (url: string, fileName: string) => {
  const data = await fetch(url)
  const blob = await data.blob()
  const objectUrl = URL.createObjectURL(blob)

  const anchor = document.createElement('a')
  anchor.setAttribute('href', objectUrl)
  // Dots ain't playing with filenames, removes the extension.
  anchor.setAttribute('download', fileName.replace(/\./g, ''))
  anchor.style.display = 'none'

  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)

  URL.revokeObjectURL(objectUrl)
}
