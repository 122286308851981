import { TabContext, TabPanel } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import EllipsisSpan from 'components/atoms/EllipsisSpan'
import SpacedDivider from 'components/atoms/SpacedDivider'
import TargetMediaType from 'components/atoms/TargetMediaType'
import RailTab from 'components/molecules/RailTab'
import RailTabList from 'components/molecules/RailTabList'
import InteractivePlan from 'interfaces/interactive-plan'
import Project from 'interfaces/project'
import { useState } from 'react'
import InteractivePlanSvg from './InteractivePlanSvg'

type Props = {
  plans: InteractivePlan[]
  project: Project
}
const InteractivePlans = ({ plans, project }: Props) => {
  const [active, setActive] = useState<InteractivePlan>(plans[0])

  const handleChange = (event: React.SyntheticEvent, newActiveId: string) => {
    const plan = plans.find(
      (plan) => plan.svg['@attributes'].id === newActiveId
    )
    if (!plan) {
      return
    }
    setActive(plan)
  }

  return (
    <>
      <TargetMediaType target="screen">
        <TabContext value={active.svg['@attributes'].id}>
          <Box sx={{ mb: 6 }}>
            <RailTabList onChange={handleChange}>
              {plans.map((plan) => (
                <RailTab
                  key={plan.svg['@attributes'].id}
                  value={plan.svg['@attributes'].id}
                  label={
                    <EllipsisSpan
                      sx={{
                        fontSize: {
                          xs: '0.775rem',
                          md: '0.875rem', // Default
                        },
                      }}
                    >
                      {plan.title ?? plan.svg['@attributes'].id}
                    </EllipsisSpan>
                  }
                />
              ))}
            </RailTabList>
          </Box>

          {plans.map((plan) => (
            <TabPanel
              key={plan.svg['@attributes'].id}
              value={plan.svg['@attributes'].id}
              sx={{ p: 0 }}
            >
              <InteractivePlanSvg
                project={project}
                svg={plan.svg}
                elevation={0}
              />
            </TabPanel>
          ))}
        </TabContext>
      </TargetMediaType>

      <TargetMediaType target="print">
        {plans.map((plan) => (
          <Stack key={plan.svg['@attributes'].id} spacing={1} sx={{ py: 2 }}>
            <Typography variant="overline">
              {plan.title ?? plan.svg['@attributes'].id}
            </Typography>

            <InteractivePlanSvg
              project={project}
              svg={plan.svg}
              elevation={0}
            />
          </Stack>
        ))}
      </TargetMediaType>
    </>
  )
}

export default InteractivePlans
