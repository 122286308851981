import DwellingFilter from 'interfaces/dwelling/filter'
import Project from 'interfaces/project'
import { getDwellingsMinMaxValues } from 'utils/dwelling/filters'

const useDwellingsTableFilters = (project?: Project | null) => {
  if (!project || !project.dwellings?.promotion?.dwellings) return null

  const {
    bedroomsMinMax,
    pricesMinMax,
    totalUsableMinMax,
    totalConstructedMinMax,
  } = getDwellingsMinMaxValues(project.dwellings?.promotion?.dwellings)

  const filters: DwellingFilter[] = [
    {
      key: 'beds',
      name: 'Bedrooms',
      type: 'slider',
      min: bedroomsMinMax.min,
      max: bedroomsMinMax.max,
      value: [bedroomsMinMax.min, bedroomsMinMax.max],
    },
    {
      key: 'price',
      name: 'Price',
      type: 'slider',
      min: pricesMinMax.min,
      max: pricesMinMax.max,
      value: [pricesMinMax.min, pricesMinMax.max],
      step: 10000,
      format: 'currency',
    },
    {
      key: 'totalUsable',
      name: 'Total Usable',
      type: 'slider',
      min: totalUsableMinMax.min,
      max: totalUsableMinMax.max,
      value: [totalUsableMinMax.min, totalUsableMinMax.max],
      suffix: (
        <span>
          &nbsp;m<sup>2</sup>
        </span>
      ),
    },
    {
      key: 'totalConstructed',
      name: 'Total Constructed',
      type: 'slider',
      min: totalConstructedMinMax.min,
      max: totalConstructedMinMax.max,
      value: [totalConstructedMinMax.min, totalConstructedMinMax.max],
      suffix: (
        <span>
          &nbsp;m<sup>2</sup>
        </span>
      ),
    },
  ]

  return filters
}

export default useDwellingsTableFilters
