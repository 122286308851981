import Project from 'interfaces/project'

/**
 * Gets renders + renders by folder.
 */
export const getProjectRenders = (project?: Project) => {
  return [
    ...(project?.resources?.renders ?? []),
    ...(project?.resources?.rendersByFolder?.folder ?? []),
  ]
}

/**
 * Gets photos + photos by folder.
 */
export const getProjectPhotos = (project?: Project) => {
  return [
    ...(project?.resources?.photos ?? []),
    ...(project?.resources?.photosByFolder?.folder ?? []),
  ]
}