import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useOdooAdmin from 'hooks/odoo/useOdooAdmin'
import useProject from 'hooks/useProject'
import { useState } from 'react'
import Status from 'types/status'
import LoadingBox from '../LoadingBox'

const DwellingsTableByEmail = () => {
  const project = useProject()
  const { data: admin, isLoading: isAdminLoading } = useOdooAdmin()
  const { data: me } = useOdooAccountMe()
  const [status, setStatus] = useState<Status>('idle')

  const handleClick = async () => {
    if (!project) {
      console.error(
        `Project couldn't be retrieved. Be sure to use this component in a context where project can be retrieved`
      )
      setStatus('error')
      return
    }
    /**
     * The account needs to have an email address
     * and we shall have Odoo's login token (Odoo Auth).
     */
    if (!me?.email) {
      console.error(
        `Dwellings table by email couldn't be sent because current account doesn't have an email address`
      )
      setStatus('error')
      return
    }
    if (!admin?.success) {
      console.error(
        `Dwellings table by email couldn't be sent because authentication wasn't successful`
      )
      setStatus('error')
      return
    }

    setStatus('loading')

    const response = await fetch(
      import.meta.env.VITE_PROMOTION_PRICELIST_API_ENDPOINT,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${admin.data.token}`,
        },
        body: JSON.stringify({
          params: {
            args: [project.id, me.email, true],
          },
        }),
      }
    )

    if (response.ok) {
      setStatus('success')
    } else {
      setStatus('error')
    }
  }

  const Icon = (() => {
    switch (status) {
      case 'idle':
      case 'loading':
        return EmailOutlinedIcon
      case 'success':
        return DoneOutlinedIcon
      case 'error':
        return ErrorOutlineOutlinedIcon
    }
  })()

  if (!project) {
    return null
  }

  return (
    <LoadingBox loading={isAdminLoading}>
      <LoadingButton
        loadingPosition="start"
        startIcon={<Icon />}
        loading={status === 'loading'}
        onClick={handleClick}
        disableElevation
        disabled={!me?.email || status === 'success'}
        sx={{
          textTransform: 'initial',
          color: 'action.active',
        }}
      >
        {status === 'success' ? 'Sent by email' : 'Send by email'}
      </LoadingButton>
    </LoadingBox>
  )
}

export default DwellingsTableByEmail
