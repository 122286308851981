/**
 * These are the tags.
 */
export const partnerCategories = {
  dashboardUser: 13,
  agent: 8,
}

/**
 * Contact type.
 */
export const partnerTypes = {
  direct: 31,
  external: 30,
}
