import useOdooPartnerLeads from 'hooks/odoo/partner/useOdooPartnerLead'
import useProjects from 'hooks/useProjects'
import { getProjectsExtendedDwellings } from 'utils/dwelling'

/**
 * @returns `DwellingExtended[]` associated to the currently logged in `me`.
 */
const useOdooPartnerDwellings = () => {
  const { data: projects, isLoading: isProjectsLoading } = useProjects()
  const { data: leads, isLoading: isLeadsLoading } = useOdooPartnerLeads()

  // Extract dwelling IDs from leads.
  const leadDwellingIds: number[] = []
  for (const lead of leads || []) {
    if (!lead.product_id) {
      continue
    }
    leadDwellingIds.push(lead.product_id.id)
  }

  // Use those IDs to filter all dwellings to just the lead related ones.
  const dwellings = getProjectsExtendedDwellings(projects).filter((dwelling) =>
    leadDwellingIds.includes(dwelling.id)
  )

  return {
    dwellings,
    isLoading: isProjectsLoading || isLeadsLoading,
  }
}

export default useOdooPartnerDwellings
