import Dwelling from 'interfaces/dwelling'
import Project from 'interfaces/project'
import {
  getDwellingsMediaByNomenclature,
  getDwellingsMediaByTypology,
} from 'utils/dwelling'

const useDwellingsMediaRenders = (dwellings: Dwelling[], project?: Project) => {
  if (!project) {
    return []
  }

  return [
    ...getDwellingsMediaByTypology(dwellings, project.resources?.renders ?? []),
    ...getDwellingsMediaByNomenclature(
      dwellings,
      project.resources?.rendersByFolder?.folder ?? [],
      project.resources?.rendersByFolder?.nomenclatureSegment
    ),
  ]
}

export default useDwellingsMediaRenders
