import { TabContext, TabPanel } from '@mui/lab'
import { Badge, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { changelogDialogModes } from 'constants/changelog/changelog-dialog-modes'
import ChangelogEntry from 'interfaces/changelog/changelog-entry'
import { useEffect, useState } from 'react'
import ChangelogDialogMode from 'types/changelog/changelog-dialog-mode'
import {
  getDefaultActiveMode,
  getUnreadChangelogEntries,
  isChangelogEntryUnread,
  updateLastRead,
} from 'utils/changelog/local-storage'
import ButtonTab from '../../atoms/tabs/ButtonTab'
import ButtonTabList from '../../atoms/tabs/ButtonTabList'

type Props = {
  changelogEntries: ChangelogEntry[]
}
const ChangelogDialogBody = ({ changelogEntries }: Props) => {
  const [active, setActive] = useState<ChangelogDialogMode>(
    getDefaultActiveMode(changelogEntries)
  )
  const handleChange = (
    event: React.SyntheticEvent,
    newActive: ChangelogDialogMode
  ) => {
    setActive(newActive)
  }

  useEffect(() => {
    if (!getUnreadChangelogEntries(changelogEntries).length) {
      updateLastRead(active)
    }
  }, [active, changelogEntries])

  const getButtonTabLabel = (text: string, mode: ChangelogDialogMode) => {
    const isBadgeInvisible = !changelogEntries
      .filter((changelogEntry) => changelogEntry.type === mode)
      .some((changelogEntry) => {
        return isChangelogEntryUnread(changelogEntry)
      })
    return (
      <Badge
        sx={{
          '& .MuiBadge-badge': {
            top: -2,
          },
        }}
        color="primary"
        variant="dot"
        invisible={isBadgeInvisible}
      >
        {text}
      </Badge>
    )
  }

  if (!changelogEntries || !changelogEntries.length) {
    return (
      <Typography variant="subtitle2" sx={{ py: 6, textAlign: 'center' }}>
        There are no notifications yet
      </Typography>
    )
  }

  return (
    <TabContext value={active}>
      <Stack
        sx={{
          mb: 3,
        }}
      >
        <ButtonTabList onChange={handleChange} sx={{ overflow: 'visible' }}>
          {changelogDialogModes.map(
            (mode) =>
              changelogEntries.some(
                (changelogEntry) => changelogEntry.type === mode.slug
              ) && (
                <ButtonTab
                  key={mode.slug}
                  label={getButtonTabLabel(mode.name, mode.slug)}
                  value={mode.slug}
                  iconPosition="start"
                  icon={mode.icon}
                  sx={(() => {
                    if (!mode.divider) {
                      return undefined
                    }

                    return {
                      overflow: 'visible',
                      marginRight: 2,
                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        right: (theme) => theme.spacing(-1.5),
                        transform: 'translateX(50%)',
                        height: '80%',
                        width: '1px',
                        bgcolor: 'action.focus',
                      },
                    }
                  })()}
                />
              )
          )}
        </ButtonTabList>
      </Stack>
      {changelogDialogModes.map(
        (mode) =>
          changelogEntries.some(
            (changelogEntry) => changelogEntry.type === mode.slug
          ) && (
            <TabPanel
              key={mode.slug}
              value={mode.slug}
              sx={{ paddingX: 0 }}
            >
              {mode.component}
            </TabPanel>
          )
      )}
    </TabContext>
  )
}

export default ChangelogDialogBody
