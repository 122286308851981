import { deburr } from 'lodash'
import { normalizeToString } from './normalization'

/**
 * Checks if a sufficient number of properties matches a set of values for a given object.
 *
 * @param object Object whose properties will be matched to values
 * @param values Array of values that count towards a match with the object
 * @param accuracy Number of values required for a match
 *
 * @returns Does the object have a sufficient amount of properties in common with `values`?
 */
export const heuristicMatch = <T extends object>(
  object: T,
  values: string[],
  accuracy: number
) => {
  const normalizeStrings = (values: string[]) =>
    values.map((value) => deburr(normalizeToString(value).toLowerCase()))

  const properties = normalizeStrings(Object.values(object))

  let hits = 0

  for (const value of normalizeStrings(values)) {
    if (properties.includes(value)) {
      hits++
    }
  }

  return hits >= accuracy
}
