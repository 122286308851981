import jwtDecode from 'jwt-decode'
import { epoch } from 'utils/date'

export const isTokenExpired = <T extends { exp?: number }>(token: string) => {
  try {
    const decoded = jwtDecode<T>(token)

    // Assume it isn't expired if it doesn't include the exp claim.
    if (!decoded.exp) {
      return false
    }

    const currentTime = epoch()
    const expired = decoded.exp < currentTime

    return expired
  } catch (error) {
    // Assume it expired if it throws when decoding.
    return true
  }
}
