import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material'
import { PortableText, PortableTextReactComponents } from '@portabletext/react'
import Lightbox from 'components/atoms/Lightbox'
import Overline from 'components/atoms/Overline'
import { ChangelogEntryWithIsLastEntryUnread } from 'interfaces/changelog/changelog-entry-with-is-last-entry-unread'
import Image from 'interfaces/image'
import { useEffect, useRef, useState } from 'react'
import { updateLastRead } from 'utils/changelog/local-storage'

type Props = {
  entry: ChangelogEntryWithIsLastEntryUnread
  entryUnread: boolean
  onExpandedChange: (newExpanded: boolean) => void
}

const ChangelogEntryPortableText = ({
  entry,
  entryUnread,
  onExpandedChange,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [expanded, setExpanded] = useState(false)
  const [overflows, setOverflows] = useState(false)

  const date = new Date(entry.date)
  const components: Partial<PortableTextReactComponents> = {
    types: {
      video: ({ value }) => {
        const { asset } = value
        const { url } = asset
        return <video src={url} controls style={{ maxWidth: '100%' }} />
      },
      image: ({ value }) => {
        const { asset } = value
        const { url, metadata } = asset

        const image: Image = {
          url: url,
          width: metadata.dimensions.width,
          height: metadata.dimensions.height,
        }
        return (
          <Lightbox
            image={image}
            id="changelog-entry-image"
            imageStyle={{ width: '100%', objectFit: 'cover', display: 'block' }}
          />
        )
      },
    },
  }

  const handleToggle = () => {
    const newExpanded = !expanded

    setExpanded(newExpanded)
    onExpandedChange(newExpanded)
  }

  /**
   * Calculates when the entry content
   * overflows it's collapsed state height.
   */
  useEffect(() => {
    const handleResize = () => {
      const element = ref.current

      if (element) {
        /**
         * It is important to give the button some margin to appear, as due to the line height of the text,
         * the final result may be unexpected, and the button might show up when the text is fully visible.
         * In this case, we apply a margin of 6 px to the button to appear or not.
         */

        if (element.clientHeight < element.scrollHeight - 6 || expanded) {
          setOverflows(true)
        } else {
          setOverflows(false)
        }
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [expanded])

  useEffect(() => {
    if (entry.isLastEntryUnread) {
      updateLastRead(entry.type)
    }
  }, [entry.isLastEntryUnread, entry.type])

  return (
    <Grid item xs={12} sm={entry.featuredImage && !expanded ? 8 : 12}>
      <Grid
        item
        maxHeight={expanded ? '100%' : 187}
        sx={{
          overflowY: 'hidden',
          boxSizing: 'content-box',
          p: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        <Box
          ref={ref}
          sx={{
            maxHeight: expanded
              ? '100%'
              : (theme) => ({
                  xs: `calc(187px - ${theme.spacing(2)})`,
                  sm: `calc(187px - ${theme.spacing(4)})`,
                }),
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
          >
            <Stack spacing={1}>
              <Overline>{date.toLocaleDateString('es-ES')}</Overline>

              <Typography variant="h6">{entry.title}</Typography>
            </Stack>

            {(entryUnread || entry.isLastEntryUnread) && (
              <Chip label="NEW" color="primary" size="small" />
            )}
          </Stack>

          <Box
            sx={{
              textAlign: expanded ? 'start' : 'justify',
            }}
          >
            <PortableText value={entry.content} components={components} />
          </Box>
        </Box>
      </Grid>

      {overflows && (
        <Grid p={2} item display="flex" justifyContent="end">
          <Button variant="outlined" size="small" onClick={handleToggle}>
            {expanded ? 'Show less' : 'Show more'}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default ChangelogEntryPortableText
