import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queries'
import { queryFn as odooApiQueryFn } from 'hooks/odoo/api/useOdooApiQuery'
import useOdooPartnerLeads from 'hooks/odoo/partner/useOdooPartnerLead'
import useOdooAdmin from '../useOdooAdmin'

const useOdooPartnerAttachments = () => {
  const { data: admin } = useOdooAdmin()
  const { data: leads } = useOdooPartnerLeads()

  return useQuery(
    [...queryKeys.odooApiQuery, 'partner-documents'],
    async () => {
      /**
       * Categorise attachments (lead `document_ids`
       * is actually an array of categories).
       */
      const categorizedIds: { [key: string]: number[] } = {}

      for (const lead of leads || []) {
        if (!lead.document_ids || !lead.document_ids.length) {
          continue
        }

        for (const category of lead.document_ids) {
          const name = category['display_name']

          if (!categorizedIds[name]) {
            categorizedIds[name] = []
          }

          categorizedIds[name].push(...category.attachment_ids)
        }
      }

      /**
       * Query all document IDs together at once.
       */
      const ids = Object.values(categorizedIds).flat()
      if (!ids.length) {
        return []
      }
      const objects = await odooApiQueryFn<{
        id: number
        display_name: string
        name: string
        datas: string
        mimetype: string
      }>({
        model: 'ir.attachment',
        query: ['id', 'display_name', 'name', 'datas', 'mimetype'],
        filter: [['id', 'in', ids]],
        token: admin?.data.token || '',
      })

      /**
       * Merge categories with the retrieved attachments by their ID.
       */
      const categorizedObjects: {
        [key: string]: {
          id: number
          display_name: string
          name: string
          /**
           * base64
           */
          datas: string
          mimetype: string
        }[]
      } = {}

      for (const category in categorizedIds) {
        if (!Object.prototype.hasOwnProperty.call(categorizedIds, category)) {
          continue
        }

        const ids = categorizedIds[category]
        if (!ids.length) {
          continue
        }

        categorizedObjects[category] = ids
          .map((id) => objects.find((object) => object.id === id))
          .filter(Boolean)
          // This last line is dumb but necessary for the TS compiler.
          .map((object) => object!)
      }

      return (
        Object.entries(categorizedObjects)
          .map(([category, attachments]) => ({
            category,
            attachments,
          }))
          // Filter out possible categories without attachments.
          .filter(({ attachments }) => !!attachments.length)
      )
    },
    {
      enabled: !!leads && !!admin?.data.token,
    }
  )
}

export default useOdooPartnerAttachments
