import OdooApiQueryVariables from 'interfaces/odoo/api/query/variables'

export function odooBuildQuery(
  query: NonNullable<OdooApiQueryVariables['query']>
) {
  return `{${query.join(',')}}`
}

export function odooBuildStringFilter(
  filter: NonNullable<OdooApiQueryVariables['filter']>
) {
  function buildArrayFilterValue(value: unknown[]) {
    return `[${value.map((v) => JSON.stringify(v)).join(',')}]`
  }

  return `[${filter
    .map((filter) => {
      let value = filter[filter.length === 2 ? 1 : 2]
      if (Array.isArray(value)) {
        value = buildArrayFilterValue(value)
      } else {
        value = JSON.stringify(value)
      }

      if (filter.length === 2) {
        return `["${filter[0]}", "=", ${value}]`
      }
      return filter.length === 1
        ? `"${filter}"`
        : `["${filter[0]}", "${filter[1]}", ${value}]`
    })
    .join(',')}]`
}
