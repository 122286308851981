import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { IconButton, Stack, Tooltip } from '@mui/material'
import ProjectFocusContext from 'contexts/ProjectFocusContext'
import { useContext } from 'react'

/**
 * Uses `ProjectFocusContext` and sets it to `null` when clicked.
 */
const ShareSeeMore = () => {
  const { value: focusedProject, setter: setFocusedProject } =
    useContext(ProjectFocusContext)

  const handleClick = () => {
    setFocusedProject(null)
  }

  if (!focusedProject) {
    return null
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Tooltip title="Show more">
        <IconButton aria-label="Show more" onClick={handleClick}>
          <AddOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}

export default ShareSeeMore
