import { Box, Container } from '@mui/material'
import Iframe from 'components/atoms/Iframe'
import Subtitle from 'components/atoms/Subtitle'
import CenteredSpinner from 'components/molecules/CenteredSpinner'
import FatalErrorMessage from 'components/organisms/FatalErrorMessage'
import AccountHeader from 'components/organisms/account/AccountHeader'
import DwellingDetail from 'components/organisms/dwellings/detail/DwellingDetail'
import { errorMessages } from 'constants/errors'
import useProjects from 'hooks/useProjects'
import { useNavigate, useParams } from 'react-router-dom'
import { accountPropertiesRouteMetadata } from 'routes-metadata'
import { getProjectsExtendedDwellings } from 'utils/dwelling'
import { normalizeToNumber } from 'utils/normalization'

const AccountPropertiesSingle = () => {
  const { data: projects, isLoading } = useProjects()
  const dwellings = getProjectsExtendedDwellings(projects)
  const { id } = useParams()
  const navigate = useNavigate()

  if (isLoading) {
    return <CenteredSpinner in />
  }

  const dwelling = dwellings.find(
    (dwelling) => dwelling.id === normalizeToNumber(id)
  )
  if (!dwelling) {
    return (
      <FatalErrorMessage
        hideLogoutButton
        text={errorMessages.odooAccountPropertyNotFound}
      />
    )
  }
  const project = projects.find((project) => project.name === dwelling.project)

  const handleBack = () => {
    navigate(`/${accountPropertiesRouteMetadata.slug}`)
  }

  return (
    <>
      <AccountHeader />

      <Container
        sx={{
          py: {
            xs: 4,
            lg: 6,
          },
        }}
      >
        <DwellingDetail
          dwelling={dwelling}
          variant="single"
          onBack={handleBack}
        >
          {!!project?.resources?.brochure && (
            <Box>
              <Subtitle value="Brochure" sx={{ mb: 2 }} />

              <Iframe
                title="Brochure"
                src={project.resources.brochure}
              />
            </Box>
          )}

          {!!project?.resources?.qualitySpecs && (
            <Box>
              <Subtitle value="Quality specs" sx={{ mb: 2 }} />

              <Iframe
                title="Quality specs"
                src={project.resources.qualitySpecs}
              />
            </Box>
          )}

          {!!project?.resources?.optionsAndUpgrades && (
            <Box>
              <Subtitle value="Options & Upgrades" sx={{ mb: 2 }} />

              <Iframe
                title="Options & Upgrades"
                src={project.resources.optionsAndUpgrades}
              />
            </Box>
          )}
        </DwellingDetail>
      </Container>
    </>
  )
}

export default AccountPropertiesSingle
