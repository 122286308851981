import { Box, Grid } from '@mui/material'
import DwellingMediaLightbox from 'components/molecules/dwellings/media/DwellingMediaLightbox'
import useDwellingMediaFloorPlans from 'hooks/dwelling/media/useDwellingMediaFloorPlans'
import useDwellingMediaPlotPlans from 'hooks/dwelling/media/useDwellingMediaPlotPlans'
import useDwellingMediaRealViews from 'hooks/dwelling/media/useDwellingMediaRealViews'
import useDwellingsMediaPhotos from 'hooks/dwelling/media/useDwellingsMediaPhotos'
import useDwellingsMediaRenders from 'hooks/dwelling/media/useDwellingsMediaRenders'
import Dwelling from 'interfaces/dwelling'
import Project from 'interfaces/project'
import React from 'react'
import DwellingMediaType from 'types/dwelling/media-type'

type Props = {
  dwelling: Dwelling
  project: Project
  includes?: DwellingMediaType[]
}

const DwellingMedia = ({
  dwelling,
  project,
  includes = ['floor-plans', 'plot-plans', 'real-views', 'renders', 'photos'],
}: Props) => {
  const floorPlans = useDwellingMediaFloorPlans(dwelling, project)
  const plotPlans = useDwellingMediaPlotPlans(dwelling, project)
  const realViews = useDwellingMediaRealViews(dwelling, project)
  const renders = useDwellingsMediaRenders([dwelling], project)
  const photos = useDwellingsMediaPhotos([dwelling], project)

  /**
   * Based on the types of media included
   * (floor plans, plot plans, etc.),
   * add components as necessary.
   */
  const components = []
  for (const type of includes) {
    switch (type) {
      case 'floor-plans':
        !!floorPlans.length &&
          components.push(
            <DwellingMediaLightbox title="Floor plans" images={floorPlans} />
          )
        break

      case 'plot-plans':
        !!plotPlans.length &&
          components.push(
            <DwellingMediaLightbox title="Plot plans" images={plotPlans} />
          )
        break

      case 'real-views':
        !!realViews.length &&
          components.push(
            <DwellingMediaLightbox title="Views" images={realViews} />
          )
        break

      case 'renders':
        !!renders.length &&
          components.push(
            <DwellingMediaLightbox title="Renders" images={renders} />
          )
        break

      case 'photos':
        !!photos.length &&
          components.push(
            <DwellingMediaLightbox title="Photos" images={photos} />
          )
        break
    }
  }

  if (!components.length) {
    return null
  }

  return (
    <Box>
      <Grid container rowSpacing={6} columnSpacing={3}>
        {components.map((component, index) => {
          const odd = components.length % 2 !== 0
          const last = index === components.length - 1

          return (
            <Grid item key={index} xs={odd && last ? 12 : 6}>
              <React.Fragment>{component}</React.Fragment>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default DwellingMedia
