import { Stack, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import HomeButton from 'components/molecules/HomeButton'
import AccountMenu from 'components/molecules/account/AccountMenu'
import Project from 'interfaces/project'
import DwellingsFavoritesDialog from '../dwellings/DwellingsFavoritesDialog'
import NavDrawer from '../nav/NavDrawer'
import NavList from '../nav/NavList'
import ShareDialog from '../share/ShareDialog'
import TargetMediaType from 'components/atoms/TargetMediaType'

type Props = {
  project: Project
}

const ProjectHeader = ({ project }: Props) => {
  return (
    <TargetMediaType target="screen">
      <AppBar
        sx={{
          zIndex: 3 /* Otherwise it glitches with fading Swiper and TabList's */,
          border: 'none',
          borderBottom: '2px solid',
          borderColor: 'grey.600',
          bgcolor: 'common.white',
        }}
        position="sticky"
        elevation={0}
      >
        <Toolbar sx={{ color: 'grey.600' }}>
          <HomeButton edge="start" />

          <Box
            sx={{
              paddingLeft: {
                xs: 1,
                sm: 3,
              },
            }}
          >
            <Typography variant="h6" component="h1">
              {project.name}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: {
                  xs: 'none',
                  xl: 'inherit',
                },
              }}
            >
              <NavList project={project} variant="row" />
            </Box>
          </Box>

          <Stack
            direction="row"
            /**
             * This `alignItems` is required or else
             * buttons will stretch and not be round.
             */
            alignItems="center"
            spacing={2}
            sx={{
              display: {
                xs: 'none',
                xl: 'inherit',
              },
            }}
          >
            <ShareDialog />
            <DwellingsFavoritesDialog />
            <AccountMenu />
          </Stack>

          <Box
            sx={{
              display: {
                xl: 'none',
              },
            }}
          >
            <NavDrawer project={project} />
          </Box>
        </Toolbar>
      </AppBar>
    </TargetMediaType>
  )
}

export default ProjectHeader
