import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queries'
import OdooAuthResponse from 'interfaces/odoo/auth-response'
import { mutationFn as auth } from './useOdooAuth'

type TQueryFnData = OdooAuthResponse['result']
type TError = Error

const useOdooAdmin = () => {
  return useQuery<TQueryFnData, TError>(
    queryKeys.odooAdmin,
    async () =>
      await auth({
        email: import.meta.env.VITE_ODOO_ADMIN_EMAIL,
        password: import.meta.env.VITE_ODOO_ADMIN_PASSWORD,
      }),
    {
      staleTime: Infinity,
    }
  )
}

export default useOdooAdmin
