import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import Logo from 'components/atoms/Logo'
import AccessRequestDialog from 'components/organisms/AccessRequestDialog'
import LoginForm from 'components/organisms/LoginForm'

const Login = () => {
  return (
    <Container>
      <Grid
        container
        spacing={3}
        sx={{ height: '100vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={8} md={4}>
          <Box
            sx={{
              marginBottom: 6,
            }}
          >
            <Logo />
          </Box>

          <Stack
            direction="row"
            justifyContent="space-between"
            mb={3}
            alignItems="center"
          >
            <Typography variant="h6" component="h1">
              Log in
            </Typography>

            <AccessRequestDialog />
          </Stack>

          <LoginForm />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Login
