import Account from 'interfaces/account'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingFilter from 'interfaces/dwelling/filter'
import Project from 'interfaces/project'
import { accountIsAdmin } from 'utils/account/role'
import { getDwellingsMinMaxValues } from 'utils/dwelling/filters'

const useAllDwellingsTableFilters = (
  dwellings: DwellingExtended[],
  projects: Project[],
  account?: Account
) => {
  const projectNames = new Set<string>()
  const locations = new Set<string>()
  const constructionTypes = new Set<string>()

  for (const project of projects) {
    const promotion = project.dwellings?.promotion

    if (!promotion) {
      continue
    }

    projectNames.add(project.name)
    if (promotion.city) {
      locations.add(promotion.city.name)
    }
    if (promotion.promotionContructionType) {
      constructionTypes.add(promotion.promotionContructionType.name)
    }
  }

  const {
    bedroomsMinMax,
    pricesMinMax,
    totalUsableMinMax,
    totalConstructedMinMax,
  } = getDwellingsMinMaxValues(dwellings)

  const filters: DwellingFilter[] = [
    {
      key: 'beds',
      name: 'Bedrooms',
      type: 'slider',
      min: bedroomsMinMax.min,
      max: bedroomsMinMax.max,
      value: [bedroomsMinMax.min, bedroomsMinMax.max],
    },
    {
      key: 'price',
      name: 'Price',
      type: 'slider',
      min: pricesMinMax.min,
      max: pricesMinMax.max,
      value: [pricesMinMax.min, pricesMinMax.max],
      step: 10000,
      format: 'currency',
    },
    {
      key: 'totalUsable',
      name: 'Total Usable',
      type: 'slider',
      min: totalUsableMinMax.min,
      max: totalUsableMinMax.max,
      value: [totalUsableMinMax.min, totalUsableMinMax.max],
      suffix: (
        <span>
          &nbsp;m<sup>2</sup>
        </span>
      ),
    },
    {
      key: 'totalConstructed',
      name: 'Total Constructed',
      type: 'slider',
      min: totalConstructedMinMax.min,
      max: totalConstructedMinMax.max,
      value: [totalConstructedMinMax.min, totalConstructedMinMax.max],
      suffix: (
        <span>
          &nbsp;m<sup>2</sup>
        </span>
      ),
    },
    {
      key: 'project',
      name: 'Project',
      type: 'select',
      value: '',
      options: Array.from(projectNames),
      suffix: (
        <span>
          &nbsp;m<sup>2</sup>
        </span>
      ),
    },
    {
      key: 'location',
      name: 'Location',
      type: 'select',
      value: '',
      options: Array.from(locations),
      suffix: (
        <span>
          &nbsp;m<sup>2</sup>
        </span>
      ),
    },
    {
      key: 'constructionType',
      name: 'Construction type',
      type: 'select',
      value: '',
      options: Array.from(constructionTypes),
      suffix: (
        <span>
          &nbsp;m<sup>2</sup>
        </span>
      ),
    },
    {
      key: 'keyReady',
      name: 'Key ready',
      type: 'switch',
      value: false,
      suffix: (
        <span>
          &nbsp;m<sup>2</sup>
        </span>
      ),
    },
  ]

  if (accountIsAdmin(account)) {
    filters.push({
      key: 'resale',
      name: 'Resale',
      type: 'switch',
      value: false,
    })
  }

  return filters
}

export default useAllDwellingsTableFilters
