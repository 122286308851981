import { ToggleButtonGroup } from '@mui/material'
import ToggleChip from 'components/atoms/ToggleChip'
import PlansWithVariantsStateContext from 'contexts/PlansWithVariantsStateContext'
import PlansWithVariantsVariant from 'interfaces/plans-with-variants/variant'
import PlansWithVariantsVariantValue from 'interfaces/plans-with-variants/variant-value'
import { useContext } from 'react'
import { getVariantSelectedValue } from 'utils/plans-with-variants'

type Props = {
  variant: PlansWithVariantsVariant
  disabled?: boolean
}

const PlansWithVariantsVariantActions = ({
  variant,
  disabled = false,
}: Props) => {
  const { name, type, slug, values } = variant
  const { value: variantsState, setter: setVariantsState } = useContext(
    PlansWithVariantsStateContext
  )
  const selectedValue = getVariantSelectedValue(variant, variantsState)

  const setNewVariantState = (
    variant: PlansWithVariantsVariant,
    newSelected: PlansWithVariantsVariantValue
  ) => {
    setVariantsState((prevVariantsState) => {
      const nextVariantsState = [...prevVariantsState]

      const index = nextVariantsState.findIndex(
        (nextVariantState) =>
          nextVariantState.slug.current === variant.slug.current
      )
      if (index !== -1) {
        nextVariantsState[index].selected = newSelected
      } else {
        console.warn(`Initialized variant state couldn't be found`)
      }

      return nextVariantsState
    })
  }

  return (
    <>
      {type === 'toggle' && (
        <ToggleChip
          color="secondary"
          value={slug.current}
          selected={selectedValue.slug.current === values[1].slug.current}
          disabled={disabled}
          onChange={() => {
            /**
             * Toggle behaves setting:
             *  - Index 1 of `values` when selected
             *  - Index 0 of `values` when not.
             */
            setNewVariantState(
              variant,
              selectedValue.slug.current === values[0].slug.current
                ? values[1]
                : values[0]
            )
          }}
        >
          {`${
            selectedValue === values[1] ? 'Hide' : 'Show'
          } ${name.toLowerCase()}`}
        </ToggleChip>
      )}

      {type === 'toggle-group' && (
        <ToggleButtonGroup
          value={selectedValue.slug.current}
          exclusive
          aria-label={`${name} values`}
          disabled={disabled}
          onChange={(
            event: React.MouseEvent<HTMLElement>,
            newSelectedSlug: string | null
          ) => {
            if (!newSelectedSlug) {
              return
            }

            const newSelected = values.find(
              ({ slug }) => slug.current === newSelectedSlug
            )
            if (!newSelected) {
              console.error(
                `Toggle group trying to set a new selected that isn't found in the values array`
              )
              return
            }

            setNewVariantState(variant, newSelected)
          }}
        >
          {values.map(({ slug, label }) => (
            <ToggleChip
              color="secondary"
              key={slug.current}
              value={slug.current}
              aria-label={`${name} ${slug.current}`}
            >
              {label}
            </ToggleChip>
          ))}
        </ToggleButtonGroup>
      )}
    </>
  )
}

export default PlansWithVariantsVariantActions
