import { Box, Grid, Typography } from '@mui/material'
import Image from 'components/atoms/Image'
import OverviewContentSectionInterface from 'interfaces/overview-content-section'

type Props = {
  contentSection: OverviewContentSectionInterface | null
}

const OverviewContentSection = ({ contentSection }: Props) => {
  const content = contentSection?.content ?? []
  const featured = contentSection?.featured ?? []

  return (
    <Grid
      container
      alignItems={{ xs: 'flex-start', lg: 'center' }}
      spacing={{ xs: 3, sm: 6, md: 9 }}
    >
      <Grid item xs={12} md={7}>
        {contentSection?.title && (
          <Typography sx={{ marginBottom: 3 }} variant="h4" component="h2">
            {contentSection.title}
          </Typography>
        )}

        {!!content.length && (
          <Box
            sx={{
              marginBottom: {
                xs: 3,
                md: 6,
              },
            }}
          >
            {content.map((contentItem, index) => (
              <Typography key={index} sx={{ marginBottom: 3 }}>
                {contentItem}
              </Typography>
            ))}
          </Box>
        )}

        {!!featured.length && (
          <Box
            sx={{
              marginBottom: {
                xs: 3,
                md: 6,
              },
            }}
          >
            {featured.map((featuredItem, index) => (
              <Box sx={{ marginBottom: 3 }} key={index}>
                <Typography
                  sx={{ marginBottom: 2 }}
                  variant="h5"
                  component="h3"
                >
                  {featuredItem.title}
                </Typography>

                <Typography>{featuredItem.content}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </Grid>

      {contentSection?.image && (
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              width: {
                xs: '50%',
                md: '97%',
              },
              margin: {
                xs: '0 auto',
              },
            }}
          >
            <Image
              style={{ width: '100%', height: 'auto' }}
              src={contentSection.image}
              alt="Primary"
            />
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default OverviewContentSection
