import SSOStorageConnection from 'interfaces/sso/storage/connection'
import SSOStoragePostMessage from 'interfaces/sso/storage/post-message'
import {
  connectionRetryMs,
  connectionTimeoutMs,
  postMessageSource,
  url,
} from './config'
import { getIframeEl } from './iframe'

/**
 * Attempts to connect with the SSO bridge.
 *
 * NOTE: This method uses postMessage directly instead of leveraging
 * the dispatch/listen methods because a established connection is
 * a prerequisite for those.
 */
export const connect = () =>
  new Promise<SSOStorageConnection>((resolve, reject) => {
    const iframeEl = getIframeEl()

    // Check if already connected, resolve early in that case.
    if (iframeEl.dataset.connected === 'true') {
      const connection: SSOStorageConnection = {
        status: 'connected',
        bridge: iframeEl.contentWindow,
      }

      resolve(connection)
      return
    }

    /**
     * Send connection request.
     */
    const requestIntervalID = setInterval(() => {
      if (iframeEl.dataset.loaded === 'false') {
        return
      }

      const message: SSOStoragePostMessage = {
        source: postMessageSource,
        description: {
          type: 'connection-request',
        },
      }

      iframeEl.contentWindow?.postMessage(message, url)
    }, connectionRetryMs)

    /**
     * Timeout if the connection request
     * takes too long to get answered.
     */
    const requestTimeoutId = setTimeout(() => {
      clearInterval(requestIntervalID)

      reject(
        new Error(`SSO: Connection timed out after ${connectionTimeoutMs} ms`)
      )
    }, connectionTimeoutMs)

    /**
     * Listen to the connection response.
     */
    const handleMessage = (e: MessageEvent) => {
      const message = e.data as SSOStoragePostMessage

      if (message.source !== postMessageSource) {
        return
      }

      if (message.description.type === 'connection-response') {
        const connection: SSOStorageConnection = {
          status: 'connected',
          bridge: iframeEl.contentWindow,
        }

        iframeEl.dataset.connected = 'true'
        window.removeEventListener('message', handleMessage)

        clearInterval(requestIntervalID)
        clearTimeout(requestTimeoutId)

        resolve(connection)
      }
    }
    window.addEventListener('message', handleMessage)
  })
