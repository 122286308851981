import Image from 'interfaces/image'
import { useEffect, useState } from 'react'
import { addImagesDimensions } from 'utils/images'

/**
 * Adds dimensions to images.
 *
 * @param imageOrImages Array of `Image` or single `Image` without dimensions.
 * @returns Array of `Image` with dimensions, and their loading progress.
 */
const useAddImagesDimensions = (
  imageOrImages: Image[] | Image
): [Image[], number] => {
  const [imagesWithDimensions, setImagesWithDimensions] = useState<Image[]>([])
  const [imagesLoadingProgress, setImagesLoadingProgress] = useState(0)

  // Add dimensions to images.
  useEffect(() => {
    const images = !Array.isArray(imageOrImages)
      ? [imageOrImages]
      : imageOrImages

    if (!images.length) {
      return
    }

    const fetch = async () => {
      const result = await addImagesDimensions(images, (progress: number) => {
        setImagesLoadingProgress(progress)
      })

      if (!result || !result.length) {
        return
      }

      setImagesWithDimensions(result)
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [imagesWithDimensions, imagesLoadingProgress]
}

export default useAddImagesDimensions
