import { Container, Stack } from '@mui/material'
import Content from 'components/atoms/Content'
import PrintButton from 'components/atoms/PrintButton'
import PrintDateOfIssue from 'components/molecules/print/PrintDateOfIssue'
import PrintHeader from 'components/molecules/print/PrintHeader'
import AvailabilityColors from 'components/organisms/AvailabilityColors'
import InteractivePlanSvg from 'components/organisms/interactive-plan/InteractivePlanSvg'
import ProjectHeader from 'components/organisms/project/ProjectHeader'
import useProject from 'hooks/useProject'

const ProjectMasterPlan = () => {
  const project = useProject()
  const interactiveMasterPlan = project?.resources?.interactiveMasterPlan

  if (!project || !interactiveMasterPlan) {
    return null
  }

  return (
    <>
      <ProjectHeader project={project} />

      <Container>
        <Content>
          <PrintHeader project={project} />

          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              mb: 3,
            }}
          >
            <AvailabilityColors
              sx={{
                '@media print': {
                  pl: 0,
                },
              }}
            />

            <PrintButton />
          </Stack>

          <InteractivePlanSvg
            project={project}
            svg={interactiveMasterPlan.svg}
          />

          <PrintDateOfIssue />
        </Content>
      </Container>
    </>
  )
}

export default ProjectMasterPlan
