import SSOStoragePostMessage from 'interfaces/sso/storage/post-message'
import { postMessageSource, url } from './config'
import { connect } from './connection'

/**
 * Dispatches a message through the SSO bridge.
 */
export const dispatch = async (
  description: SSOStoragePostMessage['description'],
  payload?: SSOStoragePostMessage['payload']
) => {
  try {
    const connection = await connect()

    if (connection.status !== 'connected' || !connection.bridge) {
      throw new Error(
        'SSO: Trying to dispatch a message without a proper connection'
      )
    }

    connection.bridge.postMessage(
      {
        source: postMessageSource,
        description,
        payload,
      },
      url
    )
  } catch (error) {
    console.error(error)
  }
}

/**
 * Listens to a response from a dispatched message
 * through the SSO bridge, only once.
 */
export const listen = (
  description: SSOStoragePostMessage['description'],
  listener: (message: SSOStoragePostMessage) => void
) => {
  const handleMessage = (e: MessageEvent) => {
    const message = e.data as SSOStoragePostMessage

    // Check source and the description type matches.
    if (
      message.source !== postMessageSource ||
      message.description.type !== description.type
    ) {
      return
    }

    /**
     * Additionaly, in case the message needs to be identified by
     * a description detail, make sure it also matches.
     */
    if (
      // Detail exists in both the parameter description and the message description.
      message.description.detail &&
      description.detail &&
      // They don't match.
      message.description.detail !== description.detail
    ) {
      return
    }

    listener(message)

    window.removeEventListener('message', handleMessage)
  }

  window.addEventListener('message', handleMessage)
}
