import { Stack, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import HomeButton from 'components/molecules/HomeButton'
import AccountMenu from 'components/molecules/account/AccountMenu'
import DwellingsFavoritesDialog from '../dwellings/DwellingsFavoritesDialog'

const AccountHeader = () => {
  return (
    <AppBar
      sx={{
        border: 'none',
        borderBottom: '2px solid',
        borderColor: 'grey.600',
        bgcolor: 'common.white',
      }}
      position="sticky"
      elevation={0}
    >
      <Toolbar sx={{ color: 'grey.600' }}>
        <HomeButton edge="start" />

        <Box
          sx={{
            paddingLeft: {
              xs: 1,
              sm: 3,
            },
            display: 'flex',
            flexGrow: 1,
          }}
        >
          <Typography variant="subtitle1" component="h1">
            My Private Area
          </Typography>
        </Box>

        <Stack
          direction="row"
          /**
           * This `alignItems` is required or else
           * buttons will stretch and not be round.
           */
          alignItems="center"
          spacing={2}
        >
          <DwellingsFavoritesDialog />
          <AccountMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default AccountHeader
