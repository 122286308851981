import { Container, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Content from 'components/atoms/Content'
import Logo from 'components/atoms/Logo'
import LegalContent from 'components/organisms/LegalContent'

export const LegalTitle = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 3 }}>
    {children}
  </Typography>
)

export const LegalSubtitle = ({ children }: { children: React.ReactNode }) => (
  <Typography
    variant="subtitle1"
    component="h3"
    sx={{ my: 3, fontWeight: 500 }}
  >
    {children}
  </Typography>
)

export const LegalParagraph = ({ children }: { children: React.ReactNode }) => (
  <Typography
    variant="body1"
    textAlign="justify"
    sx={{
      mb: 1.5,
    }}
  >
    {children}
  </Typography>
)

export const LegalLink = (props: React.HTMLProps<HTMLAnchorElement>) => {
  const theme = useTheme()
  const { style, children, ...rest } = props

  return (
    <a
      style={{
        color: theme.palette.primary.main,
        textDecoration: 'none',
        ...style,
      }}
      {...rest}
    >
      <Typography
        component="span"
        sx={{
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {children}
      </Typography>
    </a>
  )
}

const Legal = () => {
  return (
    <Container>
      <Content sx={{ paddingY: { xs: 6, sm: 12 } }}>
        <Logo />

        <LegalContent />
      </Content>
    </Container>
  )
}

export default Legal
