/**
 * Sorts arrays/objects recursively.
 *
 * If any other type of value is provided, it gets returned as is.
 */
export const sortRecursively = (value: any): any => {
  if (Array.isArray(value)) {
    // If it's an array, recursively sort each element.
    return value.map(sortRecursively).sort()
  } else if (typeof value === 'object' && value !== null) {
    // If it's an object, recursively sort its properties.
    const sortedObject: {
      [key: string]: any
    } = {}
    Object.keys(value)
      .sort()
      .forEach((key) => {
        sortedObject[key] = sortRecursively(value[key])
      })
    return sortedObject
  } else {
    // For non-object types, return as is.
    return value
  }
}
