import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import EllipsisSpan from 'components/atoms/EllipsisSpan'
import RailTab from 'components/molecules/RailTab'
import RailTabList from 'components/molecules/RailTabList'
import useProject from 'hooks/useProject'
import { useState } from 'react'
import DwellingTypologyContent from './DwellingTypologyContent'

const DwellingTypologies = () => {
  const project = useProject()
  const typologies = project?.dwellings?.typologies
  const [active, setActive] = useState(
    typologies ? typologies[0].slug.current : ''
  )

  const handleChange = (event: React.SyntheticEvent, newActive: string) => {
    setActive(newActive)
  }

  if (!typologies) {
    return null
  }

  return (
    <TabContext value={active}>
      <RailTabList onChange={handleChange}>
        {typologies.map((typology) => (
          <RailTab
            key={typology.slug.current}
            value={typology.slug.current}
            label={
              <EllipsisSpan
                sx={{
                  fontSize: {
                    xs: '0.775rem',
                    md: '0.875rem', // Default
                  },
                }}
              >
                {typology.name}
              </EllipsisSpan>
            }
          />
        ))}
      </RailTabList>

      {typologies.map((typology) => (
        /**
         * Using PersistentTabPanel here collides with DwellingBasements's PersistentTabPanel for some reason?
         *
         * TODO: Fix this somehow? DwellingBasements *needs* the
         * PersistentTabPanel for the portals in the variants inside to work.
         * This could use portals, but as of now, it doesn't.
         *
         * Maybe it's a MUI bug @link https://github.com/mui/material-ui/issues/32749.
         */
        <TabPanel
          key={typology.slug.current}
          value={typology.slug.current}
          sx={{ px: 0, py: 4 }}
        >
          <DwellingTypologyContent typology={typology} />
        </TabPanel>
      ))}
    </TabContext>
  )
}

export default DwellingTypologies
