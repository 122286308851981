import {
  ExpiringShareToken,
  ShareToken,
} from 'interfaces/account/tokens/share-token'
import Project from 'interfaces/project'
import { shareRouteMetadata } from 'routes-metadata'

export const getShareTokenFilteredProjects = (
  projects: Project[],
  tokenDecoded: ShareToken | ExpiringShareToken
) => {
  const filteredProjects: Project[] = []

  for (const project of projects) {
    const dwellings = project.dwellings

    if (!dwellings?.promotion?.dwellings) {
      continue
    }

    // Filter dwellings data to only include those in tokenDecoded.
    dwellings.promotion.dwellings =
      dwellings.promotion.dwellings.filter((dwelling) => {
        if (Array.isArray(tokenDecoded)) {
          return (tokenDecoded as ShareToken).includes(dwelling.id)
        } else {
          return (tokenDecoded as ExpiringShareToken).dwe.includes(dwelling.id)
        }
      }) ?? []

    // If there are no dwellings matching in this project, we don't need it.
    if (!dwellings.promotion.dwellings.length) {
      continue
    }

    filteredProjects.push({
      ...project,
      dwellings,
    })
  }

  return filteredProjects
}

export const isShareRoute = (pathname: string) =>
  pathname.includes(
    shareRouteMetadata.slug.substring(
      0,
      shareRouteMetadata.slug.lastIndexOf('/')
    )
  )
