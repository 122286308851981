import { Typography } from '@mui/material'
import SpacedDivider from 'components/atoms/SpacedDivider'
import ShareMasterPlan from 'components/molecules/share/ShareMasterPlan'
import Project from 'interfaces/project'

type Props = {
  project: Project
}

const ShareSectionMasterPlan = ({ project }: Props) => {
  if (!project.overview?.masterPlan) {
    return null
  }

  return (
    <>
      <SpacedDivider />

      <Typography variant="h6" sx={{ mb: 3 }}>
        Location
      </Typography>

      <ShareMasterPlan project={project} />
    </>
  )
}

export default ShareSectionMasterPlan
