import { Box, Fade } from '@mui/material'
import Image from 'interfaces/image'
import ImageGalleryItemCaption from './ImageGalleryItemCaption'

type Props = {
  image: Image
  in: boolean
}

const ImageGalleryItemHover = ({ image, in: propIn }: Props) => {
  return (
    <Fade in={propIn}>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          backgroundColor: (theme) => theme.custom.darkOverlayColor,
          color: 'common.white',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <ImageGalleryItemCaption
          title={image.title}
          subtitle={image.subtitle}
        />
      </Box>
    </Fade>
  )
}

export default ImageGalleryItemHover
