import { Container, Stack, Typography } from '@mui/material'
import Lightbox from 'components/atoms/Lightbox'
import { sectionSpacing } from 'constants/section'
import useAddImagesDimensions from 'hooks/useAddImagesDimensions'
import SectionImageProps from 'interfaces/section/image'

const SectionImage = ({
  title,
  image,
  index,
}: SectionImageProps & { index: number }) => {
  const [[imageWithDimensions]] = useAddImagesDimensions(
    image
      ? {
          url: image,
        }
      : []
  )

  return (
    <Container>
      <Stack spacing={sectionSpacing}>
        {!!title && (
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
        )}

        {!!imageWithDimensions && (
          <Lightbox id={`section-image-${index}`} image={imageWithDimensions} />
        )}
      </Stack>
    </Container>
  )
}

export default SectionImage
