import { Box } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

type Props = {
  title: string
  src?: string
  sx?: SxProps<Theme>
}

const Iframe = ({ title, src, sx }: Props) => {
  if (!src) {
    console.error('Trying to include an iframe without src')
    return null
  }

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        maxHeight: (theme) => theme.custom.fullHeightMinusHeader,
        aspectRatio: 16 / 9,
        ...sx,
      }}
    >
      <iframe
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          border: 0,
        }}
        allowFullScreen
        title={title}
        src={src}
      ></iframe>
    </Box>
  )
}

export default Iframe
