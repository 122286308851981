import FormatType from 'types/format/type'
import { normalizeToNumber } from './normalization'

const format = (
  type: FormatType,
  value: string | number,
  options?: Intl.NumberFormatOptions
) => {
  switch (type) {
    case 'currency':
      const formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
        ...options,
      })
      return formatter.format(normalizeToNumber(value))

    default:
      return value.toString()
  }
}

export default format
