import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import PasswordTextField from 'components/atoms/PasswordTextField'
import { accountUpdateFormDefaultConfig as defaultConfig } from 'constants/account/forms/update'
import { errorMessages } from 'constants/errors'
import { queryKeys } from 'constants/queries'
import useOdooAccountUpdate from 'hooks/odoo/account/useOdooAccountUpdate'
import AccountUpdateFormConfig from 'interfaces/account/forms/update'
import { merge } from 'lodash'
import { useState } from 'react'
import { legalRouteMetadata } from 'routes-metadata'
import { checkIsValid } from 'utils/account/forms/update'
import { epoch } from 'utils/date'
import isEmail from 'validator/lib/isEmail'

type Props = {
  accountId: number
  config: AccountUpdateFormConfig
  onSuccess?: (
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) => Promise<void>
}

// TODO: Make so the account update form returns the whole account (as in the AccountMe interface).

const AccountUpdateForm = ({ accountId, config, onSuccess }: Props) => {
  const mergedConfig: AccountUpdateFormConfig = merge(defaultConfig, config)
  const {
    initialValues,
    labels,
    components,
    passwordNotRequired,
    legalNotRequired,
  } = mergedConfig

  const queryClient = useQueryClient()
  const { mutateAsync: update } = useOdooAccountUpdate()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [done, setDone] = useState(false)

  const [firstName, setFirstName] = useState(initialValues!.firstName!)
  const [lastName, setLastName] = useState(initialValues!.lastName!)
  const [email, setEmail] = useState(initialValues!.email!)
  const [isEmailValid, setIsEmailValid] = useState(true) // Assume valid as email is static.
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [legal, setLegal] = useState(false)

  const changed =
    firstName !== initialValues?.firstName ||
    lastName !== initialValues?.lastName ||
    password.trim() !== ''

  const isValid = () =>
    checkIsValid(
      firstName,
      lastName,
      isEmailValid,
      password,
      repeatPassword,
      legal,
      passwordNotRequired!,
      legalNotRequired!
    )

  const handleUpdate = async () => {
    setLoading(true)

    try {
      const response = await update({
        id: accountId,
        email,
        metadata: {
          // It always enables the account just in case.
          enabled: true,
          legal: !legalNotRequired ? epoch() : undefined,
          contact_name: firstName,
          last_name: lastName,
        },
        password,
      })

      if (!response?.updated) {
        throw new Error(errorMessages.odooAccountUpdate)
      }

      if (onSuccess) {
        await onSuccess(firstName, lastName, email, password)
      }

      // Invalidate the account me query, updating it.
      queryClient.invalidateQueries(queryKeys.me)

      setSuccess(`Account updated successfully`)
      setDone(true)
    } catch (error: any) {
      setError(error.message)
    }

    setLoading(false)
  }

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && isValid() && changed && !done) {
      handleUpdate()
    }
  }

  return (
    <>
      <TextField
        autoFocus
        fullWidth
        disabled={done}
        sx={{ marginBottom: 3 }}
        id="first-name"
        type="text"
        label={labels!.firstName!}
        variant="filled"
        value={firstName}
        onChange={(e) => {
          setError('')

          const value = e.target.value
          setFirstName(value)
        }}
        onKeyUp={handleKeyUp}
      />

      <TextField
        fullWidth
        disabled={done}
        sx={{ marginBottom: 3 }}
        id="last-name"
        type="text"
        label={labels!.lastName!}
        variant="filled"
        value={lastName}
        onChange={(e) => {
          setError('')

          const value = e.target.value
          setLastName(value)
        }}
        onKeyUp={handleKeyUp}
      />

      <TextField
        fullWidth
        disabled
        sx={{ marginBottom: 3 }}
        id="email"
        type="email"
        label={labels!.email!}
        variant="filled"
        value={email}
        error={!isEmailValid && email !== ''}
        onChange={(e) => {
          setError('')

          const value = e.target.value
          setIsEmailValid(isEmail(value))
          setEmail(value)
        }}
        onKeyUp={handleKeyUp}
      />

      <Box sx={{ marginBottom: 3 }}>
        <PasswordTextField
          disabled={done}
          id="password"
          label={labels!.password!}
          password={password}
          onChange={(e) => {
            setError('')
            setPassword(e.target.value)
          }}
          onKeyUp={handleKeyUp}
        />
      </Box>

      <Box sx={{ marginBottom: 3 }}>
        <PasswordTextField
          disabled={done}
          id="repeat-password"
          label={labels!.repeatPassword!}
          password={repeatPassword}
          onChange={(e) => {
            setError('')
            setRepeatPassword(e.target.value)
          }}
          onKeyUp={handleKeyUp}
        />
      </Box>

      {!legalNotRequired && (
        <Box sx={{ marginBottom: 3 }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={done}
                id="legal"
                checked={legal}
                onChange={(e) => {
                  setError('')
                  setLegal(e.target.checked)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography variant="body2">
                By enabling my account I agree to the{' '}
                <Link
                  target="_blank"
                  href={`/${legalRouteMetadata.slug}`}
                  underline="hover"
                >
                  Terms & Conditions and Privacy Policy
                </Link>
              </Typography>
            }
          />
        </Box>
      )}

      <Collapse in={error !== ''} timeout="auto">
        <Alert sx={{ marginBottom: 3 }} severity="error">
          {error}
        </Alert>
      </Collapse>

      <Collapse in={error === '' && success !== ''} timeout="auto">
        <Alert sx={{ marginBottom: 3 }} severity="success">
          {success}
        </Alert>
      </Collapse>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {/* This Box makes sure the loading button stays at the right */}
        <Box>{components?.submitMessage}</Box>

        <LoadingButton
          disableElevation
          variant="contained"
          disabled={!isValid() || !changed || done}
          onClick={handleUpdate}
          loading={loading}
        >
          {labels!.submitButton!}
        </LoadingButton>
      </Stack>
    </>
  )
}

export default AccountUpdateForm
