import { Grid, Stack, Typography } from '@mui/material'
import DwellingInfoField from 'components/molecules/dwellings/DwellingInfoField'
import DwellingField from 'interfaces/dwelling/field'
import Project from 'interfaces/project'
import { memo } from 'react'
import { getAvailableDwellings } from 'utils/dwelling'

type Props = {
  project: Project
}

const DwellingsInfoFields = ({ project }: Props) => {
  const dwellings = project.dwellings?.promotion?.dwellings
  const availableDwellings = getAvailableDwellings(project)

  if (!dwellings?.length || !availableDwellings.length) {
    return null
  }

  const fields: DwellingField[] = [
    {
      name: 'Bedrooms',
      key: 'beds',
    },
    {
      name: 'Price',
      key: 'price',
      format: 'currency',
    },
    {
      name: 'Total Usable',
      key: 'totalUsable',
      suffix: 'm<sup>2</sup>',
    },
    {
      name: 'Total Constructed',
      key: 'totalConstructed',
      suffix: 'm<sup>2</sup>',
    },
  ]
  const sumFields = ((): DwellingField[] => {
    return [
      {
        name: 'Available/Total Units',
        value: `${availableDwellings.length}/${dwellings.length}`,
      },
    ]
  })()

  return (
    <>
      {!!fields.length || !!sumFields.length ? (
        <Grid
          container
          spacing={3}
          sx={{
            py: 3,
            px: {
              xs: 1.5,
              sm: 3,
            },
          }}
        >
          {fields.map((field, index) => (
            <Grid item key={index} xs={6}>
              <DwellingInfoField
                dwellings={availableDwellings}
                field={field}
                type="range"
                variant="vertical"
              />
            </Grid>
          ))}

          {sumFields.map((sumField, index) => (
            <Grid item key={index} xs={6}>
              <DwellingInfoField
                field={sumField}
                type="sum"
                variant="vertical"
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            height: '100%',
            py: 3,
            px: {
              xs: 1.5,
              sm: 3,
            },
          }}
        >
          <Typography variant="subtitle2" color="text.secondary">
            No commercial information available
          </Typography>
        </Stack>
      )}
    </>
  )
}

export default memo(DwellingsInfoFields)
