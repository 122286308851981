import { Box, Container, Stack, Typography } from '@mui/material'
import Content from 'components/atoms/Content'
import Video from 'components/molecules/Video'
import LoadingImageGallery from 'components/organisms/LoadingImageGallery'
import ProjectHeader from 'components/organisms/project/ProjectHeader'
import VirtualTourItem from 'components/organisms/virtual-tour/VirtualTourItem'
import useProject from 'hooks/useProject'
import { getProjectPhotos, getProjectRenders } from 'utils/project'

const ProjectGallery = () => {
  const project = useProject()

  if (!project) {
    return null
  }

  const photos = getProjectPhotos(project)
  const renders = getProjectRenders(project)
  const videos = project.resources?.videos
  const virtualTours = project?.resources?.virtualTours

  return (
    <>
      <ProjectHeader project={project} />

      <Container>
        <Content>
          <Stack spacing={8}>
            {!!photos.length && (
              <Box>
                <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
                  Photos
                </Typography>

                <LoadingImageGallery id="photos-gallery" images={photos} />
              </Box>
            )}

            {!!renders.length && (
              <Box>
                <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
                  Renders
                </Typography>

                <LoadingImageGallery id="renders-gallery" images={renders} />
              </Box>
            )}

            {!!videos?.length && (
              <Stack spacing={8}>
                {videos.map((video, index) => (
                  <Video key={index} {...video} />
                ))}
              </Stack>
            )}

            {!!virtualTours?.length && (
              <Box>
                <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
                  Virtual Tours
                </Typography>

                {virtualTours.map((virtualTour, index) => (
                  <VirtualTourItem virtualTour={virtualTour} key={index} />
                ))}
              </Box>
            )}
          </Stack>
        </Content>
      </Container>
    </>
  )
}

export default ProjectGallery
