import { Box, Stack, SxProps, Theme } from '@mui/material'
import AvailabilityChip from 'components/molecules/AvailabilityChip'
import dwellingStatusCodes from 'constants/dwelling/status-codes'
import DwellingFilter from 'interfaces/dwelling/filter'
import { memo, useState } from 'react'
import { getAvailabilityColor } from 'styles/availability-colors'
import DwellingStatusCode from 'types/dwelling/status-code'

type Props = {
  onFilter: (filter: DwellingFilter) => void
  sx?: SxProps<Theme>
  includeCodes?: DwellingStatusCode[]
}

const AvailabilityChips = ({
  onFilter,
  sx,
  includeCodes = dwellingStatusCodes,
}: Props) => {
  const [filterStatusCodes, setFilterStatusCodes] = useState<number[]>([])

  const chips: {
    color: string
    name: string
    statusCodes: DwellingStatusCode[]
  }[] = [
    {
      color: getAvailabilityColor(0),
      name: 'Unknown',
      statusCodes: [0],
    },
    {
      color: getAvailabilityColor(1),
      name: 'Available',
      statusCodes: [1],
    },
    {
      // This could also have been 3.
      color: getAvailabilityColor(2),
      name: 'Locked/Reserved',
      statusCodes: [2, 3],
    },
    {
      color: getAvailabilityColor(4),
      name: 'Sold',
      statusCodes: [4],
    },
  ]

  const filteredChips = chips.filter((chip) =>
    chip.statusCodes.some((statusCode) => includeCodes.includes(statusCode))
  )

  const handleClick = (statusCodes: DwellingStatusCode[], active: boolean) => {
    setFilterStatusCodes((prevActiveStatusCodes) => {
      let nextActiveStatusCodes: number[] = []

      if (active) {
        nextActiveStatusCodes = [...prevActiveStatusCodes, ...statusCodes]
      } else {
        nextActiveStatusCodes = prevActiveStatusCodes.filter(
          (prevStatusCode) =>
            !statusCodes.includes(prevStatusCode as DwellingStatusCode)
        )
      }

      onFilter({
        key: 'statusCode',
        name: 'Status code',
        type: 'chip',
        value: nextActiveStatusCodes,
      })

      return nextActiveStatusCodes
    })
  }

  return (
    <Box
      sx={{
        ...sx,
        // Not interactable when unnecessary.
        pointerEvents: filteredChips.length < 2 ? 'none' : 'auto',
      }}
    >
      <Stack
        direction="row"
        gap={{ xs: 1, sm: 2 }}
        sx={{ flexWrap: 'wrap' }}
      >
        {filteredChips
          .filter((chip) =>
            chip.statusCodes.some((statusCode) =>
              includeCodes.includes(statusCode)
            )
          )
          .map((chip) => (
            <AvailabilityChip
              key={chip.name}
              statusCodes={chip.statusCodes}
              active={filterStatusCodes.some((filterStatusCode) =>
                chip.statusCodes.includes(
                  filterStatusCode as DwellingStatusCode
                )
              )}
              name={chip.name}
              color={chip.color}
              onClick={handleClick}
            />
          ))}
      </Stack>
    </Box>
  )
}

export default memo(AvailabilityChips)
