import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import TabContext from '@mui/lab/TabContext'
import { Dialog, DialogContent, Fade } from '@mui/material'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import PersistentTabPanel from 'components/atoms/PersistentTabPanel'
import SlideUp from 'components/atoms/SlideUp'
import ShareDialogHeader from 'components/molecules/share/ShareDialogHeader'
import { shareDialogModes } from 'constants/share/dialog-modes'
import ProjectFocusContext from 'contexts/ProjectFocusContext'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useProject from 'hooks/useProject'
import Project from 'interfaces/project'
import { useMemo, useState } from 'react'
import ShareDialogMode from 'types/share/dialog-mode'
import { accountCanShare } from 'utils/account/role'

type Props = {
  iconButtonProps?: IconButtonProps
}

const ShareDialog = ({ iconButtonProps }: Props) => {
  const [open, setOpen] = useState(false)
  const [mode, setMode] = useState<ShareDialogMode>('projects')
  const { data: me } = useOdooAccountMe()

  const project = useProject()
  const [focusedProject, setFocusedProject] = useState<Project | null>(
    project ?? null
  )
  const memoizedFocusedProject = useMemo(
    () => ({
      value: focusedProject,
      setter: setFocusedProject,
    }),
    [focusedProject]
  )

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true })

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleModeChange = (
    event: React.SyntheticEvent,
    newMode: ShareDialogMode
  ) => {
    setMode(newMode)
  }

  if (!me || !accountCanShare(me)) {
    return null
  }

  return (
    <>
      <IconButton
        aria-label="Share"
        color="inherit"
        onClick={handleOpen}
        {...iconButtonProps}
      >
        <ShareOutlinedIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="share-dialog-title"
        maxWidth="lg"
        fullWidth
        fullScreen={mobile}
        TransitionComponent={mobile ? SlideUp : Fade}
      >
        <DialogContent>
          <TabContext value={mode}>
            <ShareDialogHeader
              titleId="share-dialog-title"
              title="Share"
              onCloseClick={handleClose}
              onTabChange={handleModeChange}
            />

            <ProjectFocusContext.Provider value={memoizedFocusedProject}>
              {shareDialogModes.map((mode) => (
                <PersistentTabPanel key={mode.slug} value={mode.slug}>
                  {mode.component}
                </PersistentTabPanel>
              ))}
            </ProjectFocusContext.Provider>
          </TabContext>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ShareDialog
