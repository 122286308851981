import { Box, Card, CardActionArea, CardContent, Grid } from '@mui/material'
import FavoriteButton from 'components/molecules/FavoriteButton'
import DwellingInfoField from 'components/molecules/dwellings/DwellingInfoField'
import DwellingMediaLightbox from 'components/molecules/dwellings/media/DwellingMediaLightbox'
import useDwellingMediaFloorPlans from 'hooks/dwelling/media/useDwellingMediaFloorPlans'
import useDwellingMediaPlotPlans from 'hooks/dwelling/media/useDwellingMediaPlotPlans'
import useDwellingMediaRealViews from 'hooks/dwelling/media/useDwellingMediaRealViews'
import useDwellingEnabled from 'hooks/dwelling/useDwellingEnabled'
import useProject from 'hooks/useProject'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingField from 'interfaces/dwelling/field'
import { useState } from 'react'
import DwellingDetailDialog from './detail/DwellingDetailDialog'

type Props = {
  dwelling: DwellingExtended
  title?: React.ReactNode
  onClick?: (dwelling: DwellingExtended) => void
}

const DwellingCard = ({ dwelling, title, onClick }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const project = useProject(dwelling.project, true)

  const floorPlans = useDwellingMediaFloorPlans(dwelling, project)
  const plotPlans = useDwellingMediaPlotPlans(dwelling, project)
  const realViews = useDwellingMediaRealViews(dwelling, project)

  const enabled = useDwellingEnabled(dwelling)

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const handleClick = () => {
    if (!enabled) {
      return
    }

    if (onClick) {
      onClick(dwelling)
    } else {
      handleDialogOpen()
    }
  }

  if (!project) {
    return null
  }

  const fields: DwellingField[] = [
    {
      name: 'Bedrooms',
      value: dwelling.beds,
    },

    {
      name: 'Baths',
      value: dwelling.baths,
    },
    {
      name: 'Parking',
      value: dwelling.parkingLots,
    },
    {
      name: 'Total Constructed Area',
      value: dwelling.totalConstructed,
      suffix: 'm<sup>2</sup>',
    },
    {
      name: 'Total Usable Area',
      value: dwelling.totalUsable,
      suffix: 'm<sup>2</sup>',
    },
  ]

  return (
    <>
      <Card onClick={handleClick} sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: (theme) => theme.spacing(2.5),
            right: (theme) => theme.spacing(3.5), // Easier on the eyes this way
            zIndex: 1,
          }}
        >
          {/* Favorite button positioned like this because you can't nest buttons (CardActionArea) */}
          <FavoriteButton
            dwelling={dwelling}
            iconButtonProps={{ edge: 'end' }}
          />
        </Box>

        <CardActionArea disabled={!enabled}>
          <CardContent
            sx={{
              p: 3,
            }}
          >
            {!!title && <Box sx={{ mb: 3 }}>{title}</Box>}

            <Grid
              container
              spacing={{
                xs: 4,
                md: 3,
              }}
              alignItems="center"
            >
              <Grid item xs={12} md={4}>
                <Grid container item spacing={2}>
                  {fields.map((field, index) => (
                    <Grid key={index} item xs={6} md={12}>
                      <DwellingInfoField
                        field={field}
                        type="normal"
                        variant="horizontal"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12} md={8}>
                <Box
                  sx={[
                    {
                      /**
                       * Prevents the click in the CardActionArea passing through
                       * and hitting the media lightboxes. That would open the
                       * dwelling detail and the lightbox at the same time.
                       */
                      pointerEvents: 'none',
                    },

                    !enabled && {
                      position: 'relative',
                      padding: 2,
                      '&:after': {
                        position: 'absolute',
                        content: '"Not available anymore"',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'secondary.light',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        backdropFilter: 'blur(10px)',

                        fontFamily: (theme) =>
                          theme.typography.overline.fontFamily,
                        fontSize: (theme) => theme.typography.overline.fontSize,
                        fontWeight: (theme) =>
                          theme.typography.overline.fontWeight,
                        lineHeight: (theme) =>
                          theme.typography.overline.lineHeight,
                        textTransform: (theme) =>
                          theme.typography.overline.textTransform,

                        bgcolor: (theme) =>
                          `rgba(255,255,255,${theme.palette.action.disabledOpacity})`,
                        borderRadius: (theme) => theme.shape.borderRadius,
                      },
                    },
                  ]}
                >
                  <Grid container spacing={3}>
                    {!!floorPlans.length && (
                      <Grid item xs={4} sm>
                        <DwellingMediaLightbox images={floorPlans} hideHint />
                      </Grid>
                    )}

                    {!!plotPlans.length && (
                      <Grid item xs={4} sm>
                        <DwellingMediaLightbox images={plotPlans} hideHint />
                      </Grid>
                    )}

                    {!!realViews.length && (
                      <Grid item xs={4} sm>
                        <DwellingMediaLightbox images={realViews} hideHint />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>

      <DwellingDetailDialog
        dwelling={dwelling}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default DwellingCard
