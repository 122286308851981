import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { Box, Grid, Link, Stack, Typography } from '@mui/material'
import Notice from 'components/molecules/Notice'
import useOdooPartnerAttachments from 'hooks/odoo/partner/useOdooPartnerAttachments'
import { base64ToBlobUrl } from 'utils/base64'

const AccountAttachments = () => {
  const { data: categories, isLoading, isError } = useOdooPartnerAttachments()

  if (isLoading || isError) {
    return null
  }

  if (!categories.length) {
    return (
      <Notice
        message="No documents currently available, please come back later"
        icon={FolderOffOutlinedIcon}
      />
    )
  }

  return (
    <Box>
      {categories.map(({ category, attachments }) => (
        <Stack key={category} spacing={2} sx={{ mb: 4 }}>
          <Typography variant="subtitle2" component="h3">
            {category}
          </Typography>

          <Box
            sx={{
              p: 3,
              bgcolor: 'grey.50',
              borderRadius: (theme) => theme.shape.borderRadius,
            }}
          >
            <Grid container spacing={3}>
              {attachments.map((attachment) => {
                const name = attachment.display_name || attachment.name

                return (
                  <Grid item xs={6} sm={3} md={2} key={attachment.id}>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                      component={Link}
                      href={base64ToBlobUrl(
                        attachment.datas,
                        attachment.mimetype
                      )}
                      target="_blank"
                      underline="none"
                      color="inherit"
                      sx={{
                        height: '100%',
                        p: 2,
                        textAlign: 'center',
                        borderRadius: 2,
                        transition: (theme) =>
                          theme.transitions.create('background-color', {
                            duration: theme.transitions.duration.short,
                          }),
                        '&:hover': {
                          bgcolor: (theme) => theme.palette.action.hover,
                        },
                      }}
                    >
                      <InsertDriveFileOutlinedIcon />

                      <Typography variant="body2" sx={{ textWrap: 'balance' }}>
                        {name.includes('.') ? name.split('.')[0] : name}
                      </Typography>
                    </Stack>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Stack>
      ))}
    </Box>
  )
}

export default AccountAttachments
