import { SxProps, Theme } from '@mui/material/styles'
import Overline from 'components/atoms/Overline'
import DwellingFilter from 'interfaces/dwelling/filter'

type Props = {
  name: DwellingFilter['name']
  sx?: SxProps<Theme>
}

const DwellingsFilterName = ({ name, sx }: Props) => {
  return (
    <Overline
      sx={{
        mb: 1,
        ...sx,
      }}
    >
      {name}
    </Overline>
  )
}

export default DwellingsFilterName
