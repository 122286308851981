import DeselectOutlinedIcon from '@mui/icons-material/DeselectOutlined'
import SelectAllOutlinedIcon from '@mui/icons-material/SelectAllOutlined'
import { Box, Stack, StackProps, Typography } from '@mui/material'
import Dwelling from 'interfaces/dwelling'

type Props = {
  selection: Dwelling[]
  stackProps?: StackProps
}

const ShareDwellingsSelectionStatus = ({ selection, stackProps }: Props) => {
  return (
    <Stack spacing={1} direction="row" alignItems="center" {...stackProps}>
      {!!selection.length ? (
        <SelectAllOutlinedIcon fontSize="small" color="primary" />
      ) : (
        <DeselectOutlinedIcon fontSize="small" color="action" />
      )}

      <Typography variant="body2">
        <span>{selection.length}</span>
        <Box
          component="span"
          sx={{
            display: {
              xs: 'none',
              sm: 'initial',
            },
          }}
        >
          &nbsp;units selected
        </Box>
      </Typography>
    </Stack>
  )
}

export default ShareDwellingsSelectionStatus
