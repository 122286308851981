import { Box, Stack, Typography } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import { getAvailabilityColor } from 'styles/availability-colors'

type Props = {
  sx?: SxProps<Theme>
}

const AvailabilityColors = ({ sx }: Props) => {
  const items = [
    {
      color: getAvailabilityColor(1),
      name: 'Available',
    },
    {
      // This could also have been 3.
      color: getAvailabilityColor(2),
      name: 'Locked/Reserved',
    },
    {
      color: getAvailabilityColor(4),
      name: 'Sold',
    },
  ]

  return (
    <Box
      sx={{
        paddingX: 3,
        ...sx,
      }}
    >
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 6 }}>
        {items.map((item) => (
          <Typography
            key={item.name}
            sx={{
              paddingLeft: 3,
              position: 'relative',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: 0,
                width: 8,
                height: 8,
                borderRadius: '50%',
                bgcolor: item.color,
              },
              '@media print': {
                fontSize: '0.6875rem',
              },
            }}
            variant="body2"
          >
            {item.name}
          </Typography>
        ))}
      </Stack>
    </Box>
  )
}

export default AvailabilityColors
