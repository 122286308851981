import { normalizeToString } from 'utils/normalization'

export const checkIsValid = (
  firstName: string,
  lastName: string,
  isEmailValid: boolean,
  password: string,
  repeatPassword: string,
  legal: boolean,
  passwordNotRequired: boolean,
  legalNotRequired: boolean
) => {
  // 'n' stands for normalized.
  const nFirstName = normalizeToString(firstName)
  const nLastName = normalizeToString(lastName)
  const nPassword = normalizeToString(password).trim()
  const nRepeatPassword = normalizeToString(repeatPassword).trim()

  if (nFirstName === '') {
    return false
  }

  if (nLastName === '') {
    return false
  }

  if (!isEmailValid) {
    return false
  }

  if (
    // Password is not required and
    passwordNotRequired &&
    // is set and
    (nPassword !== '' || nRepeatPassword !== '') &&
    // password doesn't match repeat password
    nPassword !== nRepeatPassword
  ) {
    return false
  }

  if (
    // Password is required and
    !passwordNotRequired &&
    // isn't set or password doesn't match repeat password
    (nPassword === '' || nPassword !== nRepeatPassword)
  ) {
    return false
  }

  if (!legalNotRequired && !legal) {
    return false
  }

  return true
}
