import { Container, Grid, Stack, Typography } from '@mui/material'
import Lightbox from 'components/atoms/Lightbox'
import { sectionSpacing } from 'constants/section'
import useAddImagesDimensions from 'hooks/useAddImagesDimensions'
import SectionText66Image33Props from 'interfaces/section/text66-image33'

const SectionText66Image33 = ({
  title,
  leftText,
  rightImage,
  index,
}: SectionText66Image33Props & { index: number }) => {
  const [[rightImageWithDimensions]] = useAddImagesDimensions(
    rightImage
      ? {
          url: rightImage,
        }
      : []
  )

  return (
    <Container>
      <Grid container spacing={sectionSpacing * 2} alignItems="center">
        <Grid item xs={12} md={8}>
          {!!title && (
            <Typography variant="h5" component="h2" sx={{ mb: sectionSpacing }}>
              {title}
            </Typography>
          )}

          {!!leftText?.length && (
            <Stack spacing={sectionSpacing}>
              {leftText.map((p, index) => (
                <Typography key={index}>{p}</Typography>
              ))}
            </Stack>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          {!!rightImageWithDimensions && (
            <Lightbox
              id={`section-text66-image33-${index}`}
              image={rightImageWithDimensions}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default SectionText66Image33
