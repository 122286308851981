import { Tab, tabClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const ButtonTab = styled(Tab)(({ theme }) => ({
  textTransform: 'initial',
  minHeight: 'initial',
  minWidth: 'initial',
  borderRadius: 4,

  marginLeft: theme.spacing(1),
  '&:first-of-type': {
    marginLeft: 0,
  },

  fontSize: '0.775rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.875rem',
  },

  [`&.${tabClasses.selected}`]: {
    [`&.${tabClasses.textColorPrimary}`]: {
      backgroundColor: `${theme.palette.primary.light}54`,
    },
    [`&.${tabClasses.textColorSecondary}`]: {
      backgroundColor: `${theme.palette.secondary.light}0d`,
    },
  },
}))

export default ButtonTab
