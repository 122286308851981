import { useMutation } from '@tanstack/react-query'
import { errorMessages } from 'constants/errors'
import OdooApiCreateResponse from 'interfaces/odoo/api/create/response'
import useOdooAdmin from '../useOdooAdmin'

type TVariables = {
  model: string
  data: Record<string, unknown>
  context?: Record<string, unknown>
}
type TData = OdooApiCreateResponse['result']
type TError = Error

const useOdooApiCreate = () => {
  const { data: admin } = useOdooAdmin()

  const mutation = async (variables: TVariables): Promise<TData> => {
    if (!admin?.success) {
      throw new Error(errorMessages.odooAdmin)
    }

    const response = await fetch(
      `${import.meta.env.VITE_ODOO_OBJECT_ENDPOINT}/${variables.model}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${admin.data.token}`,
        },
        body: JSON.stringify({
          params: {
            data: variables.data,
            context: variables.context,
          },
        }),
      }
    )

    const { result, error }: OdooApiCreateResponse = await response.json()

    if (!response.ok || !result || error) {
      throw new Error(error?.data.message ?? errorMessages.odooApi)
    }

    return result
  }

  return useMutation<TData, TError, TVariables>(mutation)
}

export default useOdooApiCreate
