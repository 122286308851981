import { Stack, Typography } from '@mui/material'
import Iframe from 'components/atoms/Iframe'
import VideoProps from 'interfaces/video'

const Video = ({ title, type, url }: VideoProps) => {
  const Component: React.ReactNode = (() => {
    switch (type) {
      case 'youtube': {
        const params = new URLSearchParams(new URL(url).search)

        if (!params.has('v')) {
          console.error(
            `Trying to include a YouTube video but its URL search params didn't have a "v" parameter`
          )
          return
        }

        return (
          <Iframe
            title={title ?? 'YouTube iframe'}
            src={`https://www.youtube.com/embed/${params.get('v')}`}
          />
        )
      }

      case 'fileUrl':
        return <video src={url} controls style={{ maxWidth: '100%' }} />
    }
  })()

  return (
    <Stack spacing={3}>
      {!!title && (
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
      )}

      {Component}
    </Stack>
  )
}

export default Video
