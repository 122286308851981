import { Box, Stack, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { SxProps, Theme } from '@mui/material/styles'
import AccountChips from 'components/molecules/account/AccountChips'
import AccountProps from 'interfaces/account'
import { stringAvatar } from 'utils/account/avatar'
import { getAccountName } from 'utils/account/metadata'

type Props = {
  account: AccountProps
  sx?: SxProps<Theme>
}

const Account = ({ account, sx }: Props) => {
  const name = getAccountName(account)

  return (
    <Stack direction="row" alignItems="center" sx={sx}>
      <Box
        sx={{
          mr: {
            xs: 0,
            sm: 1.5,
          },
          display: {
            xs: 'none',
            sm: 'inline-block',
          },
        }}
      >
        <Avatar {...stringAvatar(name)} />
      </Box>

      <Stack
        direction="column"
        sx={{
          maxWidth: {
            xs: '100%',
            // 40px is approx what the Avatar measures.
            sm: 'calc(100% - 40px)',
          },
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            variant="subtitle1"
            component="span"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name || (
              <span style={{ fontStyle: 'italic' }}>Pending registration</span>
            )}
          </Typography>

          <AccountChips account={account} />
        </Stack>

        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'grey.400',
            fontSize: {
              xs: '0.775rem',
              sm: '1rem',
            },
          }}
          component="span"
        >
          {account.email}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default Account
