import SSOStoragePostMessage from 'interfaces/sso/storage/post-message'
import { dispatch, listen } from './messages'

const setItem = (key: string, value: string) =>
  new Promise<void>((resolve, reject) => {
    dispatch(
      {
        type: 'set-item-request',
        detail: key,
      },
      value
    )
      .then(() => {
        listen(
          {
            type: 'set-item-response',
            detail: key,
          },
          () => {
            resolve()
          }
        )
      })
      .catch((reason) => {
        console.error(reason)
        reject(reason)
      })
  })

const getItem = (key: string) =>
  new Promise<string | null>((resolve, reject) => {
    dispatch({
      type: 'get-item-request',
      detail: key,
    })
      .then(() => {
        listen(
          {
            type: 'get-item-response',
            detail: key,
          },
          (message: SSOStoragePostMessage) => {
            resolve(message.payload ?? null)
          }
        )
      })
      .catch((reason) => {
        console.error(reason)
        reject(reason)
      })
  })

const removeItem = (key: string) =>
  new Promise<void>((resolve, reject) => {
    dispatch({
      type: 'remove-item-request',
      detail: key,
    })
      .then(() => {
        listen(
          {
            type: 'remove-item-response',
            detail: key,
          },
          () => {
            resolve()
          }
        )
      })
      .catch((reason) => {
        console.error(reason)
        reject(reason)
      })
  })

/**
 * Simplified `Storage` like API that saves items in a
 * separated domain accessible by multiple platforms.
 * 
 * Works like `Window.localStorage` but async.
 */
const ssoStorage = {
  setItem,
  getItem,
  removeItem,
}

export default ssoStorage
