import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * @link https://www.matthewhoelter.com/2022/04/02/how-to-scroll-to-top-on-route-change-with-react-router-dom-v6.html
 */
const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      /**
       * @link https://github.com/microsoft/TypeScript/issues/47441#issuecomment-1627850951
       */
      // @ts-ignore
      behavior: 'instant',
    })
  }, [pathname])

  return null
}

export default ScrollToTop
