import useChangelog from 'hooks/changelog/useChangelog'
import ChangelogList from '../../organisms/changelog/ChangelogList'

const ChangelogFeatures = () => {
  const { data: changelogEntries } = useChangelog()
  const features = changelogEntries.filter(
    (changelogEntry) => changelogEntry.type === 'feature'
  )
  if (!features.length) return null

  return <ChangelogList changelogEntries={features} />
}

export default ChangelogFeatures
