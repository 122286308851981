import { Stack, SvgIconProps } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import AccountActionDelete from 'components/molecules/account/AccountActionDelete'
import AccountActionInfoTooltip from 'components/molecules/account/AccountActionInfoTooltip'
import AccountActionProjectsPermissions from 'components/molecules/account/AccountActionProjectsPermissions'
import Account from 'interfaces/account'

type Props = {
  account: Account
  fontSize?: SvgIconProps['fontSize']
  sx?: SxProps<Theme>
}

const AccountActions = ({ account, fontSize, sx }: Props) => {
  return (
    <Stack spacing={1} direction="row" alignItems="center" sx={sx}>
      <AccountActionInfoTooltip account={account} fontSize={fontSize} />
      <AccountActionProjectsPermissions account={account} fontSize={fontSize} />
      <AccountActionDelete account={account} fontSize={fontSize} />
    </Stack>
  )
}

export default AccountActions
