import { Container, Grid, Stack, Typography } from '@mui/material'
import Lightbox from 'components/atoms/Lightbox'
import { sectionSpacing } from 'constants/section'
import useAddImagesDimensions from 'hooks/useAddImagesDimensions'
import SectionTextTextImageProps from 'interfaces/section/text-text-image'

const SectionTextTextImage = ({
  title,
  leftText,
  middleText,
  rightImage,
  index,
}: SectionTextTextImageProps & { index: number }) => {
  const [[rightImageWithDimensions]] = useAddImagesDimensions(
    rightImage
      ? {
          url: rightImage,
        }
      : []
  )

  return (
    <Container>
      <Grid
        container
        rowSpacing={sectionSpacing}
        columnSpacing={sectionSpacing * 2}
      >
        {!!title && (
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          {!!leftText?.length && (
            <Stack spacing={sectionSpacing}>
              {leftText.map((p, index) => (
                <Typography key={index}>{p}</Typography>
              ))}
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {!!middleText?.length && (
            <Stack spacing={sectionSpacing}>
              {middleText.map((p, index) => (
                <Typography key={index}>{p}</Typography>
              ))}
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {!!rightImageWithDimensions && (
            <Lightbox
              id={`section-text-text-image-${index}`}
              image={rightImageWithDimensions}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default SectionTextTextImage
