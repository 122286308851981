import { Box, Fade, Paper, Typography } from '@mui/material'
import TargetMediaType from 'components/atoms/TargetMediaType'
import CenteredSpinner from 'components/molecules/CenteredSpinner'
import InteractiveDwellingTooltipContent from 'components/molecules/InteractiveDwellingTooltipContent'
import InteractiveSvgGroupWithTooltip from 'components/molecules/InteractiveSvgGroupWithTooltip'
import useImgLoaded from 'hooks/useImgLoaded'
import DwellingExtended from 'interfaces/dwelling/extended'
import InteractivePlanSvgType from 'interfaces/interactive-plan/interactive-plan-svg'
import Project from 'interfaces/project'
import { getAvailabilityColor } from 'styles/availability-colors'
import { getExtendedDwellings } from 'utils/dwelling'

type Props = {
  project: Project
  svg: InteractivePlanSvgType
  filteredDwellings?: DwellingExtended[]
  elevation?: number
}

const InteractivePlanSvg = ({
  project,
  svg,
  filteredDwellings,
  elevation,
}: Props) => {
  const allDwellings = getExtendedDwellings(project)
  const dwellings = filteredDwellings ?? allDwellings

  const image = svg?.image['@attributes'].href
  const isImageLoaded = useImgLoaded(image)

  let svgGroups = svg.g
  if (!Array.isArray(svgGroups)) {
    svgGroups = [svgGroups]
  }

  return (
    <>
      <Fade in={isImageLoaded}>
        <Paper
          sx={{
            height: 'auto',
            width: '100%',
            '@media print': {
              maxHeight: '65vh',
            },
          }}
          elevation={elevation ?? 3}
          component={'svg'}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox={svg?.['@attributes'].viewBox}
        >
          <g id="plan">
            <image
              id="image"
              width={svg?.image['@attributes'].width}
              height={svg?.image['@attributes'].height}
              transform={svg?.image['@attributes'].transform}
              href={image}
            />
          </g>

          {!!dwellings.length &&
            svgGroups.map((svgGroup: any) => {
              const dwelling = dwellings.find(
                (dwelling) =>
                  dwelling.id === parseInt(svgGroup['@attributes'].id)
              )

              if (!dwelling) {
                // Disable the SVG group in case it isn't related with any dwelling.
                return null
              }

              return (
                <InteractiveSvgGroupWithTooltip
                  key={svgGroup['@attributes'].id}
                  id={svgGroup['@attributes'].id}
                  fill={getAvailabilityColor(dwelling.statusCode)}
                  tooltipContent={
                    <InteractiveDwellingTooltipContent
                      dwelling={dwelling}
                      project={project}
                    />
                  }
                >
                  <path d={svgGroup.path['@attributes'].d} />
                </InteractiveSvgGroupWithTooltip>
              )
            })}
        </Paper>
      </Fade>

      <CenteredSpinner in={!isImageLoaded} />
    </>
  )
}

export default InteractivePlanSvg
