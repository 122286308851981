import SlimChip from 'components/atoms/SlimChip'
import { useState } from 'react'
import AccountRole from 'types/account/role'
import { getAccountRoleFormatted } from 'utils/account/role'

type Props = {
  roles: AccountRole[]
  onChange: (value: AccountRole | null) => void
}

const AccountsFiltersRoles = ({ roles, onChange }: Props) => {
  const [selectedRole, setSelectedRole] = useState<AccountRole | null>(null)

  return (
    <>
      {roles.map((role) => (
        <SlimChip
          clickable
          key={role}
          size="small"
          variant={role === selectedRole ? 'filled' : 'outlined'}
          label={getAccountRoleFormatted(role)}
          onClick={() => {
            const newRole = selectedRole === role ? null : role

            onChange(newRole)
            setSelectedRole(newRole)
          }}
        />
      ))}
    </>
  )
}

export default AccountsFiltersRoles
