import { Grid } from '@mui/material'
import ChangelogEntryProps from 'interfaces/changelog/changelog-entry'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getLastChangelogEntryUnread } from 'utils/changelog/local-storage'
import ChangelogEntry from './ChangelogEntry'

type Props = {
  changelogEntries: ChangelogEntryProps[]
  itemsPerPage?: number
}
const ChangelogList = ({ changelogEntries, itemsPerPage = 3 }: Props) => {
  const initialPage = 1
  const [page, setPage] = useState(initialPage)
  const [hasMore, setHasMore] = useState(false)
  const observer = useRef<IntersectionObserver>()
  const lastChangelogEntryRef = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(
        (entries) => {
          const lastEntry = entries[0]
          if (lastEntry.isIntersecting && hasMore) {
            //Implement logic to load the next page when the last element is fully visible
            setPage((prevPage) => prevPage + 1)
            // setLoadingMore(true)
            // setTimeout(() => {

            //   setLoadingMore(false)
            // }, 300)
          }
        },
        { threshold: 0.99 }
      )
      if (node) observer.current.observe(node)
    },
    [hasMore]
  )

  const pages = Math.ceil(changelogEntries.length / itemsPerPage)
  const pageStart = (page - 1) * itemsPerPage
  const pageEnd = pageStart + itemsPerPage

  useEffect(() => {
    setHasMore(page < pages)
  }, [page, pages])

  if (!changelogEntries) return null
  const lastChangelogEntryUnread = getLastChangelogEntryUnread(changelogEntries)

  const changelogEntriesSorted = changelogEntries
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .slice(0, pageEnd)

  return (
    <Grid container rowSpacing={2}>
      {changelogEntriesSorted.map((changelogEntry, index) => {
        if (changelogEntriesSorted.length === index + 1) {
          return (
            <Grid xs={12} ref={lastChangelogEntryRef} pb={8} key={index} item>
              <ChangelogEntry
                {...changelogEntry}
                isLastEntryUnread={
                  lastChangelogEntryUnread?._id === changelogEntry._id
                }
              />
            </Grid>
          )
        } else {
          return (
            <Grid key={index} pb={4} xs={12} item>
              <ChangelogEntry
                {...changelogEntry}
                isLastEntryUnread={
                  lastChangelogEntryUnread?._id === changelogEntry._id
                }
              />
            </Grid>
          )
        }
      })}
      {/* <CenteredSpinner in={loadingMore} /> */}
    </Grid>
  )
}

export default ChangelogList
