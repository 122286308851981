import { Typography, TypographyProps } from '@mui/material'

/**
 * Decent looking preconfigured Typography component with overline variant.
 */
const Overline = ({ children, ...rest }: TypographyProps) => {
  return (
    <Typography
      variant="overline"
      color="text.secondary"
      lineHeight="normal"
      display="inline-block"
      {...rest}
    >
      {children}
    </Typography>
  )
}

export default Overline
