import { Grid } from '@mui/material'
import DwellingsFilterName from 'components/molecules/dwellings/DwellingsFilterName'
import DwellingFilter from 'interfaces/dwelling/filter'
import { memo, useEffect, useState } from 'react'
import DwellingsFilterSelect from './DwellingsFilterSelect'
import DwellingsFilterSlider from './DwellingsFilterSlider'
import DwellingsFilterSwitch from './DwellingsFilterSwitch'

type Props = {
  initialFilters: DwellingFilter[]
  onChange: (filters: DwellingFilter[]) => void
  children?: React.ReactNode
}

const DwellingsFilters = ({ initialFilters, onChange, children }: Props) => {
  /**
   * Because all filters subcomponents are controlled
   * by the `filters` value, the `committed` boolean
   * is needed in order to know when we are ready to
   * trigger `onChange` and provide consumers of this
   * component the newly updated filters.
   */
  const [filters, setFilters] = useState<DwellingFilter[]>(initialFilters)
  const [committed, setCommitted] = useState(true)

  useEffect(() => {
    if (!committed) {
      return
    }

    onChange(filters)
  }, [committed, filters, onChange])

  return (
    <Grid
      container
      spacing={{
        xs: 3,
        lg: 4,
      }}
    >
      {filters.map((filter, key) => {
        let FilterComponent = null

        const handleChange = (
          event: Event | React.SyntheticEvent<Element, Event>,
          newValue: any
        ) => {
          setFilters((prevFilters) => {
            const nextFilters = [...prevFilters]

            const index = nextFilters.findIndex(
              (nextFilter) => nextFilter.key === filter.key
            )
            if (index !== -1) {
              nextFilters[index].value = newValue
            } else {
              nextFilters.push({
                ...filter,
                value: newValue,
              })
            }

            return nextFilters
          })
        }

        switch (filter.type) {
          case 'slider':
            FilterComponent = (
              <DwellingsFilterSlider
                filter={filter}
                onChange={(event, value) => {
                  setCommitted(false)
                  handleChange(event, value)
                }}
                onChangeCommitted={(event, value) => {
                  setCommitted(true)
                  handleChange(event, value)
                }}
                key={key}
              />
            )
            break

          case 'switch':
            FilterComponent = (
              <DwellingsFilterSwitch
                filter={filter}
                onChange={handleChange}
                key={key}
              />
            )
            break

          case 'select':
            FilterComponent = (
              <DwellingsFilterSelect
                filter={filter}
                onChange={handleChange}
                key={key}
              />
            )
            break
        }

        return (
          <Grid item key={filter.key} xs={12} sm={6} lg={3} xl={2}>
            <DwellingsFilterName name={filter.name} />
            {FilterComponent}
          </Grid>
        )
      })}

      {children}
    </Grid>
  )
}

export default memo(DwellingsFilters)
