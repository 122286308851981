import { LinkProps } from '@mui/material/Link'
import { grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { ResponsiveStyleValue, createBreakpoints } from '@mui/system'
import React from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'
import fontFamilyBase from './font-family-base'

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      shadowColor: string
      textShadow: string
      backdropBlurAndSaturation: string
      backdropBlur: string
      darkOverlayColor: string
      lightOverlayColor: string
      headerHeight: ResponsiveStyleValue<string>
      fullHeightMinusHeader: ResponsiveStyleValue<string>
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      shadowColor?: string
      textShadow?: string
      backdropBlurAndSaturation?: string
      backdropBlur?: string
      darkOverlayColor?: string
      lightOverlayColor?: string
      headerHeight?: ResponsiveStyleValue<string>
      fullHeightMinusHeader?: ResponsiveStyleValue<string>
    }
  }
}

const breakpoints = createBreakpoints({})

export const theme = createTheme({
  custom: {
    shadowColor: grey[900],
    textShadow: `0.5px 0.5px 6px ${grey[900]}a8`,
    backdropBlurAndSaturation: 'saturate(180%) blur(20px)',
    backdropBlur: 'blur(20px)',
    darkOverlayColor: 'rgba(0, 0, 0, .66)',
    lightOverlayColor: 'rgba(255, 255, 255, 0.8)',
    headerHeight: {
      xs: '58px',
      sm: '82px',
    },
    fullHeightMinusHeader: {
      xs: 'calc(100vh - 58px)',
      sm: 'calc(100vh - 82px)',
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(10px)',
        },
        // Make sure invisible backdrops do not get dark/blurry.
        invisible: {
          backgroundColor: 'transparent',
          backdropFilter: 'none',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 16,
          [breakpoints.up('sm')]: {
            padding: 24,
          },
          [breakpoints.up('md')]: {
            padding: 48,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          [breakpoints.up('sm')]: {
            minHeight: 80,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          '.fullscreen &': {
            display: 'none',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#64adf1',
      main: '#496ca4',
      dark: '#0b2476',
    },
    secondary: {
      light: '#333333',
      main: '#000000',
      dark: '#000000',
    },
    success: {
      main: '#50B83C',
    },
    warning: {
      main: '#EEC200',
    },
    error: {
      main: '#FC0000',
    },
  },
  typography: {
    fontFamily: ['Work Sans', ...fontFamilyBase].join(','),
  },
})
