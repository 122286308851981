import { Container, Stack, Typography } from '@mui/material'
import LoadingImageGallery from 'components/organisms/LoadingImageGallery'
import { sectionSpacing } from 'constants/section'
import SectionImageGalleryProps from 'interfaces/section/image-gallery'

const SectionImageGallery = ({
  title,
  images,
  imagesByFolder,
  index,
}: SectionImageGalleryProps & { index: number }) => {
  const allImages = [...(images ?? []), ...(imagesByFolder?.folder ?? [])]

  return (
    <Container>
      <Stack spacing={sectionSpacing}>
        {!!title && (
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
        )}

        {!!allImages?.length && (
          <LoadingImageGallery
            id={`section-image-gallery-${index}`}
            images={allImages}
          />
        )}
      </Stack>
    </Container>
  )
}

export default SectionImageGallery
