import { Fade } from '@mui/material'
import { createTheme, Theme } from '@mui/material/styles'
import ThemeProviderWithGlobalFontFamily from 'components/atoms/ThemeProviderWithGlobalFontFamily'
import Project from 'interfaces/project'
import { useState } from 'react'
import { Helmet, HelmetProvider, HelmetTags } from 'react-helmet-async'
import fontFamilyBase from 'styles/font-family-base'

type Props = {
  project: Project
  children: React.ReactNode
}

const ProjectFontFamily = ({ project, children }: Props) => {
  const fontLinkId = 'font-link'
  const [isFontLoaded, setIsFontLoaded] = useState(false)

  const handleChangeClientState = (
    newState: any,
    addedTags: HelmetTags,
    removedTags: HelmetTags
  ) => {
    if (!project) {
      return
    }

    const fontLink = addedTags?.linkTags?.find(
      (linkTag) => linkTag.id === fontLinkId
    )

    /**
     * If it wasn't found we can't listen for it's load, set loaded as true
     * to at least fade the content in. Otherwise the page'll remain blank.
     */
    if (!fontLink) {
      setIsFontLoaded(true)
      return
    }

    fontLink.onload = async () => {
      await document.fonts.load(`16px ${project.fontFamily?.name}`)

      setIsFontLoaded(true)
    }
  }

  if (!project.fontFamily) {
    return <>{children}</>
  }

  return (
    <HelmetProvider>
      <Helmet onChangeClientState={handleChangeClientState}>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          id={fontLinkId}
          rel="stylesheet"
          href={project.fontFamily.href}
        ></link>
      </Helmet>

      <ThemeProviderWithGlobalFontFamily
        theme={(theme: Theme) =>
          createTheme({
            ...theme,
            typography: {
              fontFamily: [project.fontFamily?.name, ...fontFamilyBase].join(
                ','
              ),
            },
          })
        }
      >
        <Fade in={isFontLoaded}>
          <div>{children}</div>
        </Fade>
      </ThemeProviderWithGlobalFontFamily>
    </HelmetProvider>
  )
}

export default ProjectFontFamily
