import { Box } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

type Props = {
  children: React.ReactNode
  sx?: SxProps<Theme>
}

const EllipsisSpan = ({ children, sx }: Props) => {
  return (
    <Box
      component="span"
      sx={{
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default EllipsisSpan
