const useMail = () => {
  const endpoint = `${import.meta.env.VITE_MAIL_API_ENDPOINT}?key=${
    import.meta.env.VITE_MAIL_API_KEY
  }`

  /**
   * Sends a new email.
   *
   * @param subject Subject of the mail.
   * @param template Which template should the email use.
   * @param data Data to send, based on the template.
   * @param reply To which address should replies be addressed to.
   *
   * @returns `true` if sent correctly; `false` otherwise.
   */
  const send = async (
    to: string[],
    subject: string,
    template: string,
    data: {
      [key: string]: any
    },
    reply?: string
  ) => {
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to,
          subject,
          template,
          data,
          reply,
        }),
      })

      return response.ok
    } catch (error) {
      return false
    }
  }

  return { send }
}

export default useMail
