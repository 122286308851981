import Dwelling from 'interfaces/dwelling'
import Project from 'interfaces/project'
import { getDwellingsMediaByNomenclature } from 'utils/dwelling'
import { mapImageSrcsToImages } from 'utils/images'

const useDwellingMediaRealViews = (dwelling: Dwelling, project?: Project) => {
  if (!project) {
    return []
  }

  return getDwellingsMediaByNomenclature(
    [dwelling],
    mapImageSrcsToImages(project.resources?.realViews?.data),
    project.resources?.realViews?.nomenclatureSegment
  )
}

export default useDwellingMediaRealViews
