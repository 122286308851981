import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import ConfirmDialog from './ConfirmDialog'

type Props = {
  icon: React.ReactNode
  title: string
  description: React.ReactNode
  action: string
  handleConfirm: () => void
}

const ConfirmDialogWithIcon = ({
  icon,
  title,
  description,
  action,
  handleConfirm,
}: Props) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <IconButton aria-label={action} onClick={handleOpen}>
        {icon}
      </IconButton>

      <ConfirmDialog
        open={open}
        title={title}
        description={description}
        action={action}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </>
  )
}

export default ConfirmDialogWithIcon
