import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useOdooApiQuery from 'hooks/odoo/api/useOdooApiQuery'
import Lead from 'interfaces/odoo/lead'

const useOdooPartnerLeads = () => {
  const { data: me } = useOdooAccountMe()
  const { data: leads, ...rest } = useOdooApiQuery<Lead>(
    {
      model: 'crm.lead',
      query: [
        'id',
        'name',
        'contact_name',
        'last_name',
        'partner_id{id,name}',
        'email_from',
        'description',
        'type',
        'phone',
        'mobile',
        'country_id{id,name}',
        'development_of_interest{id,name}',
        'create_date',
        'priority',
        'delivery_date{id,name}',
        'preferred_contact_method',
        'construction_type{id,name}',
        'room_numbers',
        'price_min',
        'price_max',
        'square_meters_needed',
        'features{id,name}',
        'locations_of_interest{id,name}',
        'document_ids{id,name,attachment_ids,display_name}',
        'product_id{id,name}',
      ],
      filter: [['partner_id', '=', me?.partner_id?.id]],
    },
    {
      enabled: !!me?.partner_id?.id,
    }
  )

  return {
    data: leads,
    ...rest,
  }
}

export default useOdooPartnerLeads
