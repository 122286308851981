import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queries'
import Project from 'interfaces/project'
import { useLocation } from 'react-router-dom'
import { accountIsAdmin } from 'utils/account/role'
import { isShareRoute } from 'utils/share'
import useOdooAccountMe from './odoo/account/useOdooAccountMe'

/**
 * Queries the projects available.
 *
 * @param unfiltered Normally projects get filtered using the
 * currently logged in account, but if this flag is `true` then
 * filtering gets disabled and all projects will be retrieved.
 */
const useProjects = (unfiltered = false) => {
  const api = `${import.meta.env.VITE_PROJECTS_API_ENDPOINT}?key=${
    import.meta.env.VITE_PROJECTS_API_KEY
  }`
  const { pathname } = useLocation()
  const { data: me, isLoading: isMeLoading } = useOdooAccountMe()
  const isAdmin = accountIsAdmin(me)
  const shareRoute = isShareRoute(pathname)

  let { data, ...rest } = useQuery<Project[]>(
    queryKeys.projects,
    () => fetch(api).then((res) => res.json()),
    // This staleTime avoids re-renders.
    { staleTime: Infinity, enabled: !isMeLoading || unfiltered }
  )

  // Sort by the "order" field.
  data?.sort((a, b) => parseInt(a.order ?? '0') - parseInt(b.order ?? '0'))

  // Maybe filter projects using the account.
  data = data?.filter((project) => {
    /**
     * Filtering shouldn't be done if:
     * - Current user is admin
     * - The unfiltered flag is `true`
     */
    if (isAdmin || unfiltered) {
      return true
    }

    const projectsPermissions = me?.metadata?.projects || []

    return !!projectsPermissions.find(
      (projectPermissions) => projectPermissions.slug === project.slug.current
    )
  })

  // Maybe filter projects' resales.
  if (!isAdmin && !shareRoute) {
    data = data?.map((project) => {
      if (project.dwellings?.promotion?.dwellings) {
        project.dwellings.promotion.dwellings =
          project.dwellings.promotion.dwellings.filter(
            (dwelling) => !dwelling.resale
          )
      }

      return project
    })
  }

  return { data: data ?? [], ...rest }
}

export default useProjects
