import { ToggleButton, toggleButtonClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const ToggleChip = styled(ToggleButton)(({ theme }) => ({
  textTransform: 'initial',
  minHeight: 32,
  lineHeight: 1.25,
  borderRadius: 16,
  padding: `0 ${theme.spacing(2)}`,
  // NOTE: These styles make it look like a PillTab.
  [`&.${toggleButtonClasses.primary}`]: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.light}54`,
    border: `1px solid ${theme.palette.primary.light}cc`,
    [`&.${toggleButtonClasses.selected}`]: {
      color: theme.palette.primary.dark,
      backgroundColor: `${theme.palette.primary.light}cc`,
    },
  },
  [`&.${toggleButtonClasses.secondary}`]: {
    color: theme.palette.secondary.light,
    backgroundColor: `${theme.palette.secondary.light}0d`,
    border: `1px solid ${theme.palette.secondary.light}26`,
    [`&.${toggleButtonClasses.selected}`]: {
      color: theme.palette.secondary.main,
      backgroundColor: `${theme.palette.secondary.light}26`,
    },
  },
  [`&.${toggleButtonClasses.disabled}`]: {
    opacity: theme.palette.action.disabledOpacity,
  },
}))

export default ToggleChip
