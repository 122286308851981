import { Stack, Typography } from '@mui/material'
import SpacedDivider from 'components/atoms/SpacedDivider'
import LoadingImageGallery from 'components/organisms/LoadingImageGallery'
import useDwellingsMediaPhotos from 'hooks/dwelling/media/useDwellingsMediaPhotos'
import Project from 'interfaces/project'
import { getExtendedDwellings } from 'utils/dwelling'
import { getProjectPhotos } from 'utils/project'

type Props = {
  project: Project
}

const ShareSectionPhotos = ({ project }: Props) => {
  const dwellings = getExtendedDwellings(project)
  const dwellingsPhotos = useDwellingsMediaPhotos(dwellings, project)

  const photos = dwellingsPhotos.length
    ? dwellingsPhotos
    : getProjectPhotos(project)

  if (!photos.length) {
    return null
  }

  return (
    <>
      <SpacedDivider />

      <Stack
        spacing={3}
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{ mb: 3 }}
      >
        <Typography variant="h6">Photos</Typography>

        <Typography variant="caption" sx={{ textAlign: 'right' }}>
          Intended for illustrative purposes only
        </Typography>
      </Stack>

      <LoadingImageGallery id="photos-gallery" images={photos} />
    </>
  )
}

export default ShareSectionPhotos
