import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined'
import { Container, Grid } from '@mui/material'
import Notice from 'components/molecules/Notice'
import ProjectCard from 'components/molecules/ProjectCard'
import Header from 'components/organisms/Header'
import useProjects from 'hooks/useProjects'

const Selector = () => {
  let { data: projects, isLoading } = useProjects()

  if (isLoading) {
    return null
  }

  projects = projects.filter((project) => !project.hidden)

  return (
    <>
      <Header />

      <Container
        maxWidth="xl"
        sx={{
          py: {
            xs: 4,
            lg: 6,
          },
        }}
      >
        <Grid
          container
          spacing={{
            xs: 3,
            lg: 6,
          }}
          rowSpacing={{
            xs: 3,
            sm: 1.5,
          }}
        >
          {!projects.length && (
            <Grid item xs={12}>
              <Notice
                message="No projects currently available"
                icon={MapsHomeWorkOutlinedIcon}
              />
            </Grid>
          )}

          {projects.map((project, index) => (
            <Grid item xs={12} md={6} lg={4} key={project.slug.current}>
              <ProjectCard project={project} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}

export default Selector
