import { Avatar, AvatarGroup, AvatarGroupProps } from '@mui/material'
import { SxProps, Theme, useTheme } from '@mui/material/styles'
import useProjects from 'hooks/useProjects'
import Account from 'interfaces/account'
import { stringAvatar } from 'utils/account/avatar'
import ClickAwayTooltip from '../ClickAwayTooltip'

type Props = {
  account: Account
  spacing?: AvatarGroupProps['spacing']
  max?: AvatarGroupProps['max']
  sx?: SxProps<Theme>
}

const AccountProjects = ({ account, spacing, max, sx }: Props) => {
  const theme = useTheme()

  // Only use those projects the account has access to.
  const { data: allProjects } = useProjects()
  const accountProjectSlugs = account.metadata?.projects
    ? account.metadata.projects.map((project) => project.slug)
    : []
  const projects = allProjects.filter((project) =>
    accountProjectSlugs?.includes(project.slug.current)
  )

  const sizeSx: SxProps<Theme> = {
    width: {
      xs: theme.spacing(3),
      sm: theme.spacing(4),
      lg: theme.spacing(5), // Default
    },
    height: {
      xs: theme.spacing(3),
      sm: theme.spacing(4),
      lg: theme.spacing(5), // Default
    },
    fontSize: {
      xs: '0.6875rem',
      sm: '0.875rem',
      lg: '1.25rem', // Default
    },
  }

  if (!projects?.length) {
    return null
  }

  return (
    <AvatarGroup
      spacing={spacing}
      max={max}
      sx={sx}
      componentsProps={{
        additionalAvatar: {
          sx: sizeSx,
        },
      }}
    >
      {projects.map((project) => {
        const { children, sx: bgColorSx } = stringAvatar(
          project.slug.current.replaceAll('-', ' ')
        )
        const sx: SxProps<Theme> = {
          ...sizeSx,
          ...bgColorSx,
        }

        return (
          <ClickAwayTooltip
            key={project.slug.current}
            title={project.name}
            PopperProps={{ disablePortal: false }}
          >
            <Avatar sx={sx}>{children}</Avatar>
          </ClickAwayTooltip>
        )
      })}
    </AvatarGroup>
  )
}

export default AccountProjects
