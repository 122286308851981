import { Box } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import logo from 'images/logo.svg'

export const width = 128
export const height = {
  sm: 52,
  xs: 40,
}

type Props = {
  sx?: SxProps<Theme>
}

const Logo = ({ sx }: Props) => {
  return (
    <Box
      component="img"
      alt="logo"
      src={logo}
      sx={{
        maxWidth: width,
        maxHeight: height,
        ...sx,
      }}
    />
  )
}

export default Logo
