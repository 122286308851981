import { Fade } from '@mui/material'
import ImageListItem from '@mui/material/ImageListItem'
import Watermarked from 'components/atoms/Watermarked'
import useImgLoaded from 'hooks/useImgLoaded'
import Image from 'interfaces/image'
import { useState } from 'react'
import ImageGalleryItemDownloadableHover from './ImageGalleryItemDownloadableHover'
import ImageGalleryItemHover from './ImageGalleryItemHover'

type Props = {
  galleryId: string
  image: Image
  index: number
  downloadable?: boolean
}

const ImageGalleryItem = ({
  galleryId,
  image,
  index,
  downloadable = false,
}: Props) => {
  const [hover, setHover] = useState(false)
  const isImgLoaded = useImgLoaded(image.thumbUrl || image.url)

  const Img = (
    <img
      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      src={image.thumbUrl || image.url}
      id={`${galleryId}-${index}-img`}
      alt={`${image.title || ''} ${image.subtitle || ''}`.trim()}
      data-title={image.title}
      data-subtitle={image.subtitle}
      loading="lazy"
    />
  )

  return (
    <ImageListItem>
      <a
        style={{ height: '100%', position: 'relative' }}
        href={image.url}
        data-pswp-width={image.width}
        data-pswp-height={image.height}
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Fade in={isImgLoaded}>
          {downloadable ? Img : <Watermarked>{Img}</Watermarked>}
        </Fade>

        {downloadable ? (
          <ImageGalleryItemDownloadableHover image={image} in={hover} />
        ) : (
          <ImageGalleryItemHover image={image} in={hover} />
        )}
      </a>
    </ImageListItem>
  )
}

export default ImageGalleryItem
