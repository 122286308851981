/**
 * Only routes with metadata.
 */

import Project from 'interfaces/project'
import RouteMetadata from 'interfaces/route-metadata'
import AccountEnableRoute from 'routes/account/enable'
import AccountPasswordResetRoute from 'routes/account/password-reset'
import AccountPasswordResetRequestRoute from 'routes/account/password-reset-request'
import AccountPropertiesRoute from 'routes/account/properties'
import AccountPropertiesSingleRoute from 'routes/account/properties-single'
import LegalRoute from 'routes/legal'
import LoginRoute from 'routes/login'
import DownloadsRoute from 'routes/project/downloads'
import FloorPlansRoute from 'routes/project/floor-plans'
import GalleryRoute from 'routes/project/gallery'
import LibraryRoute from 'routes/project/library'
import MasterPlanRoute from 'routes/project/master-plan'
import OverviewRoute from 'routes/project/overview'
import ServicesRoute from 'routes/project/services'
import ThreeDToolRoute from 'routes/project/three-d-tool'
import TypologiesRoute from 'routes/project/typologies'
import UnitsListRoute from 'routes/project/units-list'
import SelectorRoute from 'routes/selector'
import ShareRoute from 'routes/share'
import { getDownloadableImages } from 'utils/images'
import { getProjectPhotos, getProjectRenders } from 'utils/project'

export const loginRouteMetadata: RouteMetadata = {
  name: 'Login',
  slug: 'login',
  component: <LoginRoute />,
}

export const legalRouteMetadata: RouteMetadata = {
  name: 'Legal',
  slug: 'legal',
  component: <LegalRoute />,
}

export const accountEnableRouteMetadata: RouteMetadata = {
  name: 'Enable account',
  slug: 'account/enable/:token',
  component: <AccountEnableRoute />,
}

export const accountPasswordResetRequestRouteMetadata: RouteMetadata = {
  name: 'Request your password reset',
  slug: 'account/password-reset',
  component: <AccountPasswordResetRequestRoute />,
}

export const accountPasswordResetRouteMetadata: RouteMetadata = {
  name: 'Password reset',
  slug: 'account/password-reset/:token',
  component: <AccountPasswordResetRoute />,
}

export const accountPropertiesRouteMetadata: RouteMetadata = {
  name: 'Properties',
  slug: 'account/properties',
  component: <AccountPropertiesRoute />,
}

export const accountPropertiesSingleRouteMetadata: RouteMetadata = {
  name: 'Property',
  slug: 'account/properties/:id',
  component: <AccountPropertiesSingleRoute />,
}

export const shareRouteMetadata: RouteMetadata = {
  name: 'Share',
  slug: 'share/:token',
  component: <ShareRoute />,
}

export const selectorRouteMetadata: RouteMetadata = {
  name: 'Selector',
  slug: 'selector',
  component: <SelectorRoute />,
}

export const overviewRouteMetadata: RouteMetadata = {
  name: 'Overview',
  slug: 'overview',
  component: <OverviewRoute />,
  content: (project: Project) => project.overview,
}

export const typologiesRouteMetadata: RouteMetadata = {
  name: 'Typologies',
  slug: 'typologies',
  component: <TypologiesRoute />,
  content: (project: Project) => project.dwellings?.typologies,
}

export const galleryRouteMetadata: RouteMetadata = {
  name: 'Gallery',
  slug: 'gallery',
  component: <GalleryRoute />,
  content: (project: Project) =>
    !!getProjectPhotos(project).length ||
    !!getProjectRenders(project).length ||
    project.resources?.virtualTours ||
    project.resources?.videos,
}

export const unitsListRouteMetadata: RouteMetadata = {
  name: 'Units list',
  slug: 'units-list',
  component: <UnitsListRoute />,
  content: (project: Project) => project.dwellings?.promotion?.dwellings,
}

export const floorPlansRouteMetadata: RouteMetadata = {
  name: 'Floor plans',
  slug: 'floor-plans',
  component: <FloorPlansRoute />,
  content: (project: Project) => project.resources?.interactiveFloorPlans,
}

export const masterPlanRouteMetadata: RouteMetadata = {
  name: 'Master plan',
  slug: 'master-plan',
  component: <MasterPlanRoute />,
  content: (project: Project) => project.resources?.interactiveMasterPlan,
}

export const threeDToolRouteMetadata: RouteMetadata = {
  name: '3D tool',
  slug: '3d-tool',
  component: <ThreeDToolRoute />,
  content: (project: Project) => project.resources?.threeDTool,
  roles: ['partner', 'admin'],
}

export const libraryRouteMetadata: RouteMetadata = {
  name: 'Library',
  slug: 'library',
  component: <LibraryRoute />,
  content: (project: Project) =>
    project.resources?.brochure ||
    project.resources?.qualitySpecs ||
    project.resources?.optionsAndUpgrades,
}

export const servicesRouteMetadata: RouteMetadata = {
  name: 'Services',
  slug: 'services',
  component: <ServicesRoute />,
  content: (project: Project) => project.services,
}

export const downloadsRouteMetadata: RouteMetadata = {
  name: 'Downloads',
  slug: 'downloads',
  component: <DownloadsRoute />,
  content: (project: Project) => {
    const renders = getDownloadableImages(getProjectRenders(project))
    const photos = getDownloadableImages(getProjectPhotos(project))
    const links = project.links

    if (!renders.length && !photos.length && !links) {
      return false
    }

    return {
      renders,
      photos,
      links,
    }
  },
}

const routesMetadata = {
  login: loginRouteMetadata,
  legal: legalRouteMetadata,
  share: shareRouteMetadata,
  selector: selectorRouteMetadata,
  account: [
    accountEnableRouteMetadata,
    accountPasswordResetRequestRouteMetadata,
    accountPasswordResetRouteMetadata,
    accountPropertiesRouteMetadata,
    accountPropertiesSingleRouteMetadata,
  ],
  project: [
    overviewRouteMetadata,
    typologiesRouteMetadata,
    galleryRouteMetadata,
    unitsListRouteMetadata,
    floorPlansRouteMetadata,
    masterPlanRouteMetadata,
    threeDToolRouteMetadata,
    libraryRouteMetadata,
    servicesRouteMetadata,
    downloadsRouteMetadata,
  ],
}

export default routesMetadata
