import { Container, Stack, Typography } from '@mui/material'
import CenteredSpinner from 'components/molecules/CenteredSpinner'
import DwellingDetailTitle from 'components/molecules/dwellings/detail/DwellingDetailTitle'
import FatalErrorMessage from 'components/organisms/FatalErrorMessage'
import AccountAttachments from 'components/organisms/account/AccountAttachments'
import AccountHeader from 'components/organisms/account/AccountHeader'
import DwellingCard from 'components/organisms/dwellings/DwellingCard'
import { errorMessages } from 'constants/errors'
import useOdooPartnerDwellings from 'hooks/odoo/partner/useOdooPartnerDwellings'
import DwellingExtended from 'interfaces/dwelling/extended'
import { useNavigate } from 'react-router-dom'
import { accountPropertiesSingleRouteMetadata } from 'routes-metadata'
import { normalizeToString } from 'utils/normalization'

const AccountProperties = () => {
  const { dwellings, isLoading } = useOdooPartnerDwellings()
  const navigate = useNavigate()

  if (isLoading) {
    return <CenteredSpinner in />
  }

  if (!dwellings.length) {
    return (
      <FatalErrorMessage
        hideLogoutButton
        text={errorMessages.odooAccountNoProperties}
      />
    )
  }

  const handleClick = (dwelling: DwellingExtended) => {
    navigate(
      `/${accountPropertiesSingleRouteMetadata.slug.replace(
        ':id',
        normalizeToString(dwelling.id)
      )}`
    )
  }

  return (
    <>
      <AccountHeader />

      <Container
        sx={{
          py: {
            xs: 4,
            lg: 6,
          },
        }}
      >
        <Stack spacing={3} sx={{ mb: 8 }}>
          <Typography variant="h5" component="h2">
            Properties
          </Typography>

          {dwellings.map((dwelling) => (
            <DwellingCard
              key={dwelling.id}
              dwelling={dwelling}
              title={
                <DwellingDetailTitle
                  dwelling={dwelling}
                  projectName={dwelling.project}
                  variant="simple"
                />
              }
              onClick={handleClick}
            />
          ))}
        </Stack>

        <Stack spacing={3} sx={{ mb: 8 }}>
          <Typography variant="h5" component="h2">
            Documents
          </Typography>

          <AccountAttachments />
        </Stack>
      </Container>
    </>
  )
}

export default AccountProperties
