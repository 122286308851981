import { accountEnableRouteMetadata } from 'routes-metadata'
import AccountRole from 'types/account/role'
import useToken from './useToken'

/**
 * Allows for the generation of account invitation links through its `generate` method.
 *
 * This is intended for use with portal user accounts only,
 * it doesn't make any sense in Odoo's admins context.
 */
const useAccountInvitationLink = () => {
  const { mutateAsync: token } = useToken()

  /**
   * Generates a portal user account invitation link.
   *
   * @param id
   * @param email
   * @param role
   */
  const generate = async (id: number, email: string, role: AccountRole) => {
    /**
     * Generate a token with the necessary info to enable
     * the account and build the invitation link.
     */
    const tokenResponse = await token({
      payload: {
        id,
        email,
        role,
      },
    })
    const invitationLink = `${
      window.location.origin
    }/${accountEnableRouteMetadata.slug.replace(':token', tokenResponse.token)}`

    return invitationLink
  }

  return {
    generate,
  }
}

export default useAccountInvitationLink
