import { LoadingButton } from '@mui/lab'
import { Alert, Box, Collapse, Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import Account from 'components/organisms/account/Account'
import ProjectStripWithPermission from 'components/organisms/project/ProjectStripWithPermission'
import { errorMessages } from 'constants/errors'
import { queryKeys } from 'constants/queries'
import useOdooAccountUpdate from 'hooks/odoo/account/useOdooAccountUpdate'
import useProjectPermissions from 'hooks/useProjectPermissions'
import useProjects from 'hooks/useProjects'
import AccountProps from 'interfaces/account'
import isEqual from 'lodash/isEqual'
import { useState } from 'react'

type Props = {
  account: AccountProps
}

const AccountUpdateProjectsPermissions = ({ account }: Props) => {
  const queryClient = useQueryClient()
  const { data: projects } = useProjects()
  const { mutateAsync: update } = useOdooAccountUpdate()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const accountPermissions = account.metadata?.projects
    ? account.metadata.projects.map((permission) => permission.slug)
    : []
  const { permissions, changePermissions } =
    useProjectPermissions(accountPermissions)

  const handleUpdate = async () => {
    setLoading(true)

    try {
      const response = await update({
        id: account.id,
        email: account.email,
        metadata: {
          projects: permissions.map((slug) => ({ slug })),
        },
      })

      if (!response?.updated) {
        throw new Error(errorMessages.odooAccountUpdate)
      }

      queryClient.invalidateQueries(queryKeys.accounts)

      setSuccess(`Projects shared updated successfully`)
    } catch (error: any) {
      setError(error.message)
    }

    setLoading(false)
  }

  return (
    <>
      <Grid
        container
        spacing={{
          xs: 2,
          sm: 3,
        }}
        sx={{ mb: 3 }}
        alignItems="center"
      >
        <Grid item xs={12} sm={9}>
          <Account account={account} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <LoadingButton
            fullWidth
            disableElevation
            variant="contained"
            disabled={
              isEqual(accountPermissions, permissions) || !permissions.length
            }
            onClick={handleUpdate}
            loading={loading}
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>

      <Collapse in={error !== ''} timeout="auto">
        <Alert sx={{ marginBottom: 3 }} severity="error">
          {error}
        </Alert>
      </Collapse>

      <Collapse in={error === '' && success !== ''} timeout="auto">
        <Alert sx={{ marginBottom: 3 }} severity="success">
          {success}
        </Alert>
      </Collapse>

      {projects.map((project, index) => (
        <Box
          key={index}
          sx={{
            mb: 3,
            '&:last-child': {
              mb: 0,
            },
          }}
        >
          <ProjectStripWithPermission
            project={project}
            projectStripProps={{ collapseDisabled: true }}
            permissions={permissions}
            changePermissions={changePermissions}
          />
        </Box>
      ))}
    </>
  )
}

export default AccountUpdateProjectsPermissions
