import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Collapse, Grid, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AccountProjects from 'components/molecules/account/AccountProjects'
import Account from 'components/organisms/account/Account'
import AccountActions from 'components/organisms/account/AccountActions'
import AccountProps from 'interfaces/account'
import { useState } from 'react'

type Props = {
  account: AccountProps
  showProjects?: boolean
}

const AccountsListItem = ({ account, showProjects = false }: Props) => {
  const [xsCollapseOpen, setXsCollapseOpen] = useState(false)

  const theme = useTheme()
  const mdOrGreater = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })
  const lgOrGreater = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })

  return (
    <>
      <Grid
        container
        spacing={{
          xs: 1,
          md: 3,
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={xsCollapseOpen ? true : 7} md={5}>
          <Account account={account} />
        </Grid>

        {showProjects && (!xsCollapseOpen || mdOrGreater) && (
          <Grid item xs>
            <AccountProjects
              account={account}
              spacing={(() => {
                if (lgOrGreater) {
                  return -4
                }
                return 8
              })()}
              max={(() => {
                if (lgOrGreater) {
                  return 5
                }
                if (mdOrGreater) {
                  return 4
                }
                return 3
              })()}
              sx={{ justifyContent: 'center' }}
            />
          </Grid>
        )}

        <Grid item xs={1} md={3}>
          <AccountActions
            account={account}
            sx={{
              display: {
                xs: 'none',
                // 'flex' because it's actually a Stack.
                md: 'flex',
              },
              justifyContent: 'flex-end',
            }}
          />

          <IconButton
            aria-label="Toggle actions"
            onClick={() => {
              setXsCollapseOpen(!xsCollapseOpen)
            }}
            sx={{
              display: {
                xs: 'flex',
                md: 'none',
              },
              justifyContent: 'flex-end',
            }}
          >
            <ExpandMoreIcon
              color="action"
              fontSize="small"
              sx={{
                transform: !xsCollapseOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: (theme) =>
                  theme.transitions.create('transform', {
                    duration: theme.transitions.duration.short,
                  }),
              }}
            />
          </IconButton>
        </Grid>
      </Grid>

      <Collapse in={xsCollapseOpen}>
        <Box
          sx={{
            mt: 1.5,
            p: 1.5,
            bgcolor: 'grey.50',
            borderRadius: (theme) => theme.shape.borderRadius,

            display: {
              xs: 'flex',
              md: 'none',
            },
          }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {showProjects && (
              <Grid item xs>
                <AccountProjects
                  account={account}
                  sx={{
                    /**
                     * NOTE: This is 'flex-end' and not 'flex-start' because
                     * AvatarGroup uses flex-direction: 'row-reverse'
                     */
                    justifyContent: 'flex-end',
                    flexWrap: 'wrap',
                  }}
                />
              </Grid>
            )}

            <Grid item xs="auto">
              <AccountActions
                account={account}
                fontSize="small"
                sx={{ justifyContent: 'flex-end' }}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </>
  )
}

export default AccountsListItem
