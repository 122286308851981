import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { Menu } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import AccountProfileDialog from 'components/organisms/account/AccountProfileDialog'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import { MouseEvent, useState } from 'react'
import LogoutMenuItem from '../LogoutMenuItem'
import ChangelogDialog from 'components/organisms/changelog/ChangelogDialog'

const AccountMenu = () => {
  const theme = useTheme()
  const { data: me } = useOdooAccountMe()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!me) {
    return null
  }

  return (
    <>
      <IconButton
        id="account-menu-button"
        color="inherit"
        aria-haspopup="true"
        aria-controls={open ? 'account-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
      >
        <PersonOutlineOutlinedIcon />
      </IconButton>

      <Menu
        keepMounted
        disableScrollLock
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'account-menu-button',
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        marginThreshold={parseInt(theme.spacing(3))}
      >
        <ChangelogDialog />
        <AccountProfileDialog onClick={handleClose} />
        <LogoutMenuItem onClick={handleClose} />
      </Menu>
    </>
  )
}

export default AccountMenu
