import { Box, Stack } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

type Props = {
  children: React.ReactNode
  sx?: SxProps<Theme>
}

/**
 * A MUI's Stack with a wrapper and an overflow-x with a gradient.
 */
const StackOverflow = ({ children, sx }: Props) => {
  return (
    <Box
      sx={{
        maxWidth: '100%',
        // Scroll gradient wrapper.
        position: 'relative',
        '&:after': {
          content: '""',
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          width: (theme) => theme.spacing(2),
          background: 'linear-gradient(to left, white, transparent)',
        },
        ...sx,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={{ xs: 1, md: 1.5 }}
        sx={{
          overflowX: 'auto',
          /**
           * Hide the scrollbar and create some
           * padding for the parent gradient.
           */
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          pr: 2,
        }}
      >
        {children}
      </Stack>
    </Box>
  )
}

export default StackOverflow
