import FatalErrorMessage from 'components/organisms/FatalErrorMessage'

const NotFoundRoute = () => {
  return (
    <FatalErrorMessage
      hideLogoutButton
      text={
        <>
          Not found
        </>
      }
    />
  )
}

export default NotFoundRoute
