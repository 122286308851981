import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import ChangelogContent from 'components/molecules/changelog/ChangelogContent'
import ChangelogFeatures from 'components/molecules/changelog/ChangelogFeatures'
import ChangelogDialogMode from 'types/changelog/changelog-dialog-mode'

export const changelogDialogModes: {
  slug: ChangelogDialogMode
  name: string
  icon:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  component: React.ReactNode
  divider?: boolean
}[] = [
  {
    slug: 'feature',
    name: 'Features',
    icon: <TipsAndUpdatesOutlinedIcon fontSize="small" />,
    component: <ChangelogFeatures />,
  },
  {
    slug: 'content',
    name: 'Content',
    icon: <SourceOutlinedIcon fontSize="small" />,
    component: <ChangelogContent />,
  },
]
