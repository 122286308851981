export const errorMessages = {
  unknown: `Something didn't go as expected, please, try again later`,
  mail: 'Something went wrong trying to send a transactional email. The underlying process has probably completed successfully; if not, please, try again later',
  token:
    'Something went wrong trying to generate a token, please, try again later',

  odooAccountMe:
    'Something went wrong trying to retrieve your profile. Please, try again later',
  odooAccountCreate:
    'Something went wrong trying to create the account. Please, try again later',
  odooAccountUpdate:
    'Something went wrong trying to update the account. Please, try again later',
  odooAccountDelete:
    'Something went wrong trying to delete the account. Please, try again later',
  odooAccountExists:
    'Something went wrong trying to verify your account. Please, try again later',
  odooAccountNoProperties: `Your account doesn't currently have any recognised properties associated to it. If you think it should, please get in contact with us`,
  odooAccountPropertyNotFound: `Something went wrong trying to retrieve your property. Please, try again later`,
  odooAccountsGet:
    'Something went wrong trying to list accounts. Please, try again later',
  odooFavoriteCreate:
    'Something went wrong trying to add to favorites. Please, try again later',
  odooFavoriteDelete:
    'Something went wrong trying to delete from favorites. Please, try again later',
  odooAuth:
    'Something went wrong during the authentication process. Please, try again later',
  odooAdmin: `Something went wrong during the admin retrieval process. Maybe it's still loading. Please, try again`,
  odooApi:
    'Something went wrong trying to connect with our CRM. Please, try again later',
}
