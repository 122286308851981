import Switch from '@mui/material/Switch'
import DwellingFilter from 'interfaces/dwelling/filter'

type Props = {
  filter: DwellingFilter
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => void
}

const DwellingsFilterSwitch = ({ filter, onChange }: Props) => {
  return (
    <Switch
      size="small"
      onChange={(e) => onChange(e, e.target.checked)}
      value={filter.value}
    />
  )
}

export default DwellingsFilterSwitch
