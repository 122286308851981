import Dwelling from 'interfaces/dwelling'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingFilter from 'interfaces/dwelling/filter'
import Project from 'interfaces/project'
import DwellingSortOrder from 'types/dwelling/sort-order'
import { normalizeToNumber } from 'utils/normalization'
import { getAvailableDwellings } from '.'

const getMinMaxValuesObject = (
  bedroomsMinMax: Set<number>,
  pricesMinMax: Set<number>,
  totalUsableMinMax: Set<number>,
  totalConstructedMinMax: Set<number>
) => {
  return {
    bedroomsMinMax: {
      min: Math.min(...Array.from(bedroomsMinMax)),
      max: Math.max(...Array.from(bedroomsMinMax)),
    },
    pricesMinMax: {
      min: Math.min(...Array.from(pricesMinMax)),
      max: Math.max(...Array.from(pricesMinMax)),
    },
    totalUsableMinMax: {
      min: Math.min(...Array.from(totalUsableMinMax)),
      max: Math.max(...Array.from(totalUsableMinMax)),
    },
    totalConstructedMinMax: {
      min: Math.min(...Array.from(totalConstructedMinMax)),
      max: Math.max(...Array.from(totalConstructedMinMax)),
    },
  }
}

// TODO: `getProjectsMinMaxValues` and `getDwellingsMinMaxValues`
// share too much code. Try unifying/encapsulating them.

export const getProjectsMinMaxValues = (projects: Project[]) => {
  let bedroomsMinMax = new Set<number>()
  let pricesMinMax = new Set<number>()
  let totalUsableMinMax = new Set<number>()
  let totalConstructedMinMax = new Set<number>()

  for (const project of projects) {
    const dwellings = getAvailableDwellings(project)

    if (!dwellings?.length) {
      continue
    }

    for (const dwelling of dwellings) {
      const bedrooms = dwelling.beds
      if (!isNaN(bedrooms)) {
        bedroomsMinMax.add(bedrooms)
      }

      const price = dwelling.price
      if (!isNaN(price)) {
        pricesMinMax.add(price)
      }

      const totalUsable = dwelling.totalUsable
      if (!isNaN(totalUsable)) {
        totalUsableMinMax.add(totalUsable)
      }

      const totalConstructed = dwelling.totalConstructed
      if (!isNaN(totalConstructed)) {
        totalConstructedMinMax.add(totalConstructed)
      }
    }
  }

  return getMinMaxValuesObject(
    bedroomsMinMax,
    pricesMinMax,
    totalUsableMinMax,
    totalConstructedMinMax
  )
}

export const getDwellingsMinMaxValues = (dwellings: Dwelling[]) => {
  let bedroomsMinMax = new Set<number>()
  let pricesMinMax = new Set<number>()
  let totalUsableMinMax = new Set<number>()
  let totalConstructedMinMax = new Set<number>()

  for (const dwelling of dwellings) {
    const bedrooms = dwelling.beds
    if (!isNaN(bedrooms)) {
      bedroomsMinMax.add(bedrooms)
    }

    const price = dwelling.price
    if (!isNaN(price)) {
      pricesMinMax.add(price)
    }

    const totalUsable = dwelling.totalUsable
    if (!isNaN(totalUsable)) {
      totalUsableMinMax.add(totalUsable)
    }

    const totalConstructed = dwelling.totalConstructed
    if (!isNaN(totalConstructed)) {
      totalConstructedMinMax.add(totalConstructed)
    }
  }

  return getMinMaxValuesObject(
    bedroomsMinMax,
    pricesMinMax,
    totalUsableMinMax,
    totalConstructedMinMax
  )
}

export const dwellingSatisfiesFilters = (
  dwelling: DwellingExtended,
  filters: DwellingFilter[]
) => {
  for (const filter of filters) {
    switch (filter.type) {
      case 'slider':
        const minValue = filter.value[0]
        const maxValue = filter.value[1]

        const value = normalizeToNumber(dwelling[filter.key])

        if (value < minValue || value > maxValue) {
          return false
        }
        break
    }
  }

  return true
}

/**
 * Runs through filters and returns `true` if any are currently active.
 *
 * NOTE: A filter being active means it's value differs from its min/max.
 */
export const dwellingFiltersActive = (filters: DwellingFilter[]) => {
  if (!filters.length) {
    return false
  }

  for (const filter of filters) {
    switch (filter.type) {
      case 'slider':
        if (filter.value[0] !== filter.min || filter.value[1] !== filter.max) {
          return true
        }
        break

      case 'switch':
      case 'select':
        if (filter.value) return true
        break

      case 'chip':
        if (filter.value.length) return true
        break
    }
  }

  return false
}

/**
 * Runs through filters and dwellings and returns an array of filtered dwellings
 * All filters are taken into account.
 *
 * TODO: Would benefit of a rewrite, using flags (`doesntMatch`) isn't the best.
 */
export const filterDwellings = (
  dwellings: DwellingExtended[],
  filters: DwellingFilter[]
) => {
  const filteredDwellings = dwellings.filter((dwelling) => {
    return Object.keys(dwelling).some(() => {
      let doesntMatch = true

      filters.forEach((filter) => {
        switch (filter.type) {
          case 'slider':
            if (
              Number(dwelling[filter.key]) < Number(filter.value[0]) ||
              Number(dwelling[filter.key]) > Number(filter.value[1])
            ) {
              doesntMatch = false
              return false
            }
            break

          case 'switch':
            if (filter.value && filter.value !== dwelling[filter.key]) {
              doesntMatch = false
              return false
            }
            break

          case 'select':
            if (filter.value && filter.value !== dwelling[filter.key]) {
              doesntMatch = false
              return false
            }
            break

          case 'chip':
            if (
              !filter.value.includes(dwelling[filter.key]) &&
              filter.value.length > 0
            ) {
              doesntMatch = false
              return false
            }
            break
        }
      })
      return doesntMatch
    })
  })

  return filteredDwellings
}

/**
 * TODO: Would STRONGLY benefit of a return early type
 * refactor instead of that much nesting.
 */
export const sortDwellings = (
  dwellings: Dwelling[],
  sortOrder: DwellingSortOrder,
  sortKey: keyof Dwelling | null
): Dwelling[] => {
  function compare(a: Dwelling, b: Dwelling): number {
    if (sortKey !== null) {
      if (sortKey === 'price') {
        if (
          a.statusName === 'Reserved' ||
          a.statusName === 'Sold' ||
          a.statusName === 'Locked'
        )
          return sortOrder === 'desc' ? 1 : -1
        if (
          b.statusName === 'Reserved' ||
          b.statusName === 'Sold' ||
          b.statusName === 'Locked'
        )
          return sortOrder === 'desc' ? -1 : 1
      }

      const aValue = a[sortKey]
      const bValue = b[sortKey]

      if (
        (typeof aValue === 'number' || typeof aValue === 'string') &&
        (typeof bValue === 'number' || typeof bValue === 'string')
      ) {
        if (aValue < bValue) {
          return sortOrder === 'desc' ? 1 : -1
        }
        if (aValue > bValue) {
          return sortOrder === 'desc' ? -1 : 1
        }
      }
    }

    return 0
  }

  /**
   * TODO: This lines below (and most likely above) are
   * probably unnecessary, and would be nice to fix.
   */

  const dwellingsCopy = [...dwellings]

  const sortedDwellings = [dwellingsCopy.sort(compare)]

  return sortedDwellings[0]
}
