import { Box, Divider, DividerProps } from '@mui/material'

type Props = DividerProps & {
  spacing?: number
}

const SpacedDivider = (props: Props) => {
  const { spacing, ...rest } = props

  return (
    <Box
      sx={{
        my: spacing ?? 6,
      }}
    >
      <Divider {...rest} />
    </Box>
  )
}

export default SpacedDivider
