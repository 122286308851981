import AccountUpdateFormConfig from 'interfaces/account/forms/update'

export const accountUpdateFormDefaultConfig: AccountUpdateFormConfig = {
  labels: {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    password: 'Password',
    repeatPassword: 'Repeat password',
    submitButton: 'Update',
  },
  initialValues: {
    firstName: '',
    lastName: '',
    email: '',
  },
  components: {
    submitMessage: null,
  },
  passwordNotRequired: false,
  legalNotRequired: false,
}
