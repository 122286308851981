import { Grid, Stack, tabsClasses, Typography } from '@mui/material'
import PillTab from 'components/atoms/tabs/PillTab'
import PillTabList from 'components/atoms/tabs/PillTabList'
import PlansWithVariantsFloor from 'interfaces/plans-with-variants/floor'

type Props = {
  title: string
  subtitle?: string
  floors: PlansWithVariantsFloor[]
  setActiveFloor: React.Dispatch<React.SetStateAction<PlansWithVariantsFloor>>
}

const PlansWithVariantsHeader = ({
  title,
  subtitle,
  floors,
  setActiveFloor,
}: Props) => {
  return (
    <Grid container item spacing={3}>
      <Grid item xs={12} lg>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          alignItems={{
            xs: 'flex-start',
            sm: 'center',
          }}
          spacing={{
            xs: 1,
            sm: 3,
          }}
        >
          <Typography variant="h5" component="h2">
            {title}
          </Typography>

          {!!subtitle && <Typography variant="body2">{subtitle}</Typography>}
        </Stack>
      </Grid>

      {/* Only show the tab list when there are multiple options. */}
      {floors.length > 1 && (
        <Grid item xs={12} lg={8}>
          <PillTabList
            aria-label="Plans with variants floors pills"
            textColor="secondary"
            onChange={(event, value) => {
              const newFloor = floors.find((floor) => floor.level === value)

              if (!newFloor) {
                console.warn(`New floor couldn't be found`)
                return
              }

              setActiveFloor(newFloor)
            }}
            sx={{
              [`& .${tabsClasses.flexContainer}`]: {
                justifyContent: {
                  lg: 'flex-end',
                },
              },
            }}
          >
            {floors.map((floor) => (
              <PillTab
                key={floor.level}
                label={floor.name}
                value={floor.level}
              />
            ))}
          </PillTabList>
        </Grid>
      )}
    </Grid>
  )
}

export default PlansWithVariantsHeader
