import DwellingStatusCode from 'types/dwelling/status-code'
import { normalizeToNumber } from 'utils/normalization'
import { theme } from './theme'

export const getAvailabilityColor = (statusCode: DwellingStatusCode) => {
  const grey = theme.palette.action.disabled
  const green = theme.palette.success.main
  const yellow = theme.palette.warning.main
  const red = theme.palette.error.main

  switch (normalizeToNumber(statusCode)) {
    case 0:
      return grey

    case 1:
      return green

    case 2:
    case 3:
      return yellow

    case 4:
      return red

    default:
      return ''
  }
}
