import { Box, Typography } from '@mui/material'
import OutlinedShadowPaper from 'components/atoms/OutlinedShadowPaper'
import Overline from 'components/atoms/Overline'
import PlansWithVariantsVariantDescriptionInterface from 'interfaces/plans-with-variants/variant-description'

type Props = {
  description: PlansWithVariantsVariantDescriptionInterface
}

const PlansWithVariantsVariantDescription = ({ description }: Props) => {
  const { title, content } = description

  return (
    <Box sx={{ mb: 3 }}>
      {!!title && <Overline sx={{ mb: 2 }}>{title}</Overline>}

      <OutlinedShadowPaper>
        {content.map((p, index) => (
          <Typography key={index} variant="body2">
            <span
              dangerouslySetInnerHTML={{ __html: p }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            ></span>
          </Typography>
        ))}
      </OutlinedShadowPaper>
    </Box>
  )
}

export default PlansWithVariantsVariantDescription
