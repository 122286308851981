import { IconButton, iconButtonClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const ContainedIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  [`&.${iconButtonClasses.disabled}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  [`&.${iconButtonClasses.colorSecondary}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    [`&.${iconButtonClasses.disabled}`]: {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}))

export default ContainedIconButton
