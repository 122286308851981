import { Button } from '@mui/material'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import TargetMediaType from './TargetMediaType'

const PrintButton = () => {
  const handleClick = () => {
    window.print()
  }

  return (
    <TargetMediaType target="screen">
      <Button
        onClick={handleClick}
        startIcon={<PrintOutlinedIcon />}
        sx={{
          textTransform: 'initial',
          color: 'action.active',
        }}
      >
        Print
      </Button>
    </TargetMediaType>
  )
}

export default PrintButton
