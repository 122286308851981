import { Box, Stack, Typography } from '@mui/material'
import SlimChip, { slimChipTypographyStyles } from 'components/atoms/SlimChip'
import DwellingStatusCode from 'types/dwelling/status-code'

type Props = {
  statusCodes: DwellingStatusCode[]
  active: boolean
  name: string
  color: string
  onClick: (statusCodes: DwellingStatusCode[], active: boolean) => void
}

const AvailabilityChip = ({
  statusCodes,
  active,
  name,
  color,
  onClick,
}: Props) => {
  return (
    <SlimChip
      clickable
      variant={active ? 'filled' : 'outlined'}
      onClick={() => onClick(statusCodes, !active)}
      sx={{ textTransform: 'capitalize' }}
      label={
        <Stack
          spacing={1.5}
          component="span"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            component="span"
            sx={{
              width: (theme) => theme.spacing(1),
              height: (theme) => theme.spacing(1),
              borderRadius: '50%',
              bgcolor: color,
            }}
          />

          <Typography
            component="span"
            variant="caption"
            sx={{
              lineHeight: slimChipTypographyStyles.lineHeight,
            }}
          >
            {name}
          </Typography>
        </Stack>
      }
    />
  )
}

export default AvailabilityChip
