import { AppBar } from '@mui/material'

type Props = {
  children: React.ReactNode
}

const ShareProjectsAppBarSticky = ({ children }: Props) => {
  return (
    <AppBar
      elevation={0}
      position="sticky"
      sx={{
        bgcolor: 'transparent',
        color: 'secondary.main',
        display: 'inline-block',
        width: 'auto',
        py: {
          xs: 1.5,
          sm: 3,
        },
      }}
    >
      {children}
    </AppBar>
  )
}

export default ShareProjectsAppBarSticky
