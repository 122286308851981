import { styled } from '@mui/material/styles'

const InteractiveSvgGroup = styled('g')(({ theme }) => ({
  pointerEvents: 'auto',
  fillOpacity: '0.33',
  transition: theme.transitions.create('fill-opacity', {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    fillOpacity: '.66',
  },
}))

export default InteractiveSvgGroup
