import { LoadingButton } from '@mui/lab'
import { Alert, Box, Collapse, Grid } from '@mui/material'
import DwellingsFilterButton from 'components/atoms/dwellings/DwellingsFilterButton'
import ShareDwellingsFormSticky from 'components/atoms/share/ShareDwellingsFormSticky'
import ShareDwellingsProjects from 'components/molecules/share/ShareDwellingsProjects'
import ShareDwellingsSelectionStatus from 'components/molecules/share/ShareDwellingsSelectionStatus'
import useShareLink from 'hooks/useShareLink'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingFilter from 'interfaces/dwelling/filter'
import { useCallback, useEffect, useState } from 'react'
import { dwellingSatisfiesFilters } from 'utils/dwelling/filters'
import ShareDwellingsFilters from './ShareDwellingsFilters'
import ShareLinkConfigDialog from './ShareLinkConfigDialog'

const ShareDwellingsForm = () => {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [filters, setFilters] = useState<DwellingFilter[]>([])
  const [selection, setSelection] = useState<DwellingExtended[]>([])
  const [configDialogOpen, setConfigDialogOpen] = useState(false)
  const {
    severity: shareLinkSeverity,
    message: shareLinkMessage,
    loading: shareLinkLoading,
    copyLink: copyShareLink,
    restart: restartShareLink,
  } = useShareLink(selection)

  const handleConfigDialogOpen = () => setConfigDialogOpen(true)
  const handleConfigDialogClose = () => setConfigDialogOpen(false)

  const handleFiltersChange = useCallback(
    (filters: DwellingFilter[]) => setFilters(filters),
    []
  )

  /**
   * When filters change, run through selection
   * and remove those not satisfying conditions.
   */
  useEffect(() => {
    if (!selection.length) {
      return
    }

    setSelection((prevSelection) => {
      const nextSelection = [...prevSelection]

      return nextSelection.filter((selectedDwelling) =>
        dwellingSatisfiesFilters(selectedDwelling, filters)
      )
    })
  }, [filters, selection.length])

  return (
    <>
      <ShareDwellingsFormSticky>
        <Grid container spacing={{ xs: 2, md: 3 }} alignItems="center">
          <Grid item xs={2} md>
            <DwellingsFilterButton
              filters={filters}
              setFiltersOpen={setFiltersOpen}
              filtersOpen={filtersOpen}
            />
          </Grid>

          <Grid item xs={4} md={3}>
            <ShareDwellingsSelectionStatus
              selection={selection}
              stackProps={{
                justifyContent: {
                  xs: 'flex-end',
                  sm: 'center',
                },
              }}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <LoadingButton
              disableElevation
              fullWidth
              variant="contained"
              disabled={selection.length === 0}
              loading={shareLinkLoading}
              onClick={handleConfigDialogOpen}
            >
              Copy link
            </LoadingButton>

            <ShareLinkConfigDialog
              open={configDialogOpen}
              onClose={handleConfigDialogClose}
              onDone={copyShareLink}
            />
          </Grid>
        </Grid>

        <Collapse in={filtersOpen}>
          <Box
            sx={{
              mt: 3,
              p: 3,
              bgcolor: 'grey.50',
              borderRadius: (theme) => theme.shape.borderRadius,
            }}
          >
            <ShareDwellingsFilters onChange={handleFiltersChange} />
          </Box>
        </Collapse>

        <Collapse in={!!shareLinkSeverity && !!shareLinkMessage} timeout="auto">
          <Alert
            sx={{ mt: 3 }}
            severity={shareLinkSeverity}
            onClose={restartShareLink}
          >
            {shareLinkMessage}
          </Alert>
        </Collapse>
      </ShareDwellingsFormSticky>

      <ShareDwellingsProjects
        filters={filters}
        onSelect={(selected, dwelling) => {
          setSelection((prevSelection) => {
            const nextSelection = [...prevSelection]

            if (selected) {
              nextSelection.push(dwelling)
            } else {
              // Find dwelling's index in selection and remove it.
              const index = nextSelection.findIndex(
                (selection) => selection.id === dwelling.id
              )
              if (index !== -1) {
                nextSelection.splice(index, 1)
              }
            }

            return nextSelection
          })
        }}
      />
    </>
  )
}

export default ShareDwellingsForm
