/**
 * Primitive function to format a string into a (probably) valid Google Analytics event name.
 *
 * Lower cases everything, and replaces " " and "-" with "_".
 */
export const stringToEventName = (string: string) => {
  return string.toLowerCase().replace(/ /g, '_').replace(/-/g, '_')
}

/**
 * Sends a Google Analytics event.
 * 
 * @param action The event name.
 * @param value Event value.
 */
export const sendEvent = (action: string, value?: string) => {
  if (!window.gtag) {
    console.warn(`Trying to send an event but couldn't find gtag in window`)
    return
  }

  const valueOrAction = value ?? action

  window.gtag('event', action, { valueOrAction })
}
