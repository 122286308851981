import { Box, Paper, Stack, Typography } from '@mui/material'
import Image from 'components/atoms/Image'
import Project from 'interfaces/project'

type Props = {
  project: Project
  afterTitle?: React.ReactNode
}

const ProjectThumbnail = ({ project, afterTitle }: Props) => {
  return (
    <>
      <Image
        src={project.images?.thumbnail}
        alt={`${project.slug.current}-thumbnail`}
        style={{
          width: '100%',
          height: '100%',
          minHeight: 200,
          objectFit: 'cover',
          display: 'block',
        }}
      />

      <Box sx={{ position: 'absolute', bottom: 0, p: 1, width: '100%' }}>
        <Paper elevation={3} sx={{ py: 0.5, px: 1, pl: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="subtitle1"
              component="h4"
              sx={{
                /**
                 * This py ends up giving the surrounding Paper
                 * a min height of 46 px and that's good.
                 */
                py: 0.625,
              }}
            >
              {project.name}
            </Typography>

            {afterTitle}
          </Stack>
        </Paper>
      </Box>
    </>
  )
}

export default ProjectThumbnail
