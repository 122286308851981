import routesMetadata from 'routes-metadata'

/**
 * Try getting the route metadata by slug.
 *
 * @returns The `RouteMetadata` matching the passed string slug.
 */
export const getProjectRouteMetadataBySlug = (slug: string) => {
  const allRoutesMetadata = Object.values(routesMetadata).flat()

  return allRoutesMetadata.find((routeMetadata) => routeMetadata.slug === slug)
}
