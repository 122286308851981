import { useEffect, useState } from 'react'

/**
 * Caches a bunch of images and returns if it's done.
 */
const useCacheImages = (imagesSrc: string[]) => {
  const [cached, setCached] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (!imagesSrc.length) {
        setCached(true)
        return
      }

      const promises = await imagesSrc.map(
        (src) =>
          new Promise<void>((resolve, reject) => {
            const img = new Image()

            img.src = src
            img.onload = () => {
              resolve()
            }
            img.onerror = () => {
              reject()
            }
          })
      )

      await Promise.all(promises)

      setCached(true)
    })()
  })

  return cached
}

export default useCacheImages
