import { styled } from '@mui/material/styles'

const SimpleLi = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0.5),
  gap: theme.spacing(6),
}))

export default SimpleLi
