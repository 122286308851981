import { LoadingButton } from '@mui/lab'
import { Alert, Box, Collapse, Link } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'
import DialogHeader from 'components/molecules/DialogHeader'
import { mailSubjects, mailTemplates } from 'constants/account/mail'
import useMail from 'hooks/useMail'
import useSettings from 'hooks/useSettings'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'

const AccessRequestDialog = () => {
  const { hash } = useLocation()
  const [open, setOpen] = useState(hash === '#request-access')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState('')
  const [done, setDone] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const { send } = useMail()
  const { data: settings } = useSettings()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickSend = async () => {
    setLoading(true)

    if (!settings?.accessRequestsRecipients?.length) {
      setError('Something went wrong. Please, reload the page and try again')
      setLoading(false)
      return
    }

    const mailSent = await send(
      settings.accessRequestsRecipients,
      mailSubjects.accountAccessRequest,
      mailTemplates.accountAccessRequest,
      {
        name,
        email,
        year: new Date().getFullYear(),
      }
    )
    if (!mailSent) {
      setError(
        'Something went wrong trying to send your request. Try again later'
      )
      setLoading(false)
      return
    }

    setSuccess(
      `We've received your request. We'll get in touch with you as soon as possible`
    )
    setDone(true)
    setLoading(false)
  }

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && isEmailValid && !done) {
      handleClickSend()
    }
  }

  if (!settings?.accessRequestsRecipients?.length) {
    console.warn(
      `Access request dialog prevented rendering because no access requests recipients were retrieved from settings`
    )
    return null
  }

  return (
    <Box>
      <Link underline="hover" variant="body2" onClick={handleClickOpen}>
        Don't have access yet?
      </Link>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogHeader
            titleId="request-access-title"
            title="Request access"
            onCloseClick={handleClose}
          />

          <DialogContentText sx={{ marginBottom: 3 }}>
            To request access to our private area, please provide us with your
            contact details. We will get in touch with you as soon as possible.
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="name"
            fullWidth
            variant="filled"
            sx={{ marginBottom: 2 }}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="name"
            label="Email"
            type="email"
            fullWidth
            variant="filled"
            sx={{ marginBottom: 3 }}
            onChange={(e) => {
              setError('')

              const value = e.target.value
              setIsEmailValid(isEmail(value))
              setEmail(value)
            }}
            onKeyUp={handleKeyUp}
          />

          <Collapse in={error !== ''} timeout="auto">
            <Alert sx={{ marginBottom: 3 }} severity="error">
              {error}
            </Alert>
          </Collapse>

          <Collapse in={error === '' && success !== ''} timeout="auto">
            <Alert sx={{ marginBottom: 3 }} severity="success">
              {success}
            </Alert>
          </Collapse>

          <DialogActions sx={{ p: 0 }}>
            <LoadingButton
              disableElevation
              variant="contained"
              disabled={!isEmailValid || done}
              onClick={handleClickSend}
              loading={loading}
            >
              Send request
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default AccessRequestDialog
