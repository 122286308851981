import { useMutation, useQueryClient } from '@tanstack/react-query'
import { errorMessages } from 'constants/errors'
import { localStorageKeys } from 'constants/local-storage'
import { queryKeys } from 'constants/queries'
import { ssoStorageKeys } from 'constants/sso-storage'
import ssoStorage from 'utils/sso/storage'
import useOdooAuth from './useOdooAuth'

type TVariables = {
  email: string
  password: string
}
type TData = boolean
type TError = Error

const useOdooLogin = () => {
  const queryClient = useQueryClient()
  const { mutateAsync: auth } = useOdooAuth()

  const mutation = async (variables: TVariables): Promise<TData> => {
    const authentication = await auth(variables)

    if (!authentication?.success) {
      throw new Error(errorMessages.odooAuth)
    }

    // Set the SSO token and version of the front-end when login ocurred.
    await ssoStorage.setItem(ssoStorageKeys.token, authentication.data.token)

    localStorage.setItem(
      localStorageKeys.version,
      import.meta.env.VITE_VERSION ?? ''
    )

    queryClient.invalidateQueries(queryKeys.ssoToken)
    queryClient.invalidateQueries(queryKeys.me)

    return authentication.success
  }

  return useMutation<TData, TError, TVariables>(mutation)
}

export default useOdooLogin
