import { CardActionArea, Stack } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import SpacedDivider from 'components/atoms/SpacedDivider'
import useProjectRoutesGetters from 'hooks/useProjectRoutesGetters'
import Project from 'interfaces/project'
import { getAvailableDwellings, getUnknownDwellings } from 'utils/dwelling'
import format from 'utils/format'

type Props = {
  project: Project
}

const ProjectCard = ({ project }: Props) => {
  const excerpt = project.overview?.excerpt ?? []

  const prices = [
    ...getUnknownDwellings(project),
    ...getAvailableDwellings(project),
  ].map((dwelling) => dwelling.price)
  const minPrice = prices.length ? Math.min(...prices) : undefined

  const { getIndex } = useProjectRoutesGetters()
  const indexRoute = getIndex(project)
  const link =
    project.slug.current && indexRoute
      ? `/${project.slug.current}/${indexRoute.slug}`
      : ''

  return (
    <Card elevation={0}>
      <CardActionArea
        disableRipple
        href={link}
        disabled={!link}
        sx={{
          p: {
            xs: 1.5,
            sm: 3,
          },
        }}
      >
        <CardMedia
          component="img"
          height="256"
          image={project.images?.thumbnail}
          sx={{
            // Inherits 4px from parent.
            borderRadius: 'inherit',
          }}
        />

        <CardContent sx={{ p: 0, pt: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
          >
            <Typography variant="h6" component="h2">
              {project.name}
            </Typography>

            {!!minPrice && (
              <Typography variant="subtitle1">
                From{' '}
                {format('currency', minPrice, { maximumFractionDigits: 0 })}
              </Typography>
            )}
          </Stack>

          {!!excerpt.length && (
            <>
              <SpacedDivider light spacing={1} />

              {excerpt.map((line, index) => (
                <Typography key={index} component="h3" variant="body2">
                  {line}
                </Typography>
              ))}
            </>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default ProjectCard
