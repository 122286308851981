import { Box } from '@mui/material'

type Props = {
  children: React.ReactNode
}

const ShareDwellingsFormSticky = ({ children }: Props) => {
  return (
    <Box
      sx={{
        pb: 3,
        /**
         * This mb roughly aligns the project strips
         * below when switching between share modes.
         */
        mb: 0.5,
        position: 'sticky',
        zIndex: 2,
        top: {
          xs: -16,
          md: -48,
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          zIndex: -1,
          /**
           * Negative values to overflow a bit and make sure the
           * surrounding of the sticky Box are white.
           */
          left: {
            xs: -12,
            sm: -16,
            md: -24,
          },
          right: {
            xs: -12,
            sm: -16,
            md: -24,
          },
          top: -32,
          bottom: 0,
          bgcolor: 'common.white',
          overflowX: 'hidden',
        },
      }}
    >
      {children}
    </Box>
  )
}

export default ShareDwellingsFormSticky
