import Project from 'interfaces/project'
import routesMetadata, { overviewRouteMetadata } from 'routes-metadata'
import { getAccountRole } from 'utils/account/role'
import useOdooAccountMe from './odoo/account/useOdooAccountMe'

const useProjectRoutesGetters = () => {
  const preferredIndexRoute = overviewRouteMetadata
  const { data: me } = useOdooAccountMe()

  /**
   * Gets available project routes.
   *
   * It takes into consideration if the route has content and also
   * if the current account has permission to access it.
   */
  const get = (project: Project) =>
    routesMetadata.project.filter((routeMetadata) => {
      if (!routeMetadata.content) {
        return false
      }

      const hasContent = !!routeMetadata.content(project)

      const hasRolePermission = (() => {
        if (!routeMetadata.roles) {
          return true
        }

        if (!me) {
          return false
        }

        const accountRole = getAccountRole(me)
        if (!accountRole) {
          return false
        }
        return routeMetadata.roles.includes(accountRole)
      })()

      return hasContent && hasRolePermission
    })

  /**
   * Gets the preferred index route among available project routes.
   *
   * @returns `RouteMetadata` or `undefined` in cases where routes can't be retrieved
   * (probably a project has not been provided).
   */
  const getIndex = (project: Project) => {
    const projectRoutes = get(project)

    if (!projectRoutes?.length) {
      return
    }

    return (
      projectRoutes.find(
        (projectRoute) => projectRoute.slug === preferredIndexRoute.slug
      ) ?? projectRoutes[0]
    )
  }

  return { get, getIndex }
}

export default useProjectRoutesGetters
