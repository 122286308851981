import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queries'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import ChangelogEntry from 'interfaces/changelog/changelog-entry'
import AccountRole from 'types/account/role'
import { accountIsAdmin, getAccountRole } from 'utils/account/role'

const useChangelog = () => {
  const api = `${import.meta.env.VITE_CHANGELOG_API_ENDPOINT}?key=${
    import.meta.env.VITE_CHANGELOG_API_KEY
  }`
  const { data: me, isLoading: isMeLoading } = useOdooAccountMe()
  const projectsPermissions = me?.metadata?.projects || []
  const role = getAccountRole(me)

  const hasAccessToProjects = (changelogEntry: ChangelogEntry) => {
    // Allow by default if no projects were specified in the account.
    if (!projectsPermissions.length) {
      return true
    }
    if (changelogEntry.projects) {
      return changelogEntry.projects.some((project) => {
        return projectsPermissions.find(
          (projectPermissions) =>
            projectPermissions.slug === project.slug.current
        )
      })
    }

    return true
  }

  const hasAccessToChangelogEntry = (
    changelogEntry: ChangelogEntry,
    role?: AccountRole | false
  ) => {
    return (
      (role && changelogEntry.roles.includes(role)) ||
      (me && accountIsAdmin(me))
    )
  }

  const isChangelogEntryAvailable = (changelogEntry: ChangelogEntry) => {
    if (!changelogEntry.date) {
      return false
    }

    const currentTime = new Date().getTime()
    const changelogEntryDate = new Date(changelogEntry.date).getTime()
    return changelogEntryDate <= currentTime
  }
  let { data: changelogEntries, ...rest } = useQuery<ChangelogEntry[]>(
    queryKeys.changelog,
    () => fetch(api).then((res) => res.json()),
    // This staleTime avoids re-renders.
    { staleTime: Infinity, enabled: !isMeLoading }
  )

  const filteredChangelogEntries =
    changelogEntries?.filter((changelogEntry) => {
      return (
        isChangelogEntryAvailable(changelogEntry) &&
        hasAccessToChangelogEntry(changelogEntry, role) &&
        hasAccessToProjects(changelogEntry)
      )
    }) || []

  return { data: filteredChangelogEntries ?? [], ...rest }
}

export default useChangelog
