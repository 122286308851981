import { FormControl } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import DwellingFilter from 'interfaces/dwelling/filter'

type Props = {
  filter: DwellingFilter
  onChange: (event: SelectChangeEvent<string>, value: string) => void
}

const DwellingsFilterSelect = ({ filter, onChange }: Props) => {
  if (!filter || !filter.options) return null

  return (
    <FormControl fullWidth>
      <Select
        size="small"
        labelId={filter.key + 'select-label'}
        id={filter.key + 'select'}
        onChange={(e) => onChange(e, e.target.value)}
        label={filter.name}
        defaultValue=""
        displayEmpty
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
      >
        <MenuItem value="">All</MenuItem>

        {filter.options.map((option, index) => {
          return (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default DwellingsFilterSelect
