import ImageList from '@mui/material/ImageList'
import ImageGalleryItem from 'components/molecules/image-gallery/ImageGalleryItem'
import usePhotoSwipeLightbox from 'hooks/usePhotoSwipeLightbox'
import Image from 'interfaces/image'
import { useEffect } from 'react'

export type ImageGalleryProps = {
  id: string
  images: Image[]
  downloadable?: boolean
}

const ImageGallery = ({
  id,
  images,
  downloadable = false,
}: ImageGalleryProps) => {
  const { createLightbox } = usePhotoSwipeLightbox()

  /**
   * Create the lightbox and initialize it.
   *
   * NOTE: Only in non-downloadable galleries.
   */
  useEffect(() => {
    if (downloadable) {
      return
    }

    const lightbox = createLightbox({
      gallery: '#' + id,
      children: 'a',
    })

    lightbox.init()

    return () => {
      lightbox.destroy()
    }
  }, [createLightbox, downloadable, id])

  if (!images.length) {
    return null
  }

  return (
    <div className="pswp-gallery" id={id}>
      <ImageList
        sx={{
          margin: 0,
          width: '100%',
          /* WTF Safari does something weird with double scrolling without this below */
          overflowY: 'visible',
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr) !important',
            sm: 'repeat(2, 1fr) !important',
            lg: 'repeat(3, 1fr) !important',
          },
        }}
        cols={3}
        gap={16}
      >
        {images.map((image, index) => (
          <ImageGalleryItem
            key={id + '-' + index}
            galleryId={id}
            image={image}
            index={index}
            downloadable={downloadable}
          />
        ))}
      </ImageList>
    </div>
  )
}

export default ImageGallery
