import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton, Stack, Typography } from '@mui/material'
import SpacedDivider from 'components/atoms/SpacedDivider'
import ShareProjectsAppBarSticky from 'components/atoms/share/ShareProjectsAppBarSticky'
import shareLayouts from 'constants/share/layouts'
import Project from 'interfaces/project'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import ShareLayout from 'types/share/layout'
import ShareProjectsSelector from './ShareProjectsSelector'
import ShareSectionDwellings from './section/ShareSectionDwellings'
import ShareSectionMasterPlan from './section/ShareSectionMasterPlan'
import ShareSectionOverview from './section/ShareSectionOverview'
import ShareSectionPhotos from './section/ShareSectionPhotos'
import ShareSectionRenders from './section/ShareSectionRenders'

type Props = {
  projects: Project[]
}

const ShareProjects = ({ projects }: Props) => {
  const [activeProject, setActiveProject] = useState<Project | null>(
    projects.length === 1 ? projects[0] : null
  )

  const { hash } = useLocation()
  const layout = ((): ShareLayout => {
    const defaultLayout = 'project'

    if (!hash) {
      return defaultLayout
    }

    // Make sure the value in hash is actually a valid layout.
    const maybeLayout = hash.replace('#', '') as ShareLayout
    if (!shareLayouts.includes(maybeLayout)) {
      return defaultLayout
    }

    return maybeLayout
  })()

  const handleActiveProjectChange = (project: Project) => {
    setActiveProject(project)
  }

  if (!activeProject) {
    return (
      <ShareProjectsSelector
        projects={projects}
        onSelect={handleActiveProjectChange}
      />
    )
  }

  return (
    <>
      <ShareProjectsAppBarSticky>
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          sx={{
            // Softens the overlay of the header's border.
            boxShadow: (theme) => ({
              xs: `0px 0px 4px 8px ${theme.palette.common.white}`,
              sm: `0px 0px 4px 16px ${theme.palette.common.white}`,
            }),
            bgcolor: 'common.white',
            mb: 3,
          }}
        >
          <IconButton
            aria-label="Go back"
            edge="start"
            size="small"
            onClick={() => setActiveProject(null)}
          >
            <ArrowBackIcon />
          </IconButton>

          <Typography variant="h6">{activeProject.name}</Typography>
        </Stack>
      </ShareProjectsAppBarSticky>

      {layout === 'project' && (
        <>
          <ShareSectionOverview project={activeProject} />
          <ShareSectionMasterPlan project={activeProject} />

          <ShareSectionRenders project={activeProject} />
          <ShareSectionPhotos project={activeProject} />

          <SpacedDivider />
          <ShareSectionDwellings project={activeProject} />
        </>
      )}

      {layout === 'dwellings' && (
        <>
          <ShareSectionDwellings project={activeProject} />

          <ShareSectionRenders project={activeProject} />
          <ShareSectionPhotos project={activeProject} />

          <SpacedDivider />

          <Typography variant="h6" sx={{ mb: 3 }}>
            Project
          </Typography>
          <ShareSectionOverview project={activeProject} />
          <ShareSectionMasterPlan project={activeProject} />
        </>
      )}
    </>
  )
}

export default ShareProjects
