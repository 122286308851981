import { Container } from '@mui/material'
import Content from 'components/atoms/Content'
import DwellingsFiltered from 'components/organisms/dwellings/DwellingsFiltered'
import ProjectHeader from 'components/organisms/project/ProjectHeader'
import useDwellingsTableFilters from 'hooks/dwelling/useDwellingsTableFilters'
import useProject from 'hooks/useProject'
import { getExtendedDwellings } from 'utils/dwelling'

const ProjectUnitsList = () => {
  const project = useProject()
  const filters = useDwellingsTableFilters(project)

  if (!project || !filters || !project.dwellings?.promotion?.dwellings) {
    return null
  }

  const fields = project.dwellings?.fields?.table ?? []
  const dwellings = getExtendedDwellings(project)

  return (
    <>
      <ProjectHeader project={project} />

      <Container>
        <Content>
          <DwellingsFiltered
            initialFilters={filters}
            fields={fields}
            dwellings={dwellings}
          />
        </Content>
      </Container>
    </>
  )
}

export default ProjectUnitsList
