import { Box, Typography } from '@mui/material'
import SpacedDivider from 'components/atoms/SpacedDivider'
import TargetMediaType from 'components/atoms/TargetMediaType'

const PrintDateOfIssue = () => {
  return (
    <TargetMediaType target="print">
      <Box>
        <SpacedDivider light spacing={3} />

        <Typography variant="caption">
          This document is valid for 24 hours from the date of issue.
          <br />
          Date of issue: <strong>{new Date().toLocaleString('es-ES')}</strong>
        </Typography>
      </Box>
    </TargetMediaType>
  )
}

export default PrintDateOfIssue
