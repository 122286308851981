import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Button, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Project from 'interfaces/project'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'left',
  borderColor: theme.palette.grey[600],
  borderRight: `1px solid ${theme.palette.grey[600]}`,
}))

type Props = {
  project: Project
}

const LinksTable = ({ project }: Props) => {
  const projectLinks = project.links ?? []

  return (
    <TableContainer component={Box}>
      <Table
        sx={{ borderWidth: 1, borderStyle: 'solid', borderColor: 'grey.600' }}
        aria-label="Links table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: '25%' }}>Documents</StyledTableCell>
            <StyledTableCell>External Links</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectLinks.map((link, index) => (
            <TableRow key={index}>
              <StyledTableCell
                sx={{ width: '25%' }}
                align="left"
                component="th"
                scope="row"
              >
                {link.type}
              </StyledTableCell>
              <StyledTableCell sx={{ color: 'common.black' }}>
                <Stack
                  spacing={2}
                  direction={{ xs: 'column', lg: 'row' }}
                  justifyContent="space-between"
                  alignItems={{ xs: 'flex-start', lg: 'center' }}
                >
                  <a
                    style={{ color: 'inherit' }}
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.name}
                  </a>

                  {!!link.url && (
                    <Button
                      disableElevation
                      LinkComponent="a"
                      href={link.url}
                      target="_blank"
                      rel="noreferrer"
                      variant="contained"
                      endIcon={<OpenInNewIcon />}
                    >
                      Open link
                    </Button>
                  )}
                </Stack>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default LinksTable
