import { Box, Stack, Typography } from '@mui/material'
import PlansWithVariantsStateContext from 'contexts/PlansWithVariantsStateContext'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import PlansWithVariantsVariant from 'interfaces/plans-with-variants/variant'
import { useContext, useRef } from 'react'
import { getAccountRole } from 'utils/account/role'
import { getVariantSelectedValue } from 'utils/plans-with-variants'
import PlansWithVariantsVariantActions from './PlansWithVariantsVariantActions'
import PlansWithVariantsVariantDescription from './PlansWithVariantsVariantDescription'

type Props = {
  variants: PlansWithVariantsVariant[]
}

const PlansWithVariantsVariants = ({ variants }: Props) => {
  const { data: me } = useOdooAccountMe()
  const { value: variantsState } = useContext(PlansWithVariantsStateContext)

  const beforeVariantsEl = useRef<HTMLDivElement | null>(null)
  const afterVariantsEl = useRef<HTMLDivElement | null>(null)

  if (!me) {
    return null
  }

  return (
    <>
      <div ref={beforeVariantsEl} />

      {variants.map((variant) => {
        const selectedValue = getVariantSelectedValue(variant, variantsState)
        // Merge the (possible) selected value description and the (possible) variant description.
        const descriptions = [
          ...(selectedValue.description ?? []),
          ...(variant.description ?? []),
        ]
        const disabled = (() => {
          /**
           * Assume no role filtering is needed
           * when variant roles are not specified.
           */
          if (!variant.roles?.length) {
            return false
          }

          const accountRole = getAccountRole(me)

          // Disable for hypothetical role-less account.
          if (!accountRole) {
            return true
          }

          return !variant.roles.includes(accountRole)
        })()

        return (
          <Box key={variant.slug.current} sx={{ mb: 3 }}>
            <Stack
              spacing={3}
              justifyContent="space-between"
              direction={{
                xs: 'row',
                md: 'column',
                lg: 'row',
              }}
              alignItems={{
                xs: 'center',
                md: 'flex-start',
                lg: 'center',
              }}
              sx={{ mb: 3 }}
            >
              <Typography variant="h5" component="h2">
                {variant.name}
              </Typography>

              <Stack spacing={1} alignItems="center">
                <PlansWithVariantsVariantActions
                  variant={variant}
                  disabled={disabled}
                />

                {disabled && (
                  <Typography variant="caption">
                    Get in contact with us for further details
                  </Typography>
                )}
              </Stack>
            </Stack>

            {descriptions.map((description, index) => {
              /**
               * Descriptions render through portals or the
               * natural flow depending on the location value.
               */
              const component = (
                <PlansWithVariantsVariantDescription
                  key={index}
                  description={description}
                />
              )
              return component

              // TODO: This still needs work.
              // const { location } = description
              // switch (location) {
              //   case 'before':
              //     if (!beforeVariantsEl.current) {
              //       return null
              //     }
              //     return (
              //       <div key={index}>
              //         {createPortal(component, beforeVariantsEl.current)}
              //       </div>
              //     )

              //   case 'normal':
              //     return component

              //   case 'after':
              //     if (!afterVariantsEl.current) {
              //       return null
              //     }
              //     return (
              //       <div key={index}>
              //         {createPortal(component, afterVariantsEl.current)}
              //       </div>
              //     )

              //   default:
              //     return null
              // }
            })}
          </Box>
        )
      })}

      <div ref={afterVariantsEl} />
    </>
  )
}

export default PlansWithVariantsVariants
