import Account from 'interfaces/account'
import AccountRole from 'types/account/role'

/**
 * Given the account role, it attempts formatting it.
 *
 * @returns Formatted role name.
 */
export const getAccountRoleFormatted = (role?: AccountRole | false) => {
  if (!role) {
    return 'Unknown'
  }
  return (role[0].toUpperCase() + role.slice(1)).replace(/-/g, ' ')
}

/**
 * Given an account, gets the roles said account can use when creating (sharing) new ones.
 */
export const getAccountShareableRoles = (account?: Account): AccountRole[] => {
  const role = getAccountRole(account)

  switch (role) {
    case false:
    case undefined:
    case 'client':
    case 'investor':
      return []

    case 'agent':
      return ['client']

    case 'partner':
      return ['client', 'investor', 'agent']

    case 'admin':
      return ['client', 'investor', 'agent', 'partner']
  }
}

/**
 * These are the roles this account's "me" can share, minus the current account's role.
 *
 * NOTE: Mainly useful for the role update chip list.
 */
export const getAccountAvailableRoles = (account?: Account, me?: Account) => {
  return me
    ? getAccountShareableRoles(me).filter(
        (role) => role !== account?.metadata?.role
      )
    : []
}

export const getAccountRole = (account?: Account) => {
  if (accountIsAdmin(account)) {
    return 'admin'
  }
  return account?.metadata?.role
}

/**
 * Given an account, determines if it can be considered as admin.
 */
export const accountIsAdmin = (account?: Account) => {
  if (!account) {
    return false
  }

  // Odoo admins (paid accounts) don't have any metadata.
  return !account.metadata || account.metadata?.role === 'admin'
}

/**
 * Given an account, determines if it can share or not.
 */
export const accountCanShare = (account?: Account) => {
  const role = getAccountRole(account)

  if (role === 'agent' || role === 'partner' || accountIsAdmin(account)) {
    return true
  }

  return false
}

/**
 * Given an account, determines if it has watermarks.
 */
export const accountHasWatermarks = (account?: Account) => {
  /**
   * Disables watermarks when no account
   * is present, as in the Share page.
   */
  if (!account) {
    return false
  }

  if (getAccountRole(account) === 'partner' || accountIsAdmin(account)) {
    return false
  }

  return true
}
