import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import Overline from 'components/atoms/Overline'
import SpacedDivider from 'components/atoms/SpacedDivider'
import DialogHeader from 'components/molecules/DialogHeader'
import ShareDwellingsLayoutSelector from 'components/molecules/share/ShareDwellingsLayoutSelector'
import shareLifespans from 'constants/share/lifespan'
import { useState } from 'react'
import ShareLayout from 'types/share/layout'
import ShareLifespan from 'types/share/lifespan'
import { normalizeToNumber, normalizeToString } from 'utils/normalization'

type Props = {
  open: boolean
  onClose: () => void
  onDone: (layout: ShareLayout, lifespan: ShareLifespan) => void
}

const ShareLinkConfigDialog = ({ open, onClose, onDone }: Props) => {
  const [layout, setLayout] = useState<ShareLayout>('project')
  const [lifespan, setLifespan] = useState<ShareLifespan>(
    shareLifespans.find((lifespan) => lifespan.default) ?? shareLifespans[0]
  )

  const handleLayoutChange = (layout: ShareLayout) => {
    setLayout(layout)
  }
  const handleLifespanChange = (event: SelectChangeEvent) => {
    const lifespan = shareLifespans.find(
      (lifespan) => lifespan.value === normalizeToNumber(event.target.value)
    )

    if (!lifespan) {
      console.error(
        `Tried setting a lifespan with a value not found in the constant`
      )
      return
    }

    setLifespan(lifespan)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      aria-labelledby="share-dwellings-config-dialog-title"
      fullWidth
    >
      <DialogContent>
        <DialogHeader
          titleId="share-dwellings-config-dialog-title"
          title="Link configuration"
          onCloseClick={onClose}
        />

        <Overline sx={{ mb: 1 }}>Layout</Overline>
        <DialogContentText sx={{ marginBottom: 3 }}>
          Choose which layout you wish to display in the generated link,
          depending on who's receiving it. You can prioritize either the project
          details, or the units.
        </DialogContentText>
        <ShareDwellingsLayoutSelector
          activeLayout={layout}
          onChange={handleLayoutChange}
        />

        <SpacedDivider light spacing={4} />

        <Overline sx={{ mb: 1 }}>Duration</Overline>
        <DialogContentText sx={{ marginBottom: 2 }}>
          Choose for how long the link will be valid - its lifespan.
        </DialogContentText>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
        >
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <Select
              value={normalizeToString(lifespan['value'])}
              onChange={handleLifespanChange}
            >
              {shareLifespans.map((lifespan) => (
                <MenuItem key={lifespan.id} value={lifespan.value}>
                  {lifespan.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            disableElevation
            variant="contained"
            onClick={() => {
              onClose()
              onDone(layout, lifespan)
            }}
          >
            Copy link
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default ShareLinkConfigDialog
