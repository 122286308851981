import { Stack, Typography } from '@mui/material'
import TargetMediaType from 'components/atoms/TargetMediaType'
import useProjectRouteMetadata from 'hooks/useProjectRouteMetadata'
import Project from 'interfaces/project'

type Props = {
  project: Project
}

const PrintHeader = ({ project }: Props) => {
  const routeMetadata = useProjectRouteMetadata()
  const routeName = routeMetadata?.name

  return (
    <TargetMediaType target="print">
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        sx={{ color: 'grey.600', mb: 2 }}
      >
        <Typography variant="subtitle1">{project.name}</Typography>

        {!!routeName && <Typography variant="caption">{routeName}</Typography>}
      </Stack>
    </TargetMediaType>
  )
}

export default PrintHeader
