import MenuIcon from '@mui/icons-material/Menu'
import { Box, Drawer, IconButton, Stack } from '@mui/material'
import AccountMenu from 'components/molecules/account/AccountMenu'
import Project from 'interfaces/project'
import { useState } from 'react'
import ShareDialog from '../share/ShareDialog'
import NavList from './NavList'
import DwellingsFavoritesDialog from '../dwellings/DwellingsFavoritesDialog'

type Props = {
  project: Project
}

const NavDrawer = ({ project }: Props) => {
  const [open, setOpen] = useState(false)

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setOpen(open)
  }

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        edge="end"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>

      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          role="presentation"
        >
          <NavList project={project} variant="column" />

          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              py: 3,
              color: 'grey.600',
            }}
          >
            <ShareDialog />
            <DwellingsFavoritesDialog />
            <AccountMenu />
          </Stack>
        </Box>
      </Drawer>
    </>
  )
}

export default NavDrawer
