import { Container } from '@mui/material'
import SectionDividerProps from 'interfaces/section/divider'
import SpacedDivider from '../SpacedDivider'

const SectionDivider = ({ spacing }: SectionDividerProps) => {
  return (
    <Container>
      <SpacedDivider spacing={spacing} />
    </Container>
  )
}

export default SectionDivider
