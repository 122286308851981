import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import {
  FormControl,
  FormControlProps,
  IconButton,
  Input,
  InputAdornment,
  InputProps,
} from '@mui/material'

type Props = {
  value: string
  onChange: (value: string) => void
  formControlProps?: FormControlProps
  inputProps?: InputProps
}

const SearchInput = ({
  value,
  onChange,
  formControlProps,
  inputProps,
}: Props) => {
  return (
    <FormControl variant="standard" {...formControlProps}>
      <Input
        id="search"
        placeholder="Search"
        type="text"
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.value)
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="search adornment"
              onClick={() => onChange('')}
              disabled={value === ''}
              size="small"
            >
              {value === '' ? (
                <SearchOutlinedIcon fontSize="inherit" />
              ) : (
                <ClearOutlinedIcon fontSize="inherit" />
              )}
            </IconButton>
          </InputAdornment>
        }
        {...inputProps}
      />
    </FormControl>
  )
}

export default SearchInput
