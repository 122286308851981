import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton, Stack, Typography } from '@mui/material'
import DwellingChip from 'components/atoms/dwellings/DwellingChip'
import useDwellingEnabled from 'hooks/dwelling/useDwellingEnabled'
import Dwelling from 'interfaces/dwelling'
import format from 'utils/format'

type Props = {
  dwelling: Dwelling
  projectName?: string
  onBack?: (e: React.MouseEvent) => void
  /**
   * Simple variant avoids showing the dwelling status/price.
   */
  variant?: 'regular' | 'simple'
}

const DwellingDetailTitle = ({
  dwelling,
  projectName,
  onBack,
  variant = 'regular',
}: Props) => {
  const enabled = useDwellingEnabled(dwelling)

  return (
    <Stack
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      alignItems={{
        xs: 'flex-start',
        sm: 'center',
      }}
      spacing={{
        xs: 1.5,
        sm: 3,
      }}
    >
      {onBack && (
        <IconButton
          aria-label="Go back"
          edge="start"
          size="small"
          onClick={onBack}
        >
          <ArrowBackIcon />
        </IconButton>
      )}

      {!!projectName && (
        <Typography variant="subtitle1" color="text.secondary">
          {projectName}
        </Typography>
      )}

      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        useFlexGap
        spacing={{
          xs: 1.5,
          sm: 3,
        }}
      >
        <DwellingChip
          statusCode={variant === 'regular' ? dwelling.statusCode : undefined}
          value={dwelling.typology}
          resale={dwelling.resale}
        />

        {enabled && variant === 'regular' && (
          <Typography variant="body1" lineHeight="normal">
            {format('currency', dwelling.price)}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default DwellingDetailTitle
