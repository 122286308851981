import { Container, Stack } from '@mui/material'
import Content from 'components/atoms/Content'
import PrintButton from 'components/atoms/PrintButton'
import PrintDateOfIssue from 'components/molecules/print/PrintDateOfIssue'
import PrintHeader from 'components/molecules/print/PrintHeader'
import AvailabilityColors from 'components/organisms/AvailabilityColors'
import InteractivePlans from 'components/organisms/interactive-plan/InteractivePlans'
import ProjectHeader from 'components/organisms/project/ProjectHeader'
import useProject from 'hooks/useProject'

const ProjectFloorPlans = () => {
  const project = useProject()
  const interactiveFloorPlans = project?.resources?.interactiveFloorPlans

  if (!project || !interactiveFloorPlans) {
    return null
  }

  return (
    <>
      <ProjectHeader project={project} />

      <Container maxWidth="xl">
        <Content>
          <PrintHeader project={project} />

          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              mb: 3,
              '@media print': {
                mb: 0,
              },
            }}
          >
            <AvailabilityColors
              sx={{
                px: 0,
              }}
            />

            <PrintButton />
          </Stack>

          <InteractivePlans project={project} plans={interactiveFloorPlans} />

          <PrintDateOfIssue />
        </Content>
      </Container>
    </>
  )
}

export default ProjectFloorPlans
