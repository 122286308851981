import { ssoStorageKeys } from 'constants/sso-storage'
import ssoToken from 'types/sso/token'
import ssoStorage from './storage'

/**
 * TODO: Inform when the storage is not available
 * (localhost incognito?) as a fatal error in the front.
 */
export const getSSOToken = async (): Promise<ssoToken | null> => {
  const token = await ssoStorage.getItem(ssoStorageKeys.token)

  if (!token) {
    return null
  }

  return token
}
