import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import { Dialog, DialogContent, Fade, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import SlideUp from 'components/atoms/SlideUp'
import DialogHeader from 'components/molecules/DialogHeader'
import Notice from 'components/molecules/Notice'
import DwellingDetailTitle from 'components/molecules/dwellings/detail/DwellingDetailTitle'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useProjects from 'hooks/useProjects'
import { useState } from 'react'
import { getProjectsExtendedDwellingsById } from 'utils/dwelling'
import DwellingCard from './DwellingCard'

const DwellingsFavoritesDialog = () => {
  const [open, setOpen] = useState(false)
  const { data: me } = useOdooAccountMe()
  const { data: projects } = useProjects()

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true })

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  if (!me?.metadata) {
    return null
  }

  const dwellings = getProjectsExtendedDwellingsById(
    projects,
    me?.metadata?.favorites
  )

  return (
    <>
      <IconButton
        aria-label="Favorite units"
        color="inherit"
        onClick={handleOpen}
      >
        <FavoriteBorderOutlinedIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        fullScreen={mobile}
        TransitionComponent={mobile ? SlideUp : Fade}
      >
        <DialogContent>
          <DialogHeader
            titleId="Favorite-units-title"
            title="Favorite units"
            onCloseClick={handleClose}
          />

          {!!dwellings.length ? (
            <Stack spacing={3}>
              {dwellings.map((dwelling) => (
                <DwellingCard
                  key={dwelling.id}
                  dwelling={dwelling}
                  title={
                    <DwellingDetailTitle
                      dwelling={dwelling}
                      projectName={dwelling.project}
                    />
                  }
                />
              ))}
            </Stack>
          ) : (
            <Notice
              message="You have not added any favorites yet"
              icon={FavoriteBorderOutlinedIcon}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DwellingsFavoritesDialog
