import Account from 'interfaces/account'
import AccountFilter from 'interfaces/account/filter'
import Project from 'interfaces/project'
import { getAccountName } from './metadata'
import { accountIsAdmin, getAccountRole } from './role'

const filterAccountsByProject = (accounts: Account[], project: Project) => {
  return accounts.filter((account) => {
    // Admins must have access to all projects.
    if (accountIsAdmin(account)) {
      return true
    }

    if (!account.metadata?.projects) {
      return false
    }

    for (const accountProject of account.metadata.projects) {
      if (accountProject.slug !== project.slug.current) {
        continue
      }

      return true
    }

    return false
  })
}

const filter = (
  accounts: Account[],
  me: Account,
  project?: Project,
  filters?: AccountFilter[]
) => {
  // Maybe filter accounts by project in case it was provided.
  if (project) {
    accounts = filterAccountsByProject(accounts, project)
  }

  // If I'm not admin I can only see accounts invited by myself.
  if (!accountIsAdmin(me)) {
    accounts = accounts.filter(
      (account) => account.metadata?.invited_by === me.email
    )
  }

  if (filters) {
    for (const filter of filters) {
      switch (filter.type) {
        case 'role':
          accounts = accounts.filter(
            (account) => getAccountRole(account) === filter.value
          )
          break

        case 'pending':
          accounts = accounts.filter((account) => !account.metadata?.enabled)
          break

        case 'search':
          // TODO: Implement some kind of algorithm? https://www.npmjs.com/package/fast-levenshtein?
          accounts = accounts.filter((account) => {
            const value = filter.value.toLowerCase()
            const email = account.email.toLowerCase()
            const name = `${account.metadata?.contact_name || ''} ${
              account.metadata?.last_name || ''
            }`.toLowerCase()

            return email.includes(value) || name.includes(value)
          })
          break
      }
    }
  }

  return accounts
}

const sort = (accounts: Account[]) => {
  // Sort enabled accounts alphabetically by name. Disabled accounts go at the bottom.
  const enabledAccounts = accounts.filter(
    (account) => account.metadata?.enabled
  )
  enabledAccounts.sort((a, b) => {
    const aName = getAccountName(a)
    const bName = getAccountName(b)

    return aName.localeCompare(bName)
  })
  const disabledAccounts = accounts.filter(
    (account) => !account.metadata?.enabled
  )
  accounts = [...enabledAccounts, ...disabledAccounts]

  return accounts
}

export const filterAndSortAccounts = (
  rawAccounts: Account[],
  me: Account,
  project?: Project,
  filters?: AccountFilter[]
) => {
  let accounts = [...rawAccounts]

  /**
   * Filtering.
   */
  accounts = filter(accounts, me, project, filters)

  /**
   * Sorting.
   */
  accounts = sort(accounts)

  return accounts
}
