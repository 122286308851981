import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import {
  Dialog,
  DialogContent,
  Link,
  ListItemIcon,
  MenuItem,
} from '@mui/material'
import DialogHeader from 'components/molecules/DialogHeader'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import { useState } from 'react'
import { legalRouteMetadata } from 'routes-metadata'
import { accountHasMetadata } from 'utils/account/metadata'
import AccountUpdateForm from './AccountUpdateForm'

type Props = {
  onClick: () => void
}

const AccountProfileDialog = ({ onClick }: Props) => {
  const { data: me } = useOdooAccountMe()
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    onClick()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  if (!me || !accountHasMetadata(me)) {
    return null
  }

  return (
    <>
      <MenuItem onClick={handleClick} aria-label="Account profile">
        <ListItemIcon>
          <ManageAccountsOutlinedIcon fontSize="small" />
        </ListItemIcon>
        Profile
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="account-profile-dialog-title"
      >
        <DialogContent>
          <DialogHeader
            titleId="account-profile-dialog-title"
            title="Your profile"
            onCloseClick={handleClose}
          />

          <AccountUpdateForm
            accountId={me.id}
            config={{
              labels: {
                password: 'New password',
                repeatPassword: 'Repeat new password',
              },
              initialValues: {
                firstName: me.metadata?.contact_name || '',
                lastName: me.metadata?.last_name || '',
                email: me.email,
              },
              components: {
                submitMessage: (
                  <Link
                    target="_blank"
                    href={`/${legalRouteMetadata.slug}`}
                    underline="hover"
                    variant="caption"
                  >
                    Terms & Conditions and Privacy Policy
                  </Link>
                ),
              },
              passwordNotRequired: true,
              legalNotRequired: true,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AccountProfileDialog
