import { List } from '@mui/material'
import useProjectRoutesGetters from 'hooks/useProjectRoutesGetters'
import Project from 'interfaces/project'
import NavListItem from './NavListItem'

type Props = {
  project: Project
  variant: 'row' | 'column'
}

const NavList = ({ project, variant }: Props) => {
  const { get: getProjectRoutes } = useProjectRoutesGetters()

  return (
    <List
      sx={{
        display: variant === 'row' ? 'flex' : null,
      }}
    >
      {getProjectRoutes(project)?.map((routeMetadata) => (
        <NavListItem
          key={routeMetadata.slug}
          project={project}
          variant={variant}
          routeMetadata={routeMetadata}
        />
      ))}
    </List>
  )
}

export default NavList
