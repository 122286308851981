import { Divider, Grid, inputClasses } from '@mui/material'
import SearchInput from 'components/atoms/SearchInput'
import StackOverflow from 'components/atoms/StackOverflow'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import AccountFilter from 'interfaces/account/filter'
import { useEffect, useState } from 'react'
import { getAccountShareableRoles } from 'utils/account/role'
import AccountsFilterPending from './AccountsFiltersPending'
import AccountsFiltersRoles from './AccountsFiltersRoles'

type Props = {
  onChange: (filters: AccountFilter[]) => void
}

const AccountsFilters = ({ onChange }: Props) => {
  const { data: me } = useOdooAccountMe()
  const shareableRoles = getAccountShareableRoles(me)

  const [roleFilter, setRoleFilter] = useState<AccountFilter | null>(null)
  const [pendingFilter, setPendingFilter] = useState<AccountFilter | null>(null)
  const [searchFilter, setSearchFilter] = useState<AccountFilter | null>(null)

  useEffect(() => {
    const filters: AccountFilter[] = []

    if (roleFilter) {
      filters.push(roleFilter)
    }
    if (pendingFilter) {
      filters.push(pendingFilter)
    }
    if (searchFilter) {
      filters.push(searchFilter)
    }

    onChange(filters)
  }, [pendingFilter, onChange, roleFilter, searchFilter])

  if (!me) {
    return null
  }

  return (
    <Grid container alignItems="center" spacing={{ xs: 2, md: 3 }}>
      {shareableRoles.length > 1 && (
        <Grid item xs={12} sm={8}>
          <StackOverflow>
            <AccountsFiltersRoles
              roles={shareableRoles}
              onChange={(newRole) => {
                if (!newRole) {
                  setRoleFilter(null)
                  return
                }
                setRoleFilter({
                  type: 'role',
                  value: newRole,
                })
              }}
            />

            <Divider orientation="vertical" variant="middle" flexItem />

            <AccountsFilterPending
              pending={pendingFilter?.value === 'true'}
              onChange={(newPending) => {
                if (!newPending) {
                  setPendingFilter(null)
                  return
                }
                setPendingFilter({
                  type: 'pending',
                  value: newPending.toString(),
                })
              }}
            />
          </StackOverflow>
        </Grid>
      )}

      <Grid item xs sm sx={{ textAlign: 'right' }}>
        <SearchInput
          value={searchFilter?.value ?? ''}
          onChange={(value) => {
            if (value === '') {
              setSearchFilter(null)
              return
            }
            setSearchFilter({
              type: 'search',
              value,
            })
          }}
          formControlProps={{
            fullWidth: true,
            sx: {
              alignItems: 'flex-end',
            },
          }}
          inputProps={{
            sx: {
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.short,
                }),
              width: {
                xs: '100%',
                sm: '75%',
              },
              [`&.${inputClasses.focused}`]: {
                width: '100%',
              },
              maxWidth: {
                xs: 'none',
                sm: 300,
              },
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default AccountsFilters
