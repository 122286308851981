import PlansWithVariantsFloor from 'interfaces/plans-with-variants/floor'
import PlansWithVariantsVariant from 'interfaces/plans-with-variants/variant'
import PlansWithVariantsVariantState from 'interfaces/plans-with-variants/variant-state'

export const getInitialVariantsState = (floor: PlansWithVariantsFloor) =>
  floor.variants.map((variant) => ({
    ...variant,
    selected: variant.values[0],
  }))

export const getPlan = (
  plans: string[],
  activeFloor: PlansWithVariantsFloor,
  variantsState: PlansWithVariantsVariantState[]
): string => {
  /**
   * Build array of keys the plan has to match to get elected.
   */
  let keys: string[] = []

  // With the active floor.
  keys.push(activeFloor.level)

  // With variants.
  for (const variantState of variantsState) {
    keys.push(variantState.selected.slug.current)
  }

  const found = plans.find((plan) =>
    (keys as string[]).every((check) => plan.includes(check))
  )
  if (!found) {
    console.error(
      `Plan couldn't be found given the current activeFloor and variantsState`
    )
    return ''
  }

  return found
}

/**
 * Searches the variants state for the passed variant's selected value.
 */
export const getVariantSelectedValue = (
  variant: PlansWithVariantsVariant,
  variantsState: PlansWithVariantsVariantState[]
) => {
  const variantState = variantsState.find(
    (variantState) => variantState.slug.current === variant.slug.current
  )
  return variantState?.selected ?? variant.values[0]
}
