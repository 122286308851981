/**
 * NOTE: For some fucking reason naming the image import directly
 * as `Image` fails, wtf?!?!?!?!?!?!?!?! ImageProps works, okay.
 */
import ImageProps from 'interfaces/image'
import { allProgress } from './promises'

export const mapImageSrcsToImages = (imageSrcs?: string[]): ImageProps[] =>
  imageSrcs?.map((imageSrc) => ({ url: imageSrc })) ?? []

/**
 *
 * @param images A set of gallery images without width/height.
 * @returns A set of gallery images (hopefully) with width and height.
 */
export const addImagesDimensions = async (
  images: ImageProps[],
  progressCallback?: Function
): Promise<ImageProps[]> => {
  const withDimensionsPromises = images.map(
    (image, index): Promise<ImageProps> =>
      new Promise((resolve, reject) => {
        const img = new Image()

        img.onload = function () {
          resolve({
            ...image,
            width: img.naturalWidth,
            height: img.naturalHeight,
          })
        }
        img.onerror = function () {
          reject('Image failed loading')
        }

        img.src = image.url
      })
  )

  return await allProgress(withDimensionsPromises, progressCallback)
}

export const getDownloadableImages = (images: ImageProps[]) => {
  if (!images?.length) {
    return []
  }

  return images.filter((image) => image.downloadable)
}
