const stringToColor = (string: string) => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const stringAvatar = (name: string, maxInitials = 2) => {
  if (!name.trim()) {
    return {
      sx: {
        bgcolor: 'text.disabled',
      },
    }
  }

  // Split the name (cleaning empties) and map to initials.
  const parts = name.split(' ').filter((name) => name)
  const initials = parts
    .map((namePart) => namePart[0].toUpperCase())
    .splice(0, maxInitials)

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials.join(''),
  }
}
