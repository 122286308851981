import ImageGalleryItemCaption from 'components/molecules/image-gallery/ImageGalleryItemCaption'
import usePhotoSwipeLightbox, {
  LightboxOptions,
} from 'hooks/usePhotoSwipeLightbox'
import Image from 'interfaces/image'
import ReactDOMServer from 'react-dom/server'
import { addImagesDimensions } from 'utils/images'

const useDwellingMediaLightbox = (options?: LightboxOptions) => {
  const {
    createLightbox,
    setLightboxLoadingClass,
    removeLightboxLoadingClass,
  } = usePhotoSwipeLightbox()

  const initAndOpen = async (
    images: Image[],
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!images.length) {
      console.warn('No images to show in a dwelling media lightbox')
      return false
    }
    // Avoids flickery behavior when the loading media is already in cache.
    const loadingReportingTimeoutMs = 150

    setTimeout(() => {
      setIsLoading(true)
      setLightboxLoadingClass()
    }, loadingReportingTimeoutMs)

    const imagesWithDimensions = await addImagesDimensions(images)

    const lightbox = createLightbox({
      dataSource: imagesWithDimensions.map((imageWithDimensions) => ({
        src: imageWithDimensions.url,
        width: imageWithDimensions.width,
        height: imageWithDimensions.height,
        alt: imageWithDimensions.title,
        caption: ReactDOMServer.renderToString(
          <ImageGalleryItemCaption
            title={imageWithDimensions.title}
            subtitle={imageWithDimensions.subtitle}
          />
        ),
      })),
      ...options,
    })
    lightbox.init()
    lightbox.loadAndOpen(0)

    setTimeout(() => {
      setIsLoading(false)
      removeLightboxLoadingClass()
    }, loadingReportingTimeoutMs)

    return true
  }

  return {
    initAndOpen,
  }
}

export default useDwellingMediaLightbox
