import { useLocation } from 'react-router-dom'
import { getProjectRouteMetadataBySlug } from 'utils/project/routes'

/**
 * Tries to get the current route metadata using it's slug in the URL.
 *
 * @returns The route metadata if found, `undefined` otherwise.
 */
const useProjectRouteMetadata = () => {
  const { pathname } = useLocation()
  const routeSlug = pathname.substring(pathname.lastIndexOf('/') + 1)

  return getProjectRouteMetadataBySlug(routeSlug)
}

export default useProjectRouteMetadata
