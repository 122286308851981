export const queryKeys = {
  me: ['me'],
  accounts: ['accounts'],
  projects: ['projects'],
  settings: ['settings'],
  changelog: ['changelog'],
  ssoToken: ['sso-token'],
  odooApiQuery: ['odoo-api-query'],
  odooAdmin: ['odoo-admin'],
}
