import { Container, Stack, Typography } from '@mui/material'
import { sectionSpacing } from 'constants/section'
import SectionTextProps from 'interfaces/section/text'

const SectionText = ({ title, text }: SectionTextProps) => {
  return (
    <Container>
      <Stack spacing={sectionSpacing}>
        {!!title && (
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
        )}

        {text?.map((p, index) => (
          <Typography key={index}>{p}</Typography>
        ))}
      </Stack>
    </Container>
  )
}

export default SectionText
