import { changelogDialogModes } from 'constants/changelog/changelog-dialog-modes'
import { localStorageKeys } from 'constants/local-storage'
import ChangelogEntry from 'interfaces/changelog/changelog-entry'
import ChangelogDialogMode from 'types/changelog/changelog-dialog-mode'
import { getLocalStorageJSON } from 'utils/local-storage'

export const getDefaultActiveMode = (
  changelogEntries: ChangelogEntry[]
): ChangelogDialogMode => {
  const unReadChangelogEntries = getUnreadChangelogEntries(changelogEntries)
  const getDefaultActiveModeRead = () => {
    const defaultMode = changelogDialogModes.find((mode) =>
      changelogEntries.some(
        (changelogEntry) => changelogEntry.type === mode.slug
      )
    )
    if (!defaultMode) {
      return changelogDialogModes[0].slug
    }
    return defaultMode?.slug
  }

  return unReadChangelogEntries.length > 0
    ? unReadChangelogEntries[0].type
    : getDefaultActiveModeRead()
}

export const updateLastRead = (active: ChangelogDialogMode): void => {
  const currentTime = new Date().getTime()
  let storedChangelogLastRead = getLocalStorageJSON(
    localStorageKeys.changelogLastRead
  )
  storedChangelogLastRead = {
    ...storedChangelogLastRead,
    [active]: currentTime,
  }

  localStorage.setItem(
    localStorageKeys.changelogLastRead,
    JSON.stringify(storedChangelogLastRead)
  )
  window.dispatchEvent(new Event(localStorageKeys.changelogLastRead))
}

export const getUnreadChangelogEntries = (
  changelogEntries: ChangelogEntry[]
) => {
  const currentTime = new Date().getTime()
  const storedChangelogLastRead = getLocalStorageJSON(
    localStorageKeys.changelogLastRead
  )

  const unreadChangelogEntries = changelogEntries
    .filter((changelogEntry) => {
      const changelogEntryDate = new Date(changelogEntry.date).getTime()
      const lastReadDate =
        (storedChangelogLastRead &&
          storedChangelogLastRead[changelogEntry.type]) ||
        0

      return (
        changelogEntryDate <= currentTime && changelogEntryDate > lastReadDate
      )
    })
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())

  return unreadChangelogEntries
}

export const isChangelogEntryUnread = (changelogEntry: ChangelogEntry) => {
  const currentTime = new Date().getTime()

  const changelogEntryDate = new Date(changelogEntry.date).getTime()
  const lastReadDate = getLocalStorageJSON(localStorageKeys.changelogLastRead)

  if (!lastReadDate || !lastReadDate[changelogEntry.type]) {
    return true
  }
  return (
    changelogEntryDate <= currentTime &&
    changelogEntryDate > new Date(lastReadDate[changelogEntry.type]!).getTime()
  )
}

export const getLastChangelogEntryUnread = (
  changelogEntries: ChangelogEntry[]
) => {
  const lastUnreadEntry = getUnreadChangelogEntries(changelogEntries).pop()

  return lastUnreadEntry
}
