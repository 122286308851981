import { SvgIconComponent } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'

type Props = {
  icon: SvgIconComponent
  message: React.ReactNode
}

const Notice = (props: Props) => {
  return (
    <Stack
      spacing={3}
      alignItems="center"
      sx={{
        py: 9,
      }}
    >
      <props.icon fontSize="large" color="action" />
      <Typography>{props.message}</Typography>
    </Stack>
  )
}

export default Notice
