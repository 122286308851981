import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import SimpleLi from 'components/atoms/SimpleLi'
import SimpleUl from 'components/atoms/SimpleUl'
import DwellingDetailDialog from 'components/organisms/dwellings/detail/DwellingDetailDialog'
import useDwellingEnabled from 'hooks/dwelling/useDwellingEnabled'
import useDwellingFields from 'hooks/dwelling/useDwellingFields'
import DwellingExtended from 'interfaces/dwelling/extended'
import Project from 'interfaces/project'
import { useState } from 'react'
import DwellingFieldValue from './dwellings/DwellingFieldValue'
import DwellingDetailButton from './dwellings/detail/DwellingDetailButton'

type Props = {
  dwelling: DwellingExtended
  project?: Project
}

const InteractiveDwellingTooltipContent = ({ dwelling, project }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const dwellingFields = useDwellingFields(dwelling, 'info', project)
  const enabled = useDwellingEnabled(dwelling)
  const theme = useTheme()

  const handleDialogOpen = () => {
    if (!enabled) {
      return
    }

    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const titleFontSize = {
    xs: '1rem',
    sm: '1.25rem', // Default
  }
  const contentFontSize = {
    xs: '0.775rem',
    sm: '0.875rem', // Default
  }

  if (!dwellingFields) {
    return null
  }

  return (
    <Box
      onClick={handleDialogOpen}
      sx={[
        {
          padding: 2,
          backgroundColor: `${theme.palette.grey[100]}cc`, // 'cc' is 80% opacity,
          backdropFilter: theme.custom.backdropBlurAndSaturation,
        },
        enabled && {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: `${theme.palette.grey[200]}cc`,
          },
          transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.shortest,
          }),
        },
      ]}
    >
      <SimpleUl>
        <SimpleLi sx={{ marginBottom: 2 }}>
          <Typography
            sx={{
              fontSize: titleFontSize,
            }}
            variant="h6"
          >
            {dwelling.typology}
          </Typography>

          {enabled && (
            <>
              <DwellingDetailButton
                onClick={handleDialogOpen}
                iconButtonProps={{
                  edge: 'end',
                }}
                buttonProps={{
                  variant: 'outlined',
                }}
              />

              <DwellingDetailDialog
                dwelling={dwelling}
                open={dialogOpen}
                onClose={(e) => {
                  /**
                   * Prevents the propagation of the click event that would otherwise
                   * bubble to the surrounding `Box`'s `onClick` handler trying to
                   * close and open the dialog at the same time.
                   */
                  e.stopPropagation()

                  handleDialogClose()
                }}
              />
            </>
          )}
        </SimpleLi>

        {dwellingFields.map((field) => (
          <SimpleLi key={field.name}>
            <Typography
              sx={{ fontSize: contentFontSize, marginRight: 3 }}
              variant="body2"
            >
              {field.name}
            </Typography>
            <Typography sx={{ fontSize: contentFontSize }} variant="body2">
              <DwellingFieldValue field={field} />
            </Typography>
          </SimpleLi>
        ))}
      </SimpleUl>
    </Box>
  )
}

export default InteractiveDwellingTooltipContent
