import Account from 'interfaces/account'
import { normalizeToNumber } from 'utils/normalization'

export const getAccountHumanDates = (account: Account) => {
  /**
   * This normalization is done because at some point in
   * history these values could be both strings or numbers.
   */
  const legal = account.metadata?.legal
    ? normalizeToNumber(account.metadata?.legal)
    : false
  const invited = account.metadata?.invited
    ? normalizeToNumber(account.metadata?.invited)
    : false
  const reminded = account.metadata?.reminded
    ? normalizeToNumber(account.metadata?.reminded)
    : false

  /**
   * Dates gets multiplied by 1000 because the time is
   * stored in seconds, while JavaScript works with ms.
   */
  return {
    legal: legal ? new Date(legal * 1000).toLocaleString('es-ES') : null,
    invited: invited ? new Date(invited * 1000).toLocaleString('es-ES') : null,
    reminded: reminded
      ? new Date(reminded * 1000).toLocaleString('es-ES')
      : null,
  }
}

/**
 * Gets the full name of the account. First name,
 * and then last name.
 */
export const getAccountName = (account: Account) => {
  return `${account.metadata?.contact_name || ''} ${
    account.metadata?.last_name || ''
  }`.trim()
}

export const accountIsEnabled = (account: Account) =>
  !!account.metadata?.enabled

export const accountHasMetadata = (account: Account) => !!account.metadata
