import ProjectHeader from 'components/organisms/project/ProjectHeader'
import Sections from 'components/organisms/sections'
import useProject from 'hooks/useProject'

const ProjectServices = () => {
  const project = useProject()

  if (!project) {
    return null
  }

  return (
    <>
      <ProjectHeader project={project} />
      <Sections sections={project.services?.sections ?? []} />
    </>
  )
}

export default ProjectServices
