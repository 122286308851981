import Project from 'interfaces/project'
import { createContext } from 'react'

/**
 * Allows for "project focusing", meant to work inside the `ShareDialog`.
 *
 * Said focusing shows by default only the current project when inside one,
 * using an instance of `ShareSeeMore` to expand the rest.
 */
export default createContext<{
  value: Project | null
  setter: React.Dispatch<React.SetStateAction<Project | null>>
}>({ value: null, setter: () => null })
