import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined'
import {
  Box,
  Divider,
  List,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material'
import { SxProps, Theme, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Notice from 'components/molecules/Notice'
import DwellingsTableHead from 'components/molecules/dwellings/DwellingsTableHead'
import Dwelling from 'interfaces/dwelling'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingField from 'interfaces/dwelling/field'
import React, { useCallback, useState } from 'react'
import DwellingAction from 'types/dwelling/action'
import DwellingSortOrder from 'types/dwelling/sort-order'
import { sortDwellings } from 'utils/dwelling/filters'
import DwellingsListItem from './DwellingsListItem'
import { DwellingsSortProps } from './DwellingsSort'
import DwellingsTableRow from './DwellingsTableRow'
import DwellingDetailDialog from './detail/DwellingDetailDialog'

type Props = {
  dwellings: DwellingExtended[]
  fields: DwellingField[]
  action?: DwellingAction
  onSelect?: (selected: boolean, dwelling: DwellingExtended) => void
  sx?: SxProps<Theme>
}

const Dwellings = ({
  dwellings,
  fields,
  action = 'detail',
  onSelect,
  sx,
}: Props) => {
  const theme = useTheme()
  const mdAndBelow = useMediaQuery(theme.breakpoints.down('lg'), {
    noSsr: true,
  })
  const [sortKey, setSortKey] = useState<keyof Dwelling | null>(null)
  const [sortOrder, setSortOrder] = useState<DwellingSortOrder>(null)
  const [selection, setSelection] = useState<DwellingExtended[]>([])
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleSortChange = useCallback<DwellingsSortProps['onChange']>(
    (key, order) => {
      setSortKey(key)
      setSortOrder(order)
    },
    []
  )

  const handleDialogOpen = () => setDialogOpen(true)

  const handleDialogClose = () => setDialogOpen(false)

  const handleAction = (selected: boolean, dwelling: DwellingExtended) => {
    switch (action) {
      case 'detail':
        setSelection([dwelling])
        handleDialogOpen()
        break

      case 'select':
        if (onSelect) {
          onSelect(selected, dwelling)
        }

        setSelection((prevSelection) => {
          const nextSelection = [...prevSelection]

          if (selected) {
            nextSelection.push(dwelling)
          } else {
            // Find dwelling's index in selection and remove it.
            const index = nextSelection.findIndex(
              (selection) => selection.id === dwelling.id
            )
            if (index !== -1) {
              nextSelection.splice(index, 1)
            }
          }

          return nextSelection
        })
        break
    }
  }

  /**
   * Maybe sort dwellings.
   */
  let processedDwellings = [...dwellings]
  if (sortOrder !== null) {
    processedDwellings = sortDwellings(processedDwellings, sortOrder, sortKey)
  }

  if (!fields.length) {
    return (
      <Notice
        message="No fields currently available, please come back later"
        icon={MapsHomeWorkOutlinedIcon}
      />
    )
  }

  /**
   * Renders in a list or a table depending on current media query.
   */
  return (
    <>
      {mdAndBelow ? (
        <List disablePadding sx={sx}>
          {processedDwellings.map((dwelling, index) => (
            <React.Fragment key={index}>
              <DwellingsListItem
                dwelling={dwelling}
                fields={fields}
                action={action}
                selected={
                  !!selection.find((selected) => selected.id === dwelling.id)
                }
                onSelect={handleAction}
              />

              {index !== processedDwellings.length - 1 && (
                <Divider component="li" />
              )}
            </React.Fragment>
          ))}
        </List>
      ) : (
        <TableContainer component={Box} sx={sx}>
          <Table aria-label="Dwellings table">
            <DwellingsTableHead
              fields={fields}
              sortKey={sortKey}
              onSort={handleSortChange}
            />

            <TableBody>
              {processedDwellings.map((dwelling, index) => (
                <DwellingsTableRow
                  key={index}
                  dwelling={dwelling}
                  fields={fields}
                  action={action}
                  selected={
                    !!selection.find((selected) => selected.id === dwelling.id)
                  }
                  onSelect={handleAction}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {action === 'detail' && !!selection.length && (
        <DwellingDetailDialog
          dwelling={selection[0]}
          open={dialogOpen}
          onClose={handleDialogClose}
        />
      )}
    </>
  )
}

export default Dwellings
