import { Fade } from '@mui/material'
import useImgLoaded from 'hooks/useImgLoaded'
import usePhotoSwipeLightbox from 'hooks/usePhotoSwipeLightbox'
import Image from 'interfaces/image'
import { useEffect } from 'react'
import Watermarked from './Watermarked'

type Props = {
  image: Image
  id: string
  alt?: string
  imageStyle?: React.CSSProperties
}

const Lightbox = ({
  image,
  id,
  alt,
  imageStyle = { width: '100%' },
}: Props) => {
  const { createLightbox } = usePhotoSwipeLightbox()
  const isImgLoaded = useImgLoaded(image.thumbUrl || '')

  // Create the lightbox and initialize it.
  useEffect(() => {
    const lightbox = createLightbox({
      gallery: '#' + id,
      children: 'a',
    })

    lightbox.init()

    return () => {
      lightbox.destroy()
    }
  }, [createLightbox, id])

  return (
    <div className="pswp-gallery" id={id}>
      <a
        href={image.url}
        data-pswp-width={image.width}
        data-pswp-height={image.height}
        target="_blank"
        rel="noreferrer"
      >
        <Fade in={isImgLoaded}>
          <Watermarked>
            <img
              src={image.thumbUrl ?? image.url}
              alt={alt ?? ''}
              style={{ ...imageStyle }}
            />
          </Watermarked>
        </Fade>
      </a>
    </div>
  )
}

export default Lightbox
