import { LoadingButton } from '@mui/lab'
import { Alert, Box, Collapse, Stack, TextField } from '@mui/material'
import Link from '@mui/material/Link'
import useOdooLogin from 'hooks/odoo/useOdooLogin'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { accountPasswordResetRequestRouteMetadata } from 'routes-metadata'
import { sendEvent } from 'utils/gtag'
import isEmail from 'validator/lib/isEmail'
import PasswordTextField from '../atoms/PasswordTextField'

const LoginForm = () => {
  const navigate = useNavigate()
  const { mutateAsync: login } = useOdooLogin()
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const isValid = () => isEmailValid && password !== ''

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && isValid()) {
      handleLogin()
    }
  }

  const handleLogin = async () => {
    setLoading(true)

    try {
      const success = await login({ email, password })

      if (success) {
        sendEvent(`login_${email}`)
        navigate('/')
      }
    } catch (error: any) {
      setError(error.message)
    }

    setLoading(false)
  }

  return (
    <>
      <TextField
        autoFocus
        fullWidth
        sx={{ marginBottom: 3 }}
        id="email"
        type="email"
        label="Email"
        variant="filled"
        value={email}
        error={!isEmailValid && email !== ''}
        onChange={(e) => {
          setError('')

          const value = e.target.value
          setIsEmailValid(isEmail(value))
          setEmail(value)
        }}
        onKeyUp={handleKeyUp}
      />

      <Box sx={{ marginBottom: 3 }}>
        <PasswordTextField
          id="password"
          label="Password"
          password={password}
          onChange={(e) => {
            setError('')
            setPassword(e.target.value)
          }}
          onKeyUp={handleKeyUp}
        />
      </Box>

      <Collapse in={error !== ''} timeout="auto">
        <Alert sx={{ marginBottom: 3 }} severity="error">
          {error}
        </Alert>
      </Collapse>

      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Link
          href={`/${accountPasswordResetRequestRouteMetadata.slug}`}
          underline="hover"
          variant="body2"
        >
          Forgot your password?
        </Link>

        <LoadingButton
          disableElevation
          variant="contained"
          disabled={!isValid()}
          onClick={handleLogin}
          loading={loading}
        >
          Log in
        </LoadingButton>
      </Stack>
    </>
  )
}

export default LoginForm
