/* eslint-disable jsx-a11y/alt-text */
import { Fade } from '@mui/material'
import CenteredSpinner from 'components/molecules/CenteredSpinner'
import useImgLoaded from 'hooks/useImgLoaded'
import { useEffect } from 'react'

interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  imgLoaded?: () => void
}

/**
 * Image with a loading spinner in the center.
 */
const Image = ({ imgLoaded, ...props }: Props) => {
  const isImgLoaded = useImgLoaded(props.src || '')

  /**
   * Call the imgLoaded prop in case it exists
   * and the image has loaded.
   */
  useEffect(() => {
    if (!imgLoaded || !isImgLoaded) {
      return
    }
    imgLoaded()
  }, [imgLoaded, isImgLoaded])

  return (
    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
      <Fade in={isImgLoaded}>
        <img {...props} />
      </Fade>

      <CenteredSpinner in={!isImgLoaded} />
    </div>
  )
}

export default Image
