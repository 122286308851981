import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined'
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined'
import { Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  TransformComponent,
  TransformWrapper,
} from '@pronestor/react-zoom-pan-pinch'
import ContainedIconButton from 'components/atoms/ContainedIconButton'
import Image from 'components/atoms/Image'
import Watermarked from 'components/atoms/Watermarked'

type Props = {
  image: string
}

/**
 * NOTE: This component uses old React patterns because of the library it's using.
 */
const ZoomablePlan = ({ image }: Props) => {
  const theme = useTheme()

  return (
    <div
      style={{
        // Make sure it's absolute positioned child don't go outside ever.
        position: 'relative',
      }}
    >
      <Watermarked>
        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform, state, ...rest }) => (
            <>
              <Stack
                direction="row"
                spacing={{
                  xs: 1,
                  sm: 2,
                }}
                alignItems="center"
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 1,
                  p: {
                    xs: 1,
                    sm: 2,
                  },
                }}
              >
                <ContainedIconButton
                  color="secondary"
                  onClick={() => zoomOut()}
                  aria-label="Zoom out"
                >
                  <ZoomOutOutlinedIcon />
                </ContainedIconButton>
                <ContainedIconButton
                  color="secondary"
                  onClick={() => zoomIn()}
                  aria-label="Zoom in"
                >
                  <ZoomInOutlinedIcon />
                </ContainedIconButton>

                {/* <ContainedIconButton
              color='secondary'
                onClick={() => resetTransform()}
                aria-label="Reset zoom"
              >
                <CropFreeOutlinedIcon />
              </ContainedIconButton> */}
              </Stack>

              <TransformComponent>
                <Image
                  src={image}
                  alt={`upgrades`}
                  style={{
                    width: '100%',
                    height: 'auto',
                    marginTop: theme.spacing(1),
                  }}
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </Watermarked>
    </div>
  )
}

export default ZoomablePlan
