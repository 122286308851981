import { AlertProps, Link } from '@mui/material'
import copy from 'copy-to-clipboard'
import useToken from 'hooks/useToken'
import DwellingExtended from 'interfaces/dwelling/extended'
import { useState } from 'react'
import { shareRouteMetadata } from 'routes-metadata'
import ShareLayout from 'types/share/layout'
import ShareLifespan from 'types/share/lifespan'
import { epoch } from 'utils/date'

const useShareLink = (dwellings: DwellingExtended[]) => {
  const { mutateAsync: token } = useToken()

  const [loading, setLoading] = useState(false)
  const [severity, setSeverity] = useState<AlertProps['severity']>()
  const [message, setMessage] = useState<React.ReactNode>(null)

  const copyLink = async (layout: ShareLayout, lifespan: ShareLifespan) => {
    setLoading(true)

    // Generate a token with the selected dwellings IDs.
    const tokenResponse = await token({
      payload: {
        exp: epoch() + lifespan.value,
        dwe: dwellings.map((dwelling) => dwelling.id),
      },
    })

    // Generate the link and copy it to clipboard.
    const location = window.location.origin
    const route = shareRouteMetadata.slug.replace(':token', tokenResponse.token)
    const shareLink = `${location}/${route}#${layout}`

    // Returns `true` if no additional keystrokes were required from user.
    const copied = copy(shareLink)
    const severity: AlertProps['severity'] = copied ? 'success' : 'warning'
    const message = copied
      ? `Link copied to clipboard successfully:`
      : `Your browser does not support automatic clipboard copying. Here's
    your link:`

    setSeverity(severity)
    setMessage(
      <>
        <span>{message}</span>
        <br />
        <Link
          href={`/${route}#${layout}`}
          target="_blank"
          underline="hover"
          sx={{ wordBreak: 'break-all' }}
        >
          {shareLink}
        </Link>
      </>
    )
    setLoading(false)
  }

  const restart = () => {
    setSeverity(undefined)
    setMessage(null)
  }

  return {
    severity,
    message,
    loading,
    copyLink,
    restart,
  }
}

export default useShareLink
