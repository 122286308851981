import { Box } from '@mui/material'
import ForcedWatermarkContext from 'contexts/ForcedWatermarkContext'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import logo from 'images/logo.svg'
import { forwardRef, useContext } from 'react'
import { accountHasWatermarks } from 'utils/account/role'

type Props = {
  children: React.ReactNode
  sizePercentage?: number
}

/**
 * Shows a watermark over it's children. Only when the account can't share.
 */
const Watermarked = forwardRef(
  ({ children, sizePercentage = 50 }: Props, ref) => {
    const { data: me } = useOdooAccountMe()
    const forcedWatermark = useContext(ForcedWatermarkContext)

    const opacity = forcedWatermark || accountHasWatermarks(me) ? 0.2 : 0

    return (
      <Box
        ref={ref}
        onContextMenu={(e) => e.preventDefault()}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          '&:after': {
            content: `url(${logo})`,
            pointerEvents: 'none',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: `${sizePercentage}%`,
            opacity,
          },
        }}
      >
        {children}
      </Box>
    )
  }
)

export default Watermarked
