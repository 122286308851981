import { Fade, Stack, styled } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { useState } from 'react'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '50vw',
    textAlign: 'center',
  },
})

const ClickAwayTooltip = (props: TooltipProps) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      {/* This Stack forwards refs and centers the tooltip below */}
      <Stack>
        <StyledTooltip
          PopperProps={{
            disablePortal: true,
          }}
          open={open}
          onClose={handleClose}
          onClick={handleOpen}
          onMouseOver={handleOpen}
          TransitionComponent={Fade}
          {...props}
        />
      </Stack>
    </ClickAwayListener>
  )
}

export default ClickAwayTooltip
