import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AppBar, Dialog, IconButton, Toolbar } from '@mui/material'
import Iframe from 'components/atoms/Iframe'
import Watermarked from 'components/atoms/Watermarked'
import VirtualTour from 'interfaces/virtual-tour'

type Props = {
  virtualTour: VirtualTour
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const VirtualTourDialog = ({ virtualTour, open, setOpen }: Props) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
      <AppBar
        sx={{
          bgcolor: 'common.white',
        }}
        position="static"
        elevation={0}
      >
        <Toolbar sx={{ color: 'grey.600' }}>
          <IconButton
            onClick={() => setOpen(false)}
            aria-label="Back"
            color="inherit"
            edge="start"
          >
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Watermarked sizePercentage={25}>
        <Iframe
          title={virtualTour.title}
          src={virtualTour.url}
          sx={{
            // Accomodates for the AppBar height.
            height: (theme) => theme.custom.fullHeightMinusHeader,
          }}
        />
      </Watermarked>
    </Dialog>
  )
}

export default VirtualTourDialog
