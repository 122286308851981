import CenteredSpinner from 'components/molecules/CenteredSpinner'
import FatalErrorMessage from 'components/organisms/FatalErrorMessage'
import LegalDialog from 'components/organisms/LegalDialog'
import { localStorageKeys } from 'constants/local-storage'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useOdooAccountUpdate from 'hooks/odoo/account/useOdooAccountUpdate'
import useOdooLogout from 'hooks/odoo/useOdooLogout'
import useProject from 'hooks/useProject'
import useProjectRouteMetadata from 'hooks/useProjectRouteMetadata'
import useProjects from 'hooks/useProjects'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { loginRouteMetadata, selectorRouteMetadata } from 'routes-metadata'
import { accountIsAdmin, getAccountRole } from 'utils/account/role'
import { sendEvent } from 'utils/gtag'
import lastRead from 'utils/last-read'
import { getLocalStorageJSON } from 'utils/local-storage'
import { getSSOToken } from 'utils/sso/token'
import { isTokenExpired } from 'utils/token'

const RootRoute = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const routeMetadata = useProjectRouteMetadata()
  const [error, setError] = useState<React.ReactNode | null>(null)

  // Account hooks.
  const { mutateAsync: logout } = useOdooLogout()
  const { data: me, status: meStatus } = useOdooAccountMe()
  const { mutateAsync: update } = useOdooAccountUpdate()

  // Project hooks.
  const { projectSlug } = useParams()
  const { data: projects, status: projectsStatus } = useProjects()
  const project = useProject() // Current project (by slug, if any).

  /**
   * Ensure login.
   */
  useEffect(() => {
    ;(async () => {
      let authorized = true

      /**
       * Unauthorized if the login was made for another version of the app.
       */
      if (
        localStorage.getItem(localStorageKeys.version) !==
        import.meta.env.VITE_VERSION
      ) {
        authorized = false
      }

      const token = await getSSOToken()
      if (!token || isTokenExpired(token)) {
        authorized = false
      }

      if (meStatus === 'error') {
        authorized = false
      }

      if (!authorized) {
        await logout()
        navigate(`/${loginRouteMetadata.slug}`)
      } else if (location.pathname === '/') {
        navigate(`/${selectorRouteMetadata.slug}`)
      }
    })()
  }, [location.pathname, logout, meStatus, navigate])

  /**
   * Ensure authorization.
   */
  useEffect(() => {
    if (
      meStatus === 'loading' ||
      accountIsAdmin(me) || // Admins see everything.
      projectsStatus === 'loading'
    ) {
      return
    }

    if (!projects.length) {
      setError(
        <>
          You don't have any projects assigned to your account.
          <br /> Please, get in contact with us.
        </>
      )
      return
    }

    /**
     * Check access to the project being
     * requested in case it exists.
     */
    if (projectSlug && !project) {
      setError(
        <>
          You don't have access to this project/it doesn't exist.
          <br /> Please, get in contact with us if you don't think this is
          right.
        </>
      )
      return
    }

    const role = getAccountRole(me)
    if (
      role &&
      routeMetadata?.roles?.length &&
      !routeMetadata.roles.includes(role)
    ) {
      setError(
        <>
          You don't have access to this page.
          <br /> Please, get in contact with us if you don't think this is
          right.
        </>
      )
    }
  }, [
    me,
    meStatus,
    project,
    projectSlug,
    projects.length,
    projectsStatus,
    routeMetadata?.roles,
  ])

  /**
   * Ensure route content.
   */
  useEffect(() => {
    if (!routeMetadata || !project) {
      return
    }

    if (routeMetadata.content && !routeMetadata.content(project)) {
      setError(
        <>
          No content available.
          <br /> Please, get in contact with us if you don't think this is
          right.
        </>
      )
    }
  }, [project, routeMetadata])

  /**
   * Set last read.
   */
  useEffect(() => {
    if (!project || !routeMetadata?.content) {
      return
    }

    lastRead.set(
      lastRead.buildKey(project.slug.current, routeMetadata.slug),
      routeMetadata.content(project)
    )
  }, [project, routeMetadata])

  /**
   * Send last read + changelog last read to Odoo.
   */
  useEffect(() => {
    // Some accounts don't support metadata (e.g. admins).
    if (!me?.metadata) {
      return
    }

    const { lastRead: lastReadKey, changelogLastRead: changelogLastReadKey } =
      localStorageKeys

    // Last read.
    const handleLastRead = () => {
      update({
        id: me.id,
        email: me.email,
        metadata: {
          last_read: JSON.stringify(getLocalStorageJSON(lastReadKey)),
        },
      })
    }
    window.addEventListener(lastReadKey, handleLastRead)

    // Changelog last read.
    const handleChangelogLastRead = () => {
      update({
        id: me.id,
        email: me.email,
        metadata: {
          changelog_last_read: JSON.stringify(
            getLocalStorageJSON(changelogLastReadKey)
          ),
        },
      })
    }
    window.addEventListener(changelogLastReadKey, handleChangelogLastRead)

    return () => {
      window.removeEventListener(lastReadKey, handleLastRead)
      window.removeEventListener(changelogLastReadKey, handleChangelogLastRead)
    }
  }, [me, update])

  /**
   * Session start Google Analytics events.
   */
  useEffect(() => {
    if (!me?.email) {
      return
    }

    const key = 'session'
    const email = me.email

    if (!sessionStorage.getItem(key) && email) {
      sendEvent(`session_${email}`)
      sessionStorage.setItem(key, new Date().getTime().toString())
    }
  }, [me])

  if (meStatus === 'loading' || projectsStatus === 'loading') {
    return <CenteredSpinner in={true} />
  }

  if (error) {
    return <FatalErrorMessage text={error} />
  }

  return (
    <>
      <Outlet />
      <LegalDialog />
    </>
  )
}

export default RootRoute
