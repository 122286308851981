import { Collapse, tableCellClasses, tableRowClasses } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import Dwellings from 'components/organisms/dwellings'
import DwellingsInfoFields from 'components/organisms/dwellings/DwellingsInfoFields'
import ProjectStripMinimizable from 'components/organisms/project/ProjectStripMinimizable'
import ProjectFocusContext from 'contexts/ProjectFocusContext'
import useProjects from 'hooks/useProjects'
import DwellingExtended from 'interfaces/dwelling/extended'
import DwellingFilter from 'interfaces/dwelling/filter'
import Project from 'interfaces/project'
import { useContext } from 'react'
import { getAvailableDwellings } from 'utils/dwelling'
import {
  dwellingFiltersActive,
  dwellingSatisfiesFilters,
} from 'utils/dwelling/filters'
import ShareSeeMore from './ShareSeeMore'

const getDwellings = (project: Project, filters: DwellingFilter[]) => {
  let dwellings = getAvailableDwellings(project)

  // If there are no filters active, return them.
  if (!dwellingFiltersActive(filters)) {
    return dwellings
  }

  // Otherwise return them filtered.
  return dwellings.filter((dwelling) =>
    dwellingSatisfiesFilters(dwelling, filters)
  )
}

const getDisabledReason = (project: Project, filters: DwellingFilter[]) => {
  let dwellings = getAvailableDwellings(project)

  if (!dwellings.length) {
    return 'No units available'
  }

  if (dwellingFiltersActive(filters)) {
    return 'No units match the current filters'
  }
}

type Props = {
  filters: DwellingFilter[]
  onSelect?: (selected: boolean, dwelling: DwellingExtended) => void
}

const ShareDwellingsProjects = ({ filters, onSelect }: Props) => {
  const { data: projects } = useProjects()
  const { value: focusedProject } = useContext(ProjectFocusContext)
  const filtersActive = dwellingFiltersActive(filters)

  return (
    <>
      {projects
        .filter((project) => !project.hidden)
        .map((project, index) => {
          const dwellings = getDwellings(project, filters)
          const disabled = !dwellings.length
          const disabledReason = disabled
            ? getDisabledReason(project, filters)
            : undefined
          const show = focusedProject ? focusedProject.id === project.id : true
          const sx: SxProps<Theme> = show
            ? {
                mb: 3,
                '&:last-child': {
                  mb: 0,
                },
              }
            : {}

          return (
            <Collapse key={index} in={show} sx={sx}>
              <ProjectStripMinimizable
                project={project}
                sideContent={
                  !disabled && <DwellingsInfoFields project={project} />
                }
                collapseTitle="Units list"
                collapseContent={
                  !!dwellings?.length && (
                    <Dwellings
                      fields={project.dwellings?.fields?.table ?? []}
                      dwellings={dwellings}
                      action="select"
                      onSelect={onSelect}
                      sx={{
                        /**
                         * Removes rounded top-left/top-right borders from the head of the table.
                         */
                        [`& .${tableRowClasses.head} .${tableCellClasses.root}:first-of-type`]:
                          {
                            borderTopLeftRadius: 0,
                          },
                        [`& .${tableRowClasses.head} .${tableCellClasses.root}:last-of-type`]:
                          {
                            borderTopRightRadius: 0,
                          },
                      }}
                    />
                  )
                }
                collapseForceOpen={filtersActive}
                disabled={disabled}
                disabledReason={disabledReason}
                in={show}
              />
            </Collapse>
          )
        })}

      <ShareSeeMore />
    </>
  )
}

export default ShareDwellingsProjects
