import { Box, CircularProgress, Stack, StackProps } from '@mui/material'
import React from 'react'

type Props = {
  children: React.ReactNode
  loading: boolean
  loaderAlignItems?: StackProps['alignItems']
  loaderJustifyContent?: StackProps['justifyContent']
}

const LoadingBox = ({
  children,
  loading,
  loaderAlignItems = 'center',
  loaderJustifyContent = 'center',
}: Props) => {
  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <Stack
          alignItems={loaderAlignItems}
          justifyContent={loaderJustifyContent}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress color="secondary" size="1.5rem" />
        </Stack>
      )}

      <Box
        sx={{
          opacity: (theme) =>
            loading ? theme.palette.action.disabledOpacity : 1,
          transition: (theme) => theme.transitions.create('opacity'),
          pointerEvents: loading ? 'none' : 'auto',
        }}
      >
        {children}
      </Box>
    </div>
  )
}

export default LoadingBox
