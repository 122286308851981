import { Stack } from '@mui/material'
import DwellingDetailTitle from 'components/molecules/dwellings/detail/DwellingDetailTitle'
import DwellingCard from 'components/organisms/dwellings/DwellingCard'
import Project from 'interfaces/project'
import { getExtendedDwellings } from 'utils/dwelling'

type Props = {
  project: Project
}

const ShareSectionDwellings = ({ project }: Props) => {
  const dwellings = getExtendedDwellings(project)

  return (
    <Stack spacing={3}>
      {dwellings.map((dwelling) => (
        <DwellingCard
          key={dwelling.id}
          dwelling={dwelling}
          title={<DwellingDetailTitle dwelling={dwelling} />}
        />
      ))}
    </Stack>
  )
}

export default ShareSectionDwellings
