import SlimChip from 'components/atoms/SlimChip'

type Props = {
  pending: boolean
  onChange: (value: boolean | null) => void
}

const AccountsFilterPending = ({ pending, onChange }: Props) => {
  return (
    <SlimChip
      clickable
      size="small"
      variant={pending ? 'filled' : 'outlined'}
      label="Pending"
      onClick={() => {
        onChange(!pending)
      }}
    />
  )
}

export default AccountsFilterPending
