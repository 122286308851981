import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { SvgIconProps, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import ConfirmDialogWithIcon from 'components/organisms/ConfirmDialogWithIcon'
import { queryKeys } from 'constants/queries'
import useOdooAccountDelete from 'hooks/odoo/account/useOdooAccountDelete'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import Account from 'interfaces/account'

type Props = {
  account: Account
  fontSize?: SvgIconProps['fontSize']
}

const AccountActionDelete = ({ account, fontSize }: Props) => {
  const queryClient = useQueryClient()
  const { data: me } = useOdooAccountMe()
  const { mutateAsync: deleteFn } = useOdooAccountDelete()
  const myself = me?.email === account.email

  const handleDelete = async () => {
    await deleteFn({ id: account.id })

    queryClient.invalidateQueries(queryKeys.accounts)
  }

  if (myself) {
    return null
  }

  return (
    <ConfirmDialogWithIcon
      icon={<DeleteOutlineOutlinedIcon fontSize={fontSize} />}
      title="Delete account"
      description={
        <>
          <Typography variant="body2" sx={{ mb: 1.5 }}>
            You are about to delete a user account. Doing this will revoke
            access to all projects for said account and the credentials will no
            longer be able to log in.
          </Typography>

          <Typography variant="body2" sx={{ mb: 3 }}>
            This message is just a way to check you want to do so.
          </Typography>
        </>
      }
      action="Delete"
      handleConfirm={handleDelete}
    />
  )
}

export default AccountActionDelete
