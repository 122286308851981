import Dwelling from 'interfaces/dwelling'
import Project from 'interfaces/project'
import { getDwellingsMediaByNomenclature } from 'utils/dwelling'
import { mapImageSrcsToImages } from 'utils/images'

const useDwellingMediaFloorPlans = (dwelling: Dwelling, project?: Project) => {
  if (!project) {
    return []
  }

  return getDwellingsMediaByNomenclature(
    [dwelling],
    mapImageSrcsToImages(project.resources?.floorPlans?.data),
    project.resources?.floorPlans?.nomenclatureSegment
  )
}

export default useDwellingMediaFloorPlans
