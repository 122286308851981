import { Typography } from '@mui/material'
import Lightbox from 'components/atoms/Lightbox'
import useAddImagesDimensions from 'hooks/useAddImagesDimensions'
import Image from 'interfaces/image'

type Props = {
  title?: string
  image: Image
}

const OverviewMasterPlan = ({ title, image }: Props) => {
  const [imagesWithDimensions] = useAddImagesDimensions(image)

  const imageWithDimensions = !!imagesWithDimensions.length
    ? imagesWithDimensions[0]
    : null

  if (!imageWithDimensions) {
    return null
  }

  return (
    <>
      {!!title && (
        <Typography sx={{ mb: 3 }} variant="h4" component="h2">
          {title}
        </Typography>
      )}

      <Lightbox
        id="overview-master-plan"
        image={imageWithDimensions}
        alt="Overview master plan"
      />
    </>
  )
}

export default OverviewMasterPlan
