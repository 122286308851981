import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Collapse,
  Container,
  Grid,
  Typography,
} from '@mui/material'
import Logo from 'components/atoms/Logo'
import PasswordTextField from 'components/atoms/PasswordTextField'
import FatalErrorMessage from 'components/organisms/FatalErrorMessage'
import { accountLoginRedirectTimeoutMs } from 'constants/account'
import { mailSubjects, mailTemplates } from 'constants/account/mail'
import { errorMessages } from 'constants/errors'
import useOdooAccountUpdate from 'hooks/odoo/account/useOdooAccountUpdate'
import useMail from 'hooks/useMail'
import AccountPasswordResetToken from 'interfaces/account/tokens/password-reset-token'
import jwtDecode from 'jwt-decode'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const AccountPasswordReset = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const { send } = useMail()
  const { mutateAsync: update } = useOdooAccountUpdate()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [done, setDone] = useState(false)

  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const isValid = password === repeatPassword && password.trim() !== ''

  const tokenDecoded = token
    ? (() => {
        try {
          return jwtDecode<AccountPasswordResetToken>(token)
        } catch (error) {
          console.error(error)
        }
      })()
    : false

  if (!tokenDecoded) {
    return (
      <FatalErrorMessage
        hideLogoutButton
        text="The password reset link is not valid anymore. Ask us for a new one"
      />
    )
  }

  const handleReset = async () => {
    setLoading(true)

    try {
      const response = await update({
        id: tokenDecoded.id,
        email: tokenDecoded.email,
        metadata: {},
        password,
      })

      if (!response?.updated) {
        throw new Error(errorMessages.odooAccountUpdate)
      }

      // Send a mail notifying about the password reset.
      await send(
        [tokenDecoded.email],
        mailSubjects.accountPasswordReset,
        mailTemplates.accountPasswordReset,
        {
          name: tokenDecoded.name,
          year: new Date().getFullYear(),
        }
      )

      setSuccess(
        `Password updated successfully. We'll be redirecting you to the log in page shortly`
      )
      setDone(true)

      setTimeout(() => {
        navigate('/')
      }, accountLoginRedirectTimeoutMs)
    } catch (error: any) {
      setError(error.message)
    }

    setLoading(false)
  }

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && isValid && !done) {
      handleReset()
    }
  }

  return (
    <Container>
      <Grid
        container
        spacing={3}
        sx={{ height: '100vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              marginBottom: 6,
            }}
          >
            <Logo />
          </Box>

          <Typography variant="h6" component="h1" mb={3}>
            Reset your password
          </Typography>

          <Box sx={{ marginBottom: 3 }}>
            <PasswordTextField
              id="password"
              label="Password"
              disabled={done}
              password={password}
              onChange={(e) => {
                setError('')
                setPassword(e.target.value)
              }}
              onKeyUp={handleKeyUp}
            />
          </Box>

          <Box sx={{ marginBottom: 3 }}>
            <PasswordTextField
              id="repeat-password"
              label="Repeat password"
              disabled={done}
              password={repeatPassword}
              onChange={(e) => {
                setError('')
                setRepeatPassword(e.target.value)
              }}
              onKeyUp={handleKeyUp}
            />
          </Box>

          <Collapse in={error !== ''} timeout="auto">
            <Alert sx={{ marginBottom: 3 }} severity="error">
              {error}
            </Alert>
          </Collapse>

          <Collapse in={error === '' && success !== ''} timeout="auto">
            <Alert sx={{ marginBottom: 3 }} severity="success">
              {success}
            </Alert>
          </Collapse>

          <Box sx={{ textAlign: 'right' }}>
            <LoadingButton
              disableElevation
              variant="contained"
              disabled={!isValid || done}
              onClick={handleReset}
              loading={loading}
            >
              Reset
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AccountPasswordReset
