import { useQuery } from '@tanstack/react-query'
import { errorMessages } from 'constants/errors'
import { queryKeys } from 'constants/queries'
import OdooAccountsGetResponse from 'interfaces/odoo/account/get-response'
import useOdooAdmin from '../useOdooAdmin'

type TQueryFnData = OdooAccountsGetResponse['result']
type TError = Error

const useOdooAccountsGet = () => {
  const { data: admin } = useOdooAdmin()

  return useQuery<TQueryFnData, TError>(
    queryKeys.accounts,
    async (): Promise<TQueryFnData> => {
      const response = await fetch(
        import.meta.env.VITE_ODOO_ACCOUNTS_API_GET_ENDPOINT,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${admin!.data.token}`,
          },
          body: JSON.stringify({
            params: {
              args: [],
            },
          }),
        }
      )

      const { result, error }: OdooAccountsGetResponse = await response.json()

      if (!response.ok || result?.error || error) {
        throw new Error(error?.data.message ?? errorMessages.odooAccountsGet)
      }

      return result
    },
    {
      enabled: !!admin,
    }
  )
}

export default useOdooAccountsGet
