import { Typography } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

type Props = {
  value: string
  sx?: SxProps<Theme>
}

const Subtitle = ({ value, sx }: Props) => (
  <Typography variant="subtitle2" sx={sx}>
    {value}
  </Typography>
)

export default Subtitle
