import '@fontsource/work-sans/300.css'
import '@fontsource/work-sans/400.css'
import '@fontsource/work-sans/500.css'
import '@fontsource/work-sans/700.css'
import CssBaseline from '@mui/material/CssBaseline'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ThemeProviderWithGlobalFontFamily from 'components/atoms/ThemeProviderWithGlobalFontFamily'
import ScrollToTop from 'components/helpers/ScrollToTop'
import MaintenanceGuard from 'guards/MaintenanceGuard'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import routesMetadata, {
  accountEnableRouteMetadata,
  accountPasswordResetRequestRouteMetadata,
  accountPasswordResetRouteMetadata,
  accountPropertiesRouteMetadata,
  accountPropertiesSingleRouteMetadata,
  legalRouteMetadata,
  loginRouteMetadata,
  selectorRouteMetadata,
  shareRouteMetadata,
} from 'routes-metadata'
import NotFoundRoute from 'routes/404'
import ProjectPseudoindexRoute from 'routes/project/pseudoindex'
import ProjectRootRoute from 'routes/project/root'
import RootRoute from 'routes/root'
import 'styles/global.css'
import { theme } from 'styles/theme'

Sentry.init({
  dsn: 'https://905c97730b129ba4ecddee2e3d28f699@o4506812852076544.ingest.sentry.io/4506812868263936',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * 1000,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <CssBaseline />

    <ThemeProviderWithGlobalFontFamily theme={theme}>
      <QueryClientProvider client={queryClient}>
        <MaintenanceGuard>
          <BrowserRouter>
            <ScrollToTop />

            <Routes>
              <Route
                path={loginRouteMetadata.slug}
                element={loginRouteMetadata.component}
              />

              <Route
                path={legalRouteMetadata.slug}
                element={legalRouteMetadata.component}
              />

              <Route
                path={accountEnableRouteMetadata.slug}
                element={accountEnableRouteMetadata.component}
              />
              <Route
                path={accountPasswordResetRequestRouteMetadata.slug}
                element={accountPasswordResetRequestRouteMetadata.component}
              />
              <Route
                path={accountPasswordResetRouteMetadata.slug}
                element={accountPasswordResetRouteMetadata.component}
              />
              <Route
                path={accountPropertiesRouteMetadata.slug}
                element={accountPropertiesRouteMetadata.component}
              />
              <Route
                path={accountPropertiesSingleRouteMetadata.slug}
                element={accountPropertiesSingleRouteMetadata.component}
              />

              <Route
                path={shareRouteMetadata.slug}
                element={shareRouteMetadata.component}
              />

              <Route path="/" element={<RootRoute />}>
                <Route
                  path={selectorRouteMetadata.slug}
                  element={selectorRouteMetadata.component}
                />

                <Route path=":projectSlug" element={<ProjectRootRoute />}>
                  <Route index element={<ProjectPseudoindexRoute />} />

                  {routesMetadata.project.map((routeMetadata) => (
                    <Route
                      key={routeMetadata.slug}
                      path={routeMetadata.slug}
                      element={routeMetadata.component}
                    />
                  ))}
                </Route>
              </Route>

              <Route path="*" element={<NotFoundRoute />} />
            </Routes>
          </BrowserRouter>
        </MaintenanceGuard>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProviderWithGlobalFontFamily>
  </React.StrictMode>
)
