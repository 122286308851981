import { Box, Fade } from '@mui/material'
import Watermarked from 'components/atoms/Watermarked'
import CenteredSpinner from 'components/molecules/CenteredSpinner'
import ImageGalleryItemCaption from 'components/molecules/image-gallery/ImageGalleryItemCaption'
import useImgLoaded from 'hooks/useImgLoaded'
import VirtualTour from 'interfaces/virtual-tour'
import { useState } from 'react'
import VirtualTourDialog from './VirtualTourDialog'

type Props = {
  virtualTour: VirtualTour
}

// TODO: Add spacing config.
const VirtualTourItem = ({ virtualTour }: Props) => {
  const [hover, setHover] = useState(false)
  const [open, setOpen] = useState(false)
  const isBgImageLoaded = useImgLoaded(virtualTour.image)

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    setOpen(true)
  }

  return (
    <>
      <a
        href={virtualTour.url}
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleClick}
      >
        <div style={{ position: 'relative' }}>
          <Fade in={isBgImageLoaded}>
            <Watermarked>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  paddingBottom: '50%' /* Aspect ratio 2:1 */,
                  backgroundImage: virtualTour.image
                    ? `url(${virtualTour.image})`
                    : 'none',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  marginBottom: 3,
                }}
              >
                <Fade in={hover}>
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: (theme) => theme.custom.darkOverlayColor,
                      color: 'common.white',
                      display: 'flex',
                      alignItems: 'flex-end',
                    }}
                  >
                    <ImageGalleryItemCaption
                      title={virtualTour.title}
                      subtitle={virtualTour.subtitle}
                    />
                  </Box>
                </Fade>
              </Box>
            </Watermarked>
          </Fade>

          <CenteredSpinner in={!isBgImageLoaded} />
        </div>
      </a>

      <VirtualTourDialog
        virtualTour={virtualTour}
        open={open}
        setOpen={setOpen}
      />
    </>
  )
}

export default VirtualTourItem
