import SearchIcon from '@mui/icons-material/Search'
import { Dialog, DialogContent, Fade } from '@mui/material'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import SlideUp from 'components/atoms/SlideUp'
import DialogHeader from 'components/molecules/DialogHeader'
import useAllDwellingsTableFilters from 'hooks/dwelling/useAllDwellingsTableFilters'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useProjects from 'hooks/useProjects'
import useSettings from 'hooks/useSettings'
import { useState } from 'react'
import { getProjectsExtendedDwellings } from 'utils/dwelling'
import DwellingsFiltered from './DwellingsFiltered'

type Props = {
  iconButtonProps?: IconButtonProps
}

const DwellingsDialog = ({ iconButtonProps }: Props) => {
  const { data: projects, isLoading } = useProjects()
  const { data: settings } = useSettings()
  const { data: me } = useOdooAccountMe()
  const [open, setOpen] = useState(false)
  const dwellings = getProjectsExtendedDwellings(projects)
  const filters = useAllDwellingsTableFilters(dwellings, projects, me)

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true })

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
  }

  if (isLoading || !settings) return null

  const fields = settings.dwellings?.fields?.common ?? []

  return (
    <>
      <IconButton
        aria-label="All units"
        color="inherit"
        onClick={handleOpen}
        {...iconButtonProps}
      >
        <SearchIcon fontSize="medium" />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        fullScreen={mobile}
        TransitionComponent={mobile ? SlideUp : Fade}
      >
        <DialogContent>
          <DialogHeader
            titleId="all-units-list-title"
            title="All units"
            onCloseClick={handleClose}
          />

          <DwellingsFiltered
            initialFilters={filters}
            fields={fields}
            dwellings={dwellings}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DwellingsDialog
