import { localStorageKeys } from 'constants/local-storage'
import md5 from 'md5'
import RouteMetadataContent from 'types/route-metadata-content'
import { getLocalStorageJSON } from './local-storage'
import { sortRecursively } from './objects'

/**
 * Sets a last read value in local storage + dispatches event to sync with Odoo.
 *
 * @param key String key to identify content.
 * @param value The content itself, without stringifying or anything.
 */
const set = (key: string, value?: RouteMetadataContent) => {
  if (!value) {
    return
  }

  try {
    const items = getLocalStorageJSON(localStorageKeys.lastRead)

    items[key] = md5(JSON.stringify(sortRecursively(value)))

    localStorage.setItem(localStorageKeys.lastRead, JSON.stringify(items))
    window.dispatchEvent(new Event(localStorageKeys.lastRead))
  } catch (error) {
    console.warn(`Couldn't set last read`, error)
  }
}

/**
 * Checks if a last read value has changed compared to a new one.
 *
 * @param key String key to identify content.
 * @param value The content itself, without stringifying or anything.
 */
const changed = (key: string, value?: RouteMetadataContent) => {
  if (!value) {
    return false
  }

  try {
    const items = getLocalStorageJSON(localStorageKeys.lastRead)

    // If it doesn't exist, assume the user hasn't read the content.
    if (!items[key]) {
      return true
    }

    return items[key] !== md5(JSON.stringify(sortRecursively(value)))
  } catch (error) {
    console.warn(
      `Couldn't check if a last read value changed, assuming it didn't`,
      error
    )

    return false
  }
}

const buildKey = (projectSlug: string, routeSlug: string) => {
  return `${projectSlug}_${routeSlug}`
}

const lastRead = {
  set,
  changed,
  buildKey,
}

export default lastRead
