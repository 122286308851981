import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined'
import { Box, svgIconClasses } from '@mui/material'
import Status from 'types/status'

type Props = {
  idleIcon: React.ReactNode
  status: Status
  rotated?: boolean
}

const AccountChipIcon = ({ idleIcon, status, rotated }: Props) => {
  return (
    <Box
      component="span"
      sx={{
        display: 'flex',
        width: '1rem',
        height: '1rem',
        padding: 0.125,
        transform: !rotated ? 'rotate(0deg)' : 'rotate(180deg)',
        transition: (theme) =>
          theme.transitions.create('transform', {
            duration: theme.transitions.duration.short,
          }),

        [`& .${svgIconClasses.root}`]: {
          color: 'action.active',
          width: 'auto',
          height: 'auto',
        },
      }}
    >
      <>
        {status === 'idle' && idleIcon}
        {status === 'loading' && <PendingOutlinedIcon />}
        {status === 'success' && <CheckOutlinedIcon />}
        {status === 'error' && <ErrorOutlineOutlinedIcon />}
      </>
    </Box>
  )
}

export default AccountChipIcon
