import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined'
import { Box, Container, Stack, Typography } from '@mui/material'
import Content from 'components/atoms/Content'
import Iframe from 'components/atoms/Iframe'
import Watermarked from 'components/atoms/Watermarked'
import Notice from 'components/molecules/Notice'
import ProjectHeader from 'components/organisms/project/ProjectHeader'
import useProject from 'hooks/useProject'

const ProjectLibrary = () => {
  const project = useProject()

  if (!project) {
    return null
  }

  const brochure = project.resources?.brochure
  const qualitySpecs = project.resources?.qualitySpecs
  const optionsAndUpgrades = project.resources?.optionsAndUpgrades

  return (
    <>
      <ProjectHeader project={project} />

      <Container>
        <Content>
          <Stack spacing={8}>
            {!!brochure && (
              <Box>
                <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
                  Brochure
                </Typography>

                <Watermarked sizePercentage={25}>
                  <Iframe title="Brochure" src={brochure} />
                </Watermarked>
              </Box>
            )}

            {!!qualitySpecs && (
              <Box>
                <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
                  Quality Specs
                </Typography>

                <Watermarked sizePercentage={25}>
                  <Iframe title="Quality Specs" src={qualitySpecs} />
                </Watermarked>
              </Box>
            )}

            {!!optionsAndUpgrades && (
              <Box>
                <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
                  Options & Upgrades
                </Typography>

                <Watermarked sizePercentage={25}>
                  <Iframe title="Options & Upgrades" src={optionsAndUpgrades} />
                </Watermarked>
              </Box>
            )}

            {!brochure && !qualitySpecs && !optionsAndUpgrades && (
              <Notice
                message="No documents currently available, please come back later"
                icon={FolderOffOutlinedIcon}
              />
            )}
          </Stack>
        </Content>
      </Container>
    </>
  )
}

export default ProjectLibrary
