import { iframeId, url } from './config'

/**
 * Gets the iframe from the DOM, also creates + initializes it if needed.
 *
 * @returns The iframe element.
 */
export const getIframeEl = () => {
  let iframeEl = document.getElementById(iframeId) as HTMLIFrameElement

  if (iframeEl) {
    return iframeEl
  }

  iframeEl = document.createElement('iframe')

  iframeEl.id = iframeId
  iframeEl.src = url
  iframeEl.style.display = 'none'
  iframeEl.style.width = '0'
  iframeEl.style.height = '0'
  iframeEl.tabIndex = -1
  iframeEl.ariaHidden = 'true'

  iframeEl.dataset.connected = 'false'
  iframeEl.dataset.loaded = 'false'
  iframeEl.onload = () => {
    iframeEl.dataset.loaded = 'true'
  }

  document.body.appendChild(iframeEl)

  return iframeEl
}
