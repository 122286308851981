import { Box, Typography } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import Iframe from 'components/atoms/Iframe'
import Watermarked from 'components/atoms/Watermarked'
import CenteredSpinner from 'components/molecules/CenteredSpinner'
import ProjectHeader from 'components/organisms/project/ProjectHeader'
import useProject from 'hooks/useProject'
import Project from 'interfaces/project'
import { Suspense, lazy } from 'react'
import { threeDToolRouteMetadata } from 'routes-metadata'

/**
 * Gets the appropiate 3D tool (PlayCanvas
 * or Arcware), depending on availability.
 */
const getThreeDTool = (theme: Theme, project: Project) => {
  const arcwareShareId = project.resources?.threeDTool?.arcwareShareId
  const playCanvasUrl = project.resources?.threeDTool?.playCanvasUrl

  if (!!arcwareShareId) {
    const ArcwareClient = lazy(
      () => import('components/organisms/ArcwareClient')
    )

    return (
      <Watermarked sizePercentage={25}>
        <Suspense
          fallback={
            <Box
              sx={{
                height: theme.custom.fullHeightMinusHeader,
              }}
            >
              <CenteredSpinner in={true} />
            </Box>
          }
        >
          <ArcwareClient shareId={arcwareShareId} />
        </Suspense>
      </Watermarked>
    )
  }

  if (!!playCanvasUrl) {
    return (
      <Watermarked sizePercentage={25}>
        <Iframe
          title={threeDToolRouteMetadata.name}
          src={playCanvasUrl}
        />
      </Watermarked>
    )
  }

  return null
}

const ProjectThreeDTool = () => {
  const project = useProject()
  const theme = useTheme()

  if (!project) {
    return null
  }

  const threeDToolComponent = getThreeDTool(theme, project)

  return (
    <>
      <ProjectHeader project={project} />

      {threeDToolComponent || (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 6,
            width: '100%',
            height: theme.custom.fullHeightMinusHeader,
            textAlign: 'center',
          }}
        >
          <Typography>
            Sorry, the tool is not available. Get in contact with us if this
            persists.
          </Typography>
        </Box>
      )}
    </>
  )
}

export default ProjectThreeDTool
