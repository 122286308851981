import { Stack, Typography } from '@mui/material'
import SpacedDivider from 'components/atoms/SpacedDivider'
import LoadingImageGallery from 'components/organisms/LoadingImageGallery'
import useDwellingsMediaRenders from 'hooks/dwelling/media/useDwellingsMediaRenders'
import Project from 'interfaces/project'
import { getExtendedDwellings } from 'utils/dwelling'
import { getProjectRenders } from 'utils/project'

type Props = {
  project: Project
}

const ShareSectionRenders = ({ project }: Props) => {
  const dwellings = getExtendedDwellings(project)
  const dwellingsRenders = useDwellingsMediaRenders(dwellings, project)

  const renders = dwellingsRenders.length
    ? dwellingsRenders
    : getProjectRenders(project)

  if (!renders.length) {
    return null
  }

  return (
    <>
      <SpacedDivider />

      <Stack
        spacing={3}
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{ mb: 3 }}
      >
        <Typography variant="h6">Renders</Typography>

        <Typography variant="caption" sx={{ textAlign: 'right' }}>
          Intended for illustrative purposes only
        </Typography>
      </Stack>

      <LoadingImageGallery id="renders-gallery" images={renders} />
    </>
  )
}

export default ShareSectionRenders
