import { Grid } from '@mui/material'
import DwellingsFilterName from 'components/molecules/dwellings/DwellingsFilterName'
import useProjects from 'hooks/useProjects'
import DwellingFilter from 'interfaces/dwelling/filter'
import { memo, useState } from 'react'
import { getProjectsMinMaxValues } from 'utils/dwelling/filters'
import DwellingsFilterSlider from '../dwellings/DwellingsFilterSlider'

type Props = {
  onChange: (filters: DwellingFilter[]) => void
}

const ShareDwellingsFilters = ({ onChange }: Props) => {
  const { data: projects, isLoading: isProjectsLoading } = useProjects()
  const {
    bedroomsMinMax,
    pricesMinMax,
    totalUsableMinMax,
    totalConstructedMinMax,
  } = getProjectsMinMaxValues(projects)

  // TODO: Filter "type" and maybe add a switch or whatever for terrace.
  const [filters, setFilters] = useState<DwellingFilter[]>([
    {
      key: 'beds',
      name: 'Bedrooms',
      type: 'slider',
      min: bedroomsMinMax.min,
      max: bedroomsMinMax.max,
      value: [bedroomsMinMax.min, bedroomsMinMax.max],
    },
    {
      key: 'price',
      name: 'Price',
      type: 'slider',
      min: pricesMinMax.min,
      max: pricesMinMax.max,
      value: [pricesMinMax.min, pricesMinMax.max],
      step: 10000,
      format: 'currency',
    },
    {
      key: 'totalUsable',
      name: 'Total Usable',
      type: 'slider',
      min: totalUsableMinMax.min,
      max: totalUsableMinMax.max,
      value: [totalUsableMinMax.min, totalUsableMinMax.max],
      suffix: (
        <span>
          &nbsp;m<sup>2</sup>
        </span>
      ),
    },
    {
      key: 'totalConstructed',
      name: 'Total Constructed',
      type: 'slider',
      min: totalConstructedMinMax.min,
      max: totalConstructedMinMax.max,
      value: [totalConstructedMinMax.min, totalConstructedMinMax.max],
      suffix: (
        <span>
          &nbsp;m<sup>2</sup>
        </span>
      ),
    },
  ])

  if (isProjectsLoading) {
    return null
  }

  return (
    <Grid
      container
      spacing={{
        xs: 3,
        lg: 4,
      }}
    >
      {filters.map((filter) => {
        const handleChange = (event: Event, newValue: number | number[]) => {
          setFilters((prevFilters) => {
            const nextFilters = [...prevFilters]

            const index = nextFilters.findIndex(
              (nextFilter) => nextFilter.key === filter.key
            )
            if (index !== -1) {
              nextFilters[index].value = newValue as number[]
            } else {
              nextFilters.push({
                ...filter,
                value: newValue as number[],
              })
            }

            return nextFilters
          })
        }

        const handleChangeCommitted = () => {
          onChange(filters)
        }

        return (
          <Grid item key={filter.key} xs={12} sm={6} lg={3}>
            <DwellingsFilterName name={filter.name} />

            <DwellingsFilterSlider
              filter={filter}
              onChange={handleChange}
              onChangeCommitted={handleChangeCommitted}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default memo(ShareDwellingsFilters)
