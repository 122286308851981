import useProjectRoutesGetters from 'hooks/useProjectRoutesGetters'
import useProject from 'hooks/useProject'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { sendEvent } from 'utils/gtag'

/**
 * This route redirects to the actual index route retrieved by project
 * in case you land here by writing the URL yourself.
 */
const ProjectPseudoindexRoute = () => {
  const [projectIndexRouteSlug, setProjectIndexRouteSlug] = useState<
    string | null
  >()
  const navigate = useNavigate()
  const { projectSlug } = useParams()
  const project = useProject()
  const { getIndex } = useProjectRoutesGetters()

  // Get the available project routes and try setting the preferred one as index.
  useEffect(() => {
    if (!project) {
      return
    }

    const indexRoute = getIndex(project)

    if (!indexRoute) {
      return
    }

    // If there's no overview, just get the first one.
    setProjectIndexRouteSlug(indexRoute.slug)
  }, [getIndex, project])

  /**
   * Navigate to index route of the project, as there's
   * no actual "index" as in "just the /" route.
   */
  useEffect(() => {
    if (!projectIndexRouteSlug) {
      return
    }

    sendEvent(`page_view_${projectSlug}_${projectIndexRouteSlug}`)
    navigate(`/${projectSlug}/${projectIndexRouteSlug}`)
  }, [projectIndexRouteSlug, navigate, projectSlug])

  return null
}

export default ProjectPseudoindexRoute
