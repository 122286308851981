export const getLocalStorageJSON = (key: string) => {
  const itemsString = localStorage.getItem(key) ?? ''

  let items = null
  try {
    items = JSON.parse(itemsString)
  } catch (error) {
    items = {}
  }

  return items
}
