import { Stack } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Logo from 'components/atoms/Logo'
import AccountMenu from 'components/molecules/account/AccountMenu'
import DwellingsDialog from './dwellings/DwellingsDialog'
import DwellingsFavoritesDialog from './dwellings/DwellingsFavoritesDialog'
import ShareDialog from './share/ShareDialog'
import AccountPropertiesButton from 'components/atoms/AccountPropertiesButton'

const Header = () => {
  return (
    <AppBar
      sx={{
        top: 0,
        border: 'none',
        borderBottom: '2px solid',
        borderColor: 'grey.600',
        bgcolor: 'common.white',
      }}
      position="sticky"
      elevation={0}
    >
      <Toolbar sx={{ color: 'grey.600' }}>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Logo />
        </Box>

        <Stack
          direction="row"
          /**
           * This `alignItems` is required or else
           * buttons will stretch and not be round.
           */
          alignItems="center"
          spacing={{
            xs: 0,
            sm: 2,
          }}
        >
          <DwellingsDialog />
          <ShareDialog />
          <DwellingsFavoritesDialog />
          <AccountPropertiesButton />
          <AccountMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Header
