import { Button, Dialog, DialogContent, Stack } from '@mui/material'
import DialogHeader from 'components/molecules/DialogHeader'

type Props = {
  open: boolean
  title: string
  description: React.ReactNode
  action: string
  handleClose: () => void
  handleConfirm: () => void
}

const ConfirmDialog = ({
  open,
  title,
  description,
  action,
  handleClose,
  handleConfirm,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogContent>
        <DialogHeader
          titleId="confirm-dialog-title"
          title={title}
          onCloseClick={handleClose}
        />

        <div id="confirm-dialog-description">{description}</div>

        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Button
            disableElevation
            variant="outlined"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={() => {
              handleClose()
              handleConfirm()
            }}
          >
            {action}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmDialog
