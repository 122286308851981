import InteractiveSvgGroup from 'components/atoms/InteractiveSvgGroup'
import InteractiveTooltip from 'components/atoms/InteractiveTooltip'
import usePhotoSwipeLightbox from 'hooks/usePhotoSwipeLightbox'
import InteractiveSvgGroupWithTooltipType from 'interfaces/interactive-svg-group-with-tooltip'
import { memo, useState } from 'react'

const InteractiveSvgGroupWithTooltip = ({
  children,
  tooltipContent,
  activeId,
  needsRerender,
  ...rest
}: InteractiveSvgGroupWithTooltipType) => {
  const { loadingClass } = usePhotoSwipeLightbox()
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleTooltipClose = () => {
    /**
     * Avoids closing the tooltip in case a PhotoSwipe is open/loading.
     * If it closed, it would do so behind the gallery, without the
     * user seeing it happen.
     *
     * Specially useful for example in the interactive master plan.
     */
    const pswpExists = !!document.querySelector('.pswp')
    const pswpLoading = document
      .querySelector('body')
      ?.classList.contains(loadingClass)

    if (pswpExists || pswpLoading) {
      return
    }

    setTooltipOpen(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  return (
    <InteractiveTooltip
      title={tooltipContent}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      leaveDelay={150}
      open={tooltipOpen}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      arrow
      PopperProps={{
        /**
         * This tooltip content can likely contain a `Dialog` inside.
         *
         * If you don't keep this mounted, the inside dialog
         * will close when this unmounts.
         */
        keepMounted: true,
      }}
    >
      <InteractiveSvgGroup {...rest}>{children}</InteractiveSvgGroup>
    </InteractiveTooltip>
  )
}

export default memo(InteractiveSvgGroupWithTooltip, (prevProps, nextProps) => {
  /**
   * Compares that the current id is not an
   * active id to avoid re-renders.
   *
   * Also, allows rendering when needsRerender changes.
   */
  return (
    prevProps.id !== prevProps.activeId &&
    nextProps.id !== nextProps.activeId &&
    prevProps.needsRerender === nextProps.needsRerender
  )
})
