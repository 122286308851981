import { Typography } from '@mui/material'

const MarketingMaterialDisclaimer = () => (
  <Typography variant="caption">
    We respectfully request that you avoid posting this marketing material on
    real estate platforms and restrict their advertisement to solely your
    website and social media channels. We appreciate your comprehension and
    ongoing cooperation. If you have any inquiries or issues, feel free to reach
    out to us. Additionally, please note that if you intend to publish any of
    the material provided, it is mandatory to sign a collaboration agreement
    beforehand.
  </Typography>
)

export default MarketingMaterialDisclaimer
