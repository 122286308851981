/**
 * Tries normalizing to string if possible.
 */
export const normalizeToString = (value: any) => {
  if (typeof value === 'number') {
    return value.toString()
  }
  if (typeof value === 'string') {
    return value
  }
  return ''
}

/**
 * Tries normalizing to number if possible.
 */
export const normalizeToNumber = (value: any) => {
  if (typeof value === 'string') {
    return parseFloat(value)
  }
  if (typeof value === 'number') {
    return value
  }
  return 0
}
