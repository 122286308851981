import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Stack } from '@mui/material'
import Overline from 'components/atoms/Overline'

type Props = {
  open: boolean
  onChange: (newOpen: boolean) => void
  title?: string
}

const ProjectStripCollapseToggle = ({ open, onChange, title }: Props) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent={{
        xs: 'space-between',
        sm: 'flex-end',
      }}
      onClick={() => {
        onChange(!open)
      }}
      sx={{
        cursor: 'pointer',
      }}
    >
      {!!title && <Overline>{title}</Overline>}

      <ExpandMoreIcon
        fontSize="small"
        color="action"
        sx={{
          transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.short,
            }),
        }}
      />
    </Stack>
  )
}

export default ProjectStripCollapseToggle
