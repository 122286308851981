import { Box, Slider, Stack, Typography } from '@mui/material'
import DwellingFilter from 'interfaces/dwelling/filter'
import format from 'utils/format'

type Props = {
  filter: DwellingFilter
  onChange?: (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => void
  onChangeCommitted?: (
    event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => void
}

const DwellingsFilterSlider = ({
  filter,
  onChange,
  onChangeCommitted,
}: Props) => {
  const minValue = !!filter.format
    ? format(filter.format, filter.value[0])
    : filter.value[0]
  const maxValue = !!filter.format
    ? format(filter.format, filter.value[1])
    : filter.value[1]

  return (
    <>
      <Box
        sx={{
          // So the thumbs don't go that much outside it's container.
          px: 0.75,
        }}
      >
        <Slider
          size="small"
          valueLabelDisplay="auto"
          valueLabelFormat={(value: number) =>
            filter.format ? format(filter.format, value) : value
          }
          min={filter.min}
          max={filter.max}
          value={filter.value}
          step={filter.step}
          getAriaLabel={() => filter.name}
          onChange={onChange}
          onChangeCommitted={onChangeCommitted}
        />
      </Box>

      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="caption">
          {minValue}
          {!!filter.suffix && filter.suffix}
        </Typography>

        <Typography variant="caption">
          {maxValue}
          {!!filter.suffix && filter.suffix}
        </Typography>
      </Stack>
    </>
  )
}

export default DwellingsFilterSlider
