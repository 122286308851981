import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { Dialog, DialogContent, SvgIconProps } from '@mui/material'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import DialogHeader from 'components/molecules/DialogHeader'
import AccountUpdateProjectsPermissions from 'components/organisms/account/AccountUpdateProjectsPermissions'
import useOdooAccountMe from 'hooks/odoo/account/useOdooAccountMe'
import useProjects from 'hooks/useProjects'
import Account from 'interfaces/account'
import { useState } from 'react'
import { accountCanShare, accountIsAdmin } from 'utils/account/role'

type Props = {
  account: Account
  fontSize?: SvgIconProps['fontSize']
  iconButtonProps?: IconButtonProps
}

const AccountActionProjectsPermissions = ({
  account,
  fontSize,
  iconButtonProps,
}: Props) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { data: projects } = useProjects()
  const { data: me } = useOdooAccountMe()

  if (
    !projects ||
    /**
     * Currently logged in account
     * (me) must be able to share.
     */
    !accountCanShare(me) ||
    /**
     * The account for which the action
     * is rendered shouldn't be admin.
     */
    accountIsAdmin(account)
  ) {
    return null
  }

  return (
    <>
      <IconButton
        aria-label="Update account projects permissions"
        onClick={handleOpen}
        {...iconButtonProps}
      >
        <SettingsOutlinedIcon fontSize={fontSize} />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="account-action-projects-permissions-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <DialogHeader
            titleId="account-action-projects-permissions-dialog-title"
            title="Update projects shared"
            onCloseClick={handleClose}
          />

          <AccountUpdateProjectsPermissions account={account} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AccountActionProjectsPermissions
